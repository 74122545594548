import React, { useEffect, useState } from "react";
import SentIcon from "../../assets/icons/SentIcon";
import ImportantIcon from "../../assets/icons/ImportantIcon";
import { HideDropdown, _l, accessPremiumFeature, showContactDirectory, showError, updateBurgerMenuIDB } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import AddEditCategory from "../Modals/AddEditCategory";
import ReactHtmlParser from "react-html-parser";
import {
  leftPanelFilters,
  projectTaskCounts,
  getSelectedTask,
  getDefaultTopics,
  updateProjectTopics,
  setFilterBenchmarkCategory,
  setCreateScheduledetails,
  getTopicTasks,
  setVirtualSpaceList,
  getDetailForLoginAsUser,
  getNewCustomer,
  openChatAiInput,
  setSpaceNotificationCount,
  filterFor3Dmodel,
  setSelectedDateForChatai,
  setFetchedTaskandMessages,
  updateTaskList,
  setSelectedGloablView,
  chatUnreadMessageCount,
  unreadTaskComment,
  unReadCommentTasks,
  setVirtualSpaceNotificationCount,
  clearChatTaskList,
  setCallRecording,
  setCallDisconnect,
  accordionsEventKey,
  clearDefaultTopicsList,
  setTenatsRoleId,
  setTentantsEmployeeData,

} from "../../actions/customer";
import {
  fetchMessages,
  fetchUnreadMsgCount,
  getGroupDetails,
  groupIn,
  groupOut,
  initCall,
  setClearmessage,
  setIDofFirstMessage,
  setOpenGeneralChatGroup
} from "../../actions/chat";
import Accordion from "react-bootstrap/Accordion";
import TaskDetail from "../TaskDetail";
import jquery from "jquery";
import { useNavigate } from "react-router-dom";
import AddNewCategory from "../Modals/AddNewCategory";
import moment from "moment";
import CategoryProviderList from "../Modals/CategoryProviderList";
import Modal from "react-bootstrap/Modal";
import CreateTeam from "../Modals/CreateTeam";
import AddEmployee from "../Modals/AddEmployee";
import GeneralListingWithCheckbox from "../GeneralListingWithCheckbox";
import SelectProviderCategory from "../Modals/SelectProviderCategory";
import CustomerServices from "../../services/customer-services";
import AddOperator from "../Modals/AddOperator";
 
import AddNewMailCustomCategory from "../Modals/AddNewMailCustomCategory";
import AdminServices from "../../services/admin-services";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import SlackDiscordModal from "../Modals/SlackDiscordModal";
import { CALENDAR_TOPICS, DISCORD_TOPICS, GET_TOPICS_TASK, SLACK_TOPICS } from "../../actions/action-type";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import AddEditEquipment from "../Modals/AddEditEquipment";
import AddProvider from "../Modals/AddProvider";
import TopicSettingModal from "../Modals/TopicSettingModal";
import customerServices from "../../services/customer-services";
import chatServices from "../../services/chat-services";
import { ATTACH_BASE_URL, USR_IMG_PLACEHOLDER } from "../../actions/chat-action-type";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import $ from 'jquery'
import PremiumEstimateIconCrown from "../../assets/icons/PremiumEstimateIconCrown";
import PlusIcon from "../../assets/icons/PlusIcon";
import { insertCollectionToDB, readCollectionFromDB } from "../../hooks/indexed_db_helpers";
import Typing from "../Typing/Typing";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import outlookCalendar from "../../services/outlook-calendar";
import slackServices from "../../services/slack-services";
import discordServices from "../../services/discord-services";
import googleCalendar from "../../services/google-calendar";
import { is_discord_connected } from "../../actions/discord_auth";
import { is_google_drive_connected , is_onedrive_connected} from "../../actions/google-drive";
import { is_googlecalendar_connected } from "../../actions/googlecalendar_auth";
import FileIcon from "../../assets/icons/FileIcon";
import PencilSimpleLineIcon from "../../assets/icons/PencilSimpleLineIcon";
import { MAIL_TOPIC } from "../../constants/constants";
import { DEFAULT_TOPICS_WITH_EMAIL } from "../../constants/constants";
import { startCall } from "../../actions/vapi";
import LeftPanelCategorySkeleton from "../Skeleton/LeftPanelCategorySkeleton";
import LeftPanelCategoryHeaderSkeleton from "../Skeleton/LeftPanelCategoryHeaderSkeleton";
import UsersThreeIcon from "../../assets/icons/UsersThreeIcon";
import GearSixIcon from "../../assets/icons/GearSixIcon";
import EyeIcon from "../../assets/icons/EyeIcon";
import ChartLineIcon from "../../assets/icons/ChartLineIcon";
import HashIcon from "../../assets/icons/HashIcon";
import Tray from "../../assets/icons/Tray";
import TagChevron from "../../assets/icons/TagChevron";
import PaperPlanRight from "../../assets/icons/PaperPlanRight";
import ShareNetworkIcon from "../../assets/icons/ShareNetworkIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import ListIcon from "../../assets/icons/LinkIcon";
import UserIcon from "../../assets/icons/UserIcon";
import UserCircleGearIcon from "../../assets/icons/UserCircleGearIcon";
import CalendarCheckIcon from "../../assets/icons/CalendarCheckIcon";
import DotsThreeVerticalIcon from "../../assets/icons/DotsThreeVerticalIcon";
import PhoneCallIcon from "../../assets/icons/PhoneCallIcon";
import BuildingsIcon from "../../assets/icons/BuildingsIcon";
import WarningOctagonIcon from "../../assets/icons/WarningOctagonIcon";

const TwoStepAccordians = ({
  projectId,
  setTodoTasksCount = () => {},
  setInProgressTasksCount = () => {},
  setCompletedTasksCount = () => {},
  searchCategory,
  pageName = null,
  setShowProviderDropDown,
  setSelectOperators,
  allMAilMessages,
  allImportantMessages,
  createTeamModal,
  setCreateTeamModal,
  equipmentModal,
  setEquipmentModal,
  selectAdminTeam,
  handleEditAdminTeam,
  topicType,
  setTopicType,
  selectedTeamForEdit,
  setSelectedTeamForEdit,
  showAddTeamMember,
  setshowAddTeamMember,
  adminTeamList,
  setAdminTeamList,
  searchTopic,
  setShowForMeSection =()=>{},
  showForMeSection,
  contactlistShow =false,
  contactDirData,
  contactDirTopicChange = ()=>{},
  tenantesList= [],
  spacesList= [],
}) => {
  const Url = window.location.href;


  const [topicData, setTopicData] = useState([]);
  const [defaultTopicData, setDefaultTopicData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [show, setShow] = useState(false);
  const [activeTopicKey, setActiveTopicKey] = useState("");
  const [activeAccordianKeys, setActiveAccordianKeys] = useState([]);
  const [topicTaskFilter, setTopicTaskFilter] = useState(taskFilters);
  const [searchString, setSearchString] = useState("");
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddTopicTask, setShowAddTopicTask] = useState(false);
  const [showAddProviderCategory, setShowAddProviderCategory] = useState(false);
  const [showAddProvider, setShowAddProvider] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showAddPeople, setShowAddPeople] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [showInviteAssigneeModal, setShowInviteAssigneeModal] = useState(false);
  const [categoryOperators, setCategoryOperators] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [showAddMailCustomCategory, setShowAddMailCustomCategory] =
    useState(false);
    const isVirtualSpace = localStorage.getItem("selectedVirtualSpace") ? true : false ;
  const handleAMCCClose = () => setShowAddMailCustomCategory(false);
  const { mailFilters, get_language, newCusData, pinDelayfilter, groupDetails, accordionEventKey, leftPanelTab, agentList, tenantsEmployeeData } = useSelector((state) => state.customer);
  const {groupList, newMessage, burgerMenuTopicTyping, bergerMenuTopicAnalysis } = useSelector((state) => state.chat);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
 
  const equipmentTopics = useSelector((state) => state.customer.equipmentTopics);
  const [inboxMails] = useState([]);
  const [customTopicTitle, setCustomTopicTitle] = useState("");
  const [customTopicMail, setCustomTopicMail] = useState("");
  const [topicId, setTopicId] = useState(0);
  const isLeadPage = pageName == "leadPage" ? 1 : 0;
  const pathname = window.location.pathname;
  const [randomID] = useState(String(Math.random()));
  JSON.parse(localStorage.getItem("selectedCalendarIds")) != null
    ? JSON.parse(localStorage.getItem("selectedCalendarIds"))
    : localStorage.setItem("selectedCalendarIds", JSON.stringify([]));
  const [equipmentDetail, setEquipmentDetail] = useState("");
  const [isEditTopic, setIsEditTopic] = useState({});
  const [selectedEmployeeData, setSelectedEmployeeData] = useState("")
  const contact_role = localStorage.getItem("contact_role");
  const [inv_topic, setInv_topic] = useState([]);
  const [est_topic, setEst_topic] = useState([]);
  const [pro_topic, setPro_topic] = useState([]);
  const [space_topic, setSpace_topic] = useState([]);
  const [cont_topic, setCont_topic] = useState([]);
  const [gr_topic, setGr_topic] = useState([]);
  const [cus_topic, setCus_topic] = useState([]);
  const [providerListingCategory, setProviderListingCategory] = useState({id: 0, title: "", main_key: "", is_active: "", main_key_title: "", main_sub_key: ""});
  const [showGearIcon, setShowGearIcon] = useState(0);
  const [selectedTopicIds, setSelectedTopicIds] = useState([])
  var chatUserId = localStorage.getItem("chatUserId");
  const [groupsList, setGroupsList] = useState([])
  const [showAddCategoryForMe, setshowAddCategoryForMe] = useState(false);
  const [showSlack, setShowSlack] = useState(false);
  const rel_id =
  localStorage.getItem("user_type") === "staff"
    ? localStorage.getItem("staff_id")
    : localStorage.getItem("client_id");
  const rel_type = localStorage.getItem("user_type");
  const [allow, setAllow] = useState(0);
  
  let isRegisterUser = localStorage.getItem("space_setup_pending"); 
  const adminAsStaff = localStorage.getItem('adminArchieve') && localStorage.getItem('adminArchieve') ? true : false;
  var activeGroupId = localStorage.getItem("activeGroupId") ? localStorage.getItem("activeGroupId") : "";
  const selectedTopics = JSON.parse(localStorage.getItem("selectedTopic"))
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [isSkeltonShow, setIsSkeltonShow] = useState(true);
  const [SelectUser, setSelectUser] = useState(true);
  let activeUser = localStorage.getItem('activeGroupId');
 
  useEffect(() => {
    setGroupsList(groupList)
    let temptopic = topicData
    if (groupList && temptopic) {
      groupList.forEach((group) => {
        temptopic &&  temptopic.forEach((topic) => {      
            if(group.extraData.mainKey == "myr_support"){
              topic.categories.forEach((category) => {
                if(category.filter_key == "myrai_support"){
                  let unreadCount = groupList.filter((item) => item.extraData.filterKey == localStorage.getItem("staff_id"))
                  unreadCount =unreadCount[0] != undefined ?  unreadCount[0].unreadCount : 0
                  if (unreadCount > 0) {
                    category.total_noti_count = unreadCount 
                  } 
                }
              })
            }else if(group && group.extraData && group.extraData.mainKey && topic.main_key == group.extraData.mainKey){
            topic.categories.forEach((category) => {
              if(category.filter_key == group.extraData.filterKey){
                if(group.unreadCount > 0){
                  category.total_noti_count = group.unreadCount 
                }
              }
            }) 
          }
        })
      })      
    }
  }, [groupList, topicData, defaultTopics])

  useEffect(() => {
    let activeGroupId = localStorage.getItem("activeGroupId");
    var msgGroupId = newMessage ? newMessage.group : 0;
    let index = -1
    if (msgGroupId) {
      let temp = groupList
      if (msgGroupId) {
         index = temp && temp.findIndex((group) => group.groupId == msgGroupId)
        if (index > -1) {
          if (msgGroupId != activeGroupId) {
            temp[index].unreadCount = temp[index].unreadCount + 1
          }
        }
        setGroupsList(temp)
      }
      let temptopic = topicData
      if (index > -1) {
        let mainIndex = topicData && topicData.findIndex((topic) => topic.main_key == temp[index].extraData.mainKey)
        if (mainIndex > -1) {
          let tempdata = temptopic[mainIndex].categories
          let catIndex = tempdata && tempdata.findIndex((category) => category.title == newMessage.senderName)
          if (catIndex > - 1) {
            temptopic[mainIndex].categories[catIndex].total_notification_count.chat_count = temptopic[mainIndex].categories[catIndex].total_notification_count.chat_count + 1
          }
        }
      setTopicData(temptopic)
    }
  }
  }, [newMessage])
  
  useEffect(() =>{
    dispatch(accordionsEventKey(true));
  }, [accordionEventKey])
  useEffect(() =>{
    dispatch(getNewCustomer(1,1));
  }, [])
  useEffect(() => {
    if(newCusData)
    {
        setInv_topic(newCusData.inv_a);
        setEst_topic(newCusData.est_a);
        setPro_topic(newCusData.pro_a);
        setCus_topic(newCusData.cus_a);
        setSpace_topic(newCusData.space_a);
        setCont_topic(newCusData.cont_a);
        setGr_topic(newCusData.gr_a);
    }
  }, [newCusData])


  const hidePlusIcon = [
    "requested_task",
    "request_people",
    "request_from_users",
    "my_emails",
    "my_customers",
  ];
  const doNotNavigate = [
    "invoice",
    "MyEmail",
    "leadPage",
    "documents",
    "operatorListReporting",
    "equipmentListReporting",
    "customer",
    "credit_notes",
    "schedule",
    "benchmark",
    "Proposals",
    "subscription",
    "AdminTaskPage",
  ];
  const admin = localStorage.getItem("admin");
  const project_id = useSelector((state) => state.customer.selectedProject);
  const client_id = localStorage.getItem("client_id");
  const {
    taskFilters,
    defaultTopics,
    filterBenchmarkCategory,
    globalView,
    filterfor3dPlan
  } = useSelector((state) => state.customer);
  const handleClose = () => setShow(false);
  const userType = localStorage.getItem("user_type");
  const [showAddOperatorModal, setShowAddOperatorModal] = useState(false);
  const [heightWidth, setHeightWidth] = useState({});
  const [chatModalShow, setChatModalShow] = useState(false);
  const ChathandleClose = () => setChatModalShow(false);
  
  let IdName = "TimeSlotDropdownWithCheckboxHandle";
  const FullLeftPanelWidth = jquery(".fixed-left-panel").width();
  const estimate_topics = useSelector((state) => state.customer.estimateTopics);
  const staffTopics = useSelector((state) => state.customer.staffTopics);
  const [estimateTopics, setEstimateTopics] = useState([]);
  const [categoryListing, setCategoryListing] = useState();
  const [groupDetailsForCall, setGroupDetailsforCall] = useState({});

  if(pathname == '/estimate' || pathname == '/staff' || pathname == "/equipmentListing")
    {
      jquery('.my_task').addClass('d-none');
      jquery('.my_staff').addClass('d-none');
      jquery('.user_journey').addClass('d-none');
      jquery('.accordion-button').removeClass('collapsed');
      jquery('.accordion-collapse').removeClass('collapse');
    }
  useEffect(() => {
    if(estimate_topics)
    {
      let array = [];
      (estimate_topics.data.categories ? estimate_topics.data.categories : []).forEach(element => {
        if(element.is_active == 1)
        {
          array.push(element);
        }
      });
      setEstimateTopics(array);
    } 
     if(staffTopics)
    {
      let array = [];
      (staffTopics.data.categories ? staffTopics.data.categories : []).forEach(element => {
        if(element.is_active == 1)
        {
          array.push(element);
        }
      });
      setEstimateTopics(array);
    }
    if(equipmentTopics)
    {
      let array = [];
      (equipmentTopics.data.categories ? equipmentTopics.data.categories : []).forEach(element => {
        if(element.is_active == 1)
        {
          array.push(element);
        }
      });
      setEstimateTopics(array);
    }
  }, [estimate_topics, staffTopics, equipmentTopics])
  useEffect(() => {
    if (Object.keys(heightWidth).length) {
      IdName = "TimeSlotDropdownWithCheckboxHandle";

      let tempWidth = heightWidth.width;
      let tempHeight = heightWidth.height;
      if (
        heightWidth.width + jquery("#" + IdName).width() >
        FullLeftPanelWidth
      ) {
       
        tempWidth = 332;
      }
      if (heightWidth.height + jquery("#" + IdName).height() > 396) {
        tempHeight = 400;
      }

      jquery("#ProviderDropdownWithprogressBarDropDown").css({
        position: "absolute",
        left: tempWidth,
        top: Math.abs(tempHeight),
      });
    }
  }, [heightWidth]);

  useEffect(() => {
    /**Load team for Admin user */
    dispatch(getDetailForLoginAsUser(client_id, project_id));
    if (admin) {
      getAdminTeamList();
    }
    setTimeout(() => {
      setIsSkeltonShow(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (leftPanelTab !== "my-email" && topicData.length > 0) {
      setTopicData(topicData.filter((data) => ![ "my_emails"].includes(data.main_key)))
    }else if(leftPanelTab == "my-email" && topicData.length > 0) {
      let tempTopic = topicData.filter((data) => ["my_task","providers","my_contact", "my_emails"].includes(data.main_key))
      setTopicData(MAIL_TOPIC.concat(tempTopic && tempTopic.length > 0 ? tempTopic : []))
      dispatch(clearDefaultTopicsList(MAIL_TOPIC.concat(tempTopic && tempTopic.length > 0 ? tempTopic : [])));
    }
  }, [leftPanelTab])

  useEffect(() => {
    if (defaultTopics && defaultTopics.length > 0) {
      let Arr = []
      var main_key = userType == "contact" ? "providers" : "proposals";
      defaultTopics.map((item) => {
        if (item.main_key == main_key) {
          item.categories.map((item1) => {
            return Arr.push({
              value: parseInt(item1.filter_key),
              label: item1.title,
              is_default: item1.is_default,
            });
          })
        }
      });
      setCategoryListing(Arr)
    }
  }, [defaultTopics]);

  useEffect(() => {
    if (defaultTopics) {
      let categoriesArray = [];
      defaultTopics &&
        defaultTopics.map((topic) => {
          categoriesArray = [];
          (topic.categories) && Object.entries(topic.categories).forEach(([, v]) => {
            categoriesArray.push(v);
          });
          topic.categories = categoriesArray;
        });
      var topicData = defaultTopics;
      if(window.location.pathname == "/MyEmail" || leftPanelTab == "my-email"){
        topicData = topicData.filter((data) => ["my_task", "providers","my_contact", "my_emails"].includes(data.main_key))
      } else if("my-request" === leftPanelTab || "my-calendar" === leftPanelTab) {
        topicData = topicData.filter((data) => data.main_key === "my_task")
          topicData && topicData.categories && topicData.categories.filter((t,i)=> i == 0).map((dt) => { 
          localStorage.setItem("selectedTopic", JSON.stringify({id: dt.filter_key, title: dt.title, main_key: topicData.main_key , is_active: dt.is_active, main_key_title: topicData.title, main_sub_key: dt.main_sub_key, contact_role : dt.contact_role, total_notification_count: dt.total_notification_count, thread_id : dt.thread_id, filter_key: dt.filter_key,image :localStorage.getItem("contact_image") }))
        })
      } else if (contact_role == 3) {
        topicData = topicData.filter((data) =>["my_task", "providers","my_contact" ].includes(data.main_key))
      } else if (contact_role == "undefined" && userType == "staff") {
        if (leftPanelTab == "my-staff") {
          topicData = topicData.filter((filter) => filter.main_key == "my_staff")
        } else {
          topicData = topicData.filter((data) => ["myr_support", "my_task"].includes(data.main_key))
        }
      } else if(userType == "contact"){
        topicData = topicData.filter((data) => 
        ["my_task", "providers","my_contact","equipments", "my_emails"].includes(data.main_key))
      } else if(userType == "operator"){
        topicData = topicData.filter((data) => 
        ["my_task", "providers","my_operator","equipments", "my_emails"].includes(data.main_key))
      } else{
        topicData = topicData.filter((data) => data.main_key)
      }
      if (topicData.length > 0) {
          setTopicData(leftPanelTab == "my-staff" ? topicData.find((filter) => filter.main_key == "my_staff") ? topicData : MAIL_TOPIC.concat(topicData) : topicData );
      }

      setActiveAccordianKeys(
        defaultTopics &&
        defaultTopics.map((value, index) => {
          return index.toString();
        })
      );
      setDefaultTopicData(defaultTopics);
      setTodoTasksCount(
        defaultTopics &&
          defaultTopics.task_count &&
          defaultTopics.task_count.todo_count
          ? defaultTopics.task_count.todo_count
          : 0
      );
      setInProgressTasksCount(
        defaultTopics &&
          defaultTopics.task_count &&
          defaultTopics.task_count.ongoing_count
          ? defaultTopics.task_count.ongoing_count
          : 0
      );
      setCompletedTasksCount(
        defaultTopics &&
          defaultTopics.task_count &&
          defaultTopics.task_count.completed_count
          ? defaultTopics.task_count.completed_count
          : 0
      );
    }
  }, [defaultTopics, pageName, leftPanelTab]);

  useEffect(() => {
    jquery(".accordion-button").each((index, element) => {
      jquery(element).parent().removeClass("collapsed");
    });

    if (window.location.pathname == "/MyEmail") {
      const emailTopic = DEFAULT_TOPICS_WITH_EMAIL
      setTopicData(DEFAULT_TOPICS_WITH_EMAIL)  
        setActiveAccordianKeys(
          emailTopic &&
          emailTopic.map((value, index) => {
            return index.toString();
          })
        );
      }
  }, []);

  useEffect(() => {
    setSearchString(searchCategory);
  }, [searchCategory]);

  useEffect(() => {
    if (categoryId > 0) {
      CustomerServices.getOperatorsByCategory(projectId, categoryId ,0).then(
        (res) => {
          if (res.status && res.data) {
            setCategoryOperators(res.data);
          }
        }
      );
    }
  }, [categoryId]);

  const handleSelectedTask = (taskId, show) => {
    dispatch(getSelectedTask(project_id, taskId));
    setSelectedTaskId(taskId);
    setShowTaskModal(show);
  };


  const getLatestDefaultTopics = () => {
    dispatch(getDefaultTopics(project_id, "", "", pageName));
  };

  const addCategoryTopic = (mainKey) => {
    switch (mainKey) {
      case "my_task":
        setShowAddCategory(true);
        break;
      case "my_emails":
        setShowAddCategory(true);
      break;
      case "providers":
        navigate(`/connect-provider?space=${projectId}&provider-type=invite_provider`);
        break;
      case "my_people":
      case "my_contact":
      case "my_operator":
      case "my_staff":
        navigate(userType === "operator" ? `/employees?space=${projectId}` : `/tenants?space=${projectId}`);
        break;
      case "proposals":
        setShowAddProviderCategory(true);
        break;
      case "my_customs":
        setShowAddMailCustomCategory(true);
        break;
      case "task_topic":
        setShowAddPeople(true);
        setTopicType("task_topic");
        break;
      case "user_journey":
        setTopicType("user_journey");
        setCreateTeamModal(true);
        break;
      case "my_staff":
        setTopicType("my_staff");
        setShowAddContact(true);
        break;
      case "equipments":
        if(projectId > 0)
        {
          navigate(`/equipments?space=${projectId}`)
        }
        else {
          showError("l_please_select_project");
        }
        break;
      default:
        return;
    }
  };
  const editCategoryTopic = (mainKey) => {
    switch (mainKey) {
      case "my_task":
        setShowAddCategory(true);
        break;
      case "providers":
        setShowAddProviderCategory(true);
        break;
      case "proposals":
        setShowAddProviderCategory(true);
        break;
      case "my_customs":
        setShowAddMailCustomCategory(true);
        break;
      case "task_topic":
        setShowAddPeople(true);
        setTopicType("task_topic");
        break;
      case "user_journey":
        setTopicType("user_journey");
        setCreateTeamModal(true);
        break;
      case "my_staff":
        setTopicType("my_staff");
        setShowAddContact(true);
        break;
      case "equipments":
        setTopicType('equipments')
        setEquipmentModal(true);
        setEquipmentDetail(undefined)
        break;
      default:
        return;
    }
    setActiveTopicKey(mainKey);
  };
  const setFilterCategory = (categoryId) => {
    dispatch(setFilterBenchmarkCategory(categoryId));
  };

  const handleAddProviderCategory = (category) => {
    dispatch(
      updateProjectTopics(
        {
          filter_key: category.provider_id,
          title: category.name,
          custom_keys: [],
        },
        userType == "contact" ? "providers" : "proposals",
        Object.keys(isEditTopic).length > 0 ? isEditTopic.id : 0
      )
    );
  };

  const handleAddTopic = (category) => {
    let tag = { filter_key: category.id, 
                title: category.name,
                email : category.email.map((item) => {
                  return {
                    email : item
                  }
                }),
                count : 0,
                image_url : '',
                is_active : 1,
                total_noti_count : 0,
                main_sub_key : "custom_tag",
                total_notification_count : {task_count: 0, schedule_count: 0, chat_count: 0, missed_call_count: 0},
                custom_keys: [] 
              }
    dispatch(
      updateProjectTopics(
        tag,
        pageName === "MyEmail" || leftPanelTab == "my-email" ? "my_emails" : "my_task",
        Object.keys(isEditTopic).length > 0 ? isEditTopic.id : 0
      )
    );
    setIsEditTopic({})
  };
  const handleAddTaskTopic = (category) => {
    dispatch(
      updateProjectTopics(
        { filter_key: category.id, title: category.name, custom_keys: [] },
        "task_topic"
      )
    );
    getLatestDefaultTopics();
  };
  const handleAddedTeam = (team, is_edit_id = 0) => {
    if (admin && topicType == "") {
      team["handler"] = selectAdminTeam;
      if (team.new) {
        setAdminTeamList([team].concat(adminTeamList));
        dispatch(setVirtualSpaceList([team].concat(adminTeamList)));
      } else {
        const index = adminTeamList.findIndex((x) => x.id == team.id);
        setAdminTeamList(Object.values({ ...adminTeamList, [index]: team }));
        dispatch(setVirtualSpaceList(Object.values({ ...adminTeamList, [index]: team })));
      }
    } else {
      dispatch(
        updateProjectTopics(
          {
            filter_key: (isVirtualSpace == true ? team.id : team.id * -1).toString(),
            title: team.teamTitle,
            is_active: 0,
            custom_keys: [],
          },
          topicType != "" ? topicType : "my_people",
          is_edit_id > 0 ? topicType == 'my_people' ? is_edit_id * -1 : is_edit_id : 0
        )
      );
    }
  };

  const handleAddedContact = (contact) => {
    dispatch(
      updateProjectTopics(
        {
          filter_key: topicType == "task_topic" ? "-" + contact.id : contact.id,
          title: contact.full_name,
          is_active: 0,
          custom_keys: [],
          image:
            topicType == "my_staff" || topicType == "task_topic"
              ? contact.image
              : "",
        },
        topicType != "" ? topicType : "my_people",
        Object.keys(isEditTopic).length > 0 ? isEditTopic.id : 0
      )
    );

  };
  const handleAddedOperator = (contact) => {
    dispatch(
      updateProjectTopics(
        {
          filter_key: `${contact.id}`,
          title: contact.full_name,
          is_active: 0,
          custom_keys: [],
          image: contact.image,
        },
        "my_people",
        Object.keys(isEditTopic).length > 0 ? isEditTopic.id : 0
      )
    );
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isActiveCategory")) == true && selectedTopics) {
      localStorage.removeItem("isActiveCategory");
      handleCreateChatGroup(selectedTopics.main_key, selectedTopics.main_sub_key, selectedTopics.filter_key);
    }
  }, [JSON.parse(localStorage.getItem("isActiveCategory"))])
  
  useEffect(() => {
    if (agentList && agentList.length > 0 && leftPanelTab == "my-agents") {
      showContactDirectory();
      handleCreateChatGroup("my_contact","people",agentList[0].staffid)
      dispatch(
        leftPanelFilters({
          main_key: "my_contact",
          filter_key: agentList[0].staffid,
          title: agentList[0].firstname  + " " + agentList[0].lastname,
          main_sub_key: "people",
          sub_category: [],
        })
      );
    }
  }, [JSON.stringify(agentList), leftPanelTab])
  

  const handleCreateChatGroup =(main_key,main_sub_key,filter_key, unread_count, agentProjectId = 0)=>{
    let mainKey 
    let filterKey
    let mainSubKey
    let tempProjectid
    if(filter_key == "myrai_support" && userType != "sataff"){
      mainKey = "myr_support"
      filterKey = localStorage.getItem("staff_id")
      mainSubKey = userType
    }else{
      mainKey = main_key
      filterKey = filter_key
      mainSubKey = main_sub_key ? main_sub_key : []

    }
    if (leftPanelTab == "my-tenants") {
      tempProjectid = 0
    }else{
      if (agentProjectId) {
        tempProjectid = agentProjectId;
      }else{
        tempProjectid = isRegisterUser && mainKey == "myr_support" ? localStorage.getItem("chat_project_id") : project_id ? project_id : localStorage.getItem("selectedOffice")
      }
    }
    dispatch(setFetchedTaskandMessages([]));
    customerServices.createChatGroup(tempProjectid,mainKey,mainSubKey,filterKey).then((res)=>{
      if(res && res.status && res.data){
        dispatch(getGroupDetails({}));
        dispatch(setFetchedTaskandMessages([]));
        let groupID = res.data._id
        localStorage.setItem("activeGroupId", groupID);
        chatServices.getGroupDetails(chatUserId, res.data._id).then((res) => {
          if (res.success) {
            
            var activeGroupId = localStorage.getItem("activeGroupId");
            if (chatUserId != "" && activeGroupId != "" && activeGroupId != undefined) {
              var socketDataIn = {
                groupId: activeGroupId,
                userId: chatUserId,
              };
                dispatch(groupIn(socketDataIn));
                dispatch(fetchMessages( 
                  localStorage.getItem("chatUserId"), 
                  groupID,
                  0,
                  ()=>{},
                  1,
                  "",
                  "",
                  "",
                  unread_count,
              ));  
            }
              dispatch(getGroupDetails(res.result))
              localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
            let temp = groupsList
            let index = temp && temp.length > 0 && temp.findIndex((group) => group.groupId == groupID)           
           
            if(index > -1){
              let tempdata = temp[index]
              if(tempdata && tempdata.extraData && tempdata.extraData.filterKey === localStorage.getItem("staff_id")){
                index = temp.findIndex((gd)=> gd.extraData.filterKey == "myrai_support")
                tempdata = temp[index]
              }
              if (tempdata) {
                
                tempdata.unreadCount = 0
                let data = (
                  Object.values({ ...temp, [index]: tempdata })
                  )
                  setGroupsList(data.slice());
                }
                  setTimeout(() => {
                    localStorage.removeItem("unread_chat_notification_count")                    
                  }, 3000);
            }

          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        });
        
      }else{
        localStorage.setItem("activeGroupId",0);
        dispatch(showMessage("unsucess", _l("l_error"),_l(res && res.message ? res.message : "l_something_went_wrong")));
      }
    })

  }
  const handleCreateChatGroupforCalll =(main_key,main_sub_key,filter_key)=>{
    let mainKey 
    let filterKey
    let mainSubKey
    if(filter_key == "myrai_support" && userType != "sataff"){
      mainKey = "myr_support"
      filterKey = localStorage.getItem("staff_id")
      mainSubKey = userType
    }else{
      mainKey = main_key
      filterKey = filter_key
      mainSubKey = main_sub_key ? main_sub_key : []

    }
    customerServices.createChatGroup(project_id,mainKey,mainSubKey,filterKey).then((res)=>{
      if(res.status && res.data){
        let groupID = res.data._id
        localStorage.setItem("activeGroupId", groupID);

        chatServices.getGroupDetails(chatUserId, res.data._id).then((res) => {
          if (res.success) {
            localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
            if(main_sub_key == "people" || main_sub_key == "contact" || main_sub_key == "operator"){
              let tempArr = res.result.members
              let templist =  tempArr.filter((member)=> member._id != localStorage.getItem("orgChatUserId"))
              if(templist.length > 0){
                makeCall(templist[0]._id ,templist[0].fname + " " + templist[0].lname, res.result._id)      
              }
              
            }
            setGroupDetailsforCall(res.result);
          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        });
        
      }else{
        dispatch(showMessage("unsucess", _l("l_error"),_l(res.message)));
      }
    })

  }

  const handleChangeViewtoFavView  = () =>{
    if(!doNotNavigate.includes(pageName)){
    let FavView = localStorage.getItem("fav_dashboard")
    navigate("/") 
    FavView == "AichatView" ?
      dispatch(setSelectedGloablView("AichatView")) :
        FavView == "calendar" ?
          dispatch(setSelectedGloablView("calendarView"))
          : FavView == "my_spaces" ?
            dispatch(setSelectedGloablView("spaceView"))
            : FavView == "kanban_view" ?
              dispatch(setSelectedGloablView("kanbanView"))
              : FavView == "my_tasks" ? dispatch(setSelectedGloablView("listView"))
                : dispatch(setSelectedGloablView("AichatView"));
  }}

  useEffect(() => {
  var orgChatUserId = localStorage.getItem("orgChatUserId");
  var activeGroupId = localStorage.getItem("activeGroupId");


    if (chatUserId != "" && chatUserId != null) {
      activeGroupId = localStorage.getItem("activeGroupId");
      if (activeGroupId != "" && activeGroupId != undefined) {
        var socketData = {
          groupId: activeGroupId,
          userId: chatUserId,
        };
        dispatch(groupOut(socketData));
      }
      activeGroupId = "";
      $('a[id^="group_card_unread_count_display_"]').removeClass("active");
      if (localStorage.getItem('user_type') == "staff" && !localStorage.getItem("selectedVirtualSpace") && (localStorage.getItem("isLiteVersion") == "false")) {
      } else {
        chatUserId = orgChatUserId;
        localStorage.setItem("chatUserId", orgChatUserId);
      }
      if (projectId > 0) {
        dispatch(fetchUnreadMsgCount(chatUserId,localStorage.getItem("isLiteVersion") == "true" || projectId == null ? 0 : projectId));
      }
    }
    
  }, [groupDetails, globalView]);
 
useEffect(() => {
  if(!(taskFilters.length == selectedTopicIds.length)){
    let temp_key = []
    taskFilters.map((filter)=>{
      temp_key.push(filter.filter_key)
      setSelectedTopicIds(temp_key)
    })
  }
},[taskFilters])

  const handleAddCMTopic = () => {
    getLatestDefaultTopics();
    dispatch(getTopicTasks(projectId, "MyEmail"));
  };
  const extractEmails = (text) => {
    return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  };
  const editCustomTopic = (id, email, title) => {
    setCustomTopicMail(email);
    setCustomTopicTitle(title);
    setTopicId(id);
    setShowAddMailCustomCategory(true);
  };
  const deleteCustomTopic = (id) => {
    CustomerServices.deleteCustomTopic(id).then((res) => {
      if (res.status) {
        window.dispatchEvent(new Event("addEditCategoryModalClose"));
        dispatch(getDefaultTopics(projectId, "", "", pageName));
        dispatch(getTopicTasks(projectId, pageName));
      }
    });
  };

  const deleteSingleEquipment = (equipmentId, deleteddefaultTopics = false,handleClose = () => {}) => {
    CustomerServices.DeleteEquipment(equipmentId, project_id).then((res) => {
      if (res.status == 1) {
        setEquipmentModal(false)
        handleClose();
        dispatch(showMessage('sucess', _l('l_success'), res.message ? res.message : ''));
        if (deleteddefaultTopics) {
          
          dispatch(getDefaultTopics(project_id));
        } else {
          let topicDetail = topicData
          for (let i = 0; i < topicDetail.length; i++) {
            if (topicDetail[i].main_key == "equipments") {
              topicDetail[i].categories = topicDetail[i].categories.filter(c => c.filter_key != equipmentId)
            }
          }
          if (topicDetail) {
            dispatch({
              type: GET_TOPICS_TASK,
              payload: { topics: topicDetail },
              project_id,
            });
          }
        }
      }
    })
  }
  const deleteEquipment = (id, deleteddefaultTopics,handleClose) => {
    dispatch(showConfirmation(_l('l_delete')+" "+_l('l_equipment'), _l('l_delete_equipment_confirmation') + " " +_l('l_equipment_quantity_also_delete') , _l('l_delete'), _l('l_cancel'), () => { }, () => { deleteSingleEquipment(id, deleteddefaultTopics,handleClose); dispatch(toastCloseAction()) }))
  }

  const removeTaskTopic = (id) => {
    CustomerServices.removeTopicTag(id).then((res) => {
      if (res.status == 1) {
        dispatch(
          updateProjectTopics(
            { filter_key: id, title: 'aa', custom_keys: [] },
            "my_task",
            0,
            "delete"
          )
        );
        dispatch(toastCloseAction());
        if (selectedTask && Object.keys(selectedTask).length > 0) {
          dispatch(
            getSelectedTask(
              localStorage.getItem("selectedOffice"),
              selectedTask.id
            )
          );
        }
      }
      else{
        dispatch(showMessage("unsucess", _l("l_error"),res.message));
      }
    });
  };
  const SelectTeamContactModal = (
    <Modal
      show={showAddPeople}
      onHide={() => {
        setShowAddPeople(false);
      }}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{_l("l_add_new_people")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 my-3">
        <div className="left-content-part p-0 res-width-100 d-flex flex-column">
          <div className="comman-content-scroll-wrapper overflow-hiiden-web">
            <ul className="comman-content-scroll comman-verticle-tab">
              <li className={`comman-list with-after-30 p-0  `}>
                <a href="#/"
                  className={`w-100 position-relative tab_link company-profile`}
                  onClick={() => {
                    navigate(userType === "operator" ? `/employees?space=${projectId}&actionType=create-team` : `/tenants?space=${projectId}&actionType=create-team`);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="comman-list-left w100minus20">
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="h25w25  d-flex align-items-center p-1">
                            <UsersThreeIcon className="HW16" />
                          </div>
                        </div>
                        <div className="c-list-detail w100minus20 text-truncate color-white-80 c-font f-12">
                          {_l("l_to_teams")}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li className="comman-list with-after-50 p-0">
                <a href="#/"
                  className={`w-100 position-relative tab_link company-profile`}
                  onClick={() => {
                    navigate(userType === "operator" ? `/employees?space=${projectId}&actionType=add-contact` : `/tenants?space=${projectId}&actionType=add-contact`);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="comman-list-left w100minus20">
                      <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                          <div className="h25w25  d-flex align-items-center p-1">
                            <UserIcon className="HW14" />
                          </div>
                        </div>
                        <div className="c-list-detail c-font f-14 w100minus20 text-truncate color-white-80 c-font f-12">
                          {_l("l_to")}{" "}
                          {userType == "operator"
                            ? _l("l_operator")
                            : topicType == "task_topic"
                              ? _l("l_staff")
                              : _l("l_contact")}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  const getAdminTeamList = async () => {
    try {
      const virtualSpaceFromIDB = await readCollectionFromDB("virtualSpaces", "project_id");

      if(virtualSpaceFromIDB && virtualSpaceFromIDB.length)
      {
        setAdminTeamList(
          virtualSpaceFromIDB.map((data) => {
            return { ...data, handler: selectAdminTeam };
          })
        );
        dispatch(setVirtualSpaceList(virtualSpaceFromIDB));
      }

      const response = await AdminServices.getVirtualSpaceList();
      
      if(response.data)
      {
        insertCollectionToDB("virtualSpaces", response.data.map((w) => {return {...w, project_id: w.id}}), "project_id");
        if(!virtualSpaceFromIDB.length) {
          dispatch(setVirtualSpaceList(response.data));
          setAdminTeamList(
            response.data.map((data) => {
              return { ...data, handler: selectAdminTeam };
            })
          );
        }
      }
    } catch (e) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
      );
      console.error(e);
    }
  };

  const navigateToReportingFeature = (topic) => {
    if(topic.main_key == "providers") {
      navigate("/provider-reporting");
    } else if(topic.main_key == "equipments"){
      navigate("/equipment-reporting");
    }
    else
    {
      navigate("/employee-reporting");
    }
  }

  const addNewCategoryTopic = (topic) => {
    addCategoryTopic(topic.main_key);
    setActiveTopicKey(topic.main_key);
  }

  const viewTopicCategories = (topic) => {
    if(topic.main_key == "my_task" || topic.main_key == "my_emails")
    {
      setActiveTopicKey(topic.main_key);
      setProviderListingCategory({id: -1, title: _l("l_all"), main_key: topic.main_key, is_active: "", main_key_title: topic.title, main_sub_key: ""}); 
    }
    else {
      if((!projectId || isNaN(projectId) || Number(projectId) < 0) && (userType !== "contact" || [2, 3].includes(Number(contact_role))))
      {
        showError("l_please_select_project");
        return;
      }
      setProviderListingCategory({id: -1, title: _l("l_all"), main_key: topic.main_key, is_active: "", main_key_title: topic.title, main_sub_key: topic.categories && topic.categories[0] ?  topic.categories[0].main_sub_key : '', hideFilterArea: (pageName === "dashboardnew" || ["my-request"].includes(leftPanelTab))});   
    }
  }

  useEffect(() => {
    if (taskFilters) {
      setTopicTaskFilter(taskFilters);
    }
  }, [JSON.stringify(taskFilters)]);

  const handleFilter = (mainKey) => {
    let tempFilter = filterfor3dPlan
    if (["providers","my_contact","equipments","my_staff","myr_support","my_operator"].includes(mainKey)) {
      dispatch(leftPanelFilters("", mainKey, "hold_range_filter_task_count"))
      
    }else{
    if (mainKey == "all") {
        topicData.map((topic) => {
          if (topic.main_key == "equipments") {
            if (tempFilter.length == topic.categories.length) {
              tempFilter = []
            }else{
              tempFilter = []
            topic.categories.map((category) => {
              tempFilter.push(category.filter_key)
            })
          }
          }
        })
    }else{
    if (tempFilter.includes(mainKey)) {
      tempFilter = tempFilter.filter((filter) => filter != mainKey);
    }else{
      tempFilter.push(mainKey);
    }
  }
    dispatch(filterFor3Dmodel(tempFilter.slice()));
  }
}
  
  const makeCall = (receiverId = "" , full_name = "", groupId = "") => {
    dispatch(setCallDisconnect(false));
    dispatch(setCallRecording(false));
    dispatch(initCall({
      userId: localStorage.getItem("orgChatUserId"),
      receiverId: receiverId,
      extraData: {
        full_name: full_name,
        groupId: groupId
      }
    }));
  };

  const filterCategories = (categories, adminAsStaff, searchTopic, main_key) => {
    const filteredCategories = categories.filter((activeCat) => {
      if (main_key === "my_emails") {
        return activeCat
      } else {
      const isFilterKeyValid = activeCat.filter_key !== localStorage.getItem("staff_id");
      const isAdminAsStaffCondition = (localStorage.getItem("user_type") == "staff" && leftPanelTab == "my-staff") ? activeCat : adminAsStaff ? activeCat.is_active == 1 : activeCat.contact_role
        ? activeCat.contact_role != 4 && activeCat.is_active == 1
        : activeCat.is_active == 1;
      const isTitleMatch = activeCat.title.toLowerCase().includes(searchTopic.toLowerCase());
  
      return isFilterKeyValid && isAdminAsStaffCondition && isTitleMatch;
    }
  })
    return filteredCategories;
  };

  const slack_integration = (_slack) => {
    if (_slack && _slack.data && _slack.status) {
      const channel_ids = [];
      const slack_channels = [];
      _slack.data.categories.map((_channel) => {
        if (_channel.is_active == 1) {
          slack_channels.push(_channel);
        }
        channel_ids.push(_channel.filter_key);
      });
      dispatch({
        type: SLACK_TOPICS,
        payload: _slack.data,
      });

      setShowSlack(true);
    } else {
      setShowSlack(false);
    }
  };

  const discord_integration = (_discord) => {
    if (_discord && _discord.data && _discord.data.categories && _discord.status) {
      const channel_ids = [];
      const discord_channels = [];
      _discord.data.categories.map((_channel) => {
        if (_channel.is_active == 1) {
          discord_channels.push(_channel);
        }
        channel_ids.push(_channel.filter_key);
      });
      dispatch({
        type: DISCORD_TOPICS,
        payload: _discord.data,
      });
    } else {
      dispatch({
        type: DISCORD_TOPICS,
        payload: [],
      });
    }
  };

  const google_integration = (_google) => {
    if (_google && _google.data && Object.keys(_google.data).length && _google.status && _google.data.categories) {
      const channel_ids = [];
      const calendar_list = [];
      _google.data.categories.map((_channel) => {
        if (_channel.is_active == 1) {
          calendar_list.push(_channel);
        }
        channel_ids.push(_channel.filter_key);
      });
      dispatch({
        type: CALENDAR_TOPICS,
        payload: _google.data,
      });
    } else {
      dispatch({
        type: CALENDAR_TOPICS,
        payload: [],
      });
    }
  };

  const updateUnreadTenantData = (topic) => {
    const updateTenantData = tenantsEmployeeData && tenantsEmployeeData.map((tenant) => {
      if (tenant.filter_key == topic.filter_key && tenant.chat_data) {
        tenant.chat_data.unreadCount = 0;
      }
      return tenant
    })
    dispatch(setTentantsEmployeeData(updateTenantData));
    updateBurgerMenuIDB('0', "my_contact", updateTenantData)
  }

  const changeTentantsTopic = (topic) => {
    localStorage.setItem("tenantesRole", topic.contact_role);
    handleCreateChatGroup("my_contact","people",topic.filter_key)
    dispatch(
      leftPanelFilters({
        main_key: "my_contact",
        filter_key: topic.filter_key,
        title: topic.title,
        main_sub_key: "people",
        sub_category: [],
      })
    );
    if (globalView == 'AichatView') {
      if (topic.chat_data && topic.chat_data.unreadCount > 0) {
        updateUnreadTenantData(topic);
      }
    }
  }

  useEffect(() => {
    let topicTaskFilter = taskFilters && taskFilters.find(task => task.main_key == "my_contact");
   if (globalView == 'AichatView' && leftPanelTab == "my-tenants" && tenantsEmployeeData && tenantsEmployeeData.length > 0 && topicTaskFilter) {
    updateUnreadTenantData(topicTaskFilter);
   }
  }, [globalView])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state") != null ? urlParams.get("state") : "";
    const project_id = localStorage.getItem("selectedOffice");
    if (state.includes("zoom_meeting") == true) {
      outlookCalendar.zoomAthentication(state, code).then((res) => {
        if (res.status == 1) {
          window.close();
        }
      });
    }
    if (Url.includes("slack")) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code != "") {
        slackServices.user_auth(code, "slack", project_id).then((res) => {
          if (res.data) {
            window.opener.dispatchEvent(
              new CustomEvent("slack_auth", { detail: res })
            );
            window.close();
          }
        });
      }
    }

    window.addEventListener("slack_auth", (data) => {
      slack_integration(data.detail);
    });

    if (Url.includes("guild_id")) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      discordServices
        .discord_auth(rel_id, rel_type, code, project_id)
        .then((response) => {
          window.opener.dispatchEvent(
            new CustomEvent("discord_auth", { detail: response })
          );
          window.close();
        });
    }

    window.addEventListener("discord_auth", (data) => {
      discord_integration(data.detail);
    });

    if (state.includes("google_calendar") == true) {
      if (project_id && code) {
        const exchange_code = {
          provider: "google_calendar",
          code: code,
          project_id: project_id,
          is_calendar_sync_for_all: allow,
        };

        googleCalendar.ExchangeCode(exchange_code).then((res) => {
          if (res.status && res.data) {
            window.opener.dispatchEvent(new CustomEvent("google_auth"), {
              detail: res.data,
            });
            window.close();
          }
        });
      }
    }

    window.addEventListener("google_auth", (data) => {
      google_integration(data.detail);
      window.dispatchEvent(new Event("handleClose"));
      dispatch(is_googlecalendar_connected(project_id));
      dispatch(
        showMessage("sucess", _l("l_success"), _l("l_authorization_sucessfully"))
      );
    });

    dispatch(is_discord_connected(project_id)); 
    dispatch(is_google_drive_connected(project_id));
    dispatch(is_googlecalendar_connected(project_id));
    dispatch(is_onedrive_connected(project_id));
    if (Url.includes("ExchangeGmailCode")) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      CustomerServices.exchangeGoogleCode(code, "gmail").then((res) => {
        handleClose();
        if (res.status) {
          dispatch(getTopicTasks(projectId, "MyEmail"));
          window.dispatchEvent(new Event("gmail_exchange"));
          window.close();

        } else {
          dispatch(showMessage("unsucess", _l("l_oops"), res.message));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (tenantsEmployeeData && tenantsEmployeeData.length > 0 && leftPanelTab == "my-tenants" && !activeGroupId) {
      handleCreateChatGroup("my_contact","people",tenantsEmployeeData[0].filter_key)
      dispatch(
        leftPanelFilters({
          main_key: "my_contact",
          filter_key: tenantsEmployeeData[0].filter_key,
          title: tenantsEmployeeData[0].title,
          main_sub_key: "people",
          sub_category: [],
        }, "", "applyAll")
      )
      localStorage.setItem("tenantesRole", tenantsEmployeeData[0].contact_role);
      dispatch(setTenatsRoleId(1));
    }else{
      localStorage.removeItem("tenantesRole")
    }
  }, [JSON.stringify(tenantsEmployeeData), leftPanelTab])

  useEffect(() => {
    if (spacesList && spacesList.length > 0 && leftPanelTab == "chat-agents") {
      handleCreateChatGroup("my_contact","people",localStorage.getItem("staff_id"),'', spacesList[0].project_id)
      dispatch(
        leftPanelFilters({
          main_key: "my_contact",
          filter_key: spacesList[0].project_id,
          title: spacesList[0].office_title,
          main_sub_key: "people",
          sub_category: [],
        })
      );
    }
   
  }, [JSON.stringify(spacesList), leftPanelTab])

  useEffect(() => {
    if ((leftPanelTab == "my-staff" || leftPanelTab == "my-tenants") && !SelectUser) {
      setSelectUser(true);
    }
  }, [leftPanelTab]);

  useEffect(() => {
    if ((leftPanelTab == "my-staff" || leftPanelTab == "my-tenants") && SelectUser) {
      selectInitUser();
    }
  }, [topicData]);

  const selectInitUser = () => {
    let firstUser = [];

    topicData && topicData.map((item) => {
      if (item.main_key === "my_contact" || item.main_key === "my_staff") {
        firstUser = item.categories.sort((a, b) => {
          if (!a.chat_data || !a.chat_data.lastMessage || a.chat_data.lastMessage === '') return 1;
          if (!b.chat_data || !b.chat_data.lastMessage || b.chat_data.lastMessage === '') return -1;

        const timeA = moment(a.chat_data && a.chat_data.lastMessageDate ? a.chat_data.lastMessageDate : '');
        const timeB = moment(b.chat_data && b.chat_data.lastMessageDate ? b.chat_data.lastMessageDate : '');

          return timeB - timeA;
        });
      }
    });

    if (firstUser.length > 0) {
      let userMainKey = userType == "staff" ? "my_staff" : "my_contact";
      let userFilterKey = firstUser[0];
  
      handleCreateChatGroup(userMainKey, "people", userFilterKey.filter_key);
      dispatch(
        leftPanelFilters({
          main_key: userMainKey,
          filter_key: userFilterKey.filter_key,
          title: userFilterKey.title,
          main_sub_key: "people",
          sub_category: [],
        }, "", "applyAll")
      );

      if (userFilterKey.chat_data && Object.keys(userFilterKey.chat_data).length > 0) {
        localStorage.setItem("activeGroupId", userFilterKey.chat_data.groupId);
      }

      localStorage.setItem("selectedTopic", JSON.stringify({
        id: userFilterKey.filter_key,
        title: userFilterKey.title,
        main_key: userMainKey,
        is_active: userFilterKey.is_active,
        main_key_title: userFilterKey.title,
        main_sub_key: userFilterKey.main_sub_key,
        contact_role: userFilterKey.contact_role,
        total_notification_count: userFilterKey.total_notification_count,
        thread_id: userFilterKey.thread_id,
        filter_key: userFilterKey.filter_key,
        image: userFilterKey.image
      }))

      setSelectUser(false);
    }
  }

  useEffect(() => {
    if (activeUser) {
      const UpdatedData = tenantsEmployeeData && tenantsEmployeeData.map((items) => {
        if (items && items.chat_data && items.chat_data.groupId && items.chat_data.groupId == activeUser) {
          items.chat_data.unreadCount = 0
        }
        return items
      })

      dispatch(setTentantsEmployeeData(UpdatedData));
    }
  }, [activeUser]);
  
  useEffect(() => {
    // don't remove this useeffect 
  }, [tenantsEmployeeData])
  

  return (
    <>
      {
        <div className="custom-accordian-main with-left-header-arrow">
          {!["my-agents", "chat-agents", "my-tenants"].includes(leftPanelTab)   && topicData && topicData.length && !contactlistShow && accordionEventKey ? (
            topicData.filter((data) => leftPanelTab !== "my-email" ? ![ "my_emails"].includes(data.main_key) : !["my_task"].includes(data.main_key)).map((topic, index) => {
              let formeTotalTaskCount = 0;
              if (["my_task", "my_emails"].includes(topic.main_key)) {
                topic.categories.filter((active) => active.is_active == 1).map((category) => {
                  formeTotalTaskCount += category.total_notification_count && category.total_notification_count.task_count;
                })
              }
              return (
                <Accordion
                  defaultActiveKey={userType == "staff" && pageName !== "leadPage" ? (leftPanelTab != "my-staff" && !isVirtualSpace) ? activeAccordianKeys.filter(() =>  topic.main_key != "my_staff") : activeAccordianKeys : activeAccordianKeys}
                  key={index}
                  alwaysOpen
                  className={` ${topic.main_key} ${ !["my-request", "my-calendar"].includes(leftPanelTab) && ["my_task" ].includes(topic.main_key) ? "bg-base-header-color position-sticky top-0 z-index-6  pt-0 mb-1":""}`}
                  data-main-key-div={`${topic.main_key}`}
                >
                 {!["my-request", "my-calendar"].includes(leftPanelTab) && ["my_task"].includes(topic.main_key) ?
                    <>
                    
                 { isSkeltonShow ? 
                 <>
                   {/* LeftPanelCategorySkeleton start  */}
                    <LeftPanelCategorySkeleton />
                      {/* LeftPanelCategorySkeleton end  */} </>
                      :
                      <div className={`d-flex align-items-center position-relative task-sub-list m-0 p-10 h70px ${taskFilters.filter((filter) =>  ["my_task"].includes(filter.main_key))[0] ? 'active' : ''}`}>
                        <div className="d-flex align-items-center justify-content-between w-100 dropdown position-relative radius_3 on-hover-active-toggle-img text-on-hover-active-green d-flex">
                          <div className="d-flex align-items-center  flex-grow-1" 
                              onClick={() => {
                                dispatch(unReadCommentTasks(false));
                                dispatch(leftPanelFilters("","","applyForMe"));
                                setShowForMeSection(!showForMeSection)
                                topic.categories.filter((t,i)=> i == 0).map((dt) => { 
                                localStorage.setItem("selectedTopic", JSON.stringify({id: dt.filter_key, title: dt.title, main_key: topic.main_key, is_active: dt.is_active, main_key_title: topic.title, main_sub_key: dt.main_sub_key, contact_role : dt.contact_role, total_notification_count: dt.total_notification_count, thread_id : dt.thread_id, filter_key: dt.filter_key,image :localStorage.getItem("contact_image") }))
                                HideDropdown();
                                dispatch(unreadTaskComment(topic.main_key))
                                if(pageName == "dashboardnew" || ["my-request"].includes(leftPanelTab) ){
                                  handleChangeViewtoFavView();
                                }else {
                                  if ( pageName != "MyEmail" && leftPanelTab != "my-email") {
                                    handleCreateChatGroup(topic.main_key, dt.main_sub_key,dt.filter_key)
                                  }
                                }
                                })
                              }}>
                          <div className="h40w40 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle position-relative active_border">
                            <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                              style={{ backgroundImage: `url(${localStorage.getItem("contact_image")})` }}
                            ></div>
                          </div>
                          <div className="d-flex align-items-center ps-2 w100minus100per" 
                          data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            type="button"
                          >
                            <div className="flex-grow-1 text-truncate on-hover-transition-dropdown">
                              <div className="fw-semibold c-font f-13 title-fonts  text-truncate">{["l_for_me", "l_my_emails"].includes(topic.title) ? localStorage.getItem("full_name") : _l(topic.title)}</div>
                                <div className="with-transform-revert-main">
                                  <ActionDropdown
                                    actions={topic.categories.map((dt) => {
                                      let acvtiveIds = []
                                      taskFilters.map((dd) => {
                                        if (dd.main_key == topic.main_key) {
                                          acvtiveIds.push(dd.filter_key)
                                        }
                                      })
                                      return {
                                        ...dt,
                                        actionName: _l(dt.title),
                                        active: acvtiveIds.includes(dt.filter_key),
                                        iconDisplay: dt.filter_key == "created_by_me" || dt.filter_key == "assigned_to_me" ? false : true,
                                        actionHandler: () => {
                                          localStorage.setItem("email_filter_key", dt.filter_key);
                                          localStorage.removeItem("email_filter_email");
                                          window.dispatchEvent( new Event("email_filter"));
                                          dispatch(
                                            leftPanelFilters({
                                              main_key: topic.main_key,
                                              filter_key: dt.filter_key,
                                              title: dt.title,
                                              main_sub_key: dt.main_sub_key,
                                              sub_category: dt.sub_category,
                                            })
                                          );
                                          dispatch(
                                            projectTaskCounts(
                                              projectId,
                                              taskFilters,
                                              {
                                                main_key: topic.main_key,
                                                filter_key: dt.filter_key,
                                                title: dt.title,
                                              },
                                              isLeadPage, pageName
                                            )
                                          );
                                          if (pageName != "dashboardnew" && pageName != "MyEmail" && !["my-email" ,"my-request"].includes(leftPanelTab)) {
                                            handleCreateChatGroup(topic.main_key, dt.main_sub_key,dt.filter_key)
                                          }
                                          HideDropdown();
                                          handleChangeViewtoFavView();
                                          localStorage.setItem("selectedTopic", JSON.stringify({id: dt.filter_key, title: dt.title, main_key: topic.main_key, is_active: dt.is_active, main_key_title: topic.title, main_sub_key: dt.main_sub_key, contact_role : dt.contact_role, total_notification_count: dt.total_notification_count, thread_id : dt.thread_id, filter_key: dt.filter_key, image : dt.image}))
                                          
                                        },
                                        profileImage: dt.filter_key == "created_by_me" ? localStorage.getItem("contact_image") : dt.filter_key == "assigned_to_me" ? "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg" : "",
                                        actionIcon: dt.filter_key == "pinned" 
                                                      ? "PushPinIcon" 
                                                      : dt.filter_key == "inbox"
                                                        ? "Tray"
                                                        : dt.filter_key == "important" 
                                                          ? "TagChevron"
                                                          : dt.filter_key == "sent" 
                                                            ? "PaperPlanRight"
                                                            : dt.filter_key == "draft" 
                                                              ? "FileIcon"
                                                              : dt.filter_key == "shared" 
                                                                ? "ShareNetworkIcon"
                                                                : "HashIcon" 
                                      }
                                    })
                                    }
                                    widthClass={`w-100 with-transform-revert ${showForMeSection ? "show" : ""}`}
                                    type={"forMe"}
                              /> 
                              </div>
                              <div className="pill-wrapper">
                                <div className="d-flex align-items-center">
                                  {
                                    taskFilters && taskFilters.map((tf,index) => {
                                      if (["my_task" , "my_emails"].includes(tf.main_key) && index < 2) {
                                        return (
                                          <div className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 max-width75 text-truncate ">
                                            <div className="d-flex align-items-center text-truncate">
                                              <div className="text-truncate">{_l(tf.title)}</div>
                                              <a href="#/" className="ps-1"
                                              onClick={() => {

                                                dispatch(
                                                  leftPanelFilters({
                                                    main_key: topic.main_key,
                                                    filter_key: tf.filter_key,
                                                    title: tf.title,
                                                    main_sub_key: tf.main_sub_key,
                                                    sub_category: tf.sub_category,
                                                  })
                                                );
                                                dispatch(
                                                  projectTaskCounts(
                                                    projectId,
                                                    taskFilters,
                                                    {
                                                      main_key: topic.main_key,
                                                      filter_key: tf.filter_key,
                                                      title: tf.title,
                                                    },
                                                    isLeadPage, pageName
                                                  )
                                                );
                                              }}>
                                              </a>
                                            </div>
                                          </div>
                                        )
                                      }
                                    })
                                  }
                                  {taskFilters && taskFilters.filter((mainkey) =>  ["my_task" , "my_emails"].includes(mainkey.main_key) ).length > 2 ?
                                    <div className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 max-width75 text-truncate ">
                                      <div className="d-flex align-items-center text-truncate">
                                        <div className="text-truncate">+{taskFilters.filter((mainkey) =>  ["my_task" , "my_emails"].includes(mainkey.main_key) ).length - 2}</div>
                                      </div>
                                    </div>
                                    : <></>}
                                </div>
                              </div>
                            </div>

                          </div>
                          </div>
                          <div className={`rounded-pill badge-custom d-flex with-position-relative border-0 h18w18 m-auto ps-0 ${formeTotalTaskCount != 0 ? '' : "d-none"}`}>
                              {formeTotalTaskCount}
                          </div>
                            <a href="#/" className=" action_icon d-flex with_separator_10" 
                            onClick={() => {
                              setshowAddCategoryForMe(true);
                              setActiveTopicKey(topic.main_key);
                            }
                          }
                            ><PlusIcon className="HW18" /></a>
                            <a href="#/" className="with_separator_10  action_icon d-flex pe-0 before-dnone" onClick={() => {accessPremiumFeature(projectId, () => {viewTopicCategories(topic)})}}>
                              <GearSixIcon className="HW18" />
                            </a>
                        </div>
                      </div>}
                      
                    </> :
                  <Accordion.Item
                    eventKey={`${index}`}
                    className={`mainTopicTaskFilter border-bottom ${topic.main_key == "providers" ? "ai-welcome-category" : ""}`}
                    data-step='9'
                  >
                    { isSkeltonShow ? <>
                    {/* LeftPanelCategoryHeaderSkeleton start  */}
                      <LeftPanelCategoryHeaderSkeleton />
                      {/* LeftPanelCategoryHeaderSkeleton end  */}
                    </> : <>
                    <div className="hovertextgreen accordian-header-box d-flex align-content-center accordian-main-header title-fonts custom-accordian-Header-sticky radius_3">
                    {
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100 line-height-1 ">
                          <div
                            className="title-font c-font f-14 color-white fw-bold text-uppercase  text-truncate w100minus70 categorytitle "
                            onClick={() => {
                            }}
                          >
                            {_l(topic.title)}
                          </div>
                        </div>
                      </Accordion.Header>
                    }
                      {
                        
                        pageName != "dashboard" ?
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3 align-items-center">
                          {topic.main_key === "my_emails" ?  
                            <a href="#/" className="with_separator_10" 
                                onClick={() => { 
                                  setshowAddCategoryForMe(true);
                                  setActiveTopicKey(topic.main_key);
                                }
                              }
                            >
                              <PlusIcon  className="HW18" />
                            </a>
                            : <></> }
                            {globalView != "AichatView" && pageName !== "MyEmail" && !["my-email", "my-staff", "my-request", "my-calendar"].includes(leftPanelTab) ?
                            <a href="#/"
                              className={`z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img SettingIcon`}
                              onClick={() => handleFilter(topic.main_key == "equipments" && globalView == "spaceView" ? "all" : topic.main_key) } 
                            >
                              <div className="d-flex h-100 w-100">
                                {topic && topic.categories && topic.categories.filter((category) => (category.filter_key != localStorage.getItem("staff_id")) && (adminAsStaff ? category.is_active == 1 : category.contact_role && category.contact_role ? (category.contact_role != 4 && category.is_active == 1) : category.is_active == 1)).length ==  taskFilters.filter((key) => key.main_key != "date_range_filter").length 
                                ? <EyeIcon className="HW18 green-light-grey m-auto" />
                                : <EyeIcon className="HW18 m-auto" />
                                }
                              </div>
                            </a> 
                            : <></>}
                             
                         {!["dashboardnew", "MyEmail"].includes(pageName) &&  !["my-email" ,"my-request", "my-staff", "my-calendar"].includes(leftPanelTab) ? (
                          <a href="#/"
                            className={`z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img SettingIcon ${topic.main_key == "providers" || topic.main_key == "my_people" || topic.main_key == "my_staff" || topic.main_key == "equipments" || topic.main_key == "my_contact" || topic.main_key == "my_operator"
                              ? ""
                              : "d-none"
                              } ${topic.main_key == "providers" && window.location.pathname === "/provider-reporting" ? "active d-block before-dnone transformX-0 " : ((topic.main_key == "my_people" || topic.main_key == "my_staff" ||topic.main_key == "my_operator" || topic.main_key ==  "my_contact") && window.location.pathname === "/employee-reporting" ? "active d-block before-dnone transformX-0" : (topic.main_key == "equipments" && window.location.pathname == "/equipment-reporting") ? "active d-block before-dnone transformX-0" : "" )}
                              `}
                            onClick={() => {
                              if(taskFilters.filter((mainkey) => mainkey.main_key == topic.main_key).length == 0){
                                dispatch(leftPanelFilters("", topic.main_key, "hold_range_filter_task_count"))
                              }
                              accessPremiumFeature(projectId, () => {navigateToReportingFeature(topic)})
                            }}
                          ><div className="d-flex h-100 w-100">
                            <ChartLineIcon className="HW18 m-auto green_icon" />
                          </div>
                          </a>) 
                          : 
                          (<></>)
                        }
                         <a href="#/"
                            className={`z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img SettingIcon ${topic.main_key == "my_task" 
                              ? ""
                              : "d-none"
                              }`}
                              onClick={() => {accessPremiumFeature(projectId, () => {addNewCategoryTopic(topic)})}}
                          ><div className="d-flex h-100 w-100">
                            <PlusIcon className="HW16 m-auto" />
                          </div>
                          </a>
                        <a href="#/"
                          className={`d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img d-none ${hidePlusIcon.indexOf(topic.main_key) > -1
                            ? "d-none"
                            : ""
                            }`}
                          onClick={() => {accessPremiumFeature(projectId, () => {addNewCategoryTopic(topic)})}}
                          
                        >
                          <PlusIcon className="HW18 m-auto" />
                        </a>
                       
                        {
                         ((pageName !== "dashboardnew" && !["my-staff"].includes(leftPanelTab)) || (userType == "contact" && contact_role == 1 && topic.main_key === "providers")) ? (
                          <a href="#/"
                            id={`topic-${topic.main_key}`}
                            className={`d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img`}
                            onClick={() => {accessPremiumFeature(projectId, () => {viewTopicCategories(topic)})}}
                          >
                            <React.Fragment>
                              <GearSixIcon className="HW18 m-auto SettingIcon" />
                            </React.Fragment>
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                      :
                      <></>
                      }
                    </div>
                    </>}

                    <Accordion.Body className="py-1 pt-0 childTopicTaskFilter accordian-body pb-1 gap1px"> 
                        {
                          isSkeltonShow ?
                            ["0", "1", "2"].map((s) => {
                              return <>
                                {/* LeftPanelCategorySkeleton start */}
                                <LeftPanelCategorySkeleton />
                                {/* LeftPanelCategorySkeleton end  */}
                              
                              </>
                            }) :
                            <>
                        {topic && topic.categories && (filterCategories(topic.categories.filter((c) => c.filter_key == "ai_call"  && spacesList.find(spaces => spaces.vapi_assistant_id) ? c : c), adminAsStaff, searchTopic, topic.main_key)
                        ).length > 0  ?
                        ((filterCategories(topic.categories.filter((c) => c.filter_key == "ai_call"  && spacesList.find(spaces => spaces.vapi_assistant_id) ? c : c), adminAsStaff, searchTopic, topic.main_key)
                        ).sort((a, b) => {
                            let total_a = 0;
                            if (a.total_notification_count != undefined) {
                              let tnc = a.total_notification_count
                              total_a = tnc.chat_count + tnc.missed_call_count + tnc.service_count + tnc.task_count;
                            } else {
                              total_a = 0;
                            }
                            let total_b = 0;
                            if ((total_b - total_a) != 0) {
                              let tnc = b.total_notification_count
                              total_b = tnc.chat_count + tnc.missed_call_count + tnc.service_count + tnc.task_count;
                            } else {
                              total_b = 0;
                            }
                            if( a.filter_key == "myrai_support" || b.filter_key == "myrai_support"){
                              return 0
                            }
                          if (!a.chat_data || !a.chat_data.lastMessage ||  a.chat_data.lastMessage === '') return 1;
                          if (!b.chat_data || !b.chat_data.lastMessage ||  b.chat_data.lastMessage === '') return -1;
                          // If notifications are the same, compare by time in ascending order
                          const timeA = moment(a.chat_data && a.chat_data.lastMessageDate ? a.chat_data.lastMessageDate : '');
                          const timeB = moment(b.chat_data && b.chat_data.lastMessageDate ? b.chat_data.lastMessageDate : '');
                        
                          return timeB - timeA;
                          }).map((category, index) => {
                            let activeClassTask = taskFilters
                              ? taskFilters.filter(
                                (task) =>
                                  task.main_key == topic.main_key &&
                                  task.filter_key == category.filter_key &&
                                  task.title == category.title
                              )
                              : [];
                              let selectedTopic = JSON.parse(localStorage.getItem("selectedTopic"))
                              if (category.filter_key == selectedTopic && selectedTopic.id && globalView != "AichatView") {
                                let selectedTopicUnreadChatCount = category.total_notification_count.chat_count;
                                localStorage.setItem("selectedTopicUnreadChatCount",selectedTopicUnreadChatCount)
                              }
                              let totalChatTaskCounts = category.total_notification_count ?  category.total_notification_count.chat_count + category.total_notification_count.task_count : 0
                              let missedcallCounts = category.total_notification_count && category.total_notification_count.missed_call_count ? category.total_notification_count.missed_call_count : 0
                              let scheduledcallCounts = category.total_notification_count && category.total_notification_count.service_count ? category.total_notification_count.service_count : 0
                              return (
                                <div
                                    onClick={() => {
                                      localStorage.setItem("isdefaultFilter", false);
                                      if (pageName === "MyEmail" || leftPanelTab == "my-email") {
                                        window.dispatchEvent( new Event("email_filter")); 
                                      }
                                      dispatch(setIDofFirstMessage(""));
                                      if(["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName) ){
                                          if (selectedTopicIds.length >= topic.categories.filter((category) =>(category.filter_key != localStorage.getItem("staff_id")) && (adminAsStaff ? category.is_active == 1 : category.contact_role && category.contact_role ? (category.contact_role != 4 && category.is_active == 1) : category.is_active == 1)).length) {
                                            dispatch(
                                              leftPanelFilters({
                                                main_key: topic.main_key,
                                                filter_key: category.filter_key,
                                                title: category.title,
                                                image: category.image,
                                                main_sub_key: category.main_sub_key,
                                                sub_category: category.sub_category,
                                              }, '', "remove_range_filter_task"))
                                              setSelectedTopicIds([category.filter_key])
                                          }else{
                                            dispatch(
                                              leftPanelFilters({
                                                main_key: topic.main_key,
                                                filter_key: category.filter_key,
                                                title: category.title,
                                                image: category.image,
                                                main_sub_key: category.main_sub_key,
                                                sub_category: category.sub_category,
                                              })
                                            );
                                            if(selectedTopicIds.length == 0 && category.filter_key > 0){
                                              setSelectedTopicIds([category.filter_key])
                                            }else{  
                                              selectedTopicIds.push(category.filter_key)
                                            }
                                          }
                                      }else{
                                      dispatch(openChatAiInput(true));
                                      dispatch(unReadCommentTasks(false));
                                      if (chatUserId != "" && localStorage.getItem("activeGroupId") != "" && localStorage.getItem("activeGroupId") != undefined) {
                                        var socketDataOut = {
                                          groupId: localStorage.getItem("activeGroupId"),
                                          userId: chatUserId,
                                        };
                                        if (globalView == "AichatView") {
                                          dispatch(groupOut(socketDataOut));
                                        }
                                      }
                                      localStorage.removeItem("activeGroupId");     
                                      setSelectedTopicIds([])                                     
                                      if (pageName == "Proposals" && topic.main_key == "proposals") {
                                        dispatch(
                                          leftPanelFilters({
                                            main_key: topic.main_key,
                                            filter_key: category.filter_key,
                                            title: category.title,
                                            image: category.image,
                                            main_sub_key: category.main_sub_key,
                                            sub_category: category.sub_category,
                                          })
                                        );
                                      } else {
                                      if (pageName == "MyEmail" || leftPanelTab == "my-email") {
                                        localStorage.setItem("email_filter_key", topic.main_key == "my_emails" ? category.email && category.email.length > 0 ? category.email.map(item => item.email) : category.filter_key : category.email );
                                        localStorage.setItem( "email_filter_email", topic.main_key == "providers" 
                                                                                      ? JSON.stringify( category.member_list || [] ) 
                                                                                      : (topic.main_key == "my_emails" 
                                                                                        ? category.email && category.email.length > 0 ? JSON.stringify(category.email.map(item => item.email)) 
                                                                                        : '' 
                                                                                        : JSON.stringify(category.email.split(",")))
                                        );
                                        window.dispatchEvent( new Event("email_filter"));
                                      }
                                      if ( pageName == "benchmarkList" && (topic.main_key == "proposals" || topic.main_key =="providers")) {
                                        setFilterCategory(
                                          category.filter_key
                                        );
                                      }else if(window.location.pathname == '/clients'){
                                        dispatch(
                                          leftPanelFilters({
                                            main_key: topic.main_key,
                                            filter_key:
                                              category.filter_key,
                                            title: category.title,
                                            image: category.image,
                                            main_sub_key: category.main_sub_key,
                                            sub_category: category.sub_category,
                                          })
                                        );
                                        
                                      } else {
                                          if(globalView == "AichatView" && taskFilters.some((key) => key.filter_key == category.filter_key)){

                                            let temparr = groupsList || [];
                                            let index = temparr.findIndex((group) =>  group &&  group.extraData &&  group.extraData.filterKey == category.filter_key)
                                          
                                            if(index > -1){
                                              let tempdata = temparr[index]
                                              if(tempdata.extraData.filterKey === localStorage.getItem("staff_id")){
                                                index = temparr.findIndex((gd)=> gd.extraData.mainKey == "myr_support")
                                                tempdata = temparr[index]
                                              }
                                              tempdata.unreadCount = 0
                                              let data = (
                                                Object.values({ ...temparr, [index]: tempdata })
                                              )
                                              setGroupsList(data.slice());
                                            }

                                          }else{                                          
                                          if (topic && topic.categories && topic.categories.filter((category) => (category.filter_key != localStorage.getItem("staff_id")) && (adminAsStaff ? category.is_active == 1 : category.contact_role && category.contact_role ? (category.contact_role != 4 && category.is_active == 1) : category.is_active == 1)).length ==  taskFilters.filter((key) => key.main_key != "date_range_filter").length) {
                                            dispatch(leftPanelFilters("","", "hold_range_filter"))
                                              dispatch(
                                                leftPanelFilters({
                                                  main_key: topic.main_key,
                                                  filter_key: category.filter_key,
                                                  title: category.title,
                                                  image: category.image,
                                                  main_sub_key: category.main_sub_key,
                                                  sub_category: category.sub_category,
                                                })
                                              );                                              
                                          }else{
                                          dispatch(
                                            leftPanelFilters({
                                              main_key: topic.main_key,
                                              filter_key: category.filter_key,
                                              title: category.title,
                                              image: category.image,
                                              main_sub_key: category.main_sub_key,
                                              sub_category: category.sub_category,
                                            })
                                          );
                                          }
                                          }
                                        if (pageName == "Dashboard" || pageName == "dashboard" || ["my-request"].includes(leftPanelTab)) {
                                        }
                                        if (leftPanelTab !== "my-request") {
                                        dispatch(updateTaskList([]));
                                        }

                                        if(pageName == "Chat"){
                                          dispatch(setOpenGeneralChatGroup(false));
                                        }
                                        dispatch(getGroupDetails({}))
                                        dispatch(setFetchedTaskandMessages([]))
                                        dispatch(setClearmessage());
                                        dispatch(clearChatTaskList());

                                        localStorage.setItem("selectedTopic", JSON.stringify({id: category.filter_key, title: category.title, main_key: topic.main_key, is_active: category.is_active, main_key_title: topic.title, main_sub_key: category.main_sub_key, contact_role : category.contact_role, total_notification_count: category.total_notification_count, thread_id : category.thread_id,filter_key: category.filter_key,image : category.image}))

                                          if (pageName != "dashboardnew" && pageName != "MyEmail" && !["my-email" ,"my-request"].includes(leftPanelTab)) {
                                            handleCreateChatGroup(topic.main_key, category.main_sub_key,category.filter_key, category.total_notification_count.chat_count > 20 ? category.total_notification_count.chat_count : 20) 
                                          }
                                          
                                          let temparr = groupsList
                                          let index = temparr && temparr.length > 0 && temparr.findIndex((group) =>  group &&  group.extraData &&  group.extraData.filterKey == category.filter_key)
                                        
                                          if(index > -1){
                                            let tempdata = temparr[index]
                                            if(tempdata && tempdata.extraData.filterKey == localStorage.getItem("staff_id")){
                                              index = temparr.findIndex((gd)=> gd.extraData.filterKey == "myrai_support")
                                              tempdata = temparr[index]
                                            }
                                            if(tempdata && tempdata.unreadCount){
                                              tempdata.unreadCount = 0
                                            }
                                          
                                              temparr[index] = tempdata
                                            setGroupsList(temparr.slice());
                                          }
                                        }
                                        if (category.total_notification_count && category.total_notification_count.chat_count) {
                                          localStorage.setItem("unread_chat_notification_count", category.total_notification_count.chat_count)
                                          
                                        }
                                        if (localStorage.getItem("fav_dashboard") == "AichatView") {
                                          dispatch(setVirtualSpaceNotificationCount(projectId, -category.total_notification_count.chat_count, 1, "chat"));
                                          dispatch(setSpaceNotificationCount(projectId, -category.total_notification_count.chat_count, 1, "chat"));
                                          dispatch(chatUnreadMessageCount(category.chat_data && category.chat_data.groupId, 'remove', "chatMSG"))
                                        }
                                        if(category.filter_key == "myrai_support"){
                                          let temparr = groupsList
                                          let index = groupsList && groupsList.findIndex((gd)=> gd.extraData && gd.extraData.mainKey && gd.extraData.mainKey == "myr_support")
                                          if (index > -1) {
                                            let tempdata = temparr[index]
                                            if(tempdata){
                                              tempdata.unreadCount = 0
                                              let data = (Object.values({ ...temparr, [index]: tempdata }));
                                              setGroupsList(data.slice());
                                              }
                                            }
                                        }
                                      dispatch(setSelectedDateForChatai(new Date()));
                                      dispatch(setFetchedTaskandMessages([]));
                                    }
                                    }
                                    dispatch(unreadTaskComment(topic.main_key))
                                  }}
                                  // onMouseOver={()=>{
                                  //   if(parseInt(category.filter_key) > 0 || category.filter_key == "myrai_support"){
                                  //     setShowGearIcon(category.filter_key)
                                  //   }
                                  // }}
                                  // onMouseLeave={()=>{setShowGearIcon(0)}}
                                  key={index}
                                  className={`position-relative task-sub-list w-100 py-3 ps-10px pe-10px rounded-0 mb-0 on-hover-active-toggle-img text-on-hover-active-green hovertextgreen d-flex align-items-center w-100 ${ localStorage.getItem("user_type") == "staff" && leftPanelTab == "my-staff" ?  "":category.is_active == 0 ? "d-none" : ""} ${(activeClassTask &&
                                    activeClassTask.length) ||
                                     pinDelayfilter.includes(category.filter_key) ||
                                    (pageName == "benchmarkList" &&
                                      (topic.main_key == "proposals" ||
                                        topic.main_key == "providers") &&
                                      category.filter_key ==
                                      filterBenchmarkCategory)
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                    {category &&
                                      category.tasks &&
                                      category.tasks.length ? (
                                      <></>
                                    ) : (
                                      <></>
                                    )}
                                    <a href="#/"
                                      id={`${topic.main_key}_${category.filter_key}`}
                                      className="accordian-left-title w-100 d-flex position-relative overflow-visible "
                                    >
                                      <div className="d-flex align-items-center w100minus100per comman_action_icon">
                                        {[
                                          "my_people",
                                          "my_staff",
                                          "my_contact",
                                          "my_operator",
                                          "request_people",
                                          "my_customers",
                                          "myr_customers",
                                          "providers",
                                          "task_topic",
                                          "requested_task",
                                        ].includes(topic.main_key) ? (
                                          <div className="d-flex align-items-center">
                                          <div
                                            className={`h40w40 d-flex action_icon with_bg rounded-5 position-relative  ${category.main_sub_key == "people" || category.main_sub_key == "team"
                                              ? "comman-image-box rounded-circle"
                                              : ""
                                              }`}
                                          >
                                            {category.main_sub_key == "team" ? (
                                              topic.main_key == "my_people" ? (
                                                <UsersThreeIcon className="HW24 active-svg" />
                                              ) : topic.main_key =="task_topic" &&
                                                category.image ? (
                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${category.image}')`, }}></div>
                                              ) : (
                                                <UsersThreeIcon className="HW24 active-svg" />
                                              )
                                            ) :
                                              category.title == "All Requested Task" ||
                                                ((topic.main_key ==  "request_people" || topic.main_key ==  "providers" || topic.main_key == "requested_task") &&!category.image) ? (
                                                  <>{
                                                  topic.main_key == "providers" ?
                                                   groupsList && pageName != "Chat" && groupsList.filter((gd) => gd.extraData && parseInt(gd.extraData.filterKey) == parseInt(category.filter_key))[0] &&   groupsList && groupsList.filter((gd) => parseInt(gd.extraData.filterKey) == parseInt(category.filter_key))[0].displayPicture != ""
                                                   ?
                                                      <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${ ATTACH_BASE_URL.concat(groupsList && groupsList.filter((gd) => gd.extraData && parseInt(gd.extraData.filterKey) == parseInt(category.filter_key))[0].displayPicture) }')`, }}>
                                                      </div>
                                                  :
                                                  <UsersThreeIcon className="HW24 active-svg" />:
                                                <HashIcon className="HW24 active-svg" />
                                               } </> ) : category.filter_key && category.filter_key.split("") .some((c) => c == "_") ? (
                                                <UsersThreeIcon className="HW24 active-svg" />
                                              ) : topic.main_key ==  "task_topic" ? (
                                                <UsersThreeIcon className="HW24 active-svg" />
                                              ) : (
                                                <div
                                                  className="comman-bg-img w-100 h-10 bg-style-cover rounded-circle"
                                                  style={{
                                                    backgroundImage: `url('${category.image}')`,
                                                  }}
                                                ></div>
                                              )}
                                              {category.chat_data && ((burgerMenuTopicTyping && burgerMenuTopicTyping.includes(category.chat_data.groupId)) || (bergerMenuTopicAnalysis && bergerMenuTopicAnalysis.includes(category.chat_data.groupId))) ?
                                                <div className="typing-main typing-with-profile">
                                                  <Typing />
                                                </div>
                                                : <></>}
                                          </div>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="comman_action_icon list-icon position-relative">
                                              <div className={`position-relative h40w40 rounded-5 light-theme-img ${topic.main_key == "myr_support"  ? category.user_type == "contact" ? "contact_user rounded-circle" : "operator_user rounded-circle" : "action_icon comman_action_icon with_bg" }`}>
                                                {category.filter_key == "inbox"
                                                        ? 
                                                        <Tray className="HW18" />
                                                        : category.filter_key == "important"
                                                          ?  <TagChevron className="HW18" />
                                                          : category.filter_key == "sent" 
                                                            ?  <PaperPlanRight className="HW18" />
                                                            : category.filter_key == "draft" 
                                                              ?  <FileIcon className="HW18" />
                                                              : category.filter_key == "shared" 
                                                                ?  <ShareNetworkIcon className="HW18" /> 
                                                                : category.filter_key == "spam"  ? 
                                                                  <WarningOctagonIcon className="HW18" /> 
                                                                  : category.filter_key == "scheduled" 
                                                                  ?  <CalendarIcon className="HW18" />
                                                                    : category.filter_key == "all" ? (
                                                  <ListIcon
                                                    className="HW18 active-svg"
                                                  />
                                                ) : category.filter_key == "important" ? (
                                                  <ImportantIcon className="active-svg" />
                                                ) : category.filter_key == "sent" ? (
                                                  <SentIcon className="active-svg" />
                                                ) : category.filter_key == "draft" ? (
                                                  <FileIcon className="HW18 active-svg" />
                                                ) : category.filter_key < 0 &&
                                                  topic.main_key != "user_journey" ? (
                                                  <UserIcon className="HW18 active-svg" />
                                                ) : !isNaN( parseInt( category.filter_key )) && topic.main_key == "user_journey" ? (
                                                  <UsersThreeIcon className="HW18 active-svg" />
                                                ) : (
                                                  <>  
                                                  {groupsList && pageName != "Chat" && groupsList.filter((gd) =>gd.extraData &&  parseInt(gd.extraData.filterKey) == parseInt(category.filter_key) ||parseInt(gd.extraData.filterKey) )[0] && groupsList.filter((gd) => parseInt(gd.extraData.filterKey) == parseInt(category.filter_key))[0] != undefined &&  groupsList.filter((gd) => parseInt(gd.extraData.filterKey) == parseInt(category.filter_key))[0].displayPicture != ""                                                  
                                                   ? <>
                                                    {category.role == 1 ?  
                                                      <span className="bg-transparent absolutecrownbadge  ps-0 border-0  badge-custom d-flex  light-pill bg-none">
                                                         <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
                                                       </span> : <></>}
                                                       {category.role == 2 ?  
                                                      <span className="bg-base-header-color absoluteiconbadge ps-0 border-0  badge-custom d-flex  light-pill bg-none">
                                                          <UserCircleGearIcon className="HW24" />
                                                        </span>
                                                       : <></>}
                                                      <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${ ATTACH_BASE_URL.concat(groupsList && groupsList.filter((gd) => gd.extraData && parseInt(gd.extraData.filterKey) == parseInt(category.filter_key))[0].displayPicture) }')`, }}>                                                        
                                                      </div>
                                                      </>
                                                  :<>
                                                  {
                                                    topic.main_key == "equipments"
                                                    ? 
                                                         <UsersThreeIcon className="HW24 active-svg" />
                                                    : topic.main_key == "myr_support" ? 
                                                    <>
                                                      {category.role == 1 ?  
                                                      <span className="bg-transparent absolutecrownbadge  ps-0 border-0  badge-custom d-flex  light-pill bg-none">
                                                         <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
                                                       </span> : <></>}
                                                      {category.role == 2 ?  
                                                      <span className="bg-base-header-color absoluteiconbadge ps-0 border-0  badge-custom d-flex  light-pill bg-none">
                                                          <UserCircleGearIcon className="HW24" />
                                                       </span> : <></>}
                                                      <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{backgroundImage: `url('${category.image}')`}}> </div>
                                                    </>
                                                     :
                                                     <>
                                                        <HashIcon className="HW18 active-svg" />
                                                        </>
                                                  }
                                                  </>}
                                                  </> 
                                                )}
                                              </div>
                                              {totalChatTaskCounts > 0 ?
                                              <div class="badge-custom badge-green d-flex h18w18 rounded-circle with-bg topicNotification" data-filter-key-cnt="assigned_to_me"><span class="m-auto ps-0 ">{totalChatTaskCounts}</span></div>
                                              : <></> }
                                            </div>
                                          </>
                                        )}
                                    <div className={` ps-2 w-100 ${leftPanelTab == "my-calendar" ? "d-flex" : ""}` }>
                                      <div className="d-flex justify-content-between w-100">
                                        <div
                                            className={`title-font text-truncate  left_panel_toggle categorytitle w100minus100per ${leftPanelTab == "my-space" ? "fw-semibold " : ""} ${activeClassTask &&
                                            activeClassTask.length
                                            ? ""
                                            : ""
                                            }  c-font f-13`}
                                          >
                                          {
                                          (get_language && category.languages ? category.languages[get_language] : _l(category.title))
                                          }
                                        </div>
                                        {/* Status count start */}
                                        { category.total_task && (category.total_task.is_requested > 0 || category.total_task.not_started > 0 || category.total_task.ongoing > 0) ?
                                        <div className="align-items-center d-flex merge gap-1">
                                          <div className="d-flex align-items-center gap-1">
                                            {category.total_task.is_requested > 0 ?
                                            <div className="align-items-center badge-green bg-warning d-flex f-10 f-black h18w18 justify-content-center rounded-circle">{category.total_task.is_requested}</div>
                                            : <></> }
                                            {category.total_task.not_started > 0 ?
                                            <div className="align-items-center badge-green in-progress-white d-flex f-10 f-black h18w18 justify-content-center rounded-circle">{category.total_task.not_started}</div>
                                            : <></> }
                                            {category.total_task.ongoing > 0 ?
                                            <div className="align-items-center badge-green bg-blue d-flex f-10 f-black h18w18 justify-content-center rounded-circle">{category.total_task.ongoing}</div>
                                            : <></> }
                                          </div>
                                        </div>
                                        : <></>}
                                        {/* Status count over */}
                                        {/* {leftPanelTab == "my-request" ? <div
                                          className={`${totalChatTaskCounts > 0 ? category.filter_key == "overdue" ? "" : "ms-1 with-bg h18w18 justify-content-center position-static my-auto border-1": category.filter_key == "overdue" && category.tasks_count > 0 ? "" : "d-none"} 
                                          ${topic.main_key == "proposals" || topic.main_key == "providers" ? 'rounded-pill badge-custom h18w18 position-static my-auto' : ''}
                                          ${topic.main_key == "my_task" && category.filter_key == "overdue" ? "bg-danger fw-bold p-0" : "badge-green"}
                                          ${category.filter_key == "overdue" ? "h18w18 d-flex badge badge-green rounded-pill text-nowrap list-toggle-view-none" : "ms-1 h18w18 rounded-circle d-flex right-accordian-header-pill "}
                                          `}
                                          data-filter-key-cnt={`${category.filter_key}`}
                                        >
                                        <span className={`m-auto ps-0 ${category.filter_key == "overdue" ? "color-white" : ""}`}>
                                          { totalChatTaskCounts }
                                        </span>
                                      </div> : <></>} */}
                                        { category && category.chat_data && category.chat_data.lastMessage == "" ? <React.Fragment></React.Fragment>
                                                : 
                                                category && category.chat_data && category.chat_data.lastMessageDate ? 
                                                 <div className="c-font f-10 fw-light m-w100px rounded-pill text-truncate">
                                                  <span className={`text-truncate ps-0`}>
                                                    { category.chat_data.lastMessageDate && moment(category.chat_data.lastMessageDate).format("L") == moment(new Date()).format("L") ? moment(category.chat_data.lastMessageDate).format("hh:mm A")
                                                    : category.chat_data.lastMessageDate ? moment(category.chat_data.lastMessageDate).format("DD/MM/YYYY") : <></>
                                                    }
                                                  </span>
                                                  </div> : <></> 
                                              }
                                        { allMAilMessages ? 
                                           <div
                                          className={`right-accordian-header transparent-bg`}
                                        >
                                          <div className="d-flex align-items-center">
                                            {((category.email ||
                                              topic.main_key ==
                                              "providers") &&
                                              allMAilMessages &&
                                              (
                                                allMAilMessages
                                                  .filter(
                                                    (convr) =>
                                                      convr["conversation"][
                                                        "messages"
                                                      ].filter((mess) =>
                                                        [
                                                          ...(category.member_list ||
                                                            []),
                                                          ...((
                                                            (topic.main_key == "my_emails" ? category.email.map(item => item.email) : category.email) || ""
                                                          ) || []),
                                                        ].includes(
                                                          extractEmails(
                                                            mess["payload"][
                                                              "headers"
                                                            ].filter(
                                                              (filter) =>
                                                                filter[
                                                                "name"
                                                                ] == "From"
                                                            )[0]["value"]
                                                          )[0]
                                                        )
                                                      ).length > 0
                                                  )
                                                  .filter(
                                                    (con) =>
                                                      con["thread"][
                                                        "mentions"
                                                      ].filter(
                                                        (men) =>
                                                          men["is_read"] == 0
                                                      ).length >
                                                      0 ==
                                                      true
                                                  ) || []
                                              ).length) ||
                                              ((inboxMails || [])
                                                .filter(
                                                  (conversation) =>
                                                    conversation["conversation"][
                                                      "messages"
                                                    ].filter(
                                                      (message) =>
                                                        message[
                                                          "labelIds"
                                                        ].includes(
                                                          category.filter_key
                                                            .toString()
                                                            .toUpperCase()
                                                        ) == true &&
                                                        [
                                                          "INBOX",
                                                          "IMPORTANT",
                                                          "SENT",
                                                          "DRAFT",
                                                        ]
                                                          .filter(
                                                            (type) =>
                                                              type !=
                                                              category.filter_key.toUpperCase()
                                                          )
                                                          .includes(
                                                            category.filter_key
                                                              .toString()
                                                              .toUpperCase()
                                                          ) == false
                                                    ).length > 0
                                                )
                                                .filter(
                                                  (con) =>
                                                    con["thread"][
                                                      "mentions"
                                                    ].filter(
                                                      (men) => men["is_read"] == 0
                                                    ).length >
                                                    0 ==
                                                    true
                                                ).length -
                                                (
                                                  (allImportantMessages || [])
                                                    .filter(
                                                      (convr) =>
                                                        convr["conversation"][
                                                          "messages"
                                                        ].filter(
                                                          (mess) =>
                                                            !mailFilters.includes(
                                                              extractEmails(
                                                                mess["payload"][
                                                                  "headers"
                                                                ].filter(
                                                                  (filter) =>
                                                                    filter[
                                                                    "name"
                                                                    ] == "From"
                                                                )[0]["value"]
                                                              )[0]
                                                            )
                                                        ).length > 0
                                                    )
                                                    .filter(
                                                      (con) =>
                                                        con["thread"][
                                                          "mentions"
                                                        ].filter(
                                                          (men) =>
                                                            men["is_read"] == 0
                                                        ).length >
                                                        0 ==
                                                        true
                                                    ) || []
                                                ).length >
                                                0 &&
                                                [
                                                  "all",
                                                  "inbox",
                                                  "sent",
                                                  "draft",
                                                ].includes(
                                                  category.filter_key
                                                )) ||
                                              (["important"].includes(
                                                category.filter_key
                                              ) == true &&
                                                (
                                                  (
                                                    allImportantMessages || []
                                                  ).filter(
                                                    (con) =>
                                                      con["thread"][
                                                        "mentions"
                                                      ].filter(
                                                        (men) =>
                                                          men["is_read"] == 0
                                                      ).length >
                                                      0 ==
                                                      true
                                                  ) || []
                                                ).length > 0) ? (
                                              <div
                                                className={`rounded-circle badge-green h18w18 d-flex p-0 me-1`}
                                              >
                                                <span className="m-auto #3">
                                                  {category.email ||
                                                    topic.main_key ==
                                                    "providers"
                                                    ? (
                                                      allMAilMessages
                                                        .filter(
                                                          (convr) =>
                                                            convr[
                                                              "conversation"
                                                            ][
                                                              "messages"
                                                            ].filter((mess) =>
                                                              [
                                                                ...(category.member_list ||
                                                                  []),
                                                                ...((
                                                                  category.email ||
                                                                  ""
                                                                ).split(
                                                                  ","
                                                                ) || []),
                                                              ].includes(
                                                                extractEmails(
                                                                  mess[
                                                                    "payload"
                                                                  ][
                                                                    "headers"
                                                                  ].filter(
                                                                    (
                                                                      filter
                                                                    ) =>
                                                                      filter[
                                                                      "name"
                                                                      ] ==
                                                                      "From"
                                                                  )[0][
                                                                  "value"
                                                                  ]
                                                                )[0]
                                                              )
                                                            ).length > 0
                                                        )
                                                        .filter(
                                                          (con) =>
                                                            con["thread"][
                                                              "mentions"
                                                            ].filter(
                                                              (men) =>
                                                                men[
                                                                "is_read"
                                                                ] == 0
                                                            ).length >
                                                            0 ==
                                                            true
                                                        ) || []
                                                    ).length
                                                    : category.filter_key ==
                                                      "important"
                                                      ? allImportantMessages.filter(
                                                        (con) =>
                                                          con["thread"][
                                                            "mentions"
                                                          ].filter(
                                                            (men) =>
                                                              men["is_read"] ==
                                                              0
                                                          ).length >
                                                          0 ==
                                                          true
                                                      ).length
                                                      : inboxMails
                                                        .filter(
                                                          (conversation) =>
                                                            conversation[
                                                              "conversation"
                                                            ][
                                                              "messages"
                                                            ].filter(
                                                              (message) =>
                                                                message[
                                                                  "labelIds"
                                                                ].includes(
                                                                  category.filter_key
                                                                    .toString()
                                                                    .toUpperCase()
                                                                ) == true &&
                                                                [
                                                                  "INBOX",
                                                                  "IMPORTANT",
                                                                  "SENT",
                                                                  "DRAFT",
                                                                ]
                                                                  .filter(
                                                                    (type) =>
                                                                      type !=
                                                                      category.filter_key
                                                                        .toString()
                                                                        .toUpperCase()
                                                                  )
                                                                  .includes(
                                                                    category.filter_key
                                                                      .toString()
                                                                      .toUpperCase()
                                                                  ) == false
                                                            ).length > 0
                                                        )
                                                        .filter(
                                                          (con) =>
                                                            con["thread"][
                                                              "mentions"
                                                            ].filter(
                                                              (men) =>
                                                                men[
                                                                "is_read"
                                                                ] == 0
                                                            ).length >
                                                            0 ==
                                                            true
                                                        ).length -
                                                      (
                                                        allImportantMessages
                                                          .filter(
                                                            (convr) =>
                                                              convr[
                                                                "conversation"
                                                              ][
                                                                "messages"
                                                              ].filter(
                                                                (mess) =>
                                                                  !mailFilters.includes(
                                                                    extractEmails(
                                                                      mess[
                                                                        "payload"
                                                                      ][
                                                                        "headers"
                                                                      ].filter(
                                                                        (
                                                                          filter
                                                                        ) =>
                                                                          filter[
                                                                          "name"
                                                                          ] ==
                                                                          "From"
                                                                      )[0][
                                                                      "value"
                                                                      ]
                                                                    )[0]
                                                                  )
                                                              ).length > 0
                                                          )
                                                          .filter(
                                                            (con) =>
                                                              con["thread"][
                                                                "mentions"
                                                              ].filter(
                                                                (men) =>
                                                                  men[
                                                                  "is_read"
                                                                  ] == 0
                                                              ).length >
                                                              0 ==
                                                              true
                                                          ) || []
                                                      ).length}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}

                                            {((category.email ||
                                              topic.main_key ==
                                              "providers") &&
                                              allMAilMessages &&
                                              (
                                                allMAilMessages.filter(
                                                  (convr) =>
                                                    convr["conversation"][
                                                      "messages"
                                                    ].filter(
                                                      (mess) =>
                                                        [
                                                          ...(category.member_list ||
                                                            []),
                                                          ...((
                                                            (topic.main_key == "my_emails" ? category.email.map((item) => item.email) : category.email) || ""
                                                          ) || []),
                                                        ].includes(
                                                          extractEmails(
                                                            mess["payload"][
                                                              "headers"
                                                            ].filter(
                                                              (filter) =>
                                                                filter[
                                                                "name"
                                                                ] == "From"
                                                            )[0]["value"]
                                                          )[0]
                                                        ) &&
                                                        mess[
                                                          "labelIds"
                                                        ].includes("UNREAD") ==
                                                        true
                                                    ).length > 0
                                                ) || []
                                              ).length) ||
                                              ((inboxMails || []).filter(
                                                (conversation) =>
                                                  conversation["conversation"][
                                                    "messages"
                                                  ].filter(
                                                    (message) =>
                                                      message[
                                                        "labelIds"
                                                      ].includes(
                                                        category.filter_key
                                                          .toString()
                                                          .toUpperCase()
                                                      ) == true &&
                                                      message[
                                                        "labelIds"
                                                      ].includes("UNREAD") ==
                                                      true &&
                                                      [
                                                        "INBOX",
                                                        "IMPORTANT",
                                                        "SENT",
                                                        "DRAFT",
                                                      ]
                                                        .filter(
                                                          (type) =>
                                                            type !=
                                                            category.filter_key.toUpperCase()
                                                        )
                                                        .includes(
                                                          category.filter_key
                                                            .toString()
                                                            .toUpperCase()
                                                        ) == false
                                                  ).length > 0
                                              ).length -
                                                (
                                                  (
                                                    allImportantMessages || []
                                                  ).filter(
                                                    (convr) =>
                                                      convr["conversation"][
                                                        "messages"
                                                      ].filter(
                                                        (mess) =>
                                                          !mailFilters.includes(
                                                            extractEmails(
                                                              mess["payload"][
                                                                "headers"
                                                              ].filter(
                                                                (filter) =>
                                                                  filter[
                                                                  "name"
                                                                  ] == "From"
                                                              )[0]["value"]
                                                            )[0]
                                                          ) &&
                                                          mess[
                                                            "labelIds"
                                                          ].includes("UNREAD") ==
                                                          true
                                                      ).length > 0
                                                  ) || []
                                                ).length >
                                                0 &&
                                                [
                                                  "all",
                                                  "inbox",
                                                  "sent",
                                                  "draft",
                                                ].includes(
                                                  category.filter_key
                                                )) ||
                                              (["important"].includes(
                                                category.filter_key
                                              ) == true &&
                                                (
                                                  (
                                                    allImportantMessages || []
                                                  ).filter(
                                                    (imm) =>
                                                      imm["conversation"][
                                                        "messages"
                                                      ].filter(
                                                        (message) =>
                                                          message[
                                                            "labelIds"
                                                          ].includes("UNREAD") ==
                                                          true
                                                      ).length > 0
                                                  ) || []
                                                ).length > 0) ? (
                                              <div
                                                className={`rounded-circle badge-green email-orange d-flex p-0 me-1 ${category ? "h18w18" : "d-none"
                                                  }`}
                                                data-filter-key-cnt={`mail-counter-${category.email}`}
                                              >
                                                <span className="m-auto">
                                                  {category.email ||
                                                    topic.main_key ==
                                                    "providers"
                                                    ? (
                                                      allMAilMessages.filter(
                                                        (convr) =>
                                                          convr[
                                                            "conversation"
                                                          ][
                                                            "messages"
                                                          ].filter(
                                                            (mess) =>
                                                              [
                                                                ...(category.member_list ||
                                                                  []),
                                                                ...((
                                                                  (topic.main_key == "my_emails" ? category.email.map((item) => item.email) : category.email) ||
                                                                  ""
                                                                ) || []),
                                                              ].includes(
                                                                extractEmails(
                                                                  mess[
                                                                    "payload"
                                                                  ][
                                                                    "headers"
                                                                  ].filter(
                                                                    (
                                                                      filter
                                                                    ) =>
                                                                      filter[
                                                                      "name"
                                                                      ] ==
                                                                      "From"
                                                                  )[0][
                                                                  "value"
                                                                  ]
                                                                )[0]
                                                              ) &&
                                                              mess[
                                                                "labelIds"
                                                              ].includes(
                                                                "UNREAD"
                                                              ) == true
                                                          ).length > 0
                                                      ) || []
                                                    ).length
                                                    : category.filter_key ==
                                                      "important"
                                                      ? allImportantMessages.filter(
                                                        (imm) =>
                                                          imm["conversation"][
                                                            "messages"
                                                          ].filter(
                                                            (message) =>
                                                              message[
                                                                "labelIds"
                                                              ].includes(
                                                                "UNREAD"
                                                              ) == true
                                                          ).length > 0
                                                      ).length
                                                      : inboxMails.filter(
                                                        (conversation) =>
                                                          conversation[
                                                            "conversation"
                                                          ]["messages"].filter(
                                                            (message) =>
                                                              message[
                                                                "labelIds"
                                                              ].includes(
                                                                category.filter_key
                                                                  .toString()
                                                                  .toUpperCase()
                                                              ) == true &&
                                                              message[
                                                                "labelIds"
                                                              ].includes(
                                                                "UNREAD"
                                                              ) == true &&
                                                              [
                                                                "INBOX",
                                                                "IMPORTANT",
                                                                "SENT",
                                                                "DRAFT",
                                                              ]
                                                                .filter(
                                                                  (type) =>
                                                                    type !=
                                                                    category.filter_key
                                                                      .toString()
                                                                      .toUpperCase()
                                                                )
                                                                .includes(
                                                                  category.filter_key
                                                                    .toString()
                                                                    .toUpperCase()
                                                                ) == false
                                                          ).length > 0
                                                      ).length -
                                                      (
                                                        allImportantMessages.filter(
                                                          (convr) =>
                                                            convr[
                                                              "conversation"
                                                            ][
                                                              "messages"
                                                            ].filter(
                                                              (mess) =>
                                                                !mailFilters.includes(
                                                                  extractEmails(
                                                                    mess[
                                                                      "payload"
                                                                    ][
                                                                      "headers"
                                                                    ].filter(
                                                                      (
                                                                        filter
                                                                      ) =>
                                                                        filter[
                                                                        "name"
                                                                        ] ==
                                                                        "From"
                                                                    )[0][
                                                                    "value"
                                                                    ]
                                                                  )[0]
                                                                ) &&
                                                                mess[
                                                                  "labelIds"
                                                                ].includes(
                                                                  "UNREAD"
                                                                ) == true
                                                            ).length > 0
                                                        ) || []
                                                      ).length}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {["my_customs"].includes(
                                              topic.main_key
                                            ) ? (
                                              <div>
                                                <div className="d-flex align-items-center right-pill-addtask">
                                                  <div className="d-flex align-items-center text-truncate">
                                                    <div className="c-list-icon two-step-hover-icon">
                                                      <div
                                                        className="h25w25 comman-round-box  d-flex align-items-center"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          editCustomTopic(
                                                            category.filter_key,
                                                            category.email,
                                                            category.title
                                                          );
                                                        }}
                                                      >
                                                        <PencilSimpleLineIcon
                                                          className="HW16"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>  
                                        : <></> }
                                      </div>
                                      <div className={`d-flex justify-content-between align-items-center color-white-60 mt2px ${leftPanelTab == "my-calendar" ? "" : "w-100"}`}>
                                            <>
                                              {category && category.chat_data ? (category && category.chat_data && category.chat_data.lastMessage && (category.chat_data.lastMessage.trim() == "" || category.chat_data.lastMessage == null || category.chat_data.lastMessage == undefined)) ? <div className="c-font f-10 opacity-60">  {_l("l_no_new_messages")}</div> :
                                                <div className="c-font f-9 text-truncate pe-2 w100minus100per hover-span">
                                                  { category && category.chat_data && category.chat_data.lastMessage 
                                                    ?  <b style={{fontWeight:600}}>{category.chat_data.lastMsgUserName && ReactHtmlParser(category.chat_data.lastMsgUserName.split(" ")[0])}{": "}</b>
                                                    : <></>
                                                  }
                                                  {category && category.chat_data && ReactHtmlParser(category.chat_data.lastMessage)}
                                                </div>
                                               : leftPanelTab == "my-space" && <div className="c-font f-10 ">  {_l("l_no_new_messages")}</div> } 
                                                {/* Status count start */}
                                              <div className="d-none align-items-center d-flex merge gap-1">
                                                <div className="d-flex align-items-center gap-1">
                                                <div className="align-items-center badge-green bg-warning d-flex f-10 f-black h18w18 justify-content-center rounded-circle">10</div>
                                                <div className="align-items-center badge-green in-progress-white d-flex f-10 f-black h18w18 justify-content-center rounded-circle">10</div>
                                                <div className="align-items-center badge-green bg-blue d-flex f-10 f-black h18w18 justify-content-center rounded-circle">10</div>
                                                </div>
                                                {/* Status count over */}
                                        {leftPanelTab != "my-request" ? <div
                                          className={`${totalChatTaskCounts > 0 ? category.filter_key == "overdue" ? "" : "ms-0 with-bg h18w18 justify-content-center position-static my-auto border-1": category.filter_key == "overdue" && category.tasks_count > 0 ? "" : "d-none"} 
                                          ${topic.main_key == "proposals" || topic.main_key == "providers" ? 'rounded-pill badge-custom h18w18 position-static my-auto' : ''}
                                          ${topic.main_key == "my_task" && category.filter_key == "overdue" ? "bg-danger fw-bold p-0" : "badge-green"}
                                          ${category.filter_key == "overdue" ? "h18w18 d-flex badge badge-green rounded-pill text-nowrap list-toggle-view-none" : "ms-1 rounded-circle d-flex right-accordian-header-pill h18w18"}
                                          `}
                                          data-filter-key-cnt={`${category.filter_key}`}
                                        >
                                        <span className={`m-auto ps-0 ${category.filter_key == "overdue" ? "color-white" : ""}`}>
                                          { totalChatTaskCounts }
                                        </span>
                                      </div> : <></>}
                                        {
                                          ["proposals", "providers", "equipments", "my_contact", "my_operator", "my_staff", "myr_support", "my_emails"].includes(topic.main_key)
                                          ?<>
                                          {showGearIcon == category.filter_key || selectedTopicIds.includes(category.filter_key) || filterfor3dPlan.includes(category.filter_key) || missedcallCounts > 0 || scheduledcallCounts > 0 ?
                                           <div className={` d-flex comman_action_icon align-items-center`}>
                                            <a href="#/"  
                                              className={`${topic.main_key == "equipments" && globalView == "spaceView" ? "" : "d-none"} ${(showGearIcon != "myrai_support" && category.filter_key != "myrai_support") && (showGearIcon == category.filter_key || selectedTopicIds.includes(category.filter_key)) ? "with_separator_10" : ""}`}
                                              onClick={(e) => {e.stopPropagation();
                                                 handleFilter(category.filter_key)}}
                                               >
                                                <div className="h25w25 d-flex">
                                              <EyeIcon
                                                className="HW18 green-light-grey"
                                              />
                                              </div>
                                            </a>
                                            
                                            
                                            {(selectedTopicIds.includes(category.filter_key)) ? 
                                            <>
                                            {((category.filter_key == "myrai_support") || (showGearIcon != category.filter_key && !selectedTopicIds.includes(category.filter_key) && scheduledcallCounts == 0) || (pageName === "dashboardnew") || ["my-request","my-calendar", "my-staff"].includes(leftPanelTab)) || !Number.isInteger(Number(category.filter_key))  ? <></> :
                                            <> 
                                            <div className={`with_separator_10 before-block ps-0 h-100  ${missedcallCounts > 0 ? 'd-none' : ''}`}></div> 
                                            <a href="#/"  
                                              className={` d-flex w-100 pe-0 position-relative ps-10px gap-2 ${scheduledcallCounts > 0 ? '' : ''}`}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                if((!projectId || isNaN(projectId) || Number(projectId) < 0))
                                                {
                                                  showError("l_please_select_project");
                                                  return;
                                                }
                                                if (topic.main_key == "my_emails") {
                                                  setshowAddCategoryForMe(true);
                                                  setActiveTopicKey(topic.main_key);
                                                  setIsEditTopic({ id: category.filter_key, title: category.title, email : category.email.map(item => item.email) });
                                                }else{
                                                  accessPremiumFeature(projectId, () => {
                                                    setProviderListingCategory({id: category.filter_key, title: category.title, main_key: topic.main_key, is_active: category.is_active, main_key_title: topic.title, main_sub_key: category.main_sub_key});
                                                  });
                                                }
                                              }}
                                               >
                                                {topic.main_key == "my_emails" ? 
                                                    <PencilSimpleLineIcon
                                                    className="HW18 green-light-grey"
                                                  /> 
                                                  :
                                                  <GearSixIcon
                                                    className="HW18 green-light-grey"
                                                  />
                                                }
                                                { scheduledcallCounts   > 0 ? <div class="badge-custom bagdeabsright  d-flex rounded-pill border-0 h18w18 position-static"><span class="ps-0">{scheduledcallCounts}</span></div> : <></>}
                                            </a></>}
                                             
                                            </> : <></>}
                                          </div> : <></>}
                                          </>
                                          :
                                          <React.Fragment></React.Fragment>
                                        }
                                        </div>
                                              </>
                                          
                                      </div>
                                    </div>
                                      </div>
                                      
                                    </a>
                                    
                                    <div
                                      className={`right-accordian-header transparent-bg`}
                                    >
                                      <div className="d-flex align-items-center right-pill-addtask ">
                                        
                                        {
                                          topic.main_key == "my_task" && category.filter_key != "created_by_me" && category.filter_key != "assigned_to_me" && category.filter_key != "pinned" && category.filter_key != "overdue" ? (
                                            <>
                                              
                                              {showGearIcon == category.filter_key || selectedTopicIds.includes(category.filter_key) ? 
                                              <div className="comman_action_icon dropstart d-flex " onClick={(e) => e.stopPropagation()}>
                                                <div className="with_separator2_10 p-0 ms-1"></div>
                                                <a href="#/"
                                                  className="comman_action_icon ms-1" id="ActiveOfficeOptionsDropdown"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                   
                                                   
                                                   
                                                   
                                                  data-bs-auto-close="outside"
                                                >
                                                  <GearSixIcon
                                                    className="HW18 green-light-grey"
                                                  />
                                                </a>
                                                <ActionDropdown
                                                widthClass="w-170"
                                                  actions={
                                                    localStorage.getItem('selectedVirtualSpace') && !isNaN(
                                                      parseInt(
                                                        category.filter_key
                                                      )) ?
                                                      [
                                                        {
                                                          actionName: 'Share as task topic',
                                                          actionHandler: () => {
                                                            setTopicType("task_topic");
                                                            setCreateTeamModal(true);
                                                          },
                                                          actionIcon: "ListIcon",
                                                        },
                                                        {
                                                          actionName: _l("l_edit"),
                                                          actionHandler: () => {
                                                            accessPremiumFeature(projectId, () => {
                                                              setIsEditTopic({ id: category.filter_key, title: category.title })
                                                              editCategoryTopic('my_task')
                                                            });
                                                          },
                                                          actionIcon: "PencilSimpleLineIcon",
                                                        }

                                                      ] :
                                                      !isNaN(
                                                        parseInt(
                                                          category.filter_key
                                                        )) ?
                                                        [
                                                          {
                                                            actionName: _l("l_edit"),
                                                            actionHandler: () => {
                                                              accessPremiumFeature(projectId, () => {
                                                                setIsEditTopic({ id: category.filter_key, title: category.title })
                                                                editCategoryTopic('my_task')
                                                              });
                                                            },
                                                            actionIcon: "PencilSimpleLineIcon",
                                                          },
                                                          {
                                                            actionName: _l("l_delete"),
                                                            actionHandler: () => {
                                                              dispatch(
                                                                showConfirmation(
                                                                  _l("l_are_you_sure"),
                                                                  _l("l_you_want_to_delete_this_topic_with_assigh_all_task_this_tag_also_remove"),
                                                                  _l("l_yes"),
                                                                  _l("l_no"),
                                                                  undefined,
                                                                  () => { removeTaskTopic(category.filter_key); }
                                                                )
                                                              );
                                                            },
                                                            actionIcon: "TrashIcon",
                                                          }

                                                        ] : <></>
                                                  }

                                                />
                                              </div> : <></>}
                                            </>
                                          ) : <></>
                                        }
                                        {[

                                          "providers",
                                          "user_journey",
                                          "support_journey",
                                          "task_topic"
                                        ].includes(topic.main_key) ? (
                                          <React.Fragment>
                                            <div className="right-accordian-options">
                                              <div className="d-flex align-items-center">
                                                <a href="#/"
                                                  className="comman_action_icon ps-2 d-none"
                                                
                                                   
                                                  data-className="tooltip-main"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setFilterCategory(
                                                      category.filter_key
                                                    );
                                                    if (
                                                      pageName !=
                                                      "benchmarkList" &&
                                                      (topic.main_key ==
                                                        "proposals" ||
                                                        topic.main_key ==
                                                        "providers")
                                                    ) {
                                                      navigate("/schedules", {
                                                        state: {
                                                          provider_id:
                                                            category.filter_key,
                                                          provider_name:
                                                            category.title,
                                                          schedule: {},
                                                          client_id: 0,
                                                          company: "",
                                                        },
                                                      });
                                                    } else {
                                                      dispatch(
                                                        setCreateScheduledetails(
                                                          {
                                                            provider_id:
                                                              category.filter_key,
                                                            provider_name:
                                                              category.title,
                                                            schedule: {},
                                                            client_id: 0,
                                                            company: "",
                                                          }
                                                        )
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <div className="d-flex align-items-center">
                                                    <div className="action_icon with_bg position-relative on-hover-active-toggle-img h20w20 light-theme-img">
                                                      <ChartLineIcon
                                                        className="HW14"
                                                      />
                                                    </div>
                                                  </div>
                                                </a>
                                                {topic.main_key ==
                                                  "proposals" ||
                                                  topic.main_key ==
                                                  "providers" ? (
                                                  <>
                                                    <a href="#/"
                                                      className="comman_action_icon ps-2 d-none"
                                                       
                                                      data-className="tooltip-main"
                                                       
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        setFilterCategory(
                                                          category.filter_key
                                                        );
                                                        if (
                                                          pageName !=
                                                          "benchmarkList" &&
                                                          (topic.main_key ==
                                                            "proposals" ||
                                                            topic.main_key ==
                                                            "providers")
                                                        ) {
                                                          navigate(
                                                            "/schedules",
                                                            {
                                                              state: {
                                                                provider_id:
                                                                  category.filter_key,
                                                                provider_name:
                                                                  category.title,
                                                                schedule:
                                                                  userType ==
                                                                    "operator"
                                                                    ? {
                                                                      email:
                                                                        localStorage.getItem(
                                                                          "email"
                                                                        ),
                                                                    }
                                                                    : {},
                                                                client_id: 0,
                                                                company: "",
                                                              },
                                                            }
                                                          );
                                                        } else {
                                                          setFilterCategory(
                                                            category.filter_key
                                                          );
                                                          dispatch(
                                                            setCreateScheduledetails(
                                                              {
                                                                provider_id:
                                                                  category.filter_key,
                                                                provider_name:
                                                                  category.title,
                                                                schedule:
                                                                  userType ==
                                                                    "operator"
                                                                    ? {
                                                                      email:
                                                                        localStorage.getItem(
                                                                          "email"
                                                                        ),
                                                                    }
                                                                    : {},
                                                                client_id: 0,
                                                                company: "",
                                                              }
                                                            )
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <div className="d-flex align-items-center">
                                                        <div className="action_icon with_bg position-relative on-hover-active-toggle-img h20w20 light-theme-img">
                                                          <CalendarCheckIcon
                                                            className="HW14"
                                                          />
                                                        </div>
                                                      </div>
                                                    </a>
                                                    {topic.main_key ==
                                                      "proposals" && category.filter_key != "myrai_support" ? (
                                                      <>
                                                        <div className="comman_action_icon dropstart" onClick={(e) => e.stopPropagation()}>
                                                          <a href="#/"
                                                            className="comman_action_icon ps-2"
                                                            id="ActiveOfficeOptionsDropdown"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            data-bs-auto-close="outside"
                                                          >
                                                            <DotsThreeVerticalIcon
                                                              className="HW16"
                                                            />
                                                          </a>
                                                          <ActionDropdown
                                                          widthClass="w-170"
                                                            actions={[
                                                              {
                                                                actionName:
                                                                  _l("l_add"),
                                                                actionHandler:
                                                                  () => { addNewCategoryTopic(topic)},
                                                                actionIcon:
                                                                  "PlusIcon",
                                                              },
                                                              {
                                                                actionName: _l("l_edit"),
                                                                actionHandler: () => {
                                                                  setIsEditTopic({ id: category.filter_key, title: category.title })
                                                                  editCategoryTopic(topic.main_key)
                                                                },
                                                                actionIcon: "PencilSimpleLineIcon",
                                                              }
                                                            ]}
                                                          />
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}

                                                {
                                                  topic.main_key ==
                                                    "equipments" ? (
                                                    <>
                                                      <div className="comman_action_icon dropstart" onClick={(e) => e.stopPropagation()}>
                                                        <a href="#/"
                                                          className="comman_action_icon ps-2" id="ActiveOfficeOptionsDropdown"
                                                          data-bs-toggle="dropdown"
                                                          aria-expanded="false"
                                                           
                                                           
                                                           
                                                           
                                                          data-bs-auto-close="outside"
                                                        >
                                                          <DotsThreeVerticalIcon
                                                            className="HW16" />
                                                        </a>
                                                        <ActionDropdown
                                                        widthClass="w-170"
                                                          actions={[
                                                            {
                                                              actionName: _l("l_list"),
                                                              actionHandler: () => {
                                                                setEquipmentDetail(category);
                                                                setEquipmentModal(true);
                                                              },
                                                              actionIcon: "ListIcon",
                                                            },
                                                            {
                                                              actionName: _l("l_delete"),
                                                              actionHandler: () => { deleteEquipment(category.filter_key) },
                                                              actionIcon: "TrashIcon",
                                                            },
                                                          ]}

                                                        />
                                                      </div>
                                                    </>
                                                  ) : <></>
                                                }
                                                {!["proposals", "equipments", "providers"].includes(topic.main_key) ? (
                                                  <>
                                                    <div className={"comman_action_icon dropstart " + (topic.main_key == "task_topic" && category.filter_key < 0 || category.filter_key == "myrai_support" ? ' d-none' : '')}
                                                      onClick={(e) => e.stopPropagation()}>
                                                      <a href="#/"
                                                        className="comman_action_icon"
                                                        id="ActiveOfficeOptionsDropdown"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      
                                                        data-bs-auto-close="outside"
                                                      >
                                                        <DotsThreeVerticalIcon
                                                            className="HW16" />
                                                      </a>

                                                      <ActionDropdown
                                                      widthClass="w-170"
                                                        actions={
                                                          topic.main_key ==
                                                            "user_journey" || topic.main_key == "task_topic"
                                                            ? [
                                                              {
                                                                actionName: _l("l_edit"),
                                                                actionHandler: () => {
                                                                  handleEditAdminTeam(category.filter_key, topic.main_key)
                                                                },
                                                                actionIcon: "PencilSimpleLineIcon",
                                                              },
                                                             
                                                            ]
                                                            : category.is_default == 0 ? [
                                                              {
                                                                actionName:
                                                                  _l(
                                                                    "l_provider_list"
                                                                  ),
                                                                actionHandler:
                                                                  () => {
                                                                    accessPremiumFeature(projectId, () => {
                                                                      setShowProviderDropDown(
                                                                        true
                                                                      );
                                                                      setSelectOperators(
                                                                        category)
                                                                    })

                                                                  },
                                                                actionIcon:
                                                                  "UserGearCircleIcon",
                                                              },
                                                              {
                                                                actionName:
                                                                  _l("l_add"),
                                                                  actionHandler:() => { 
                                                                    accessPremiumFeature(projectId, () => {
                                                                      setShowAddProvider(true)
                                                                      setSelectedCategory(parseInt(category.filter_key))
                                                                    })
                                                                  },
                                                                actionIcon:
                                                                  "PlusIcon",
                                                              },
                                                              {
                                                                actionName: _l("l_edit"),
                                                                actionHandler: () => {
                                                                  accessPremiumFeature(projectId, () => {
                                                                    setIsEditTopic({ id: category.filter_key, title: category.title, languages: category.languages, tools: category.tools, people: category.people, cost: category.cost })
                                                                    editCategoryTopic(topic.main_key)
                                                                  });
                                                                },
                                                                actionIcon: "PencilSimpleLineIcon",
                                                              }

                                                            ] : [
                                                              {
                                                                actionName:
                                                                  _l(
                                                                    "l_provider_list"
                                                                  ),
                                                                actionHandler:
                                                                  () => {
                                                                    accessPremiumFeature(projectId, () => {
                                                                      setShowProviderDropDown(
                                                                        true
                                                                      );
                                                                      setSelectOperators(
                                                                        category);
                                                                    })
                                                                  },
                                                                actionIcon:
                                                                  "UserGearCircleIcon",
                                                              },
                                                              {
                                                                actionName:
                                                                  _l("l_add"),
                                                                actionHandler:() => { 
                                                                  accessPremiumFeature(projectId, () => {
                                                                    setShowAddProvider(true)
                                                                    setSelectedCategory(parseInt(category.filter_key))
                                                                  })
                                                                },
                                                                actionIcon:
                                                                  "PlusIcon",
                                                              },
                                                            ]
                                                        }
                                                      />
                                                    </div>
                                                    
                                                    <a href="#/"
                                                      className={`comman_action_icon ps-1 dropstart ${topic.main_key ==
                                                        "providers" ||
                                                        topic.main_key ==
                                                        "user_journey" ||
                                                        topic.main_key ==
                                                        "task_topic"
                                                        ? "d-none"
                                                        : ""
                                                        }`}
                                                      data-className="tooltip-main"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowInviteAssigneeModal(
                                                          true
                                                        );
                                                        setCategoryId(
                                                          parseFloat(
                                                            category.filter_key
                                                          )
                                                        );
                                                      }}
                                                    >
                                                      <div className="d-flex align-items-center">
                                                        <div className="action_icon with_bg position-relative on-hover-active-toggle-img h20w20 light-theme-img">
                                                          <UserCircleGearIcon
                                                            className="HW14"
                                                          />
                                                        </div>
                                                        <GeneralListingWithCheckbox
                                                          list={[
                                                            1, 2, 3, 4,
                                                          ].map((index) => {
                                                            return {
                                                              name: "Wallet Filter 1",
                                                              image: "",
                                                              id: index,
                                                            };
                                                          })}
                                                        />
                                                      </div>
                                                    </a>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        ) : <></>
                                        }
                                      </div>
                                    </div>
                                    {
                                      [
                                        "providers",
                                        "user_journey",
                                        "support_journey",
                                      ].includes(topic.main_key) ? (
                                        <React.Fragment>
                                        </React.Fragment>
                                      ) : (
                                        <></>
                                      )
                                    }
                                </div>
                              );
                          })
                        ) 
                        : (
                          <> 
                          <CommanPlaceholder border={true} mainSpacing="h-100" imgType="no-user" placeholderText={_l("l_no_data")}/>
                          </>
                        )}
                            </>
                        }
                    </Accordion.Body>
                  </Accordion.Item>}
                </Accordion>
              );
            })
          ) : (
            <></>
          )}

          {leftPanelTab == "my-agents" ?
          <Accordion>
          <Accordion.Item>
             <div className="hovertextgreen accordian-header-box d-flex align-content-center accordian-main-header title-fonts custom-accordian-Header-sticky radius_3">
             <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky w-100">
             </div>
             </div>

             { agentList && agentList.filter((w)=> w.firstname.toLowerCase().includes(searchString.toLowerCase()) || w.lastname.toLowerCase().includes(searchString.toLowerCase())).length > 0
               ? agentList.filter((w)=> w.firstname.toLowerCase().includes(searchString.toLowerCase()) || w.lastname.toLowerCase().includes(searchString.toLowerCase())).map((user, index) => {
                let activeClassTask = taskFilters
                ? taskFilters.filter(
                  (task) =>
                    task.filter_key == user.staffid
                )
                : [];
               return (
               <Accordion.Body>
                 <div className={`py-1 pt-0 childTopicTaskFilter accordian-body pb-1 gap1px`}
                   onClick={() => {
                    handleCreateChatGroup("my_contact","people",user.staffid)
                    dispatch(
                      leftPanelFilters({
                        main_key: "my_contact",
                        filter_key: user.staffid,
                        title: user.firstname  + " " + user.lastname,
                        main_sub_key: "people",
                        sub_category: [],
                      })
                    );
                   }}
                   >
                   <div className={`position-relative task-sub-list w-100 p-10 rounded-0 mb-0 ${activeClassTask.length > 0 ? "active" : ""}`}>
                     <div className="d-flex align-items-center w-100">
                       <a href="#/" className="accordian-left-title w-100 on-hover-active-toggle-img text-on-hover-active-green d-flex position-relative overflow-visible hovertextgreen">
                        <div className="h40w40 d-flex align-items-center justify-content-center ic-provider-list office-list-circle rounded-circle comman-round-box bgspacelisting">
                          <div className="h-100 w-100 comman-round-box radius_3 title-fonts d-flex flex-column rounded-circle">
                            <div className="comman-bg-img w-100 h-100 bg-style-cover rounded-circle" style={{ 
                                 backgroundImage: `url(${user.profile_image != "" ? user.profile_image : 'https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg'})`,
                               }}></div>
                          </div>
                        </div>
                        
                         <div className="d-flex justify-content-between flex-grow-1 align-items-center ps10px">
                          <div className="text-truncate w100minus100per">
                            <div className="title-font text-truncate left_panel_toggle categorytitle accordion-bodyc-font f-13 text-capitalize">
                            {user.firstname  + " " + user.lastname}
                            </div>
                          </div>
                         </div>
                       </a>
                       <div className="right-accordian-header transparent-bg">
                        <div className="d-flex align-items-center right-pill-addtask gap10 comman_action_icon dropstart">
                          <a href="#/" className="h18w18 on-hover-active-toggle-img d-flex">
                            <PhoneCallIcon className="HW16" />
                          </a>
                          <a href="#/" className="action_icon h18w18">
                            <GearSixIcon className="HW16" />
                          </a>
                        </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </Accordion.Body>
               )})
               : <CommanPlaceholder mainSpacing="h-100" imgType="no-user" placeholderText={_l("l_no_user")}/>
             }
          </Accordion.Item>
         </Accordion>
         : leftPanelTab == "chat-agents" ?
          <Accordion>
          <Accordion.Item>
             <div className="hovertextgreen accordian-header-box d-flex align-content-center accordian-main-header title-fonts custom-accordian-Header-sticky radius_3">
             <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky w-100">
             </div>
             </div>

             { spacesList && spacesList.filter((w)=> w.office_title.toLowerCase().includes(searchString.toLowerCase()) || w.postal_address.toLowerCase().includes(searchString.toLowerCase())).length > 0
               ? spacesList.filter((w)=> w.office_title.toLowerCase().includes(searchString.toLowerCase()) || w.postal_address.toLowerCase().includes(searchString.toLowerCase())).map((user, index) => {
                let activeClassTask = taskFilters
                ? taskFilters.filter(
                  (task) =>
                    task.filter_key == user.project_id
                )
                : [];
               return (
               <Accordion.Body>
                 <div className={`py-1 pt-0 childTopicTaskFilter accordian-body pb-1 gap1px`}
                   onClick={() => {
                    handleCreateChatGroup("my_contact","people",localStorage.getItem("staff_id"),'', user.project_id)
                    dispatch(
                      leftPanelFilters({
                        main_key: "my_contact",
                        filter_key: user.project_id,
                        title: user.office_title,
                        main_sub_key: "people",
                        sub_category: [],
                      })
                    );
                   }}
                   >
                   <div className={`position-relative task-sub-list w-100 p-10 rounded-0 mb-0 on-hover-active-toggle-img  ${activeClassTask.length > 0 ? "active" : ""} `}>
                     <div className="d-flex align-items-center w-100">
                       <a href="#/" className={`accordian-left-title w-100  d-flex position-relative overflow-visible hovertextgreen `}>
                        <div className="h40w40 d-flex align-items-center justify-content-center ic-provider-list office-list-circle rounded-circle comman-round-box bgspacelisting">
                          <div className="h-100 w-100 comman-round-box radius_3 title-fonts d-flex flex-column rounded-circle">
                            <BuildingsIcon className="HW28" />
                          </div>
                        </div>
                        
                         <div className="d-flex justify-content-between flex-grow-1 align-items-center ps10px text-on-hover-active-green">
                          <div className="text-truncate w100minus100per">
                            <div className="title-font text-truncate left_panel_toggle categorytitle accordion-body3 text-capitalize">
                            {user.office_title}
                            </div>
                          </div>
                         </div>
                       </a>
                       <div className="right-accordian-header transparent-bg">
                        <div className="d-flex align-items-center right-pill-addtask gap10 comman_action_icon dropstart">
                          <a href="#/" className="h18w18 on-hover-active-toggle-img d-flex">
                            <PhoneCallIcon className="HW16" />
                          </a>
                        </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </Accordion.Body>
               )})
               : <CommanPlaceholder mainSpacing="h-100" imgType="no-user" placeholderText={_l("l_no_user")}/>
             }
          </Accordion.Item>
         </Accordion>
          :
          leftPanelTab == "my-tenants" ? 
          <Accordion>
          <Accordion.Item>
             <div className="hovertextgreen accordian-header-box d-flex align-content-center accordian-main-header title-fonts custom-accordian-Header-sticky radius_3">
             <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky w-100">
             </div>
             </div>

             { tenantsEmployeeData && tenantsEmployeeData.filter((w)=> w && w.title && w.title.toLowerCase().includes(searchString && searchString.toLowerCase()) ).length > 0
               ? tenantsEmployeeData.filter((w)=> w.title.toLowerCase().includes(searchString.toLowerCase())).sort((a, b) => {
              if (!a.chat_data || !a.chat_data.lastMessage ||  a.chat_data.lastMessage === '') return 1;
              if (!b.chat_data || !b.chat_data.lastMessage ||  b.chat_data.lastMessage === '') return -1;
              const timeA = moment(a.chat_data && a.chat_data.lastMessageDate ? a.chat_data.lastMessageDate : '');
              const timeB = moment(b.chat_data && b.chat_data.lastMessageDate ? b.chat_data.lastMessageDate : '');
            
              return timeB - timeA;
              }).map((user, index) => {
                let activeClassTask = taskFilters
                ? taskFilters.filter(
                  (task) =>
                    task.filter_key == user.filter_key
                )
                : [];
               return (
               <Accordion.Body>
                 <div className={`py-1 pt-0 childTopicTaskFilter accordian-body pb-1 gap1px`}
                   onClick={() => {changeTentantsTopic(user);}}
                   >
                   <div className={`position-relative task-sub-list w-100 py-2 ps-10px pe-10px rounded-0 mb-0 on-hover-active-toggle-img text-on-hover-active-green hovertextgreen ${activeClassTask.length > 0 ? "active" : ""}`}>
                     <div className="d-flex align-items-center w-100">
                       <a href="#/" className="accordian-left-title w-100 d-flex position-relative overflow-visible">
                        <div className="d-flex align-items-center w100minus100per comman_action_icon">
                          <div className="d-flex align-items-center">
                            <div className="h40w40 d-flex action_icon with_bg rounded-5 position-relative  comman-image-box rounded-circle">
                              <div className="comman-bg-img w-100 h-10 bg-style-cover rounded-circle" style={{ 
                                 backgroundImage: `url(${user.image != "" ? user.image : 'https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg'})`,
                               }}>
                              </div>
                            </div>
                          </div>
                          <div className="ps-2 w-100">
                            <div className="d-flex justify-content-between w-100 align-items-center min-h-32px">
                              <div className="title-font text-truncate color-white-60 left_panel_toggle categorytitle w100minus100per   c-font f-13">{user.title}</div>
                              <div className={`ps-1 d-flex comman_action_icon align-items-center`}>
                                {user && user.chat_data && user.chat_data.unreadCount > 0 ? 
                                <div className="rounded-pill badge-custom h18w18 position-static my-auto badge-green rounded-circle d-flex right-accordian-header-pill border-0">
                                  <span className="m-auto ps-0 ">{user && user.chat_data && user.chat_data.unreadCount}</span>
                                </div> : <></>}
                            </div>
                            </div>
                            <div className="d-flex justify-content-between w-100 align-items-center color-white-60">
                                <div className="c-font f-10 text-truncate pe-2 w100minus100per hover-span">
                                { user && user.chat_data && user.chat_data.lastMsgUserName != ''
                                    ?  <b style={{fontWeight:600}}>{user.chat_data.lastMsgUserName && ReactHtmlParser(user.chat_data.lastMsgUserName.split(" ")[0])}{": "}</b>
                                    : <></>
                                  }
                                  {user && user.chat_data && ReactHtmlParser(user.chat_data.lastMessage)}
                                </div>
                                { user && user.chat_data && user.chat_data.lastMessage != "" && user.chat_data.lastMessageDate ? 
                                  <div className="c-font p-1 f-10 badge text-truncate badge-white m-w100px rounded-pill fw-light ms-1 02">
                                      <span className={`text-truncate ps-0`}>
                                        { moment(user.chat_data.lastMessageDate).format("L") == moment(new Date()).format("L") ? moment(user.chat_data.lastMessageDate).format("hh:mm A")
                                        : moment(user.chat_data.lastMessageDate).format("DD/MM/YYYY")
                                        }
                                      </span> 
                                    </div> 
                                    : <React.Fragment></React.Fragment>
                                }
                            </div>
                          </div>
                        </div>
                        
                         
                         
                       </a>
                     </div>
                   </div>
                 </div>
               </Accordion.Body>
               )})
               : <CommanPlaceholder mainSpacing="h-100" imgType="no-user" placeholderText={_l("l_no_user")}/>
             }
          </Accordion.Item>
         </Accordion>
          :
           contactlistShow ? 
          <Accordion>
           <Accordion.Item>
              <div className="hovertextgreen accordian-header-box d-flex align-content-center accordian-main-header title-fonts custom-accordian-Header-sticky radius_3">
              <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky w-100">
                <Accordion.Header>
                  <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                    {_l("l_people")}
                  </div>
                </Accordion.Header>
              </div>
              </div>
              { contactDirData && contactDirData.length > 0
                ? contactDirData.sort((a, b) => {
                if (a.unreadCount - b.unreadCount !== 0 )  {
                  return b.unreadCount - a.unreadCount;
                }
                // If notifications are the same, compare by time in ascending order
                const timeA = moment(a.lastMessageDate && a.lastMessageDate ? a.lastMessageDate : '');
                const timeB = moment(b.lastMessageDate && b.lastMessageDate ? b.lastMessageDate : '');
                return timeB - timeA;
                }).map((group, index) => {
                return (
                <Accordion.Body>
                  <div className={`py-1 pt-0 childTopicTaskFilter accordian-body pb-1 gap1px`}
                    onClick={() => {contactDirTopicChange(group, false)}}
                    >
                    <div className={`position-relative task-sub-list w-100 py-2 ps-10px pe-10px ${group.groupId == activeGroupId ? "active" : ""}`}>
                      <div className="d-flex align-items-center w-100">
                        <a href="#/" className="accordian-left-title w-100 on-hover-active-toggle-img text-on-hover-active-green d-flex position-relative overflow-visible hovertextgreen">
                          <div className="d-flex align-items-center w100minus100per comman_action_icon">
                            <div className="d-flex align-items-center">
                              <div className="h40w40 d-flex action_icon with_bg rounded-5 position-relative  comman-image-box rounded-circle">
                                <div className="comman-bg-img w-100 h-10 bg-style-cover rounded-circle"
                                style={{ 
                                  backgroundImage: `url(${group.displayPicture != "" ? group.displayPicture : 'https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg'})`,
                                }}
                                ></div>
                                { group && ((burgerMenuTopicTyping && burgerMenuTopicTyping.includes(group.groupId))) 
                                  ? <div className="typing-main typing-with-profile">
                                      <Typing /> 
                                    </div>
                                  : <></>
                                }
                              </div>
                            </div>
                            <div className="ps-2 w-100">
                              <div className="d-flex justify-content-between w-100 align-items-center min-h-32px">
                                <div className="title-font text-truncate color-white-60 left_panel_toggle categorytitle w100minus100per c-font f-13">{group.groupName}</div>
                                  { group.unreadCount && group.unreadCount > 0 
                                    ? <div class="with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill" >
                                        <span class="m-auto ps-0 ">{group.unreadCount}</span>
                                      </div>
                                    : <></>
                                  }
                              </div>
                              { group.lastMessage && group.lastMessage != "" 
                                  ? <div class="d-flex justify-content-between w-100 align-items-center color-white-60">
                                      <div class="c-font f-10 text-truncate pe-2 w100minus100per hover-span">
                                      { group && group.lastMsgUserId  !=  chatUserId 
                                          ?  <b style={{fontWeight:"600"}}>{`${ReactHtmlParser(group.lastMsgUserName.split(" ")[0])} :`}</b> 
                                          : <></>
                                      }
                                      {ReactHtmlParser(group.lastMessage)}
                                      </div>
                                        <div class="c-font p-1 f-10 badge text-truncate badge-white m-w100px rounded-pill fw-light ms-1 03">
                                            <span class="text-truncate ps-0">
                                            { moment(group.lastMessageDate).format("L") == moment(new Date()).format("L") 
                                              ? moment(group.lastMessageDate).format("hh:mm A")
                                              : moment(group.lastMessageDate).format("DD/MM/YYYY")
                                            }
                                            </span>
                                        </div>
                                    </div>
                                  : <></>
                              } 
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
                )})
                : <CommanPlaceholder mainSpacing="h-100" imgType="no-chat" placeholderText={_l("l_no_data")}/>
              }
           </Accordion.Item>
          </Accordion>
            : <></>
          }
          {
            window.location.pathname == "/clients" && newCusData ? 
            (
              <>

              <Accordion data-main-key-div={`customer_partner`} alwaysOpen>
                <Accordion.Item key="01">
                    <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky">
                      <Accordion.Header>
                        <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                          {"Category"}
                        </div>
                      </Accordion.Header>
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3">
                        <a href="#/"
                          className="d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img"
                          onClick={() => {
                            getLatestDefaultTopics();
                            setActiveTopicKey('customer_partner');
                            setShow(true);
                          }}
                        >
                          <GearSixIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                    {cus_topic &&
                    cus_topic.map((res, i) => {
                      
                      if(res.is_active == "1")
                      {
                        let onlyids = res.filter_key;
                      let onlyChannels = res.title;

                      let activeClassTask = topicTaskFilter
                        ? topicTaskFilter.filter(
                          (task) =>
                            task.main_key == 'customer_partner' &&
                            task.filter_key == onlyids &&
                            task.title == onlyChannels
                        )
                        : [];
                      return (
                        <Accordion.Body key={i}>
                          <div
                            id={`slack_id${onlyids}`}
                            className={`position-relative task-sub-list w-100 ${activeClassTask && activeClassTask.length
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <span className=""></span>
                              <a href="#/"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: 'customer_partner',
                                      filter_key: onlyids,
                                      title: onlyChannels,
                                    })
                                  );
                                }}
                                id="my_task_created_by_me"
                                className="accordian-left-title flex-grow-1 on-hover-active-toggle-img text-on-hover-active-green"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon">
                                    <div className="action_icon position-relative  h20w20 light-theme-img">
                                      <HashIcon
                                        className={`HW16 ${activeClassTask &&
                                          activeClassTask.length
                                          ? "active-svg"
                                          : ""
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className="title-font w100minus20 text-truncate pl-10 left_panel_toggle   c-font f-13 ps-2">
                                    {onlyChannels}
                                  </div>
                                </div>
                              </a>
                              <div className="right-accordian-header transparent-bg">
                                <div className="d-flex align-items-center right-pill-addtask">
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      );
                      }
                    }
                    )}
                </Accordion.Item>
              </Accordion>

              <Accordion data-main-key-div={`customer_groups`} alwaysOpen>
                <Accordion.Item key="01">
                    <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky">
                      <Accordion.Header>
                        <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                          {"Groups"}
                        </div>
                      </Accordion.Header>
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3">
                        <a href="#/"
                          className="d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img"
                          onClick={() => {
                            getLatestDefaultTopics();
                            setActiveTopicKey('customer_groups');
                            setShow(true);
                          }}
                        >
                          <GearSixIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                    {gr_topic &&
                    gr_topic.map((res, i) => {
                      
                      if(res.is_active == "1")
                      {
                        let onlyids = res.filter_key;
                      let onlyChannels = res.title;

                      let activeClassTask = topicTaskFilter
                        ? topicTaskFilter.filter(
                          (task) =>
                            task.main_key == 'customer_groups' &&
                            task.filter_key == onlyids &&
                            task.title == onlyChannels
                        )
                        : [];
                      return (
                        <Accordion.Body key={i}>
                          <div
                            id={`slack_id${onlyids}`}
                            className={`position-relative task-sub-list w-100 ${activeClassTask && activeClassTask.length
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <span className=""></span>
                              <a href="#/"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: 'customer_groups',
                                      filter_key: onlyids,
                                      title: onlyChannels,
                                    })
                                  );
                                }}
                                id="my_task_created_by_me"
                                className="accordian-left-title flex-grow-1 on-hover-active-toggle-img text-on-hover-active-green"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon">
                                    <div className="action_icon position-relative  h20w20 light-theme-img">
                                      <HashIcon
                                        className={`HW16 ${activeClassTask &&
                                          activeClassTask.length
                                          ? "active-svg"
                                          : ""
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className="title-font w100minus20 text-truncate pl-10 left_panel_toggle   c-font f-13 ps-2">
                                    {onlyChannels}
                                  </div>
                                </div>
                              </a>
                              <div className="right-accordian-header transparent-bg">
                                <div className="d-flex align-items-center right-pill-addtask">
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      );
                      }
                    }
                    )}
                </Accordion.Item>
              </Accordion>
              
              <Accordion data-main-key-div={`invoice_topic`} alwaysOpen>
                <Accordion.Item key="02">
                    <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky">
                      <Accordion.Header>
                        <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                          {"Invoice"}
                        </div>
                      </Accordion.Header>
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3">
                        <a href="#/"
                          className="d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img"
                          onClick={() => {
                            getLatestDefaultTopics();
                            setActiveTopicKey('invoice_topic');
                            setShow(true);
                          }}
                        >
                          <GearSixIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                    {inv_topic &&
                    inv_topic.map((res, i) => {
                      
                      if(res.is_active == "1")
                      {
                        let onlyids = res.filter_key;
                      let onlyChannels = res.title;

                      let activeClassTask = topicTaskFilter
                        ? topicTaskFilter.filter(
                          (task) =>
                            task.main_key == 'invoice_topic' &&
                            task.filter_key == onlyids &&
                            task.title == onlyChannels
                        )
                        : [];
                      return (
                        <Accordion.Body key={i}>
                          <div
                            id={`slack_id${onlyids}`}
                            className={`position-relative task-sub-list w-100 ${activeClassTask && activeClassTask.length
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <span className=""></span>
                              <a href="#/"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: 'invoice_topic',
                                      filter_key: onlyids,
                                      title: onlyChannels,
                                    })
                                  );
                                }}
                                id="my_task_created_by_me"
                                className="accordian-left-title flex-grow-1 on-hover-active-toggle-img text-on-hover-active-green"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon">
                                    <div className="action_icon position-relative  h20w20 light-theme-img">
                                      <HashIcon
                                        className={`HW16 ${activeClassTask &&
                                          activeClassTask.length
                                          ? "active-svg"
                                          : ""
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className="title-font w100minus20 text-truncate pl-10 left_panel_toggle   c-font f-13 ps-2">
                                    {onlyChannels}
                                  </div>
                                </div>
                              </a>
                              <div className="right-accordian-header transparent-bg">
                                <div className="d-flex align-items-center right-pill-addtask">
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      );
                      }
                    }
                    )}
                </Accordion.Item>
              </Accordion>

              <Accordion data-main-key-div={`estimate_topic`} alwaysOpen>
                <Accordion.Item key="01">
                    <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky">
                      <Accordion.Header>
                        <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                          {"Estimate"}
                        </div>
                      </Accordion.Header>
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3">
                        <a href="#/"
                          className="d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img"
                          onClick={() => {
                            getLatestDefaultTopics();
                            setActiveTopicKey('estimate_topic');
                            setShow(true);
                          }}
                        >
                          <GearSixIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                    {est_topic &&
                    est_topic.map((res, i) => {
                      
                      if(res.is_active == "1")
                      {
                        let onlyids = res.filter_key;
                      let onlyChannels = res.title;

                      let activeClassTask = topicTaskFilter
                        ? topicTaskFilter.filter(
                          (task) =>
                            task.main_key == 'estimate_topic' &&
                            task.filter_key == onlyids &&
                            task.title == onlyChannels
                        )
                        : [];
                      return (
                        <Accordion.Body key={i}>
                          <div
                            id={`slack_id${onlyids}`}
                            className={`position-relative task-sub-list w-100 ${activeClassTask && activeClassTask.length
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <span className=""></span>
                              <a href="#/"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: 'estimate_topic',
                                      filter_key: onlyids,
                                      title: onlyChannels,
                                    })
                                  );
                                }}
                                id="my_task_created_by_me"
                                className="accordian-left-title flex-grow-1 on-hover-active-toggle-img text-on-hover-active-green"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon">
                                    <div className="action_icon position-relative  h20w20 light-theme-img">
                                      <HashIcon
                                        className={`HW16 ${activeClassTask &&
                                          activeClassTask.length
                                          ? "active-svg"
                                          : ""
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className="title-font w100minus20 text-truncate pl-10 left_panel_toggle   c-font f-13 ps-2">
                                    {onlyChannels}
                                  </div>
                                </div>
                              </a>
                              <div className="right-accordian-header transparent-bg">
                                <div className="d-flex align-items-center right-pill-addtask">
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      );
                      }
                    }
                    )}
                </Accordion.Item>
              </Accordion>

              <Accordion data-main-key-div={`space_topic`} alwaysOpen>
                <Accordion.Item key="01">
                    <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky">
                      <Accordion.Header>
                        <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                          {"Projects"}
                        </div>
                      </Accordion.Header>
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3">
                        <a href="#/"
                          className="d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img"
                          onClick={() => {
                            getLatestDefaultTopics();
                            setActiveTopicKey('space_topic');
                            setShow(true);
                          }}
                        >
                          <GearSixIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                    {space_topic &&
                    space_topic.map((res, i) => {
                      
                      if(res.is_active == "1")
                      {
                        let onlyids = res.filter_key;
                      let onlyChannels = res.title;

                      let activeClassTask = topicTaskFilter
                        ? topicTaskFilter.filter(
                          (task) =>
                            task.main_key == 'space_topic' &&
                            task.filter_key == onlyids &&
                            task.title == onlyChannels
                        )
                        : [];
                      return (
                        <Accordion.Body key={i}>
                          <div
                            id={`slack_id${onlyids}`}
                            className={`position-relative task-sub-list w-100 ${activeClassTask && activeClassTask.length
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <span className=""></span>
                              <a href="#/"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: 'space_topic',
                                      filter_key: onlyids,
                                      title: onlyChannels,
                                    })
                                  );
                                }}
                                id="my_task_created_by_me"
                                className="accordian-left-title flex-grow-1 on-hover-active-toggle-img text-on-hover-active-green"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon">
                                    <div className="action_icon position-relative  h20w20 light-theme-img">
                                      <HashIcon
                                        className={`HW16 ${activeClassTask &&
                                          activeClassTask.length
                                          ? "active-svg"
                                          : ""
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className="title-font w100minus20 text-truncate pl-10 left_panel_toggle   c-font f-13 ps-2">
                                    {onlyChannels}
                                  </div>
                                </div>
                              </a>
                              <div className="right-accordian-header transparent-bg">
                                <div className="d-flex align-items-center right-pill-addtask">
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      );
                      }
                    }
                    )}
                </Accordion.Item>
              </Accordion>

              <Accordion data-main-key-div={`proposal_topic`} alwaysOpen>
                <Accordion.Item key="01">
                    <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky">
                      <Accordion.Header>
                        <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                          {"Proposals"}
                        </div>
                      </Accordion.Header>
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3">
                        <a href="#/"
                          className="d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img"
                          onClick={() => {
                            getLatestDefaultTopics();
                            setActiveTopicKey('proposal_topic');
                            setShow(true);
                          }}
                        >
                          <GearSixIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                    {pro_topic &&
                    pro_topic.map((res, i) => {
                      
                      if(res.is_active == "1")
                      {
                        let onlyids = res.filter_key;
                      let onlyChannels = res.title;

                      let activeClassTask = topicTaskFilter
                        ? topicTaskFilter.filter(
                          (task) =>
                            task.main_key == 'proposal_topic' &&
                            task.filter_key == onlyids &&
                            task.title == onlyChannels
                        )
                        : [];
                      return (
                        <Accordion.Body key={i}>
                          <div
                            id={`slack_id${onlyids}`}
                            className={`position-relative task-sub-list w-100 ${activeClassTask && activeClassTask.length
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <span className=""></span>
                              <a href="#/"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: 'proposal_topic',
                                      filter_key: onlyids,
                                      title: onlyChannels,
                                    })
                                  );
                                }}
                                id="my_task_created_by_me"
                                className="accordian-left-title flex-grow-1 on-hover-active-toggle-img text-on-hover-active-green"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon">
                                    <div className="action_icon position-relative  h20w20 light-theme-img">
                                      <HashIcon
                                        className={`HW16 ${activeClassTask &&
                                          activeClassTask.length
                                          ? "active-svg"
                                          : ""
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className="title-font w100minus20 text-truncate pl-10 left_panel_toggle   c-font f-13 ps-2">
                                    {onlyChannels}
                                  </div>
                                </div>
                              </a>
                              <div className="right-accordian-header transparent-bg">
                                <div className="d-flex align-items-center right-pill-addtask">
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      );
                      }
                    }
                    )}
                </Accordion.Item>
              </Accordion>

              <Accordion data-main-key-div={`country_topic`} alwaysOpen>
                <Accordion.Item key="01">
                    <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky">
                      <Accordion.Header>
                        <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                          {"Country"}
                        </div>
                      </Accordion.Header>
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3">
                        <a href="#/"
                          className="d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img"
                          onClick={() => {
                            getLatestDefaultTopics();
                            setActiveTopicKey('country_topic');
                            setShow(true);
                          }}
                        >
                          <GearSixIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                    {cont_topic &&
                    cont_topic.map((res, i) => {
                      
                      if(res.is_active == "1")
                      {
                        let onlyids = res.filter_key;
                      let onlyChannels = res.title;

                      let activeClassTask = topicTaskFilter
                        ? topicTaskFilter.filter(
                          (task) =>
                            task.main_key == 'country_topic' &&
                            task.filter_key == onlyids &&
                            task.title == onlyChannels
                        )
                        : [];
                      return (
                        <Accordion.Body key={i}>
                          <div
                            id={`slack_id${onlyids}`}
                            className={`position-relative task-sub-list w-100 ${activeClassTask && activeClassTask.length
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <span className=""></span>
                              <a href="#/"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: 'country_topic',
                                      filter_key: onlyids,
                                      title: onlyChannels,
                                    })
                                  );
                                }}
                                id="my_task_created_by_me"
                                className="accordian-left-title flex-grow-1 on-hover-active-toggle-img text-on-hover-active-green"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon">
                                    <div className="action_icon position-relative  h20w20 light-theme-img">
                                      <HashIcon
                                        className={`HW16 ${activeClassTask &&
                                          activeClassTask.length
                                          ? "active-svg"
                                          : ""
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className="title-font w100minus20 text-truncate pl-10 left_panel_toggle   c-font f-13 ps-2">
                                    {onlyChannels}
                                  </div>
                                </div>
                              </a>
                              <div className="right-accordian-header transparent-bg">
                                <div className="d-flex align-items-center right-pill-addtask">
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      );
                      }
                    }
                    )}
                </Accordion.Item>
              </Accordion>
            </>
            ) :
            <></>
          }
          {
            (pathname == "/estimate" || pathname == "/staff"  || pathname == "/equipmentListing") && (
              <Accordion defaultActiveKey={activeAccordianKeys} alwaysOpen>
                <Accordion.Item key="1">
                  {estimateTopics && estimateTopics.length > 0 && (
                    <div className="accordian-header-box d-flex align-content-center accordian-main-header slack-header title-fonts custom-accordian-Header-sticky">
                      <Accordion.Header>
                        <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                          {pathname == "/staff" ? "Staff Categories" : pathname == "/equipmentListing" ? 'Equipment Categories' : 'Estimates'}
                        </div>
                      </Accordion.Header>
                      <div className="mlminus75 d-flex flex-grow-1 justify-content-end z-index-3">
                        <a href="#/"
                          className="d-flex z-index-2 with_separator_10 justify-content-end on-hover-active-toggle-img light-theme-img"
                          onClick={() => {
                            getLatestDefaultTopics();
                            setActiveTopicKey(pathname == "/staff"? "my_staff_categories" : pathname == "/equipmentListing" ? 'equipment_categories' : "my_estimates");
                            setShow(true);
                          }}
                        >
                          <GearSixIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                  )}
                  {estimateTopics &&
                    estimateTopics.length > 0 &&
                    estimateTopics.map((res, i) => {
                      let onlyids = res.filter_key;
                      let onlyChannels = res.title;

                      let activeClassTask = topicTaskFilter
                        ? topicTaskFilter.filter(
                          (task) =>
                            task.main_key == (pathname == "/staff" ? "my_staff_categories" : pathname == "/equipmentListing" ? 'equipment_categories' : "my_estimates") &&
                            task.filter_key == onlyids &&
                            task.title == onlyChannels
                        )
                        : [];
                      return (
                        <Accordion.Body key={i}>
                          <div
                            id={`slack_id${onlyids}`}
                            className={`position-relative task-sub-list w-100 ${activeClassTask && activeClassTask.length
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <span className=""></span>
                              <a href="#/"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: pathname == "/staff" ? "my_staff_categories" : pathname == "/equipmentListing" ? 'equipment_categories' : "my_estimates",
                                      filter_key: onlyids,
                                      title: onlyChannels,
                                    })
                                  );
                                }}
                                id="my_task_created_by_me"
                                className="accordian-left-title flex-grow-1 on-hover-active-toggle-img text-on-hover-active-green"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon">
                                    <div className="action_icon position-relative  h20w20 light-theme-img">
                                      <HashIcon
                                        className={`HW16 ${activeClassTask &&
                                          activeClassTask.length
                                          ? "active-svg"
                                          : ""
                                          }`}
                                      />
                                    </div>
                                  </div>
                                  <div className="title-font w100minus20 text-truncate pl-10 left_panel_toggle   c-font f-13 ps-2">
                                    {onlyChannels}
                                  </div>
                                </div>
                              </a>
                              <div className="right-accordian-header transparent-bg">
                                <div className="d-flex align-items-center right-pill-addtask">
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      );
                    })}
                </Accordion.Item>
              </Accordion>
            )
          }
        </div >
      }

      {
        chatModalShow && (
          <SlackDiscordModal
            chatShow={chatModalShow}
            chatHandleClose={ChathandleClose}
          />
        )
      }
      {showAddPeople ? SelectTeamContactModal : <></>}
      {
        Math.abs(providerListingCategory.id) > 0
        ?
        <TopicSettingModal 
          data={providerListingCategory} 
          show={Math.abs(providerListingCategory.id) > 0} 
          handleClose={() => {setProviderListingCategory({id: 0, title: "", main_key: "", is_active: "", main_key_title: "", main_sub_key: ""})}} 
          title={providerListingCategory.main_key_title} titlePrefix={"l_settings"} 
          hideFilterArea={providerListingCategory.hideFilterArea} 
          pageName = {pageName}/>
        :
        <React.Fragment></React.Fragment>
      }
      {
        show ? (
          <AddEditCategory
            projectId={projectId}
            show={show}
            handleClose={handleClose}
            activeTopic={activeTopicKey}
            pageName={pageName}
            editCustomTopic={editCustomTopic}
            deleteCustomTopic={deleteCustomTopic}
            deleteEquipment={deleteEquipment}
            setActiveTopic={setActiveTopicKey}
          />
        ) : (
          <></>
        )
      }
      {
        showAddTopicTask ? (
          <AddNewCategory
            categories={defaultTopicData
              .filter((x) => {
                if (x.main_key === activeTopicKey) {
                  return x;
                }
              })[0]
              .categories.map((w) => {
                return { value: w.filter_key, label: w.title };
              })}
            show={showAddTopicTask}
            handler={(topic) => {
              handleAddTaskTopic(topic);
            }}
            projectId={projectId}
            handleClose={() => {
              setShowAddTopicTask(false);
            }}
            activeTopicKey={activeTopicKey}
            editTopicDetails={isEditTopic}
          />
        ) : (
          <></>
        )
      }
      {
        showAddCategory ? (
          <AddNewCategory
            categories={Object.keys(isEditTopic).length > 0 ? [] : defaultTopicData
              .filter((x) => {
                if (x.main_key === activeTopicKey) {
                  return x;
                }
              })[0]
              .categories.map((w) => {
                return { value: w.filter_key, label: w.title };
              })}
            show={showAddCategory}
            handler={(topic) => {
              handleAddTopic(topic);
            }}
            projectId={projectId}
            handleClose={() => {
              setShowAddCategory(false);
            }}
            activeTopicKey={activeTopicKey}
            editTopicDetails={isEditTopic}

          />
        ) : (
          <></>
        )
      }
      {
        showAddProviderCategory ? (
          <CategoryProviderList
            handleClose={() => {
              setShowAddProviderCategory(false);
              setIsEditTopic({})
            }}
            projectId={projectId}
            show={showAddProviderCategory}
            handleAddCategory={handleAddProviderCategory}
            editTopicDetails={isEditTopic}
          />
        ) : (
          <></>
        )
      }
      {
        showAddProvider ? 
            <AddProvider
              show={showAddProvider}
              handleClose={() => {
                setShowAddProvider(false);
              }}
              categoryList={categoryListing}
              selectedCategory={selectedCategory}
            />
           : (
          <></>
        )
      }

      {
        showTaskModal ? (
          <TaskDetail
            projectId={projectId}
            taskId={selectedTaskId}
            offCanvasshow={showTaskModal}
            offCanvashandleShow={setShowTaskModal}
            offCanvashandleClose={() => {
              setSelectedTaskId(0);
              setShowTaskModal(false);
            }}
            handler={handleSelectedTask}
          />
        ) : (
          <></>
        )
      }

      {
        showAddTeamMember || createTeamModal ? (
          <CreateTeam
            dataSource={[]}
            setDataSource={() => { }}
            handleClose={() => {
              setshowAddTeamMember(false);
              setCreateTeamModal(false);
              setTopicType('')
              setSelectedTeamForEdit()
            }}
            setTeamsData={() => { }}
            show={showAddTeamMember || createTeamModal}
            team={createTeamModal ? undefined : selectedTeamForEdit}
            teamsData={[]}
            handleAddedTeam={handleAddedTeam}
            topicType={topicType}
            projectId={projectId}
          />
        ) : (
          <></>
        )
      }
      {
        equipmentModal ? (
          <AddEditEquipment
            handleClose={() => {
              setEquipmentModal(false);
            }}
            show={equipmentModal}
            data={equipmentDetail}
            deleteEquipment={deleteEquipment}
          />
        ) : (
          <></>
        )
      }
      {
        showAddContact ? (
          <AddEmployee
            handleClose={() => {
              setShowAddContact(false);
              setTopicType('')
              setSelectedEmployeeData('')
            }}
            projectId={projectId}
            show={showAddContact}
            updateDataSource={handleAddedContact}
            topicType={topicType}
            selectedRecord={selectedEmployeeData}
          />
        ) : (
          <></>
        )
      }
      {
        showAddOperatorModal ? (
          <AddOperator
            handleClose={() => {
              setShowAddOperatorModal(false);
              setSelectedEmployeeData('')
            }}
            projectId={projectId}
            show={showAddOperatorModal}
            updateDataSource={handleAddedOperator}
            selectedRecord={selectedEmployeeData}
          />
        ) : (
          <></>
        )
      }
      {
        showInviteAssigneeModal ? (
          <SelectProviderCategory
            handleProvider={((providerDetails) => {
              if (providerDetails) {
                setCategoryOperators((categoryOperators) => [
                  ...categoryOperators,
                  providerDetails,
                ]);
              }
            })[0]
              .categories.map((w) => {
                return { value: w.filter_key, label: w.title };
              })}
            show={showAddTopicTask}
            handler={(topic) => {
              handleAddTaskTopic(topic);
            }}
            projectId={projectId}
            handleClose={() => {
              setShowAddTopicTask(false);
            }}
            activeTopicKey={activeTopicKey}
          />
        ) : (
          <></>
        )
      }
      {
        showAddCategory ? (
          <AddNewCategory
            categories={Object.keys(isEditTopic).length > 0 ? [] : defaultTopicData
              .filter((x) => {
                if (x.main_key === activeTopicKey) {
                  return x;
                }
              })[0]
              .categories.map((w) => {
                return { value: w.filter_key, label: w.title };
              })}
            show={showAddCategory}
            handler={(topic) => {
              handleAddTopic(topic);
            }}
            projectId={projectId}
            handleClose={() => {
              setShowAddCategory(false);
            }}
            activeTopicKey={activeTopicKey}
          />
        ) : (
          <></>
        )
      }
      {
        showAddProviderCategory ? (
          <CategoryProviderList
            handleClose={() => {
              setShowAddProviderCategory(false);
              setIsEditTopic({})
            }}
            projectId={projectId}
            show={showAddProviderCategory}
            handleAddCategory={handleAddProviderCategory}
            editTopicDetails={isEditTopic}
          />
        ) : (
          <></>
        )
      }

      {
        showTaskModal ? (
          <TaskDetail
            projectId={projectId}
            taskId={selectedTaskId}
            offCanvasshow={showTaskModal}
            offCanvashandleShow={setShowTaskModal}
            offCanvashandleClose={() => {
              setSelectedTaskId(0);
              setShowTaskModal(false);
            }}
            handler={handleSelectedTask}
          />
        ) : (
          <></>
        )
      }

      {
        showAddTeamMember || createTeamModal ? (
          <CreateTeam
            dataSource={[]}
            setDataSource={() => { }}
            handleClose={() => {
              setshowAddTeamMember(false);
              setCreateTeamModal(false);
              setTopicType("");
              setSelectedTeamForEdit()
            }}
            setTeamsData={() => { }}
            show={showAddTeamMember || createTeamModal}
            team={createTeamModal ? undefined : selectedTeamForEdit}
            teamsData={[]}
            handleAddedTeam={handleAddedTeam}
            topicType={topicType}
            projectId={projectId}
          />
        ) : (
          <></>
        )
      }
      {
        showAddContact ? (
          <AddEmployee
            handleClose={() => {
              setShowAddContact(false);
              setTopicType("");
              setSelectedEmployeeData('')
            }}
            projectId={projectId}
            show={showAddContact}
            updateDataSource={handleAddedContact}
            topicType={topicType}
            selectedRecord={selectedEmployeeData}
          />
        ) : (
          <></>
        )
      }
      {
        showAddOperatorModal ? (
          <AddOperator
            handleClose={() => {
              setShowAddOperatorModal(false);
              setSelectedEmployeeData('')
            }}
            projectId={projectId}
            show={showAddOperatorModal}
            updateDataSource={handleAddedOperator}
            selectedRecord={selectedEmployeeData}
          />
        ) : (
          <></>
        )
      }
      {
        showInviteAssigneeModal ? (
          <SelectProviderCategory
            handleProvider={((providerDetails) => {
              if (providerDetails) {
                setCategoryOperators((categoryOperators) => [
                  ...categoryOperators,
                  providerDetails,
                ]);
              }
            })[0].categories.map((w) => {
              return { value: w.filter_key, label: w.title };
            })}
            show={showAddTopicTask}
            handler={(topic) => {
              handleAddTaskTopic(topic);
            }}
            projectId={projectId}
            handleClose={() => {
              setShowAddTopicTask(false);
            }}
            activeTopicKey={activeTopicKey}
          />
        ) : (
          <></>
        )
      }
      {
        showAddCategory ? (
          <AddNewCategory
            categories={Object.keys(isEditTopic).length > 0 ? [] : defaultTopicData
              .filter((x) => {
                if (x.main_key === activeTopicKey) {
                  return x;
                }
              })[0]
              .categories.map((w) => {
                return { value: w.filter_key, label: w.title };
              })}
            show={showAddCategory}
            handler={(topic) => {
              handleAddTopic(topic);
            }}
            projectId={projectId}
            handleClose={() => {
              setShowAddCategory(false);
            }}
            activeTopicKey={activeTopicKey}
            editTopicDetails={isEditTopic}
          />
        ) : (
          <></>
        )
      }
      {
        showAddProviderCategory ? (
          <CategoryProviderList
            handleClose={() => {
              setShowAddProviderCategory(false);
              setIsEditTopic({})
            }}
            projectId={projectId}
            show={showAddProviderCategory}
            handleAddCategory={handleAddProviderCategory}
            editTopicDetails={isEditTopic}
          />
        ) : (
          <></>
        )
      }
       {showAddMailCustomCategory ? (
        <AddNewMailCustomCategory
          show={showAddMailCustomCategory}
          handler={(topic) => {
            handleAddCMTopic(topic);
          }}
          projectId={projectId}
          handleClose={handleAMCCClose}
          activeTopic={activeTopicKey}
          customTopicTitle={customTopicTitle}
          setCustomTopicTitle={setCustomTopicTitle}
          customTopicMail={customTopicMail}
          setCustomTopicMail={setCustomTopicMail}
          topicId={topicId}
          setTopicId={setTopicId}

        />
      ) : (
        <></>
      )}
      {showAddCategoryForMe ? (
        <AddNewCategory
          projectId={projectId}
          show={showAddCategoryForMe}
          handleClose={() => {
            setshowAddCategoryForMe(false);
            setIsEditTopic({});
          }}
          activeTopicKey={activeTopicKey}
          handler={(topic) => {
            handleAddTopic(topic);
          }}
          editTopicDetails={isEditTopic}
          leftPanelTab={leftPanelTab}
        />
      ) : (
        <></>
      )}      
    </>
  );
};

export default TwoStepAccordians;
