import React, { useEffect, useState } from "react";
import { _l, showError } from "../../../hooks/utilities";
import moment from "moment";
import { showConfirmation, toastCloseAction } from "../../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import BenchmarkServices from "../../../services/benchmark-services";
import Select from "react-select";
import { setEditScheduleJsonData, setListOfSelectedOperator } from "../../../actions/customer";
import XIcon from "../../../assets/icons/XIcon";

const COLUMNS = {
  mon: "l_week_single_letter_monday",
  tue: "l_week_single_letter_tuesday",
  wed: "l_week_single_letter_wednesday",
  thu: "l_week_single_letter_thrusday",
  fri: "l_week_single_letter_friday",
  sat: "l_week_single_letter_saturday",
  sun: "l_week_single_letter_sunday",
  monthly: "l_monthly",
  quarterly: "l_quarterly",
  half_yearly: "l_half_yearly",
  yearly: "l_yearly",
};

const operatorSlot = {
  id: "0",
  staff_id: "0",
  contact_role: "1",
  fullname: "",
  avaibility: [
    {
      mon: "0",
      is_task: 0,
    },
    {
      tue: "0",
      is_task: 0,
    },
    {
      wed: "0",
      is_task: 0,
    },
    {
      thu: "0",
      is_task: 0,
    },
    {
      fri: "0",
      is_task: 0,
    },
    {
      sat: "0",
      is_task: 0,
    },
    {
      sun: "0",
      is_task: 0,
    },
  ],
  is_new_slot: 1,
};

const TaskEditHTML = (openTaskModalToEdit, day, slot, deleteSlot) => {
  const slotInformation = JSON.stringify({
    previousDay: day,
    ...slot,
  });

  const handleDragStart = (event) => {
    event.dataTransfer.setData("application/json", slotInformation);
  };

  return (
    <a
      draggable
      onDragStart={handleDragStart}
      className="Eventboxmain w-100"
      onClick={() => {
        openTaskModalToEdit(day, slot.staff_id);
      }}
    >
      <div className="fc-event-main">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fc-event-main w100minus100per flex-grow-1 text-truncate">
            <div className="align-items-center c-font d-flex f-11 fc-event-main-frame p-1 title-fonts fw-semibold">
              <div className="fc-event-title-container w100minus100per flex-grow-1">
                <div className="fc-event-title fc-sticky text-truncate">
                  {slot.fullname}
                </div>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteSlot();
                }}
              >
                <XIcon className="HW16 black-l-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const EditScheduleAssignment = ({
  operatorData,
  setOperatorData,
  openTaskModalToEdit,
}) => {

  let selectedTopic = localStorage.getItem("selectedTopic") ? JSON.parse(localStorage.getItem("selectedTopic")) : {}

  const [selectedOperatorList, setSelectedOperatorList] = useState(
    operatorData.filter(
      (w) =>
        w.avaibility &&
        w.avaibility.length &&
        w.avaibility.some((x) => x.is_task && x.is_task > 0)
    )
  );

  const dispatch = useDispatch();
  const { benchmarkDetail, editedScheduleData, selectedOperators } = useSelector((state) => state.customer);

  const [commanOffcanvasData, setcommanOffcanvasData] = useState(
    {
      "id": "",
      "name": "",
      "startdate": "",
      "duedate": null,
      "priority": "2",
      "status": "1",
      "addedfrom": "0",
      "is_added_from_contact": "1",
      "myr_total": 0,
      "repeat_every": "0",
      "recurring_type": "",
      "custom_recurring": "0",
      "recurring": "0",
      "rel_id": "",
      "rel_type": "project",
      "schedule_id": "",
      "is_requested": 0,
      "visible_to_client": "1",
      "visible_to_provider": "1",
      "request_task_id": "0",
      "calendar_id": null,
      "event_id": null,
      "meeting_link": null,
      "provider": null,
      "channel_id": null,
      "surface": "0",
      "duration": "0",
      "checklist_title_id": "0",
      "average_rating": null,
      "schedule_assignees": [
         {
            "staffid": "",
            "userid": "",
            "profile_image": "",
            "name": ""
         }
      ],
      "map_attachment_url": "",
      "tags": [],
      "task_assignee": {
         "providers": [
            {
               "name": "",
               "staffid": "",
               "email": "",
               "profile_image": "",
               "contactid": "",
               "role": ""
            }
         ],
         "equipments": [],
         "myr_network": [
            {
               "provider_id": selectedTopic.id,
               "name": selectedTopic.title
            }
         ],
         "myr_staff": [],
         "task_topic": []
      },
      "is_one_time_benchmark": 0,
      "task_creator_image": localStorage.getItem("contact_image"),
      "creator_name": localStorage.getItem("full_name"),
      "is_pinned": 0,
      "pinned": 0,
      "checklist": [],
      "checklist_progress": "0",
      "comment_count": 0,
      "attachment_count": 0,
      "link_count": 0,
      "task_share_link": "",
      "unread_comment_count": 0,
      "is_allowed_to_edit": "1",
      "hash": "",
      "assignee_list": {},
      "assignee_list_DEV": [
         {
            "main_key": "my_operator",
            "categories": [
               {
                  "filter_key": "2096",
                  "main_sub_key": "people"
               }
            ]
         }
      ],
      "attendees": null,
      "contactsGoogleCalendar": null
   }
  );

  const handleDrop = async (event, slot, updatedDay) => {
    let slotInformation = event.dataTransfer.getData("application/json");
    slotInformation = JSON.parse(slotInformation); //Contains information about the slot being dragged

    //If task is moved to the slot which has no operator selected, allow to put the task so that operator can be selected
    if (slot.staff_id == 0) {
      //If empty slot contains at least one task in any day, for now do not allow to mix other tasks / multiple days
      if (slot.avaibility && slot.avaibility.some((s) => s.is_task == 1)) {
        showError("l_slot_has_un_assigned_task");
        return false;
      }

      //Update the day task in previous slot
      let currentSlots = selectedOperatorList.slice();
      if (slot.avaibility && slot.avaibility.length) {
        slot.avaibility.forEach((z, i) => {
          if (typeof z[updatedDay] !== "undefined") {
            slot.avaibility[i].is_task = 1;
          }
        });
      }

      const newSlotIndex = selectedOperatorList.findIndex(
        (w) => slot.key && w.key === slot.key
      );

      if (newSlotIndex === -1) {
        currentSlots = currentSlots.concat([
          {
            ...slot,
            previous_assignee: slotInformation.staff_id,
            key: crypto.randomUUID(),
            previous_day: slotInformation.previousDay,
          },
        ]);
      } else {
        currentSlots = currentSlots.map((e, i) => {
          if (i === newSlotIndex) {
            e = { ...slot };
          }
          return e;
        });
      }

      currentSlots.forEach((operator) => {
        if (
          operator.staff_id == slotInformation.staff_id &&
          operator.avaibility &&
          operator.avaibility.length
        ) {
          operator.avaibility.forEach((d) => {
            if (typeof d[slotInformation.previousDay] !== "undefined") {
              d.is_task = 0;
            }
          });
        }
      });
      dispatch(setListOfSelectedOperator(currentSlots))

      return true;
    } else {
      //Check in the slot where the dragged slot is put
      let isSelectedSlotAvailable = false;
      if (slot.avaibility) {
        slot.avaibility.forEach((e) => {
          if (
            (e[updatedDay] == 1 &&
              !["monthly", "quarterly", "half_yearly", "yearly"].includes(
                slotInformation.previousDay
              ) &&
              !["monthly", "quarterly", "half_yearly", "yearly"].includes(
                updatedDay
              )) ||
            (["monthly", "quarterly", "half_yearly", "yearly"].includes(
              updatedDay
            ) &&
              ["monthly", "quarterly", "half_yearly", "yearly"].includes(
                slotInformation.previousDay
              ))
          ) {
            isSelectedSlotAvailable = true;
          }
        });
      }

      if (isSelectedSlotAvailable) {
        //Case 1: Assign to Different Provider
        if (slot.staff_id != slotInformation.staff_id) {
          try {

            const previous_assignee =
              slotInformation.staff_id > 0
                ? slotInformation.staff_id
                : slotInformation.previous_assignee;
            const new_assignee =
              slot.staff_id !== previous_assignee ? slot.staff_id : 0;
            const previous_day =
              slotInformation.previous_day || slotInformation.previousDay;

            if (
              previous_assignee == slot.staff_id &&
              slotInformation.previousDay == updatedDay
            ) {
              dispatch(setListOfSelectedOperator(
                selectedOperatorList.map((w) => {
                  const newDayIndex = w.avaibility.findIndex(
                    (z) => typeof z[updatedDay] !== "undefined"
                  );
                  const previousDayIndex = w.avaibility.findIndex(
                    (z) => typeof z[slotInformation.previousDay] !== "undefined"
                  );

                  if (newDayIndex > -1 && previousDayIndex > -1) {
                    if (w.staff_id == slot.staff_id) {
                      w.avaibility[newDayIndex].is_task = 1;
                    }

                    if (w.staff_id == slotInformation.staff_id) {
                      w.avaibility[previousDayIndex].is_task = 0;
                    }
                  }
                  return w;
                })
              ));

              return;
            }
            let obj =   {
              "is_transfer" : "1",
              "old_assignee": previous_assignee,
              "new_assignee": new_assignee == 0 ? previous_assignee  : new_assignee,
              "old_day": previous_day,
              "new_day": updatedDay
            }
            dispatch(setEditScheduleJsonData([...editedScheduleData, obj]))
              dispatch(setListOfSelectedOperator(
                selectedOperatorList.map((w) => {
                  const newDayIndex = w.avaibility.findIndex(
                    (z) => typeof z[updatedDay] !== "undefined"
                  );
                  const previousDayIndex = w.avaibility.findIndex(
                    (z) => typeof z[previous_day] !== "undefined"
                  );

                  if (newDayIndex > -1 && previousDayIndex > -1) {
                    if (w.staff_id == slot.staff_id) {
                      w.avaibility[newDayIndex].is_task = 1;
                    }

                    if (w.staff_id == slotInformation.staff_id) {
                      w.avaibility[previousDayIndex].is_task = 0;
                    }
                  }
                  return w;
                })
              ));

          } catch (e) {
            showError("l_something_went_wrong");
          }
        }

        //case 2: same provider but change the day
        else {
          const previous_assignee =
          slotInformation.staff_id > 0
            ? slotInformation.staff_id
            : slotInformation.previous_assignee;
        const new_assignee =
          slot.staff_id !== previous_assignee ? slot.staff_id : 0;
        const previous_day =
          slotInformation.previous_day || slotInformation.previousDay;
          try {
            let obj =   {
              "is_transfer" : "1",
              "old_assignee": previous_assignee,
              "new_assignee": new_assignee == 0 ? previous_assignee  : new_assignee,
              "old_day": previous_day,
              "new_day": updatedDay
            }
            dispatch(setEditScheduleJsonData([...editedScheduleData, obj]))
              dispatch(setListOfSelectedOperator(
                selectedOperatorList.map((w) => {
                  const oldDayIndex = w.avaibility.findIndex(
                    (z) => typeof z[slotInformation.previousDay] !== "undefined"
                  );
                  const newDayIndex = w.avaibility.findIndex(
                    (z) => typeof z[updatedDay] !== "undefined"
                  );

                  if (
                    oldDayIndex > -1 &&
                    newDayIndex > -1 &&
                    w.staff_id == slotInformation.staff_id
                  ) {
                    w.avaibility[newDayIndex].is_task = 1;
                    w.avaibility[oldDayIndex].is_task = 0;
                  }
                  return w;
                })
              ));

          } catch (e) {
            console.error(e);
          }
        }
      }
    }
  };

  const deleteSlot = (slotInformation, day) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        `${_l("l_delete_checklist_for")} "${_l(day)}" ?`,
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        async () => {
          try {
            dispatch(toastCloseAction());

              setOperatorData(
                operatorData.map((slot, pos) => {
                  if (
                    slot.avaibility &&
                    slotInformation.staff_id == slot.staff_id
                  ) {
                    slot[pos].avaibility = slot[pos].avaibility.map((av) => {
                      if(typeof av[day] !== "undefined")
                      {
                        av.is_task = 0;
                      } 
                      return av;
                  });
                  }
                  return slot;
                })
              );
          } catch (e) {
            console.error(e);
          }
        }
      )
    );
  };

  const formattedOperatorList = operatorData.map((operator) => {
    return {
      value: operator.staff_id,
      label: (
        <div className="d-flex align-items-center">
          <div className="c-list-icon">
            <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
              <div
                 
                 
                 
                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                style={{
                  backgroundImage: `url('${operator.image_url}')`,
                }}
              ></div>
            </div>
          </div>
          <div className="ps-2 w100minus20">
            <div className="c-font f-14 color-white-80 text-truncate">
              {operator.fullname}
            </div>
          </div>
           
        </div>
      ),
      name: operator.fullname,
      url: operator.image_url,
      is_skilled: operator.is_skilled,
      ...operator
    };
  });

  const changeOperatorSlot = async (slotInfo, newOperator) => {
    let hasAssignedTask = false;
    let day = "";
    if (slotInfo.avaibility) {
      slotInfo.avaibility.forEach((w) => {
        if (w.is_task == 1) {
          hasAssignedTask = true;
          day = Object.keys(w).find((k) => k !== "is_task"); //Get Day name key
        }
      });
    }
    //Case 1, if user first selects the operator in the empty slot, update and highlight the availability
    if (!hasAssignedTask) {
      const operatorInfo = operatorData.find(
        (w) => w.staff_id == newOperator.value
      );
      if (!operatorInfo) {
        return;
      }
      dispatch(setListOfSelectedOperator(
        selectedOperatorList.concat([{ ...operatorInfo }])
      ));
    } else {
      //case 2, if user already dragged a task in the empty slot and now the provider is selected for assignment
      try {
        const response =
          await BenchmarkServices.updateSlotAssignmentForSchedule(
            benchmarkDetail.project_id,
            benchmarkDetail.id,
            slotInfo.previous_assignee,
            slotInfo.previous_day,
            day,
            newOperator.value
          );
        if (response.status) {
          const selectedOperatorInfo = operatorData.find(
            (z) => z.staff_id == newOperator.value
          );

          dispatch(setListOfSelectedOperator(
            selectedOperatorList.map((op) => {
              if (op.key == slotInfo.key && selectedOperatorInfo) {
                op.staff_id = selectedOperatorInfo.staff_id;
                op.fullname = selectedOperatorInfo.fullname;
                op.image_url = selectedOperatorInfo.image_url;
                op.avaibility = selectedOperatorInfo.avaibility.map((av, av_i) => {
                  if(typeof av[day] !== "undefined")
                  {
                    selectedOperatorInfo.avaibility[av_i].is_task = 1;
                  }
                  return av
                })
              }
              return op;
            })
          ));
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getOperatorForNewSlot = (slot) => {
    return formattedOperatorList.filter((o, i) => {
      if(slot.avaibility && slot.avaibility.length)
      {
        const dayIndex = slot.avaibility.findIndex((x) => x.is_task == 1);
        if(dayIndex === -1 && o.avaibility && o.avaibility.every((w) => w.is_task == 0)) //Choose operators with no task assigned
        {
          return o;
        }
        else if(dayIndex > -1) {
          const dayName = Object.keys(slot.avaibility[dayIndex]).find((z) => z !== "is_task");
          if(dayName && o.avaibility.length && o.avaibility.findIndex((z) => typeof z[dayName] !== "undefined" && z.is_task == 0) !== 1)
          {
            return o;
          }
        }
      }
    });
  }


  useEffect(() => {
    dispatch(setListOfSelectedOperator(
      selectedOperators.length > 0 ?  selectedOperators :
      operatorData.filter(
      (w) =>
        w.avaibility &&
        w.avaibility.length &&
        w.avaibility.some((x) => x.is_task && x.is_task > 0)
    )));
    
  }, [])

  useEffect(() => {
    setSelectedOperatorList(selectedOperators);
  }, [JSON.stringify(selectedOperators)])
  
  return (
    <div className="benchmark-left left-content-part d-flex flex-column px-0 py-0 w-100 flex-grow-1">
      
        <>
        <table className="dataTable left-content-part bg-transparent res-height-100 p-0 comman-table">
          <thead className="bg-white-03-solid-imp position-sticky top-0 z-index-12imp">
            <tr className="bg-white-03-solid position-relative">
              <th className={`border-bottom px-0 fw-semibold px-2`}>
                {_l("l_operator")}
              </th>
              {Object.keys(COLUMNS).map((day, i) => {
                return (
                  <th
                    key={i}
                    className={`border-bottom px-0 fw-semibold px-2 text-center 
                   
                    `}
                  >
                    {_l(COLUMNS[day])}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="">
            {selectedOperatorList
              .concat(
                operatorData.length &&
                  operatorData.length !== selectedOperatorList.length
                  ? [operatorSlot]
                  : []
              )
              .map((slot, key) => {
                return (
                  <tr
                    key={key}
                    className="borderrightnth0 checklistcustomwidth"
                  >
                    <td className="border p-2">
                      <div className="d-flex alingn-items-center flex-grow-1">
                        <div className=" flex-grow-1">
                          <Select
                            className="custom-select-menu with-filter-select me-0"
                            classNamePrefix="react-select"
                            options={
                              slot.staff_id == 0 && selectedOperatorList.length
                                ? getOperatorForNewSlot(slot)
                                : formattedOperatorList.filter(
                                    (w) => w.value != slot.staff_id
                                  )
                            }
                            isDisabled={
                              slot.staff_id > 0 &&
                              slot.avaibility.some((w) => w.is_task > 0)
                            }
                            value={formattedOperatorList.filter(
                              (employee) => employee.value == slot.staff_id
                            )}
                            onChange={(e) => {
                              changeOperatorSlot(slot, e);
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            placeholder={_l("l_select_provider")}
                          />
                        </div>
                      </div>
                    </td>
                    {Object.keys(COLUMNS).map((day, index) => {
                      return (
                        <td
                          key={index}
                          onDrop={(e) => handleDrop(e, slot, day)}
                          onDragOver={(e) => e.preventDefault()}
                          className={`border p-2 ${
                            (!slot.avaibility[index] ||
                              slot.avaibility[index][day] <= 0) &&
                            slot.staff_id > 0 &&
                            ![
                              "monthly",
                              "quarterly",
                              "half_yearly",
                              "yearly",
                            ].includes(day)
                              ? "close-div"
                              : "cursor-pointer"
                          }`}
                          onClick={()=>{
                            if ((!slot.avaibility[index] ||
                              slot.avaibility[index][day] <= 0) &&
                              slot.staff_id > 0 &&
                              ![
                                "monthly",
                                "quarterly",
                                "half_yearly",
                                "yearly",
                              ].includes(day)
                            ) {                              
                            } else if(slot.avaibility.some((day) => day.is_task > 0) || slot.id == 0){

                            }else{
                              selectedOperators.forEach((i)=>{
                                if (i.id == slot.id) {
                                  i.is_new = 1 ;
                                  i.avaibility.forEach((day, dayI) =>{
                                    if (dayI == index) {
                                      day.is_task = 1 ;
                                    }
                                  })
                                }
                              })
                              openTaskModalToEdit(day, slot.staff_id , true ,{...commanOffcanvasData,
                                id:1,
                                name : slot.fullname + "'s task",
                                startdate : moment(new Date()).format("YYYY-MM-DD"),
                                rel_id : localStorage.getItem("selectedOffice"),
                                schedule_assignees : [
                                  {
                                    "staffid": slot.staff_id,
                                    "userid": "",
                                    "profile_image": slot.image_url,
                                    "name": slot.fullname
                                 }
                                ],
                                assignee_list_DEV : [
                                  {
                                     "main_key": "my_operator",
                                     "categories": [
                                        {
                                           "filter_key": slot.staff_id,
                                           "main_sub_key": "people"
                                        }
                                     ]
                                  }
                               ],
                               rel_id : localStorage.getItem("selectedOffice"),
                               checklist : [{
                                floor_id : 1
                               }]
                              } )
                            }
                          }}
                        >
                          <div>
                            {slot.avaibility[index] &&
                            slot.avaibility[index].is_task > 0 ? (
                              TaskEditHTML(openTaskModalToEdit, day, slot, () =>
                                deleteSlot(slot, day)
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        </>
     
    </div>
  );
};

export default EditScheduleAssignment;
