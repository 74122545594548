import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  leftPanelFilters,
  getTopicTasks,
  projectTaskCounts,
  setTaskTypeFilter,
  ProductTour,
  getSelectedTask,
  taskBulkAction,
  getTaskCount,
  getSpaceFloorList,
  getMentionList,
  updatedTaskList,
  setAllCheckedTask,
  SetSelectedTask,
  setProjectTasksCounts,
  updateTaskStatus,
  setWeekCount,
} from "../actions/customer";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import TaskCard from "./TaskCard";
import jquery from "jquery";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { HideDropdown, _l } from "../hooks/utilities";
import Form from "react-bootstrap/Form";
import { Steps } from "intro.js-react";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import { showMessage, showConfirmation } from "../actions/messages";
import customerServices from "../services/customer-services";
import CustomerServices from "../services/customer-services";
import {
  contactintrostep,
  Staffintrosteps,
} from "../components/IntroComponents/IntroSteps";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import { getFloorDetails } from "../actions/benchmark-actions/benchmark-actions";
import TaskListSkeleton from "./Skeleton/TaskListSkeleton";
import KanbanTaskListSkeleton from "./Skeleton/KanbanTaskListSkeleton";
import CaretDoubleDownIcon from "../assets/icons/CaretDoubleDownIcon";
import DotsThreeVerticalIcon from "../assets/icons/DotsThreeVerticalIcon";
import XIcon from "../assets/icons/XIcon";
const Tasks = ({ projectId, pageName, kanbanView = false }) => {
  const {
    calendarData,
    taskList,
    taskFilters,
    activeTaskStatusFilters,
    isAppliedUnreadComment,
    taskTypeFilter,
    globalSearchString,
    getSelectedCalendarDate,
    globalView,
    pinDelayfilter,
    getCheckedTask,
    createTaskModalIsopen,
    commanoffcanvasIsOpen,
    tenantsRoleId,
    weekCountData,
    defaultTopics
  } = useSelector((state) => state.customer);
  const isProductTourGuide = useSelector((state) => state.customer.productTour);
  const dispatch = useDispatch();
  const [checkedTask, setCheckedTask] = useState([]);
  const [commonModalData, setCommonModalData] = useState();
  const [todoTasks, setTodoTasks] = useState([]);
  const [inProgressTasks, setInProgressTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [requestTasks, setRequestTasks] = useState([]);
  const [taskType, setTaskType] = useState("2");
  //type 2 means all task
  const [todoPage, setTodoPage] = useState(1);
  const [inProgressPage, setInProgressPage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const [requestPage, setRequestPage] = useState(1);
  const [liteversionPage, setLiteversionPage] = useState(1);
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [prevCalendarObject, setPrevCalendarObject] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [IsAttachmentCountUpdate, setIsAttachmentCountUpdate] = useState(false);
  const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
  const [taskStaus, setTaskStaus] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [defaultActiveKeys, setDefaultActiveKeys] = useState([]);
  const [taskSearchString, setTaskSearchString] = useState("");
  const [loder, setLoder] = useState({
    todoLoder: false,
    inProgressLoder: false,
    doneLoder: false,
    requestLoder: false,
    liteversionLoder: false,
  });
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const topicData = useSelector((state) => state.customer.topicData);
  const handleClose = () => setShowReqestedTaskModal(false);
  const handleShow = () => setShowReqestedTaskModal(true);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const [task_type, setTask_type] = useState("");
  const [IsKanbanView, setIsKanbanView] = useState(kanbanView);
  const [taskPinned, setTaskPinned] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [callUserList, setCallUserList] = useState([]);
  const [checklistItems, setChecklistItems] = useState([]);
  const [bulkActionAllTask, setBulkActionAllTask] = useState({
    todo: false,
    inProgress: false,
    done: false,
    request: false,
  });
  const userType = localStorage.getItem("user_type");
  const [selectedTaskIdforIconToggle, setSelectedTaskIdforIconToggle] =
    useState(0);
  const [showDefaultIcons, setShowDefaultIcons] = useState([]);
  const [localTaskList, setLocalTaskList] = useState([]);
  const [isSkeltonShow, setIsSkeltonShow] = useState(true);
  const [calendarDataCount, setCalendarDataCount] = useState([])
  const [moreTaskPage, setMoreTaskPage] = useState({
    constTodoPage: false,
    constInProgressPage: false,
    constDonePage: false,
    constRequestPage: false,
    constCompletedPage : false,
  });



  const project_id = useSelector((state) => state.customer.selectedProject);
  const selectedDate =
    localStorage.getItem("calendarData") &&
    localStorage.getItem("calendarData") != "undefined" &&
    JSON.parse(localStorage.getItem("calendarData")).length
      ? JSON.parse(localStorage.getItem("calendarData"))[3].date
      : new Date();
  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );
  const [taskId, setTaskId] = useState();
  useEffect(() => {
    let checkedList =
      checklistStateData &&
      checklistStateData.filter(
        (data) => data.finished == 1 || data.finished == "true"
      );
    let checkedDropDownData =
      localTaskList && localTaskList.filter((data) => data.id == taskId);
    let tasklistNew = localTaskList;
    if (checklistStateData && checklistStateData !== undefined) {
      if (selectedTask && tasklistNew) {
        let index = tasklistNew.findIndex((data) => selectedTask.id == data.id);
        if (index > -1) {
          tasklistNew[index].checklist_progress =
            Math.ceil((checkedList.length * 100) / checklistStateData.length) ||
            0;
          dispatch(updatedTaskList(tasklistNew));
        }
      }
      if (checkedDropDownData) {
        let index = tasklistNew.findIndex((data) => taskId == data.id);
        if (index > -1) {
          tasklistNew[index].checklist_progress =
            Math.ceil((checkedList.length * 100) / checklistStateData.length) ||
            0;
          dispatch(updatedTaskList(tasklistNew));
        }
      }
    }
  }, [checklistStateData]);
  useEffect(() => {
    if (typeof globalSearchString !== "undefined") {
      setTaskSearchString(globalSearchString);
    }
  }, [globalSearchString]);
  useEffect(() => {
    setIsKanbanView(kanbanView);
  }, [kanbanView]);
  useEffect(() => {
    if (isProductTourGuide) {
      setEnabled(true);
      jquery("body").addClass("intro-js");
    } else {
      setEnabled(false);
      jquery("body").removeClass("intro-js");
    }
  }, [isProductTourGuide, contactintrostep]);
  useEffect(() => {
    if (!showTaskModal) {
      setShowTaskModal(false);
    }
    if (taskStaus != "") {
      setTodoPage(1);
      setInProgressPage(1);
      setCompletedPage(1);
      setRequestPage(1);
      setLiteversionPage(1);
      setTaskStaus("");
    }
    setPrevCalendarObject(getSelectedCalendarDate);
    if (prevCalendarObject && prevCalendarObject !== getSelectedCalendarDate) {
      setTodoTasks([]);
      setInProgressTasks([]);
      setCompletedTasks([]);
      setRequestTasks([]);
    }
    if (localStorage.getItem("is_task_delete")) {
      localStorage.removeItem("is_task_delete");
    } else {
      // initial localTaskList API Call form here so comment API call
      const savedDate = localStorage.getItem("selectedDate");
    }
  }, [JSON.stringify(activeTaskStatusFilters)]);
  useEffect(() => {
    if (taskStaus != "") {
      if (taskStaus == 1) setTodoPage(todoPage);
      else if (taskStaus == 4) setInProgressPage(inProgressPage);
      else if (taskStaus == 5) setCompletedPage(completedPage);
      else if (taskStaus == 0) setRequestPage(requestPage);
    }
    if (localStorage.getItem("request_task") == 1) {
      localStorage.removeItem("request_task");
      setTodoPage(todoPage);
    }
    // << -- code commented bcz it clear task list after task list API response  -- >>
    setTodoTasks([]);
    setInProgressTasks([]);
    setCompletedTasks([]);
    setRequestTasks([]);
    setLoder({
      todoLoder: false,
      inProgressLoder: false,
      doneLoder: false,
    });
    getProjectTasks();
  }, [
    JSON.stringify(localTaskList),
    taskSearchString,
    calendarData,
    isAppliedUnreadComment,
    taskTypeFilter,
    taskPinned,
    pinDelayfilter,
    globalView,
  ]);
  useEffect(() => {
    jquery("#filter-show-list").on("click", function (event) {
      jquery("#show-filter-list").toggleClass("block");
    });
    setTimeout(() => {
      setIsSkeltonShow(false);
    }, 500);
  }, []);
  useEffect(() => {
    dispatch(getSpaceFloorList());
    dispatch(getMentionList(0, "task", 0));
    dispatch(getFloorDetails(projectId));
  }, [projectId]);
  useEffect(() => {
    if (taskFilters && taskFilters.length) {
      setTodoPage(1);
      setInProgressPage(1);
      setCompletedPage(1);
      setRequestPage(1);
      setLiteversionPage(1);
      setLoder({
        todoLoder: false,
        inProgressLoder: false,
        doneLoder: false,
        requestLoder: false,
        liteversionLoder: false,
      });
      setLeftActivePanelFilters(taskFilters);
    }
  }, [
    JSON.stringify(taskFilters),
    JSON.stringify(activeTaskStatusFilters),
    projectId,
    getSelectedCalendarDate,
  ]);
  useEffect(() => {
    if (topicData && !leftPanelActiveFilters.length) {
      let filters = [];
      topicData.topics &&
        topicData.topics.forEach((topic) => {
          topic.categories.forEach((category) => {
            filters.push({
              main_key: topic.main_key,
              filter_key: category.filter_key,
              title: category.title,
            });
          });
        });
    }
  }, [topicData, JSON.stringify(leftPanelActiveFilters)]);
  useEffect(() => {
    if (IsAttachmentCountUpdate) {
      if (selectedTask.status == "1") {
        setTodoTasks((todoTasks) =>
          todoTasks.map((obj) => {
            if (obj && obj.id == selectedTask.id) {
              let tempImageCount = imageCount ? imageCount : 1;
              setImageCount(0);
              return {
                ...obj,
                attachments_count: obj.attachments_count + tempImageCount,
              };
            }
            return obj;
          })
        );
      } else if (selectedTask.status == "4") {
        setInProgressTasks((inProgressTasks) =>
          inProgressTasks.map((obj) => {
            if (obj && obj.id == selectedTask.id) {
              let tempImageCount = imageCount ? imageCount : 1;
              setImageCount(0);
              return {
                ...obj,
                attachments_count: obj.attachments_count + tempImageCount,
              };
            }
            return obj;
          })
        );
      } else if (selectedTask.status == "5") {
        setCompletedTasks((completedTasks) =>
          completedTasks.map((obj) => {
            if (obj && obj.id == selectedTask.id) {
              let tempImageCount = imageCount ? imageCount : 1;
              setImageCount(0);
              return {
                ...obj,
                attachments_count: obj.attachments_count + tempImageCount,
              };
            }
            return obj;
          })
        );
      }
      setIsAttachmentCountUpdate(false);
    }
  }, [IsAttachmentCountUpdate, todoTasks, inProgressTasks, completedTasks]);
  useEffect(() => {
    setTaskType(2);
    if (taskTypeFilter && taskTypeFilter != "") {
      setTaskType(
        taskTypeFilter == "requestTask" ? 1 : taskTypeFilter == "Task" ? 0 : 2
      );
    }
  }, [taskTypeFilter]);
  useEffect(() => {
    if (selectedTask) {
      setTask_type(
        selectedTask.is_requested && selectedTask.is_requested == 1
          ? "request-task"
          : "task"
      );
      setCommonModalData(selectedTask);
      setSelectedTaskId(selectedTask.task_id || selectedTask.id);
      if (selectedTask.is_requested == 1) {
        handleShow();
      }
      if (selectedTask.task_id || selectedTask.id) {
        setShowTaskModal(true);
      }
    } else {
      setShowTaskModal(false);
      setSelectedTaskId(0);
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {});
    });
    return () => {
      jquery(document).unbind("ready");
    };
  }, [selectedTask]);

  const filterCategories = (task) => {
    if (taskSearchString) {
      let taskAssigneefilter = {};
      defaultTopics && defaultTopics.map((topic) => {
        topic.categories.forEach((category) => {
          if ((category.title).toLowerCase().includes(taskSearchString.toLowerCase())) {
            task.assignee_list_DEV.forEach((assig) => {
              assig.categories.forEach((acategory) => {
                if (acategory.filter_key == category.filter_key) {
                  taskAssigneefilter = task;
                }
              });
            })
          }
        })
      })
      const isTitleMatch =  (task.name).toLowerCase().includes(taskSearchString.toLowerCase());
      if (Object.keys(taskAssigneefilter).length > 0) {
        return taskAssigneefilter || isTitleMatch
      }else{
        return isTitleMatch
      }
    }else{
      return task;
    }
  };
  

  const getProjectTasks = () => {
    var taskUnreadCmtCntAll = 0;
    if (localTaskList && localTaskList.length) {
      let tempTaskList = localTaskList.slice();
      if (activeTaskStatusFilters && activeTaskStatusFilters.length) {
        tempTaskList.sort((a, b) =>
          moment(a.startdate).isAfter(moment(b.startdate)) ? 1 : -1
        );
      }
      let todoCount = 0;
      let inProcessCount = 0;
      let doneCount = 0;
      let requestCount = 0;
      let keyArray = [];
      setTodoPage(moreTaskPage.constTodoPage ? todoPage : 1);
      setInProgressPage(moreTaskPage.constInProgressPage ? inProgressPage : 1);
      setCompletedPage(moreTaskPage.constCompletedPage ? completedPage : 1);
      setRequestPage(moreTaskPage.constRequestPage ? requestPage : 1);
      setLiteversionPage(1);
      if (
        isAppliedUnreadComment == true ||
        (taskTypeFilter && taskTypeFilter != "") ||
        pinDelayfilter.length > 0
      ) {
        let tempTaskType =
          taskTypeFilter != "all"
            ? taskTypeFilter == "requestTask"
              ? 1
              : 0
            : "all";
        tempTaskList = localTaskList.filter((task) =>
          pinDelayfilter.includes("pinned") &&
          pinDelayfilter.includes("overdue") &&
          (taskFilters.length == 0 ||
            taskFilters.some((key) => key.main_key == "date_range_filter"))
            ? task.pinned == 1
            : pinDelayfilter.includes("pinned") &&
              (taskFilters.length == 0 ||
                taskFilters.some((key) => key.main_key == "date_range_filter"))
            ? task.pinned == 1
            : pinDelayfilter.includes("overdue") &&
              (taskFilters.length == 0 ||
                taskFilters.some((key) => key.main_key == "date_range_filter"))
            ? task.unread_delay_comment_count > 0
            : task
        ); //Added flag waiting_for_response to prevent the task from diappearing from list as and when comment opened
      }
      let requestTask = [];
      let todoTask = [];
      let inprogressTask = [];
      let doneTask = [];
      tempTaskList
        .filter((task) => filterCategories(task))
        .map((task) => {
          if (task.unread_comment_count > 0) {
            taskUnreadCmtCntAll++;
          }
          if (task.is_requested == 1) {
            requestTask.push(task);
            requestCount++;
          } else {
            switch (task.status) {
              case "1":
                todoTask.push(task);
                todoCount++;
                break;
              case "4":
                inprogressTask.push(task);
                inProcessCount++;
                break;
              case "5":
                doneTask.push(task);
                doneCount++;
                break;
              default:
                break;
            }
          }
        });
      setRequestTasks(orderTask(requestTask).slice());
      setTodoTasks(orderTask(todoTask).slice());
      setInProgressTasks(orderTask(inprogressTask).slice());
      setCompletedTasks(orderTask(doneTask).slice());
      if (requestCount > 0) {
        keyArray.push("0");
      }
      if (todoCount > 0) {
        keyArray.push("1");
      }
      if (inProcessCount > 0) {
        keyArray.push("2");
      }
      if (doneCount > 0) {
        keyArray.push("3");
      }
      setDefaultActiveKeys(keyArray);
    }
  };
  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    setShowTaskModal(false);
    setSelectedTaskId(taskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {
      setShowTaskModal(show);
    }
  };
  const orderTask = (taskArr) => {
    let pinTasks = [];
    let priorityTask = [];
    let otherTask = [];
    let unreadTask = [];
    taskArr.forEach((task) => {
      if (task.unread_comment_count > 0 || task.unread_checklist_comment_count > 0) {
        unreadTask.push(task);
      } else if (task.pinned == 1) {
        pinTasks.push(task);
      } else if (task.priority == "3") {
        priorityTask.push(task);
      } else {
        otherTask.push(task);
      }
    });
    return unreadTask.concat(priorityTask, pinTasks, otherTask);
  };
  const scroll = (status) => {
    let page;
    let taskStaus = status;
    if (status == 0) {
      page = requestPage + 1;
      setRequestPage(page);
    } else if (status == 1) {
      page = todoPage + 1;
      setTodoPage(page);
    } else if (status == 4) {
      page = inProgressPage + 1;
      setInProgressPage(page);
    } else if (status == "all") {
      page = liteversionPage + 1;
      setLiteversionPage(page);
    } else {
      page = completedPage + 1;
      setCompletedPage(page);
    }
    setTaskStaus(status);
    taskStaus = [status];
    var isDateActive = 1;
    if (!jquery("#active_week_date").hasClass("c-gradinet-active-border")) {
      isDateActive = 0;
    }
    if (status == 0) {
      setLoder({ ...loder, ["requestLoder"]: false });
    } else if (status == 1) {
      setLoder({ ...loder, ["todoLoder"]: false });
    } else if (status == 4) {
      setLoder({ ...loder, ["inProgressLoder"]: false });
    } else if (status == 5) {
      setLoder({ ...loder, ["doneLoder"]: false });
    } else if (status == "all") {
      setLoder({ ...loder, ["liteversionLoder"]: false });
    }
  };
  const handleAccordianExpansion = (key) => {
    const index = defaultActiveKeys.indexOf(key);
    if (index === -1) {
      setDefaultActiveKeys(defaultActiveKeys.concat(key));
    } else {
      setDefaultActiveKeys(
        defaultActiveKeys.filter((selected) => selected !== key)
      );
    }
  };
  const updateAttchmentChecklistCount = (taskid, imageCount = 0) => {
    setImageCount(imageCount ? imageCount : 0);
    if (selectedTask) {
      setIsAttachmentCountUpdate(true);
    }
  };
  const [enabled, setEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const onExit = () => {
    setEnabled(false);
  };
  const getTask = (id, is_requested = null) => {
    if (!selectedTask || selectedTask.id !== id) {
      dispatch(
        getSelectedTask(
          localStorage.getItem("selectedOffice"),
          id,
          is_requested
        )
      );
    }
  };
  const taskCheckUncheck = (task_id) => {
    const taskPosition = checkedTask.findIndex((x) => x == task_id);
    if (taskPosition === -1) {
      setCheckedTask([...checkedTask, task_id]);
    } else {
      setCheckedTask(checkedTask.filter((taskId) => taskId != task_id));
    }
  };
  useEffect(() => {
    if (checkedTask.length > 0) {
      let Arr = [];
      for (let i = 0; i < checkedTask.length; i++) {
        for (let j = 0; j < localTaskList.length; j++) {
          if (checkedTask[i] == localTaskList[j].id) {
            Arr.push(localTaskList[j]);
          }
        }
      }
    }
    dispatch(setAllCheckedTask(checkedTask));
  }, [checkedTask]);
  useEffect(() => {
    if (getCheckedTask) {
      setCheckedTask(getCheckedTask);
    }
  }, [getCheckedTask]);
  const handleTaskBulkAction = (is_delete = 0, status, actionType, files) => {
    if (checkedTask && checkedTask.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_task"))
      );
      return false;
    } else if (actionType == "set_assignee" || actionType == "comment") {
      customerServices
        .taskBulkAction(checkedTask, actionType, status, files)
        .then((res) => {
          if (res.status) {
            setCheckedTask([]);
            setBulkActionAllTask({
              todo: false,
              inProgress: false,
              done: false,
              request: false,
            });
            dispatch(
              projectTaskCounts(projectId, taskFilters ? taskFilters : [], "")
            );
            dispatch(
              getTaskCount(
                0,
                0,
                localStorage.getItem("selectedOffice"),
                moment(localStorage.getItem("selectedDate")).format(
                  "YYYY-MM-DD"
                ),
                taskFilters ? taskFilters : [],
                "",
                activeTaskStatusFilters ? activeTaskStatusFilters : []
              )
            );
          }
          dispatch(
            showMessage(
              res.status ? "sucess" : "unsucess",
              res.status ? _l("l_success") : _l("l_error"),
              res.message ? res.message : ""
            )
          );
        });
    } else if (actionType == "set_due_date") {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_you_want_to_change_due_date_of_selected_tasks"),
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            customerServices
              .taskBulkAction(checkedTask, actionType, status)
              .then((res) => {
                if (res.status) {
                  dispatch(
                    projectTaskCounts(
                      projectId,
                      taskFilters ? taskFilters : [],
                      ""
                    )
                  );
                  dispatch(
                    getTaskCount(
                      0,
                      0,
                      localStorage.getItem("selectedOffice"),
                      moment(localStorage.getItem("selectedDate")).format(
                        "YYYY-MM-DD"
                      ),
                      taskFilters ? taskFilters : [],
                      "",
                      activeTaskStatusFilters ? activeTaskStatusFilters : []
                    )
                  );
                  setCheckedTask([]);
                  setBulkActionAllTask({
                    todo: false,
                    inProgress: false,
                    done: false,
                    request: false,
                  });
                  dispatch(
                    taskBulkAction(
                      checkedTask,
                      is_delete
                        ? "delete_task"
                        : status == "task_convert_to_request"
                        ? "task_convert_to_request"
                        : "status_change",
                      is_delete
                        ? ""
                        : status == "task_convert_to_request"
                        ? "task_convert_to_request"
                        : status
                    )
                  );
                }
                dispatch(
                  showMessage(
                    res.status ? "sucess" : "unsucess",
                    res.status ? _l("l_success") : _l("l_error"),
                    res.message ? res.message : ""
                  )
                );
              });
          }
        )
      );
    } else {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          `${
            is_delete
              ? _l("l_you_want_tot_delete_this_task")
              : _l("l_you_want_to_change_task_status")
          }`,
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            customerServices
              .taskBulkAction(
                checkedTask,
                is_delete
                  ? "delete_task"
                  : status == "task_convert_to_request"
                  ? "task_convert_to_request"
                  : "status_change",
                is_delete
                  ? ""
                  : status == "task_convert_to_request"
                  ? ""
                  : status
              )
              .then((res) => {
                if (res.status) {
                  dispatch(
                    projectTaskCounts(
                      projectId,
                      taskFilters ? taskFilters : [],
                      ""
                    )
                  );
                  dispatch(
                    getTaskCount(
                      0,
                      0,
                      localStorage.getItem("selectedOffice"),
                      moment(localStorage.getItem("selectedDate")).format(
                        "YYYY-MM-DD"
                      ),
                      taskFilters ? taskFilters : [],
                      "",
                      activeTaskStatusFilters ? activeTaskStatusFilters : []
                    )
                  );
                  setCheckedTask([]);
                  setBulkActionAllTask({
                    todo: false,
                    inProgress: false,
                    done: false,
                    request: false,
                  });
                  dispatch(
                    taskBulkAction(
                      checkedTask,
                      is_delete
                        ? "delete_task"
                        : status == "task_convert_to_request"
                        ? "task_convert_to_request"
                        : "status_change",
                      is_delete
                        ? ""
                        : status == "task_convert_to_request"
                        ? "task_convert_to_request"
                        : status
                    )
                  );
                }
                dispatch(
                  showMessage(
                    res.status ? "sucess" : "unsucess",
                    res.status ? _l("l_success") : _l("l_error"),
                    res.message ? res.message : ""
                  )
                );
              });
          }
        )
      );
    }
  };
  const handleAllTaskBulkAction = (is_checked, taskType) => {
    let selectedBulkIds = checkedTask.length ? checkedTask : [];
    let task_list =
      taskType == "requestTasks"
        ? requestTasks
        : taskType == "todoTasks"
        ? todoTasks
        : taskType == "inProgressTasks"
        ? inProgressTasks
        : completedTasks;
    task_list.forEach((task) => {
      if (
        is_checked &&
        !selectedBulkIds.includes(task.id) &&
        task.is_allowed_to_edit == 1
      ) {
        selectedBulkIds.push(task.id);
      } else if (
        selectedBulkIds.includes(task.id) &&
        task.is_allowed_to_edit == 1
      ) {
        let index = checkedTask.findIndex((x) => x == task.id);
        if (index != -1) {
          selectedBulkIds.splice(index, 1);
        }
      }
    });
    setCheckedTask(selectedBulkIds);
  };
  useEffect(() => {
    CustomerServices.getContactsByClient().then((res) => {
      if (res && res.status) {
        setContacts(res.data);
      }
    });
  }, []);
  useEffect(() => {}, [userType, localStorage.getItem("selectedOffice")]);
  const handleToggleIcons = (
    isTaskPinned,
    taskAssignee,
    taskAssigneePills,
    taskPriority,
    comments_count,
    rating,
    setTaskCardIconToggle,
    taskCardIconToggle
  ) => {
    if (taskCardIconToggle) {
      let tempDefaultIcons = [];
      if (isTaskPinned) {
        tempDefaultIcons.push("pin");
      }
      if (taskAssignee.length > 0) {
        tempDefaultIcons.push("peopels");
      }
      if (taskAssigneePills.length > 0) {
        tempDefaultIcons.push("pills");
      }
      if (taskPriority == 3) {
        tempDefaultIcons.push("priority");
      }
      if (comments_count > 0) {
        tempDefaultIcons.push("comment");
      }
      if (rating && rating > 0) {
        tempDefaultIcons.push("star");
      }
      if (pageName == "LiteVersion") {
        setShowDefaultIcons(tempDefaultIcons);
      } else {
        setShowDefaultIcons([
          "calendar",
          "assigneTo",
          "pin",
          "peopels",
          "pills",
          "priority",
          "cube",
          "map",
          "status",
          "comment",
          "star",
          ,
          "delete",
          "share",
          "call",
          "template",
        ]);
      }
      setTaskCardIconToggle(false);
    } else {
      let tempDefaultIcons = [
        "calendar",
        "assigneTo",
        "pin",
        "peopels",
        "pills",
        "priority",
        "cube",
        "map",
        "status",
        "comment",
        "star",
        ,
        "delete",
        "share",
        "call",
        "template",
      ];
      if (pageName == "LiteVersion") {
        setShowDefaultIcons(tempDefaultIcons);
      } else {
        setShowDefaultIcons([
          "calendar",
          "assigneTo",
          "pin",
          "peopels",
          "pills",
          "priority",
          "cube",
          "map",
          "status",
          "comment",
          "star",
          ,
          "delete",
          "share",
          "call",
          "template",
        ]);
      }
      setTaskCardIconToggle(true);
    }
  };
  useEffect(() => {
    setLocalTaskList(taskList);
  }, [JSON.stringify(taskList), JSON.stringify(taskFilters), tenantsRoleId]);
  useEffect(() => {
    setIsSkeltonShow(true);
    setTimeout(() => {
      setIsSkeltonShow(false);
    }, 800);
  }, [JSON.stringify(taskFilters)])
  useEffect(() => {
    let data = {
      request_count: requestTasks.length,
      todo_count: todoTasks.length,
      ongoing_count: inProgressTasks.length,
      completed_count: completedTasks.length,
      total_count: requestTasks.concat(
        todoTasks,
        inProgressTasks,
        completedTasks
      ).length,
    };
    dispatch(setProjectTasksCounts(data));
  }, [todoTasks, inProgressTasks, completedTasks, requestTasks]);
  const updateCounts = () => {
    let reqTasks = taskList.filter((task) => task.is_requested == 1);
    let todoTask = taskList.filter((task) =>  task.status == 1 && task.is_requested != 1);
    let inprogressTask = taskList.filter((task) =>  task.status == 4 && task.is_requested != 1);
    let doneTask = taskList.filter((task) =>  task.status == 5 && task.is_requested != 1); 
    const updatedCalendarData = weekCountData && weekCountData.map(day => {
      const date = moment(day.date);
      // Filter tasks based on the defined logic
      const filterTasksByDate = (tasks) => tasks.filter(task => {
        const { startdate, duedate, dateadded } = task;
        // Check if startdate is defined and matches
        if (startdate && moment(startdate).isSame(date, 'day')) {
          return true;
        }
        // If startdate is null or undefined, check duedate
        if (!startdate && duedate && moment(duedate).isSame(date, 'day')) {
          return true;
        }
        // If both startdate and duedate are null or undefined, check dateadded
        if (!startdate && !duedate && dateadded && moment(dateadded).isSame(date, 'day')) {
          return true;
        }
        return false;
      });
      const filteredTodo = filterTasksByDate(todoTask);
      const filteredInProgress = filterTasksByDate(inprogressTask);
      const filteredCompleted = filterTasksByDate(doneTask);
      const filteredRequests = filterTasksByDate(reqTasks); // Assuming requests can also have dates
      return {
        ...day,
        todo_count: filteredTodo.length,
        inprogress_count: filteredInProgress.length,
        completed_count: filteredCompleted.length,
        request_total: filteredRequests.length,
        total: filteredTodo.length + filteredInProgress.length + filteredCompleted.length + filteredRequests.length,
      };
    });
setCalendarDataCount(updatedCalendarData);
  };
  const sortedAndFilteredRequestTasks = useMemo(() => {
    return requestTasks
      .sort((a, b) => b.unread_comment_count - a.unread_comment_count)
      .slice(
        0,
        requestPage > 1 ? (requestPage - 1) * 10 + 20 : requestPage * 20
      );
  }, [requestTasks, requestPage]);
  const sortedAndFilteredTodoTasks = useMemo(() => {
    return todoTasks
      .sort((a, b) => b.unread_comment_count - a.unread_comment_count)
      .slice(0, todoPage > 1 ? (todoPage - 1) * 10 + 20 : todoPage * 20);
  }, [todoTasks, todoPage]);
  const sortedAndFilteredProgressTasks = useMemo(() => {
    return inProgressTasks
      .sort((a, b) => b.unread_comment_count - a.unread_comment_count)
      .slice(
        0,
        inProgressPage > 1
          ? (inProgressPage - 1) * 10 + 20
          : inProgressPage * 20
      );
  }, [inProgressTasks, inProgressPage]);
  const sortedAndFilteredCompletedTasks = useMemo(() => {
    return completedTasks
      .sort((a, b) => b.unread_comment_count - a.unread_comment_count)
      .slice(
        0,
        completedPage > 1 ? (completedPage - 1) * 10 + 20 : completedPage * 20
      );
  }, [completedTasks, completedPage]);
  const handleOnDrag = (e, taskUP, setStatus) => {
    try {
      e.dataTransfer.setData("setTask", JSON.stringify({ taskUP, setStatus }));
    } catch (error) {
      console.error(error);
    }
  };
  const handleOnDrop = (e, status) => {
    try {
      const { taskUP, setStatus } = JSON.parse(
        e.dataTransfer.getData("setTask")
      );
      if (status != setStatus) {
        dispatch(
          updateTaskStatus(
            taskUP.id,
            project_id,
            status,
            taskUP.is_requested,
            taskUP.provider,
            taskUP.id,
            taskUP.name
          )
        );
        dispatch(getTaskCount(client_id, contact_id, project_id, selectedDate));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleOnDragOver = (e) => {
    try {
      e.preventDefault();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    let getTaskHash = localStorage.getItem("TaskHashLink");
    let getTaskType = localStorage.getItem("TaskTypeLink");
    if (getTaskHash && getTaskType) {
      try {
        CustomerServices.getTaskData(0, 0, null, getTaskHash).then(
          (response) => {
            if (response && response.status) {
              dispatch(SetSelectedTask(response.data));
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  }, []);
  useEffect(() => {
    updateCounts();
  }, [ taskFilters, taskList]);
  useEffect(() => {
    dispatch(setWeekCount(calendarDataCount));
  }, [calendarDataCount])
  return (
    <>
      <Steps
        enabled={enabled}
        steps={userType == "staff" ? Staffintrosteps : contactintrostep}
        initialStep={initialStep}
        onExit={onExit}
        options={{
          hideNext: false,
          exitOnOverlayClick: false,
          scrollToElement: true,
        }}
        onBeforeChange={(nextStepIndex) => {
          if (nextStepIndex === 2) {
            jquery(".provider-list").css({
              transform: "translateX(0px)",
              opacity: "1",
            });
          }
          if (nextStepIndex === 3) {
            jquery(".provider-list").css({
              transform: "translateX(0px)",
              opacity: "1",
            });
          }
          if (nextStepIndex === 4) {
          }
          if (nextStepIndex === 12) {
          }
          if (nextStepIndex === 13) {
          }
          if (nextStepIndex === 14) {
            jquery("#BottomMenushow").addClass("show-menu-link");
            jquery("#BottomMenushow").css({ transform: " scale(1)" });
            jquery(".rotate-image").addClass("plus-rotate-image");
          }
          if (nextStepIndex === 15) {
            jquery("#BottomMenushow").addClass("show-menu-link");
            jquery("#BottomMenushow").css({ transform: " scale(1)" });
            jquery(".rotate-image").addClass("plus-rotate-image");
          }
          if (nextStepIndex === 16) {
            jquery("#BottomMenushow").addClass("show-menu-link");
            jquery("#BottomMenushow").css({ transform: " scale(1)" });
            jquery(".rotate-image").addClass("plus-rotate-image");
          }
          if (nextStepIndex === 17) {
            jquery("#BottomMenushow").addClass("show-menu-link");
            jquery("#BottomMenushow").css({ transform: " scale(1)" });
            jquery(".rotate-image").addClass("plus-rotate-image");
          }
          if (nextStepIndex === 18) {
            jquery("#BottomMenushow").addClass("show-menu-link");
            jquery("#BottomMenushow").removeAttr("style");
            jquery(".rotate-image").addClass("plus-rotate-image");
          }
          if (nextStepIndex === 19) {
            jquery("#BottomMenushow").removeClass("show-menu-link");
            jquery("#BottomMenushow").removeAttr("style");
            jquery(".rotate-image").removeClass("plus-rotate-image");
          }
          if (nextStepIndex === 20) {
            jquery("#BottomMenushow").removeClass("show-menu-link");
            jquery("#BottomMenushow").removeAttr("style");
            jquery(".rotate-image").removeClass("plus-rotate-image");
          }
        }}
        onChange={(nextStepIndex) => {
          if (nextStepIndex === 0) {
            handleSelectedTask(0, true, 0, 0);
            getTask(0, 0);
          }
          if (nextStepIndex === 19) {
            handleSelectedTask(252567, true, 0, 0);
            getTask(252567, 0);
          }
          if (nextStepIndex === 20) {
            handleSelectedTask(0, true, 0, 0);
            getTask(0, 0);
          }
        }}
        onBeforeExit={(nextStepIndex) => {
          setEnabled(false);
          dispatch(ProductTour(false));
          jquery("body").removeClass("intro-js");
        }}
        refresh={(nextStepIndex) => {
          if (nextStepIndex === 0) {
          }
        }}
      />
      {pageName != "dashboardnew" && globalView == "kanbanView" ? (
        <div
          id="liteversion_view"
          className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian px-0 ${
            globalView == "kanbanView"
              ? "pb-0 kanban-view overflow-hidden horizontal-scroll"
              : ""
          } ${
            showTaskModal || showReqestedTaskModal ? "list-view-toggle" : ""
          } ${IsKanbanView ? "" : ""}`}
        >
          <Accordion
            className="task-list-accordian-main with_checkbox_absolute mt-0"
            onSelect={(eventKey) => {
              handleAccordianExpansion(eventKey);
            }}
            activeKey={
              isSkeltonShow
                ? ["0", "1", "2", "3", "4"]
                : defaultActiveKeys
            }
            defaultActiveKey={["0", "1", "2", "3", "4"]}
          >
            <div className="pill-wrapper with-right-pos d-none" id="">
              <div className="pill-wrappe-modal-overlay"></div>
              <div id="" className="">
                <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end pb-2">
                  {taskType == 0 || taskType == 1 ? (
                    <div className="badge w-auto color-white-80 bg-white-05  radius_3 me-2">
                      <div className="d-flex align-items-center">
                        <span className="color-white">
                          {taskType ? _l("l_request_task") : _l("l_task")}
                        </span>
                        <a
                          href="#/"
                          className="ps-1"
                          onClick={() => {
                            dispatch(setTaskTypeFilter(_l("l_all")));
                          }}
                        >
                          <XIcon className="HW14" />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {leftPanelActiveFilters.length ? (
                    leftPanelActiveFilters.map((value, index) => {
                      if (value.main_key == "date_range_filter") {
                        let displayFilterKey = value.title;
                        return (
                          <div
                            key={index}
                            className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1 d-none"
                          >
                            <div className="d-flex align-items-center">
                              <span className="color-white">
                                {displayFilterKey}
                              </span>
                              <a
                                href="#/"
                                className="ps-1"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: value.main_key,
                                      filter_key: value.filter_key,
                                      title: value.title,
                                      main_sub_key: value.main_sub_key,
                                    })
                                  );
                                  dispatch(
                                    projectTaskCounts(projectId, taskFilters, {
                                      main_key: value.main_key,
                                      filter_key: value.filter_key,
                                      title: value.title,
                                    })
                                  );
                                  dispatch(
                                    projectTaskCounts(
                                      projectId,
                                      taskFilters,
                                      ""
                                    )
                                  );
                                  dispatch(
                                    getTopicTasks(
                                      projectId,
                                      "",
                                      0,
                                      localStorage.getItem("selectedDate"),
                                      taskFilters
                                    )
                                  );
                                }}
                              >
                                <XIcon className="HW14" />
                              </a>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                  <div
                    className={`comman_action_icon ms-2 pe-1 dropdown d-none ${
                      showTaskModal || showReqestedTaskModal ? "d-none" : ""
                    }`}
                  >
                    <a
                      href="#/"
                      type="button"
                      className={`line-height-0 light-theme-img action_icon h28w28 `}
                      id="viewDropdwon"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                    >
                      <DotsThreeVerticalIcon className="HW16" />
                    </a>
                    <ActionDropdown
                      widthClass="w-170"
                      actions={[
                        {
                          actionName: _l("l_list_view"),
                          actionIcon: "ListBulletsIcon",
                          actionHandler: () => {
                            setIsKanbanView(false);
                            HideDropdown();
                          },
                        },
                        {
                          actionName: _l("l_kanban_view"),
                          actionIcon: "Kanban",
                          actionHandler: () => {
                            setIsKanbanView(true);
                            HideDropdown();
                          },
                        },
                      ]}
                      actionStausChange={handleTaskBulkAction}
                      IsKanbanView={IsKanbanView}
                    />
                  </div>
                  <Form.Check
                    className={`line-height-0 ms-2 d-none ${
                      showTaskModal || showReqestedTaskModal ? "d-none" : ""
                    }`}
                    type="switch"
                    onChange={() => {
                      setIsKanbanView(!IsKanbanView);
                    }}
                  />
                </div>
              </div>
            </div>
            <Accordion.Item eventKey="0" className="with-pb-15">
              <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
              <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                  {requestTasks && requestTasks.length ? (
                    <span className=" checkbox_absolute">
                      <Form.Check
                        type="checkbox"
                        checked={bulkActionAllTask.request ? true : false}
                        onChange={(e) => {
                          handleAllTaskBulkAction(
                            e.target.checked,
                            "requestTasks"
                          );
                          setBulkActionAllTask({
                            ...bulkActionAllTask,
                            ["request"]: e.target.checked,
                          });
                        }}
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                  <Accordion.Header
                    className={`active orange commanTaskCount w-auto ${
                      requestTasks.length == 0 ? "radius3px" : ""
                    } `}
                    id="request"
                  >
                    <div className="accordion-header-wrapper w-auto ">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                            <span className="c-dots  c-7 bg-warning rounded-circle me-3"></span>
                            <span className="task-title me-2">
                              {_l("l_request")}
                            </span>
                            <span className="" id="request_count_display">
                              {requestTasks && requestTasks.length
                                ? "[" + requestTasks.length + "]"
                                : "[0]"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                {showTaskModal ? <div className="threedotbtn"></div> : <></>}
              </div>
              <Accordion.Body
                className="taskCountToggle"
                id="request_count"
                onDrop={(e) => {
                  handleOnDrop(e, "task_convert_to_request");
                }}
                onDragOver={handleOnDragOver}
              >
                {isSkeltonShow ? (
                  ["0", "1", "2"].map((s) => {
                    return <KanbanTaskListSkeleton />;
                  })
                ) : (
                  <>
                    {requestTasks.length ? (
                      <>
                        {sortedAndFilteredRequestTasks.map((task) => (
                          <div
                            key={task.id}
                            draggable
                            onDragStart={(e) =>
                              handleOnDrag(e, task, "task_convert_to_request")
                            }
                          >
                            <TaskCard
                              task={task}
                              active={selectedTaskId === task.id}
                              project_id={projectId}
                              handler={handleSelectedTask}
                              pageName="LiteVersion"
                              taskCheckUncheck={taskCheckUncheck}
                              checkedTask={checkedTask}
                              taskPinned={taskPinned}
                              setTaskPinned={setTaskPinned}
                              contacts={contacts}
                              setTaskId={setTaskId}
                              setCallUserList={setCallUserList}
                              setSelectedTaskIdforIconToggle={
                                setSelectedTaskIdforIconToggle
                              }
                              selectedTaskIdforIconToggle={
                                selectedTaskIdforIconToggle
                              }
                              showDefaultIcons={showDefaultIcons}
                              setShowDefaultIcons={setShowDefaultIcons}
                              handleToggleIcons={handleToggleIcons}
                            />
                          </div>
                        ))}
                        {(requestTasks.length >= (requestPage - 1) * 10 + 20 &&
                          requestTasks.length != (requestPage - 1) * 10 + 20) ||
                        loder.requestLoder ? (
                          <a
                            href="#/"
                            className="btn btn-white-03 w-100 view-more-btn"
                            onClick={() => {
                              setSelectedTaskIdforIconToggle(0);
                              setLoder({ ...loder, ["requestLoder"]: true });
                              scroll(0);
                              setMoreTaskPage({...moreTaskPage, constRequestPage: true});
                            }}
                          >
                            <div className="d-flex align-item-center justify-content-center">
                              <span className=" title-fonts c-font f-12 ms-2">
                                {_l("l_view_more_btn")} (
                                {requestPage > 1
                                  ? requestTasks.length -
                                    ((requestPage - 1) * 10 + 20)
                                  : requestTasks.length - 20}
                                )
                              </span>
                              <CaretDoubleDownIcon className="HW12 mx-2" />
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className={`ms-2 ${
                                  loder.requestLoder ? "opacity-1" : "opacity-0"
                                }`}
                                aria-hidden="true"
                              />
                            </div>
                          </a>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <CommanPlaceholder
                          imgType="request"
                          placeholderText={_l("l_no_request_task")}
                        />
                      </>
                    )}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="with-pb-15">
              <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                  {todoTasks && todoTasks.length ? (
                    <span className="checkbox_absolute">
                      <Form.Check
                        type="checkbox"
                        checked={bulkActionAllTask.todo ? true : false}
                        onChange={(e) => {
                          handleAllTaskBulkAction(
                            e.target.checked,
                            "todoTasks"
                          );
                          setBulkActionAllTask({
                            ...bulkActionAllTask,
                            ["todo"]: e.target.checked,
                          });
                        }}
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                  <Accordion.Header
                    className={`active to-do commanTaskCount w-auto  ${
                      todoTasks.length == 0 ? "radius3px" : ""
                    }`}
                    id="todo"
                  >
                    <div className="accordion-header-wrapper w-auto ">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                            <span className="c-dots  c-7 in-progress-white rounded-circle me-3"></span>
                            <span className="task-title me-2">
                              {_l("l_todo")}
                            </span>
                            <span className="" id="todo_count_display">
                              {todoTasks && todoTasks.length
                                ? "[" + todoTasks.length + "]"
                                : "[0]"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                {showTaskModal ? <div className="threedotbtn"></div> : <></>}
              </div>
              <Accordion.Body
                className="taskCountToggle"
                id="todo_count"
                onDrop={(e) => {
                  handleOnDrop(e, 1);
                }}
                onDragOver={handleOnDragOver}
              >
                {isSkeltonShow ? (
                  ["0", "1", "2"].map((s) => {
                    return <KanbanTaskListSkeleton />;
                  })
                ) : (
                  <>
                    {todoTasks.length ? (
                      <>
                        {sortedAndFilteredTodoTasks.map((task) => (
                          <div
                            key={task.id}
                            draggable
                            onDragStart={(e) => handleOnDrag(e, task, 1)}
                          >
                            <TaskCard
                              task={task}
                              active={selectedTaskId === task.id}
                              project_id={projectId}
                              handler={handleSelectedTask}
                              pageName="LiteVersion"
                              taskCheckUncheck={taskCheckUncheck}
                              checkedTask={checkedTask}
                              taskPinned={taskPinned}
                              setTaskPinned={setTaskPinned}
                              contacts={contacts}
                              setTaskId={setTaskId}
                              setCallUserList={setCallUserList}
                              setSelectedTaskIdforIconToggle={
                                setSelectedTaskIdforIconToggle
                              }
                              selectedTaskIdforIconToggle={
                                selectedTaskIdforIconToggle
                              }
                              showDefaultIcons={showDefaultIcons}
                              setShowDefaultIcons={setShowDefaultIcons}
                              handleToggleIcons={handleToggleIcons}
                            />
                          </div>
                        ))}
                        {(todoTasks.length >= (todoPage - 1) * 10 + 20 &&
                          todoTasks.length != (todoPage - 1) * 10 + 20) ||
                        loder.todoLoder ? (
                          <a
                            href="#/"
                            className="btn btn-white-03 w-100 view-more-btn"
                            onClick={() => {
                              setSelectedTaskIdforIconToggle(0);
                              setLoder({ ...loder, ["todoLoder"]: true });
                              scroll(1);
                              setMoreTaskPage({...moreTaskPage, constTodoPage: true});
                            }}
                          >
                            <div className="d-flex align-item-center justify-content-center">
                              <span className=" title-fonts c-font f-12 ms-2">
                                {_l("l_view_more_btn")} (
                                {todoPage > 1
                                  ? todoTasks.length -
                                    ((todoPage - 1) * 10 + 20)
                                  : todoTasks.length - 20}
                                )
                              </span>
                              <CaretDoubleDownIcon className="HW12 mx-2" />
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className={`ms-2 ${
                                  loder.todoLoder ? "opacity-1" : "opacity-0"
                                }`}
                                aria-hidden="true"
                              />
                            </div>
                          </a>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <CommanPlaceholder
                          imgType="todo-task"
                          placeholderText={_l("l_no_todo_task")}
                        />
                      </>
                    )}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="with-pb-15">
              <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
              <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                  {inProgressTasks && inProgressTasks.length ? (
                    <span className="checkbox_absolute">
                      <Form.Check
                        type="checkbox"
                        checked={bulkActionAllTask.inProgress ? true : false}
                        onChange={(e) => {
                          handleAllTaskBulkAction(
                            e.target.checked,
                            "inProgressTasks"
                          );
                          setBulkActionAllTask({
                            ...bulkActionAllTask,
                            ["inProgress"]: e.target.checked,
                          });
                        }}
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                  <Accordion.Header
                    className={`active  in-progress commanTaskCount w-auto ${
                      inProgressTasks.length == 0 ? "radius3px" : ""
                    }`}
                    id="inprogress"
                  >
                    <div className="accordion-header-wrapper w-auto">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 rounded-circle in-progress-blue me-3"></span>
                            <span className="task-title me-2">
                              {_l("l_inprogress")}
                            </span>
                            <span className="" id="inprogress_count_display">
                              {inProgressTasks && inProgressTasks.length
                                ? "[" + inProgressTasks.length + "]"
                                : "[0]"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
              </div>
              <Accordion.Body
                className=""
                id="inprogress_count"
                onDrop={(e) => {
                  handleOnDrop(e, 4);
                }}
                onDragOver={handleOnDragOver}
              >
                {isSkeltonShow ? (
                  ["0", "1", "2"].map((s) => {
                    return <KanbanTaskListSkeleton />;
                  })
                ) : (
                  <>
                    {inProgressTasks.length ? (
                      <>
                        {sortedAndFilteredProgressTasks.map((task) => (
                          <div
                            key={task.id}
                            draggable
                            onDragStart={(e) => handleOnDrag(e, task, 4)}
                          >
                            <TaskCard
                              task={task}
                              active={selectedTaskId === task.id}
                              project_id={projectId}
                              handler={handleSelectedTask}
                              pageName="LiteVersion"
                              taskCheckUncheck={taskCheckUncheck}
                              checkedTask={checkedTask}
                              taskPinned={taskPinned}
                              setTaskPinned={setTaskPinned}
                              contacts={contacts}
                              setTaskId={setTaskId}
                              setCallUserList={setCallUserList}
                              setSelectedTaskIdforIconToggle={
                                setSelectedTaskIdforIconToggle
                              }
                              selectedTaskIdforIconToggle={
                                selectedTaskIdforIconToggle
                              }
                              showDefaultIcons={showDefaultIcons}
                              setShowDefaultIcons={setShowDefaultIcons}
                              handleToggleIcons={handleToggleIcons}
                            />
                          </div>
                        ))}
                        {(inProgressTasks.length >=
                          (inProgressPage - 1) * 10 + 20 &&
                          inProgressTasks.length !=
                            (inProgressPage - 1) * 10 + 20) ||
                        loder.inProgressLoder ? (
                          <a
                            href="#/"
                            className="btn btn-white-03 w-100 view-more-btn"
                            onClick={() => {
                              setSelectedTaskIdforIconToggle(0);
                              setLoder({ ...loder, ["inProgressLoder"]: true });
                              scroll(4);
                              setMoreTaskPage({...moreTaskPage, constInProgressPage: true});
                            }}
                          >
                            <div className="d-flex align-item-center justify-content-center">
                              <span className="title-fonts c-font f-12 ms-2">
                                {_l("l_view_more_btn")} (
                                {inProgressPage > 1
                                  ? inProgressTasks.length -
                                    ((inProgressPage - 1) * 10 + 20)
                                  : inProgressTasks.length - 20}
                                )
                              </span>
                              <CaretDoubleDownIcon className="HW12 mx-2" />
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className={`ms-2 ${
                                  loder.todoLoder ? "opacity-1" : "opacity-0"
                                }`}
                                aria-hidden="true"
                              />
                            </div>
                          </a>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <CommanPlaceholder
                          imgType="inprogress-task"
                          placeholderText={_l("l_no_inprogress_task")}
                        />
                      </>
                    )}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="">
              <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
              <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                  {completedTasks && completedTasks.length ? (
                    <span className="checkbox_absolute">
                      <Form.Check
                        type="checkbox"
                        checked={bulkActionAllTask.done ? true : false}
                        onChange={(e) => {
                          handleAllTaskBulkAction(
                            e.target.checked,
                            "completedTasks"
                          );
                          setBulkActionAllTask({
                            ...bulkActionAllTask,
                            ["done"]: e.target.checked,
                          });
                        }}
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                  <Accordion.Header
                    className={`active  done w-auto  ${
                      completedTasks.length == 0 ? "radius3px" : ""
                    }`}
                  >
                    <div className="accordion-header-wrapper w-auto">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                            <span className="c-dots  c-7 done rounded-circle me-3"></span>
                            <span className="task-title me-2">
                              {_l("l_done")}
                            </span>
                            <span className="" id="done_count_display">
                              {completedTasks && completedTasks.length
                                ? "[" + completedTasks.length + "]"
                                : "[0]"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
              </div>
              <Accordion.Body
                className=""
                id="done_count"
                onDrop={(e) => {
                  handleOnDrop(e, 5);
                }}
                onDragOver={handleOnDragOver}
              >
                {isSkeltonShow ? (
                  ["0", "1", "2"].map((s) => {
                    return <KanbanTaskListSkeleton />;
                  })
                ) : (
                  <>
                    {completedTasks.length ? (
                      <>
                        {sortedAndFilteredCompletedTasks.map((task) => (
                          <div
                            key={task.id}
                            draggable
                            onDragStart={(e) => handleOnDrag(e, task, 5)}
                          >
                            <TaskCard
                              task={task}
                              active={selectedTaskId === task.id}
                              project_id={projectId}
                              handler={handleSelectedTask}
                              pageName="LiteVersion"
                              taskCheckUncheck={taskCheckUncheck}
                              checkedTask={checkedTask}
                              taskPinned={taskPinned}
                              setTaskPinned={setTaskPinned}
                              contacts={contacts}
                              setTaskId={setTaskId}
                              setCallUserList={setCallUserList}
                              setSelectedTaskIdforIconToggle={
                                setSelectedTaskIdforIconToggle
                              }
                              selectedTaskIdforIconToggle={
                                selectedTaskIdforIconToggle
                              }
                              showDefaultIcons={showDefaultIcons}
                              setShowDefaultIcons={setShowDefaultIcons}
                              handleToggleIcons={handleToggleIcons}
                            />
                          </div>
                        ))}
                        {(completedTasks.length >=
                          (completedPage - 1) * 10 + 20 &&
                          completedTasks.length !=
                            (completedPage - 1) * 10 + 20) ||
                        loder.doneLoder ? (
                          <a
                            href="#/"
                            className="btn btn-white-03 w-100 view-more-btn"
                            onClick={() => {
                              setSelectedTaskIdforIconToggle(0);
                              setLoder({ ...loder, ["doneLoder"]: true });
                              scroll(5);
                              setMoreTaskPage({...moreTaskPage, constCompletedPage: true});
                            }}
                          >
                            <div className="d-flex align-item-center justify-content-center">
                              <span className=" title-fonts c-font f-12 ms-2">
                                {_l("l_view_more_btn")} (
                                {completedPage > 1
                                  ? completedTasks.length -
                                    ((completedPage - 1) * 10 + 20)
                                  : completedTasks.length - 20}
                                )
                              </span>
                              <CaretDoubleDownIcon className="HW12 mx-2" />
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className={`ms-2 ${
                                  loder.todoLoder ? "opacity-1" : "opacity-0"
                                }`}
                                aria-hidden="true"
                              />
                            </div>
                          </a>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <CommanPlaceholder
                          imgType="NoDoneTask-task"
                          placeholderText={_l("l_no_done_task")}
                        />
                      </>
                    )}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {showTaskModal ? (
            <CommanOffCanvas
              show={showTaskModal}
              handleClose={() => {
                setShowTaskModal(false);
                handleClose();
                setCommonModalData();
                setSelectedTaskId(0);
                setTask_type("");
              }}
              pinHandler={(id, type, flag) => {}}
              data={commonModalData}
              docType={task_type}
              setSelectedId={setSelectedTaskId}
              selectedTaskId={selectedTaskId}
              IsKanbanView={IsKanbanView}
              meetingUrl={meetingUrl}
              handleParentComponentAction={(type, taskData) => {
                if (task_type == "task") {
                  if (type == "attachmentCountUpdate") {
                    updateAttchmentChecklistCount(
                      taskData.task_id,
                      taskData.flag
                    );
                    return;
                  } else if (type == "handleSelectedTask") {
                    handleSelectedTask(taskData.task_id, taskData.flag);
                    return;
                  } else if (type == "convertedTaskAction") {
                    handleSelectedTask(
                      taskData.convertTaskId,
                      taskData.isShow,
                      taskData.isRequestTask,
                      taskData.task_id
                    );
                    return;
                  }
                } else if (task_type == "request-task") {
                  if (type == "convertedTaskAction") {
                    handleSelectedTask(
                      taskData.convertTaskId,
                      taskData.isShow,
                      taskData.isRequestTask,
                      taskData.task_id
                    );
                    return;
                  }
                }
              }}
              contacts={contacts}
              callUserList={callUserList}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          {/* ----------------------------------------------------------------------- liteversionview start ----------------------------------------------------------------------- */}
          <div
            id="list_view"
            className={`schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column  ${
              checklistItems.length > 0 ? "" : ""
            }  ${
              showTaskModal || showReqestedTaskModal
                ? "list-view-toggle w-auto"
                : ""
            }`}
          >
            <div
              id="list_view"
              className={` d-flex flex-column h-100 flex-grow-1 backdroplighttheme  ${
                createTaskModalIsopen && globalView == "liteVersionView"
                  ? ""
                  : ""
              }`}
            >
              <div
                id="liteversion_view"
                className={`comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian ${
                  globalView == "kanbanView"
                    ? "pb-0 kanban-view overflow-hidden horizontal-scroll"
                    : ""
                } ${
                  showTaskModal || showReqestedTaskModal
                    ? "list-view-toggle"
                    : ""
                } ${IsKanbanView ? "" : ""}`}
              >
                <Accordion
                  className={`task-list-accordian-main with_checkbox_absolute mt-0 w-800px my-0 padding-bottom-60px ${
                    commanoffcanvasIsOpen ? "" : "daily-schedule-main"
                  }`}
                  onSelect={(eventKey) => {
                    handleAccordianExpansion(eventKey);
                  }}
                  activeKey={
                    isSkeltonShow
                      ? ["0", "1", "2", "3", "4"]
                      : defaultActiveKeys
                  }
                  defaultActiveKey={["0", "1", "2", "3", "4"]}
                >
                  <div className="pill-wrapper with-right-pos d-none" id="">
                    <div className="pill-wrappe-modal-overlay"></div>
                    <div id="" className="">
                      <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end pb-2">
                        {taskType == 0 || taskType == 1 ? (
                          <div className="badge w-auto color-white-80 bg-white-05  radius_3 me-2">
                            <div className="d-flex align-items-center">
                              <span className="color-white">
                                {taskType ? _l("l_request_task") : _l("l_task")}
                              </span>
                              <a
                                href="#/"
                                className="ps-1"
                                onClick={() => {
                                  dispatch(setTaskTypeFilter(_l("l_all")));
                                }}
                              >
                                <XIcon className="HW14" />
                              </a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {leftPanelActiveFilters.length ? (
                          leftPanelActiveFilters.map((value, index) => {
                            if (value.main_key == "date_range_filter") {
                              let displayFilterKey = value.title;
                              return (
                                <div
                                  key={index}
                                  className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1 d-none"
                                >
                                  <div className="d-flex align-items-center">
                                    <span className="color-white">
                                      {displayFilterKey}
                                    </span>
                                    <a
                                      href="#/"
                                      className="ps-1"
                                      onClick={() => {
                                        dispatch(
                                          leftPanelFilters({
                                            main_key: value.main_key,
                                            filter_key: value.filter_key,
                                            title: value.title,
                                            main_sub_key: value.main_sub_key,
                                          })
                                        );
                                        dispatch(
                                          projectTaskCounts(
                                            projectId,
                                            taskFilters,
                                            {
                                              main_key: value.main_key,
                                              filter_key: value.filter_key,
                                              title: value.title,
                                            }
                                          )
                                        );
                                        dispatch(
                                          projectTaskCounts(
                                            projectId,
                                            taskFilters,
                                            ""
                                          )
                                        );
                                        dispatch(
                                          getTopicTasks(
                                            projectId,
                                            "",
                                            0,
                                            localStorage.getItem(
                                              "selectedDate"
                                            ),
                                            taskFilters
                                          )
                                        );
                                      }}
                                    >
                                      <XIcon className="HW14" />
                                    </a>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <></>
                        )}
                        <div
                          className={`comman_action_icon ms-2 pe-1 dropdown d-none ${
                            showTaskModal || showReqestedTaskModal
                              ? "d-none"
                              : ""
                          }`}
                        >
                          <a
                            href="#/"
                            type="button"
                            className={`line-height-0 light-theme-img action_icon h28w28 `}
                            id="viewDropdwon"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                          >
                            <DotsThreeVerticalIcon className="HW16" />
                          </a>
                          <ActionDropdown
                            widthClass="w-170"
                            actions={[
                              {
                                actionName: _l("l_list_view"),
                                actionIcon: "ListBulletsIcon",
                                actionHandler: () => {
                                  setIsKanbanView(false);
                                  HideDropdown();
                                },
                              },
                              {
                                actionName: _l("l_kanban_view"),
                                actionIcon: "Kanban",
                                actionHandler: () => {
                                  setIsKanbanView(true);
                                  HideDropdown();
                                },
                              },
                            ]}
                            actionStausChange={handleTaskBulkAction}
                            IsKanbanView={IsKanbanView}
                          />
                        </div>
                        <Form.Check
                          className={`line-height-0 ms-2 d-none ${
                            showTaskModal || showReqestedTaskModal
                              ? "d-none"
                              : ""
                          }`}
                          type="switch"
                          onChange={() => {
                            setIsKanbanView(!IsKanbanView);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Accordion.Item eventKey="0" className="with-pb-15">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                      <div className="modal-overlay3"></div>
                      <div className="checkbox_accordian">
                        {requestTasks && requestTasks.length ? (
                          <span className=" checkbox_absolute">
                            <Form.Check
                              type="checkbox"
                              checked={bulkActionAllTask.request ? true : false}
                              onChange={(e) => {
                                handleAllTaskBulkAction(
                                  e.target.checked,
                                  "requestTasks"
                                );
                                setBulkActionAllTask({
                                  ...bulkActionAllTask,
                                  ["request"]: e.target.checked,
                                });
                              }}
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                        <Accordion.Header
                          className={`active orange commanTaskCount w-auto  ${
                            requestTasks.length == 0 ? "radius3px" : ""
                          }`}
                          id="request"
                        >
                          <div className="accordion-header-wrapper w-auto ">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="left-task-header">
                                <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                  <span className="c-dots  c-7 bg-warning rounded-circle me-3"></span>
                                  <span className="task-title me-2">
                                    {_l("l_request")}
                                  </span>
                                  <span className="" id="request_count_display">
                                    {requestTasks && requestTasks.length
                                      ? "[" + requestTasks.length + "]"
                                      : "[0]"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>
                      </div>
                      {showTaskModal ? (
                        <div className="threedotbtn"></div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Accordion.Body
                      className="taskCountToggle"
                      id="request_count"
                    >
                      {isSkeltonShow ? (
                        ["0", "1", "2"].map((s) => {
                          return <TaskListSkeleton />;
                        })
                      ) : (
                        <>
                          {requestTasks.length ? (
                            <>
                              {requestTasks
                                .sort((a, b) => {
                                  return (
                                    b.unread_comment_count -
                                    a.unread_comment_count
                                  );
                                })
                                .slice(
                                  0,
                                  requestPage > 1
                                    ? (requestPage - 1) * 10 + 3
                                    : requestPage * 3
                                )
                                .map((task, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div
                                        key={index}
                                        className=""
                                      >
                                        <TaskCard
                                          key={task.id}
                                          task={task}
                                          active={selectedTaskId == task.id}
                                          project_id={projectId}
                                          handler={handleSelectedTask}
                                          pageName="LiteVersion"
                                          taskCheckUncheck={taskCheckUncheck}
                                          checkedTask={checkedTask}
                                          taskPinned={taskPinned}
                                          setTaskPinned={setTaskPinned}
                                          contacts={contacts}
                                          setTaskId={setTaskId}
                                          setCallUserList={setCallUserList}
                                          setSelectedTaskIdforIconToggle={
                                            setSelectedTaskIdforIconToggle
                                          }
                                          selectedTaskIdforIconToggle={
                                            selectedTaskIdforIconToggle
                                          }
                                          showDefaultIcons={showDefaultIcons}
                                          setShowDefaultIcons={
                                            setShowDefaultIcons
                                          }
                                          handleToggleIcons={handleToggleIcons}
                                           />
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <CommanPlaceholder
                                imgType="request"
                                placeholderText={_l("l_no_request_task")}
                              />
                            </>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                    {isSkeltonShow ? (
                      <></>
                    ) : (requestTasks.length >= (requestPage - 1) * 10 + 3 &&
                        requestTasks.length != (requestPage - 1) * 10 + 3) ||
                      loder.requestLoder ? (
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setSelectedTaskIdforIconToggle(0);
                          setLoder({ ...loder, ["requestLoder"]: true });
                          scroll(0);
                          setMoreTaskPage({...moreTaskPage, constRequestPage: true});
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {requestPage > 1
                              ? requestTasks.length -
                                ((requestPage - 1) * 10 + 3)
                              : requestTasks.length - 3}
                            )
                          </span>
                          <CaretDoubleDownIcon className="HW12 mx-2" />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.requestLoder ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    ) : (
                      <></>
                    )}
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="with-pb-15">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                      <div className="modal-overlay3"></div>
                      <div className="checkbox_accordian">
                        {todoTasks && todoTasks.length ? (
                          <span className="checkbox_absolute">
                            <Form.Check
                              type="checkbox"
                              checked={bulkActionAllTask.todo ? true : false}
                              onChange={(e) => {
                                handleAllTaskBulkAction(
                                  e.target.checked,
                                  "todoTasks"
                                );
                                setBulkActionAllTask({
                                  ...bulkActionAllTask,
                                  ["todo"]: e.target.checked,
                                });
                              }}
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                        <Accordion.Header
                          className={`active to-do commanTaskCount w-auto ${
                            todoTasks.length == 0 ? "radius3px" : ""
                          }`}
                          id="todo"
                        >
                          <div className="accordion-header-wrapper w-auto ">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="left-task-header abc">
                                <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                  <span className="c-dots  c-7 in-progress-white rounded-circle me-3"></span>
                                  <span className="task-title me-2">
                                    {_l("l_todo_kpi_name")}
                                  </span>
                                  <span className="" id="todo_count_display">
                                    {todoTasks && todoTasks.length
                                      ? "[" + todoTasks.length + "]"
                                      : "[0]"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>
                      </div>
                      {showTaskModal ? (
                        <div className="threedotbtn"></div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Accordion.Body className="taskCountToggle" id="todo_count">
                      {isSkeltonShow ? (
                        ["0", "1", "2"].map((s) => {
                          return <TaskListSkeleton />;
                        })
                      ) : (
                        <>
                          {todoTasks.length ? (
                            <>
                              {todoTasks
                                .sort((a, b) => {
                                  return (
                                    b.unread_comment_count -
                                    a.unread_comment_count
                                  );
                                })
                                .slice(
                                  0,
                                  todoPage > 1
                                    ? (todoPage - 1) * 10 + 3
                                    : todoPage * 3
                                )
                                .map((task, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div
                                        key={index}
                                        className=""
                                      >
                                        <TaskCard
                                          key={task.id}
                                          task={task}
                                          active={selectedTaskId == task.id}
                                          project_id={projectId}
                                          handler={handleSelectedTask}
                                          pageName="LiteVersion"
                                          taskCheckUncheck={taskCheckUncheck}
                                          checkedTask={checkedTask}
                                          taskPinned={taskPinned}
                                          setTaskPinned={setTaskPinned}
                                          contacts={contacts}
                                          setTaskId={setTaskId}
                                          setCallUserList={setCallUserList}
                                          setSelectedTaskIdforIconToggle={
                                            setSelectedTaskIdforIconToggle
                                          }
                                          selectedTaskIdforIconToggle={
                                            selectedTaskIdforIconToggle
                                          }
                                          showDefaultIcons={showDefaultIcons}
                                          setShowDefaultIcons={
                                            setShowDefaultIcons
                                          }
                                          handleToggleIcons={handleToggleIcons}
                                        />
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <CommanPlaceholder
                                imgType="todo-task"
                                placeholderText={_l("l_no_todo_task")}
                              />
                            </>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                    {isSkeltonShow ? (
                      <></>
                    ) : (todoTasks.length >= (todoPage - 1) * 10 + 3 &&
                        todoTasks.length != (todoPage - 1) * 10 + 3) ||
                      loder.todoLoder ? (
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setSelectedTaskIdforIconToggle(0);
                          setLoder({ ...loder, ["todoLoder"]: true });
                          scroll(1);
                          setMoreTaskPage({...moreTaskPage, constTodoPage: true});
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {todoPage > 1
                              ? todoTasks.length - ((todoPage - 1) * 10 + 3)
                              : todoTasks.length - 3}
                            )
                          </span>
                          <CaretDoubleDownIcon className="HW12 mx-2" />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.todoLoder ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    ) : (
                      <></>
                    )}
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="with-pb-15">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                      <div className="modal-overlay3"></div>
                      <div className="checkbox_accordian">
                        {inProgressTasks && inProgressTasks.length ? (
                          <span className="checkbox_absolute">
                            <Form.Check
                              type="checkbox"
                              checked={
                                bulkActionAllTask.inProgress ? true : false
                              }
                              onChange={(e) => {
                                handleAllTaskBulkAction(
                                  e.target.checked,
                                  "inProgressTasks"
                                );
                                setBulkActionAllTask({
                                  ...bulkActionAllTask,
                                  ["inProgress"]: e.target.checked,
                                });
                              }}
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                        <Accordion.Header
                          className={`active  in-progress commanTaskCount w-auto ${
                            inProgressTasks.length == 0 ? "radius3px" : ""
                          }`}
                          id="inprogress"
                        >
                          <div className="accordion-header-wrapper w-auto">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="left-task-header">
                                <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                  <span className="c-dots c-7 rounded-circle in-progress-blue me-3"></span>
                                  <span className="task-title me-2">
                                    {_l("l_ongoing_kpi")}
                                  </span>
                                  <span
                                    className=""
                                    id="inprogress_count_display"
                                  >
                                    {inProgressTasks && inProgressTasks.length
                                      ? "[" + inProgressTasks.length + "]"
                                      : "[0]"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>
                      </div>
                    </div>
                    <Accordion.Body className="" id="inprogress_count">
                      {isSkeltonShow ? (
                        ["0", "1", "2"].map((s) => {
                          return <TaskListSkeleton />;
                        })
                      ) : (
                        <>
                          {inProgressTasks.length ? (
                            <>
                              {inProgressTasks
                                .sort((a, b) => {
                                  return (
                                    b.unread_comment_count -
                                    a.unread_comment_count
                                  );
                                })
                                .slice(
                                  0,
                                  inProgressPage > 1
                                    ? (inProgressPage - 1) * 10 + 3
                                    : inProgressPage * 3
                                )
                                .map((task, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className=""
                                    >
                                      <TaskCard
                                        key={task.id}
                                        task={task}
                                        active={selectedTaskId === task.id}
                                        project_id={projectId}
                                        handler={handleSelectedTask}
                                        pageName="LiteVersion"
                                        taskCheckUncheck={taskCheckUncheck}
                                        checkedTask={checkedTask}
                                        taskPinned={taskPinned}
                                        setTaskPinned={setTaskPinned}
                                        contacts={contacts}
                                        setTaskId={setTaskId}
                                        setCallUserList={setCallUserList}
                                        setSelectedTaskIdforIconToggle={
                                          setSelectedTaskIdforIconToggle
                                        }
                                        selectedTaskIdforIconToggle={
                                          selectedTaskIdforIconToggle
                                        }
                                        showDefaultIcons={showDefaultIcons}
                                        setShowDefaultIcons={
                                          setShowDefaultIcons
                                        }
                                        handleToggleIcons={handleToggleIcons}
                                      />
                                    </div>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <CommanPlaceholder
                                imgType="inprogress-task"
                                placeholderText={_l("l_no_inprogress_task")}
                              />
                            </>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                    {isSkeltonShow ? (
                      <></>
                    ) : (inProgressTasks.length >=
                        (inProgressPage - 1) * 10 + 3 &&
                        inProgressTasks.length !=
                          (inProgressPage - 1) * 10 + 3) ||
                      loder.inProgressLoder ? (
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setSelectedTaskIdforIconToggle(0);
                          setLoder({ ...loder, ["inProgressLoder"]: true });
                          scroll(4);
                          setMoreTaskPage({...moreTaskPage, constInProgressPage: true});
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className="title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {inProgressPage > 1
                              ? inProgressTasks.length -
                                ((inProgressPage - 1) * 10 + 3)
                              : inProgressTasks.length - 3}
                            )
                          </span>
                          <CaretDoubleDownIcon className="HW12 mx-2" />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.todoLoder ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    ) : (
                      <></>
                    )}
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                      <div className="modal-overlay3"></div>
                      <div className="checkbox_accordian">
                        {completedTasks && completedTasks.length ? (
                          <span className="checkbox_absolute">
                            <Form.Check
                              type="checkbox"
                              checked={bulkActionAllTask.done ? true : false}
                              onChange={(e) => {
                                handleAllTaskBulkAction(
                                  e.target.checked,
                                  "completedTasks"
                                );
                                setBulkActionAllTask({
                                  ...bulkActionAllTask,
                                  ["done"]: e.target.checked,
                                });
                              }}
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                        <Accordion.Header
                          className={`active  done w-auto  ${
                            completedTasks.length == 0 ? "radius3px" : ""
                          }`}
                        >
                          <div className="accordion-header-wrapper w-auto">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="left-task-header">
                                <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                  <span className="c-dots  c-7 done rounded-circle me-3"></span>
                                  <span className="task-title me-2">
                                    {_l("l_done")}
                                  </span>
                                  <span className="" id="done_count_display">
                                    {completedTasks && completedTasks.length
                                      ? "[" + completedTasks.length + "]"
                                      : "[0]"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>
                      </div>
                    </div>
                    <Accordion.Body className="" id="done_count">
                      {isSkeltonShow ? (
                        ["0", "1", "2"].map((s) => {
                          return <TaskListSkeleton />;
                        })
                      ) : (
                        <>
                          {completedTasks.length ? (
                            <>
                              {completedTasks
                                .sort((a, b) => {
                                  return (
                                    b.unread_comment_count -
                                    a.unread_comment_count
                                  );
                                })
                                .slice(
                                  0,
                                  completedPage > 1
                                    ? (completedPage - 1) * 10 + 3
                                    : completedPage * 3
                                )
                                .map((task, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="card-list-box"
                                    >
                                      <TaskCard
                                        key={task.id}
                                        task={task}
                                        active={selectedTaskId === task.id}
                                        project_id={projectId}
                                        handler={handleSelectedTask}
                                        pageName="LiteVersion"
                                        taskCheckUncheck={taskCheckUncheck}
                                        checkedTask={checkedTask}
                                        taskPinned={taskPinned}
                                        setTaskPinned={setTaskPinned}
                                        contacts={contacts}
                                        setTaskId={setTaskId}
                                        setCallUserList={setCallUserList}
                                        setSelectedTaskIdforIconToggle={
                                          setSelectedTaskIdforIconToggle
                                        }
                                        selectedTaskIdforIconToggle={
                                          selectedTaskIdforIconToggle
                                        }
                                        showDefaultIcons={showDefaultIcons}
                                        setShowDefaultIcons={
                                          setShowDefaultIcons
                                        }
                                        handleToggleIcons={handleToggleIcons}
                                      />
                                    </div>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <CommanPlaceholder
                                imgType="NoDoneTask-task"
                                placeholderText={_l("l_no_done_task")}
                              />
                            </>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                    {isSkeltonShow ? (
                      <></>
                    ) : (completedTasks.length >=
                        (completedPage - 1) * 10 + 3 &&
                        completedTasks.length !=
                          (completedPage - 1) * 10 + 3) ||
                      loder.doneLoder ? (
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setSelectedTaskIdforIconToggle(0);
                          setLoder({ ...loder, ["doneLoder"]: true });
                          scroll(5);
                          setMoreTaskPage({...moreTaskPage, constCompletedPage: true});
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {completedPage > 1
                              ? completedTasks.length -
                                ((completedPage - 1) * 10 + 3)
                              : completedTasks.length - 3}
                            )
                          </span>
                          <CaretDoubleDownIcon className="HW12 mx-2" />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.todoLoder ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    ) : (
                      <></>
                    )}
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          {showTaskModal ? (
            <CommanOffCanvas
              show={showTaskModal}
              handleClose={() => {
                setShowTaskModal(false);
                handleClose();
                setCommonModalData();
                setSelectedTaskId(0);
                setTask_type("");
              }}
              pinHandler={(id, type, flag) => {
              }}
              data={commonModalData}
              docType={task_type}
              setSelectedId={setSelectedTaskId}
              selectedTaskId={selectedTaskId}
              IsKanbanView={IsKanbanView}
              meetingUrl={meetingUrl}
              handleParentComponentAction={(type, taskData) => {
                if (task_type == "task") {
                  if (type == "attachmentCountUpdate") {
                    updateAttchmentChecklistCount(
                      taskData.task_id,
                      taskData.flag
                    );
                    return;
                  } else if (type == "handleSelectedTask") {
                    handleSelectedTask(taskData.task_id, taskData.flag);
                    return;
                  } else if (type == "convertedTaskAction") {
                    handleSelectedTask(
                      taskData.convertTaskId,
                      taskData.isShow,
                      taskData.isRequestTask,
                      taskData.task_id
                    );
                    return;
                  }
                } else if (task_type == "request-task") {
                  if (type == "convertedTaskAction") {
                    handleSelectedTask(
                      taskData.convertTaskId,
                      taskData.isShow,
                      taskData.isRequestTask,
                      taskData.task_id
                    );
                    return;
                  }
                }
              }}
              pageName={"Liteversion"}
              contacts={contacts}
              callUserList={callUserList}
            />
          ) : (
            <></>
          )}
          {/*  ----------------------------------------------------------------------- liteversionview end  -----------------------------------------------------------------------  */}
        </>
      )}
    </>
  );
};
export default Tasks;
