import React, { useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { _l } from "../../hooks/utilities";
import RiskListModal from "../Modals/RiskListModal";
import ProhibitIcon from "../../assets/icons/ProhibitIcon";
import UnControlMainFiltersDropDown from "../TaskModalComponents/LeadsMainFiltersDropDown";
import AddEditContactProvider from "../Modals/AddEditContactProvider";
import DownloadSimpleIcon from "../../assets/icons/DownloadSimpleIcon";
import PencilIcon from "../../assets/icons/PencilIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import UserIcon from "../../assets/icons/UserIcon";
const ProviderListActionModal = ({
    handleDelete =() => {},
    handleDownload =() => {},
    actionList = [],
    data,
    handleCancel = () => {},
    handleEdite = () => {},
  }) => {
  const [addRiskEdit, setAddRiskEdit] = useState(false);
  const [addContactData, setAddContactData] = useState(false);
  return (
    <>
      <DropdownMenu 
        className={`w-200 position-center-element ${actionList.includes("Assignee") ? "" : ''}`}
        container="body"
        direction="left"
      >
      <ul >
      { actionList.includes("delete") && 
        <li className="hr_sepretr   ">
        <a href="#/" className="dropdown-item " onClick={() => { handleDelete() }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon comman_action_icon">
              <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <TrashIcon className="HW20" />
              </div>
            </div>
            <div className="ps-2 d-flex align-items-center justify-content-between w100minus35">{_l("l_delete")}</div>
          </div>
        </a>
        </li>
        }
        { actionList.includes("download") && 
        <li className="hr_sepretr   ">
        <a href="#/" className="dropdown-item" onClick={() => {handleDownload() }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <DownloadSimpleIcon className="HW20" />
              </div>
            </div>
            <div className="ps-2 d-flex align-items-center justify-content-between w100minus35">{_l("l_download")}</div>
          </div>
        </a>
        </li>
        }
        { actionList.includes("l_cancel_immediately") && 
        <li className="hr_sepretr   ">
        <a href="#/" className="dropdown-item " onClick={() => {handleCancel("l_cancel_immediately", data) }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <ProhibitIcon className="HW20" />
              </div>
            </div>
            <div className="ps-2 d-flex align-items-center justify-content-between w100minus35">{_l("l_cancel_immediately")}</div>
          </div>
        </a>
        </li>
        }
        { actionList.includes("l_cancel__at_period_end") && 
        <li className="hr_sepretr   ">
        <a href="#/" className="dropdown-item " onClick={() => {handleCancel("l_cancel__at_period_end", data) }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <DownloadSimpleIcon className="HW20" />
              </div>
            </div>
            <div className="ps-2 d-flex align-items-center justify-content-between w100minus35">{_l("l_cancel__at_period_end")}</div>
          </div>
        </a>
        </li>
        }
        { actionList.includes("l_cancel") && 
        <li className="hr_sepretr   ">
        <a href="#/" className="dropdown-item " onClick={() => {handleCancel("l_cancel", data)}}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <ProhibitIcon className="HW20" />
              </div>
            </div>
            <div className="ps-2 d-flex align-items-center justify-content-between w100minus35">{_l("l_cancel")}</div>
          </div>
        </a>
        </li>
        }
        {
          actionList.includes("edit")
          ? 
        <li className="hr_sepretr   ">

          <a href="#/" className="dropdown-item " onClick={() => setAddRiskEdit(true)}>
            <div className="d-flex align-items-center">
              <div className="c-list-icon comman_action_icon">
              <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                <PencilIcon className="HW20" />
                </div>
              </div>
              <div className="ps-2 d-flex align-items-center justify-content-between w100minus35">{_l("l_edit")}</div>
            </div>
            </a>
        </li>

          : <></>
        }
        {
          actionList.includes("Assignee")
          ? <li href="#/" className="dropdown-item ">
             <UncontrolledDropdown className=""
                ><DropdownToggle
                    tag={'a'}
                    className="d-flex on-hover-active-toggle-img"
                    type="button"
                    data-bs-toggle="dropdown" 
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                > 
                <div className="d-flex align-items-center">
                  <div className="c-list-icon">
                    <UserIcon className="HW20" />
                  </div>
                  <div className="ps-2 text_wrap">{_l("l_assignee")}</div>
                </div>
                </DropdownToggle>
                <DropdownItem toggle={false} className="p-0"> 
                    <UnControlMainFiltersDropDown
                        type = {"provider_list"}
                        data={data}
                        selectedleadfilers = {data && data.provider_assignee && data.provider_assignee.map((item) => item.assignee_id)}
                    />
                </DropdownItem>
            </UncontrolledDropdown>
            </li>
          : <></>
        }
        {
          actionList.includes("editData") ?
        <li className="hr_sepretr   ">
          <a href="#/" className="dropdown-item" onClick={() => handleEdite()}>
            <div className="d-flex align-items-center">
              <div className="c-list-icon comman_action_icon">
              <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                <PencilIcon className="HW20 fill-white" />
                </div>
              </div>
              <div className="ps-2 d-flex align-items-center justify-content-between w100minus35">{_l("l_edit")}</div>
            </div>
            </a>
        </li>
          : <></>
        }
        {
          actionList.includes("editContact")
          ? 
        <li className="hr_sepretr   ">

          <a href="#/" className="dropdown-item" onClick={() => setAddContactData(true)}>
            <div className="d-flex align-items-center">
              <div className="c-list-icon comman_action_icon">
              <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                <PencilIcon className="HW20 fill-white" />
                </div>
              </div>
              <div className="ps-2 d-flex align-items-center justify-content-between w100minus35">{_l("l_edit")}</div>
            </div>
            </a>
        </li>
          : <></>
        }
        </ul>
      </DropdownMenu>
      {
        addRiskEdit ?
          <RiskListModal
          show={addRiskEdit}
          handleClose={()=>{
            setAddRiskEdit(false);
          }}
          editData={data && data.risk_detail ? JSON.parse(data.risk_detail)  : data}
          isFromEdit={true}
          hideActionMenu={true}
          actionType="edit"
          TemplateTitle = {data.title}
          id={data.id}
          />
        : <></>
      }
      {addContactData ? 
        <AddEditContactProvider show = {addContactData} handleClose={() => setAddContactData(false)} data = {data} isEdit={true}/>
        : <></>
      }
    </>
  );
};
export default ProviderListActionModal;
