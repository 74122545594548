import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import {
  _l,
  countryCodedigitLength,
  getFileType,
  getPreviewFromURL,
  showError,
} from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import CustomerServices from "../../services/customer-services";
import { showConfirmation, showMessage } from "../../actions/messages";
import OnboardingFooter from "./OnboardingFooter";
import OnboardingHeader from "./OnboardingHeader";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import AuthServices from "../../services/auth-services";
import { Row, Col } from "react-bootstrap";
import SetupAvilability from "../Modals/SetupAvilability";
import DocumentPreview from "../Documents/DocumentPreview";
import DocumentsServices from "../../services/documents-services";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import vapiService from "../../services/vapi-service";
import { COUNTRY_JSON } from "../../constants/constants";
import 'react-phone-input-2/lib/style.css';
import CloudArrowUpIcon from "../../assets/icons/CloudArrowUpIcon";
import UserCircleIcon from "../../assets/icons/UserCircleIcon";
const PASSWORD_INPUT_PAGES = ["space-invite", "MyProfile"];
const getUploadKeyName = {
  "l_id_card_upload": "id_card",
  "l_company_id_upload": "company_id",
  "l_payment_upload": "payment",
  "l_insurance_upload": "insurance",
  "l_skill_certificate_upload": "skill",
}
const UserProfile = ({
  profileData,
  loader,
  setOnboardingStep,
  pageName,
  isMyprofileStep = false,
  userId,
  selectedSection,
  employeeRoleList,
  isReadOnly,
  selectedTopicName
}) => {
  const [userProfileData, setUserProfileData] = useState({
    ...profileData,
    role: [{ value: 0 }],
  });
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState("");
  const [nextButtonText, setNextButtonText] = useState(isReadOnly ? "" : _l("l_update"));
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [imagePreviews, setImagePreviews] = useState({
    l_id_card_upload: {key : "id_card",  previewArray: [{ url: "" }] },
    l_company_id_upload: {key : "company_id", previewArray: [{ url: "" }] },
    l_payment_upload: {key : "payment", previewArray: [{ url: "" }] },
    l_insurance_upload: {key : "insurance", previewArray: [{ url: "" }] },
    l_skill_certificate_upload: {key : "skill", previewArray: [{ url: "" }] },
  });
  const [openGallery, setOpenGallery] = useState(false);
  const [lightboxInputArray, setLightboxInputArray] = useState([]);
  const [spaceSelectionOptions, setSpaceSelectionOptions] = useState([]);
  const [numberFormate, setNumberFormate] = useState('');
  const [numberlength, setNumberLength]= useState({max : 14, min : 9});
  const backButtonText = pageName === "space-invite" ? _l("l_later") : _l("");
  const spaces = useSelector((state) => state.customer.spaces);
  const { defaultTopics } = useSelector((state) => state.customer);
  const availabilityRef = useRef();
  const userType = localStorage.getItem("user_type");
  const loginUserRole = localStorage.getItem("contact_role");
  const admin = localStorage.getItem("admin")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const genderOption = [
    { value: "m", label: "Male" },
    { value: "f", label: "Female" },
  ];
  const [languageOptions, setLanguageOptions] = useState(
    [
     {
       label : "English",
       value : 12
     },
       {
       label : "French",
       value : 21
     }
   ]);
  const [mobileOptions, setMobileOptions] = useState([]);
  const [mobileNumberArray, setMobileNumberArray] = useState([]);
  const [countryCodes, setCountryCodes] = useState(COUNTRY_JSON);
  const [countryCodesWithFlag, setCountryCodesWithFlag] = useState(COUNTRY_JSON);
  const [selectedCountry, setselectedCountry] = useState({
    label: "🇺🇸 United States +1",
    value: "+1",
  });
  const navigateToPrevPage = () => {
    navigate(userId == null ? -1 : "/");
  };
  useEffect(() => {
    if (profileData) {
      setUserProfileImage(profileData.profile_image_url);
      if (profileData.phonenumber) {
        if (profileData && profileData.role && profileData.role.value == "4") {
          setUserProfileData({
            ...profileData,
            phonenumber: profileData.phonenumber
          });
        } else {
          setUserProfileData({
            ...profileData,
            phonenumber: profileData.phonenumber
              .replace(/[- .a-zA-Z]/g, "")
              .substring(1),
          });
        }
        let maxLength = countryCodedigitLength(profileData.countryCode)
        setNumberLength(maxLength)
      }else if(profileData && profileData.role && profileData.role.value == 4){
      setUserProfileData(profileData)
      }
      setImagePreviews(profileData.urls);
    }
    if (pageName === "space-invite") {
      setNextButtonText(_l("l_accept"));
    }
  }, [profileData]);
  useEffect(() => {
    CustomerServices.getAiPhoneNoList().then((res) => {
      if (res.status == 1) {
        const arr = res.data.map((d) => ({
           value : d.number, label: d.number 
        }))
      }
    }).catch((e) => console.error(e));
  }, [])
  const inviteCustomerToSpace = async () => {
    try {
      setLoadingNextStep(true);
      const response = await AuthServices.acceptProviderInvite({
        ...userProfileData,
        contact_name: `${userProfileData.firstname} ${userProfileData.lastname}`,
        phonenumber: `${userProfileData.countryCode} ${userProfileData.phonenumber}`,
      });
      if (response.status && response.data.auto_login_key) {
        sessionStorage.setItem("skip_onboarding", 1);
        navigate(`/autologin/${response.data.auto_login_key}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNextStep(false);
    }
  };
  const projectId = localStorage.getItem("selectedOffice");
  const updateProfileData = async () => {
    setLoadingNextStep(true);
    if (!fieldValidations()) {
      setLoadingNextStep(false);
      return false;
    }
    let role_Value = userProfileData.role && userProfileData.role.value ? userProfileData.role.value : '0';
    let assistant_role = userProfileData.assistant_role && userProfileData.assistant_role.value ? userProfileData.assistant_role.value : '0';
    try {
      if(selectedSection === "availablity_setup")
      {
        await availabilityRef.current.handleSave();
      }
      else {
        if (pageName === "space-invite") {
          inviteCustomerToSpace();
          return true;
        }
        let phoneNumbers;
        if (userProfileData.role.value == "4") {
          phoneNumbers = userProfileData.phonenumber ?  userProfileData.phonenumber : userProfileData.phoneNumber
        } else {
          phoneNumbers = userProfileData.countryCode + " " + (userProfileData.phonenumber).slice((userProfileData.countryCode).length - 1);
        }
        const response = await CustomerServices.editCompanyEmployees(
          userProfileData.firstname,
          userProfileData.lastname,
          userProfileData.email,
          phoneNumbers,
          role_Value,
          0,
          0,
          userProfileData.position,
          "",
          "",
          0,
          0,
          projectId,
          userProfileData.contact_id,
          userProfileData.staff_id,
          userProfileData.password,
          userType === "staff" ? "" :
          role_Value == "1"
            ? spaces
            : userProfileData.selectedSpaces,
            undefined,
            undefined,
            undefined,
            undefined,
            userProfileData.admin,
            undefined,
            undefined,
            undefined,
            userProfileData.mobile_key,
            undefined,
            userProfileData.gender,
            assistant_role
       );
        await saveUserDocuments();
        if (response.status == 1) {
          if (localStorage.getItem("membership_package_id") == 1) {
            navigate("/premiumsubscription");
          }
          setLoadingNextStep(false);
          setOnboardingStep("space-details");
          dispatch(showMessage("sucess", _l("l_success"), _l(response.message)));
          localStorage.setItem("admin", userProfileData.admin == "1" ? "true" : "0");
        } else {
          showError(_l(response.message));
        }
      }
    } catch (e) {
      console.error(e);
    }
    finally {
      setLoadingNextStep(false);
    }
  };
  const fieldValidations = () => {
    const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    let flag = true;
    if (!userProfileData) {
      return false;
    }
    if (userProfileData.email == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_email"))
      );
      flag = false;
    } else if (!pattern.test(userProfileData.email)) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email"))
      );
      flag = false;
    } else if (
      pageName == "space-invite" &&
      (!userProfileData.company || !userProfileData.company.length)
    ) {
      flag = false;
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_company_name")
        )
      );
    } else if (
      !userProfileData.firstname ||
      !userProfileData.firstname.length
    ) {
      flag = false;
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_first_name"))
      );
    } else if ((!userProfileData.lastname || !userProfileData.lastname.length) && userProfileData.contact_role != 4 ) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_last_name"))
      );
      flag = false;
    } else if (
      userProfileData.role.value != "4" && 
      (!userProfileData.phonenumber ||
      !userProfileData.phonenumber.length || 
      (!userProfileData.role.value == "4" && numberlength.max != userProfileData.phonenumber.length && numberlength.min != userProfileData.phonenumber.length))
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_mobile_number")
        )
      );
      flag = false;
    }else if (
      (!userProfileData.role ||
      userProfileData.role.value == "" ||
      !employeeRoleList.find((w) => w.value == userProfileData.role.value)) && userProfileData.contact_role != 4 
    ) {
      // console.log("userProfileData.role",userProfileData.role, userProfileData.contact_role)
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_select_role")
        )
      );
      flag = false;
    } else if (
      pageName == "space-invite" &&
      (!userProfileData.password || !userProfileData.password.trim().length)
    ) {
      showError("l_please_enter_password");
      flag = false;
    } else if (
      userProfileData.password != "" &&
      userProfileData.confirmPassword == "" &&
      PASSWORD_INPUT_PAGES.includes(pageName)
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_confirm_password")
        )
      );
      flag = false;
    } else if (
      userProfileData.confirmPassword != userProfileData.password &&
      PASSWORD_INPUT_PAGES.includes(pageName)
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_password_and_confirm_password_doesnot_match")
        )
      );
      flag = false;
    }
    return flag;
  };
  const updateProfileInputFields = (e, aiEmail = '') => {
    let inputValue = e.target.value;
    switch (e.target.name) {
      case "company":
      case "firstname":
      case "lastname":
      case "position":
        inputValue = inputValue.replace(/[^a-zA-Z. ]/g, "").substring(0, 30);
        break;
      case "email":
        inputValue = aiEmail == '' ? inputValue : aiEmail;
        break;
      case "phonenumber":
        inputValue = inputValue.replace(/[- .a-zA-Z]/g, "").substring(0, 11);
        break;
      default:
        break;
    }
    setUserProfileData({ ...userProfileData, [e.target.name]: inputValue });
  };
  const uploadUserProfileImage = async (file) => {
    if (file) {
      if (pageName === "") {
        setUserProfileData({ ...userProfileData, profile_image: file[0] });
        return true;
      }
      let thumbnails = [];
      Array.from(file).map((img) => {
        thumbnails.push({ name: img.name, url: URL.createObjectURL(img) });
      });
      try {
        const response = await CustomerServices.userUpdateEditProfile(file[0], userProfileData.staff_id);
        if (response.status == 1) {
          setUserProfileImage(thumbnails[0].url);
          if(localStorage.getItem("staff_id") == userProfileData.staff_id ){
            localStorage.setItem("contact_image", response.data.file_url);
            $("#userHeaderProfileImage").css(
              "background-image",
              `url('${response.data.file_url}')`
            );
          }
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), response.message));
        }
      } catch (e) {
        console.error(e);
      }
    }
  };
  const removeProfileImage = () => {
    CustomerServices.userProfileRemove(
      userId && userId != null ? userProfileData.staff_id : 0
    ).then((res) => {
      if (res.status == 1) {
        setUserProfileImage(res.data.file_url);
        if (userId && userId == null) {
          localStorage.setItem("contact_image", res.data.file_url);
          $("#userHeaderProfileImage").css(
            "background-image",
            `url('${res.data.file_url}')`
          );
        }
        dispatch(showMessage("sucess", _l("l_success"), res.message));
      } else {
        showError(res.message);
      }
    });
  };
  const getHeaderText = () => {
    let text = "";
    if (pageName === "space-invite") {
      text = `${"l_accept_invitation"}`;
    } else if (profileData) {
      text = isReadOnly ? "" : selectedTopicName;
    } else {
      text = "l_setup_profile";
    }
    return text;
  };
  const saveUserDocuments = async () => {
    try {
     await DocumentsServices.uploadEmployeeDocuments(imagePreviews, userProfileData.staff_id);
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    }
  };
const isCurrentUserAI = () => {
  let flag = false;
  if(defaultTopics && defaultTopics.length > 0){
    if(userType == "contact"){
      const peopleList = defaultTopics.find((topic)=> topic.main_key == "my_contact")
      if (peopleList && peopleList.categories && peopleList.categories.find((person)=> person.filter_key == userId && person.contact_role == 4)) {
        flag = true;
      }else if(userProfileData && userProfileData.role && userProfileData.role.value == 4){
        flag = true;
      }
    }
  }
  return flag;
};
const countryCodesPrefix = {
  '+1': 'US', // United States
  '+44': 'GB', // United Kingdom
  '+33': 'FR', // France
  '+49': 'DE', // Germany
  '+91': 'IN', // India
};
function getCountryCode(mobileNumber) {
  // Normalize the input by removing spaces and non-numeric characters except '+'
  const cleanedNumber = mobileNumber.replace(/\D/g, '');
  // Check if the number starts with '+'
  if (cleanedNumber.startsWith('0')) {
      return 'Invalid number format';
  }
  // Extract the country code by checking against known prefixes
  for (const [code, country] of Object.entries(countryCodesPrefix)) {
      if (cleanedNumber.startsWith(code.substring(1))) {
          return code;
      }
  }
  return 'Country code not found';
}
  useEffect(() => {
    if (spaces.length) {
      setSpaceSelectionOptions(
        spaces.map((space) => {
          return { value: space.project_id, label: space.office_title };
        })
      );
    }
  }, [JSON.stringify(spaces)]);
  useEffect(() => {
    if (numberFormate.dialCode) {
        setUserProfileData({...userProfileData, countryCode : `+${numberFormate.dialCode}`})
        const dialcodeL = Number(numberFormate.dialCode.length);
        let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
        if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
            if (["49", "44"].includes(numberFormate.dialCode)) {
                setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
            }else{
                setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
            }
        }else{
            setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
        }
    }
  }, [numberFormate])
  useEffect(() => {
    vapiService.getAssistantVoices().then((res) => {
    });
    vapiService.getAssistantMobileNumbers().then((res) => {
      if (res && res.status && res.data) {
        let arr = res.data.filter((v) => !v.assistantId).map((v) => {
          return {
            label: v.number,
            value: v.number,
          };
        });
        setMobileNumberArray(res.data.map((v)=>{
          return {
            label: v.number,
            value: v.number,
          };
        }));
        setMobileOptions(arr);
      }
    });
  }, []);   
  return (
    <React.Fragment>
      <div
        className={`${selectedSection !== "" ? "d-flex flex-column h-100" : "onboarding-wrapper-main d-flex m-auto h-100 flex-column with-modified-width onboarding-with-bg"}  ${
          [].includes() ? "onboarding-with-bg" : ""
        }`}
      >
        <OnboardingHeader
          title={getHeaderText()}
          description={
            selectedSection !== "" ?
             "" : 
            pageName === "space-invite"
              ? `${"l_invitation_page_description"} ${
                  profileData.provider_name
                }. ${_l("l_accept_invite_description")}`
              : _l("l_setup_profile_description")
          }
          backButtonText={backButtonText}
          hideBackButton={!backButtonText}
          backBtnHandler={navigateToPrevPage}
          nextBtnHandler={updateProfileData}
          nextButtonText={nextButtonText}
          loadingNextStep={loadingNextStep}
          alignHeaderContentLeft={selectedSection !== ""}
          isAssistant={isCurrentUserAI()}
        />
          <div className={`${selectedSection !== "" ? "overflow-auto flex-grow-1 pt-15 custom-modal-parent-div-flex-property" : "onboarding-content-scroll-wrapper comman-main-box pb-0"} ${isReadOnly && localStorage.getItem("is_registered") != 1 ? "for-disabled" : ""}`}>
            {selectedSection === "basic_details" ? (
              <>
              <div className="d-flex bgOfficelisting p-20 pb-0 lightthemebgwhite ">
                <div className="min-width350 mx-auto pe-3">
                  <div
                    className="form-control  file-upload with-bg position-relative comman-round-box d-flex flex-column p-0 file-upload-main"
                  >
                    <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                      <input
                        className="z-index-3 hidden-input m-auto"
                        type="file"
                        accept="image/*"
                        id="user_profile_image"
                        onInput={(e) => {
                          uploadUserProfileImage(e.target.files);
                        }}
                      />
                      <div
                        className="m-auto text-center comman-bg-img h-100 w-100 bg-style-cover d-flex flex-column rounded-circle"
                        style={{
                          backgroundImage: `url(${userProfileImage})`,
                        }}
                      >
                        <div className="m-auto rounded-circle">
                          {userProfileImage && userProfileImage !== ""  ? (
                            <></>
                          ) : (
                            <>
                              <div>
                                <UserCircleIcon
                                  className="HW60 m-0 mb-4 opacity-60"
                                />
                              </div>
                              <div className="c-font color-white f-14 fw-bold title-fonts">
                                {_l("l_profile")} {_l("l_image")}
                              </div>
                              <div className="c-font color-white-60 f-12 pt-2">
                                PNG, JPG ( MAX. 800 x 800 )
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bg-base-header-color bottom-0 c end-0 h-100 position-absolute start-0 top-0 w-100 d-none">
                      <div
                        className="bg-style-cover comman-bg-img h-100 w-100"
                        style={{
                          backgroundImage: `url('${userProfileData.profile_image_url}')`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div>
                    {
                      isReadOnly
                      ?
                      <div className="text-center">
                        <div>
                          <span>
                            {`${_l("l_member_since")}: ${moment(userProfileData.datecreated).format("YYYY-MM-DD")}`}
                          </span>
                        </div>
                        <div>
                          <span>
                            {`${_l("l_task_score")}: ${userProfileData.task_score}`}
                          </span>
                        </div>
                        <div>
                          <span>
                            {`${_l("l_tasks")}: ${userProfileData.total_tasks}`}
                          </span>
                        </div>
                      </div>
                      :
                      <Button
                        onClick={() =>
                          document.getElementById("user_profile_image").click()
                        }
                        variant="white-05 flex-grow-1 w-100 border-0 p-10 mb-15px"
                      >
                        {_l("l_profile_image_upload")}
                      </Button>
                    }
                    {userProfileData.is_profile_image_exist == 1 ? (
                      <Button
                        onClick={() =>
                          dispatch(
                            showConfirmation(
                              _l("l_are_you_sure"),
                              _l("l_you_want_to_delete_profile_image"),
                              _l("l_delete"),
                              _l("l_cancel"),
                              undefined,
                              () => {
                                removeProfileImage();
                              }
                            )
                          )
                        }
                        variant="flex-grow-1 w-100"
                        className="btn-secondary p-10"
                      >
                        {_l("l_remove_profile_image")}
                      </Button>
                    ) : (
                      <></>
                    )}
                    {userType == "operator" && selectedSection !== "basic_details"  ? (
                      <Button
                        onClick={() => {
                          setEquipmentModal(true);
                        }}
                        variant="white-05"
                        className="mt-3 text-warning w-100"
                      >
                        {_l("l_setup_avilability")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="flex-grow-1 ps-10px">
                  <div className="row">
                    {pageName === "space-invite" ? (
                      <Form.Group
                        className={`col-xl-6 c-input-box position-relative pb-4`}
                      >
                        <Form.Label className="input-label no-asterisk no-asterisk">
                          {_l("l_company_name")}
                        </Form.Label>
                        <div className="c-font f-14 mailto:title-fonts">
                          <input
                            className="form-control  c-font f-14 mailto:title-fonts"
                            type="text"
                            name="company"
                            value={userProfileData.company}
                            onChange={updateProfileInputFields}
                          />
                        </div>
                      </Form.Group>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                      <Form.Label className="input-label ">
                        {_l("l_first_name")}
                      </Form.Label>
                      <input
                        className="form-control "
                        placeholder="Paris"
                        type="text"
                        name="firstname"
                        value={userProfileData.firstname}
                        onChange={updateProfileInputFields}
                      />
                    </Form.Group>
                    <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                      <Form.Label className="input-label ">
                        {_l("l_last_name")}
                      </Form.Label>
                      <input
                        className="form-control "
                        placeholder="Paris"
                        type="text"
                        name="lastname"
                        value={userProfileData.lastname}
                        onChange={updateProfileInputFields}
                      />
                    </Form.Group>
                    <Form.Group className="col-xl-6 c-input-box position-relative pb-4 select-half-separator z-index-3">
                      <Form.Label className="input-label ">
                        {_l("l_mobile_number")}
                      </Form.Label>
                  {
                   isCurrentUserAI()
                    ? 
                    <Form.Group className="c-input-box pb-4 position-relative z-3">
                    <div className="d-flex align-items-center">
                      <Select
                        className="custom-select-menu menulistabs w90px"
                        classNamePrefix="react-select"
                        placeholder={"🇺🇸"}
                        closeMenuOnSelect={true}
                        value={countryCodesWithFlag.find(
                          (countryCode) =>
                            countryCode.value.startsWith(getCountryCode(userProfileData.phoneNumber ? userProfileData.phoneNumber : ""))
                        )}
                        options={countryCodes.sort((a, b) =>
                          a.label
                            .toLowerCase()
                            .localeCompare(b.label.toLowerCase())
                        )}
                        onChange={(value) => {
                          setselectedCountry(value);
                        }}
                        isDisabled={true}
                        isSearchable={true}
                      />
                      <Select
                        className="custom-select-menu w-100 border-start"
                        classNamePrefix="react-select"
                        placeholder={_l("l_select_phonenumber")}
                        closeMenuOnSelect={true}
                        value={
                          userProfileData.phoneNumber &&
                          userProfileData.phoneNumber != ""
                            ? mobileNumberArray.find(
                                (value) =>
                                  value.value ==
                                  userProfileData.phoneNumber
                              )
                            : ""
                        }
                        options={mobileOptions.filter((v) =>
                          v.value.startsWith(selectedCountry.value)
                        )}
                        onChange={(value) => {
                          setUserProfileData({...userProfileData,phoneNumber : value.value, mobile_key : mobileNumberArray.filter(
                            (n) => n.number == value.value
                          )[0].id })
                        }}
                      />
                    </div>
                  </Form.Group>
                    : 
                      <div className="row select-half-separator-main m-0 d-flex align-items-center react-tel-input-small">
                      <PhoneInput
                          inputClass="title-fonts f-12 c-font w100imp"
                          autoFormat={true}
                          containerClass="f-32"
                          disableCountryGuess={true}
                          countryCodeEditable={false}
                          disableSearchIcon={true}
                          enableSearch={true}
                          enableAreaCodes={true}
                          enableLongNumbers = {true}
                          localization={""}
                          country='fr'
                          preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                          value={userProfileData.phonenumber}
                          placeholder="780 1250 456"
                          isValid={(value, country)=>{
                              if (value.length > numberlength.max) {
                                  setUserProfileData({ ...userProfileData, phonenumber: value.slice(0,numberlength.max) });
                              } else {
                              return true;
                              }
                          }}
                          onChange={(phone, formattedValue) => {
                              setUserProfileData({ ...userProfileData, phonenumber: phone });
                              setNumberFormate(formattedValue);
                          }}
                          inputProps={{
                              autoFocus: true
                            }}
                          />
                      </div>
                  }
                    </Form.Group>
                    {userType !== "staff" && !isCurrentUserAI() ?
                    <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                      <Form.Label className="input-label no-asterisk no-asterisk">
                        {_l("l_position")}
                      </Form.Label>
                      <input
                        className="form-control  autosuggest-off"
                        autoComplete="off"
                        placeholder={_l("l_position")}
                        type="text"
                        name="position"
                        value={userProfileData.position}
                        onChange={updateProfileInputFields}
                      />
                    </Form.Group>
                    : <></> }
                    {userProfileData && userProfileData.role && userProfileData.role.value && !isCurrentUserAI() ? 
                      <Form.Group className="col-xl-6 c-input-box position-relative pb-3">  
                        <Form.Label className="input-label">
                         <span> {_l("l_role")} </span>
                        </Form.Label>
                        <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                          <Select
                            className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh h_45"
                            classNamePrefix="react-select"
                            placeholder={_l("l_select_role")}
                            hideSelectedOptions
                            options={employeeRoleList}
                            value={
                              userProfileData.role &&
                              userProfileData.role.value > 0
                                ? employeeRoleList.find(
                                    (w) => w.value == userProfileData.role.value
                                  )
                                : []
                            }
                            onChange={(e) => {
                              setUserProfileData({
                                ...userProfileData,
                                role: e,
                              });
                            }}
                            isDisabled={
                              parseInt(loginUserRole) != 1
                                ? parseInt(loginUserRole) >=
                                  parseInt(
                                    userProfileData.role &&
                                      userProfileData.role.value
                                  ) 
                                  : false
                                  || userProfileData.role.value == "4"
                            }
                          />
                        </div>
                      </Form.Group>
                      : <></> }
                      {userType !== "staff" ? 
                      isCurrentUserAI() ? <></> :
                      <Form.Group className="col-xl-6 c-input-box position-relative pb-3">
                        <Form.Label className="input-label">
                          {_l("l_space")}
                        </Form.Label>
                        <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                          {parseInt(
                            userProfileData.role && userProfileData.role.value
                          ) == 1 ? (
                            <div className="form-control  disabled color-white-60">
                              {" "}
                              {_l("all_project_access")}
                            </div>
                          ) : (
                            <Select
                              className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh max-h95"
                              classNamePrefix="react-select"
                              placeholder={
                                parseInt(
                                  userProfileData.role &&
                                    userProfileData.role.value
                                ) == 1
                                  ? _l("l_all_project_access")
                                  : _l("l_select_space_selection")
                              }
                              isMulti={userProfileData && userProfileData.role && userProfileData.role.value == 3 ? false : true}
                              options={spaceSelectionOptions}
                              value={spaceSelectionOptions.filter((s) =>
                                userProfileData.selectedSpaces.includes(
                                  parseInt(s.value)
                                )
                              )}
                              onChange={(e) => {
                                setUserProfileData({
                                  ...userProfileData,
                                  selectedSpaces: userProfileData && userProfileData.role && userProfileData.role.value == 3 && userProfileData.is_agent == 0 ? [parseInt(e.value)] : e.map((s) => parseInt(s.value)),
                                });
                              }}
                              hideSelectedOptions
                              isDisabled={
                                parseInt(loginUserRole) != 1
                                  ? parseInt(loginUserRole) >=
                                    parseInt(
                                      userProfileData.role &&
                                        userProfileData.role.value
                                    )
                                  : false
                              }
                            />
                          )}
                        </div>
                      </Form.Group>
                      : <></>}
                    {isMyprofileStep || pageName == "space-invite" ? (
                      <>
                      {isCurrentUserAI() ?
                        <Form.Group className="col-xl-6 c-input-box position-relative pb-4">
                          <Form.Label className="input-label no-asterisk ">
                          {_l('l_space_email')} 
                          </Form.Label>
                          <div className="row g-0">
                            <div className="col-7">
                              <Form.Control
                                placeholder={_l('l_employee_name_placeholder')} 
                                type="email"
                                name="email"
                                value={userProfileData.email && userProfileData.email != "" ? userProfileData.email.split('@')[0] : ""}
                                onChange={(e) => updateProfileInputFields(e, e.target.value + "@mg.myr.ai")}
                              />
                            </div>
                            <div className="col-5">
                              <span className="form-control for-disabled color-white-60">@mg.myr.ai</span>
                            </div>
                          </div>
                        </Form.Group>
                        :
                        <Form.Group
                          className={`col-xl-6 c-input-box position-relative pb-4`}
                        >
                          <Form.Label className="input-label">
                            {_l("l_email")}
                          </Form.Label>
                          <div className="c-font f-14 mailto:title-fonts">
                            <input
                              className="form-control  c-font f-14 mailto:title-fonts"
                              type="email"
                              name="email"
                              value={userProfileData.email}
                              onChange={updateProfileInputFields}
                            />
                          </div>
                        </Form.Group>
                        }
                        {!isCurrentUserAI() ? <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                          <Form.Label className="input-label no-asterisk ">
                            {_l("l_password")}
                          </Form.Label>
                          <Form.Control
                            type="password"
                            className="form-control "
                            placeholder="****"
                            value={
                              userProfileData && userProfileData.password
                                ? userProfileData.password
                                : ""
                            }
                            onChange={(e) => {
                              if (pageName !== "space-invite") {
                              }
                              setUserProfileData({
                                ...userProfileData,
                                password: e.target.value,
                                confirmPassword: e.target.value,
                              });
                            }}
                            autocomplete="off"
                          />
                        </Form.Group>
                        :<React.Fragment></React.Fragment>}
                        <Form.Group
                          className="col-xl-6 c-input-box pb-3 position-relative d-none"
                          autoComplete="off"
                        >
                          <Form.Label className="input-label no-asterisk ">
                            {_l("l_confirm_password")}
                          </Form.Label>
                          <Form.Control
                            type="password"
                            className="form-control "
                            placeholder="****"
                            value={
                              userProfileData && userProfileData.confirmPassword
                                ? userProfileData.confirmPassword
                                : ""
                            }
                            onChange={(e) => {
                              if (pageName !== "space-invite") {
                              }
                              setUserProfileData({
                                ...userProfileData,
                                confirmPassword: e.target.value,
                              });
                            }}
                            autoComplete="off"
                          />
                        </Form.Group>
                            {userType && userType === "staff" ?
                              <>
                              <Form.Group class="col-xl-6 c-input-box mb-4 position-relative">
                                <Form.Label className="input-label no-asterisk opacity-0">
                                  {_l("l_confirm_password")}
                                </Form.Label>
                                <div class="align-items-center bg-white-03 d-flex p-15 radius_3 justify-content-between">
                                  <div> {_l('l_is_admin')}</div>
                                  <div class="">
                                    <input className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      checked={userProfileData.admin == "1" ? true : false}
                                      disabled={admin === "true" ? false : true}
                                      onChange={() => {
                                        setUserProfileData({
                                          ...userProfileData,
                                          admin: userProfileData.admin == "1" ? "0" : "1"
                                        });
                                      }} />
                                  </div>
                                </div>
                              </Form.Group>
                              </>
                            : <></>}
                      </>
                    ) : (
                      <></>
                    )}
                    {
                      isCurrentUserAI()
                        ? 
                        <>
                        <Form.Group className="col-xl-6 c-input-box pb-4 position-relative z-3">
                      <Form.Label className="input-label">
                        {_l("l_language_selection")}
                      </Form.Label>
                      <div className="d-flex flex-grow-1 align-items-center">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder={_l("l_language_placeholder")}
                          closeMenuOnSelect={true}
                          options={languageOptions}
                          value={languageOptions.find(
                            (l) => l.value == userProfileData.language
                          )}
                          onChange={(value) => {
                            setUserProfileData({...userProfileData, language : value.value});
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-xl-6 c-input-box pb-4 position-relative z-3">
                      <Form.Label className="input-label">
                        {_l("l_voice_selection")}
                      </Form.Label>
                      <div className="d-flex flex-grow-1 align-items-center">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder={_l("l_voice_placeholder")}
                          closeMenuOnSelect={true}
                          options={genderOption}
                          value={genderOption.find(
                            (l) => l.value == userProfileData.gender
                          )}
                          onChange={(value) => {
                            setUserProfileData({...userProfileData, gender : value.value});
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-xl-6 c-input-box position-relative pb-3 d-none">  
                        <Form.Label className="input-label">
                         <span> {_l("l_role")} </span>
                        </Form.Label>
                        <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                          <Select
                            className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh h_45"
                            classNamePrefix="react-select"
                            placeholder={_l("l_select_role")}
                            hideSelectedOptions
                            options={employeeRoleList.filter(role => role.value != 4)}
                            value={
                              userProfileData.assistant_role &&
                              userProfileData.assistant_role.value > 0
                                ? employeeRoleList.find(
                                    (w) => w.value == userProfileData.assistant_role.value
                                  )
                                : []
                            }
                            onChange={(e) => {
                              setUserProfileData({
                                ...userProfileData,
                                assistant_role: e,
                              });
                            }}
                          />
                        </div>
                      </Form.Group>
                        </>
                        : <React.Fragment></React.Fragment>
                    }
                  </div>
                </div>
              </div>
              </>
            ) : selectedSection === "upload_document" ? (
              <>
                <Row className="bgOfficelisting lightthemebgwhite p-20 pb-0">
                  {imagePreviews &&
                    Object.keys(imagePreviews).map((inputName) => {
                      if(userType !== "operator" && inputName === "l_skill_certificate_upload")
                      {
                        return <></>;
                      }
                      return (
                        <Col xs={6} className="mb-20">
                          <Form.Label className="input-label form-label">{_l(inputName)}</Form.Label>
                          <div className="d-flex flex-column">
                            {
                              isReadOnly
                              ?
                              <React.Fragment></React.Fragment>
                              :
                              <div
                                className="form-control  file-upload with-bg position-relative p-0 h-100 text-center"
                              >
                                <div className="d-flex align-items-center justify-content-center p-15 flex-column">
                                  <input
                                    className="z-index-3 hidden-input m-auto"
                                    type="file"
                                    multiple
                                    accept="image/*,video/*,.pdf"
                                    onInput={(e) => {
                                      setImagePreviews({...imagePreviews, [inputName]: {key: getUploadKeyName[inputName], files: e.target.files, previewArray: Array.from(e.target.files).map((w) => {return {...w, name: w.name, url: URL.createObjectURL(w)}})}});
                                    }}
                                    disabled={inputName == "l_company_id_upload" && userProfileData.is_agent == 1 ? true : false}
                                  />
                                  <div className="m-auto">
                                    <CloudArrowUpIcon
                                      className="HW30 opacity-25 "
                                    />
                                    <div className="color-white-60 mt-10">
                                      {_l("l_click_to_upload")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          <div className="d-flex g-3 row flex-wrap pt-20px">
                            {imagePreviews[inputName].previewArray && imagePreviews[inputName].previewArray.filter((w) => w.url).length ? 
                              imagePreviews[inputName].previewArray.map(
                                (x, index) => {
                                  if(userProfileData.is_agent == 1 && inputName == "l_company_id_upload"  ){
                                    let preview = {};
                                    return(<>
                                    { x.url.map((w, i)=>{              
                                      preview.url = getPreviewFromURL(w) 
                                      return (
                                        <div
                                        className="upload-image-preview cursor-pointer col-auto"
                                        key={index}
                                        >
                                        <div
                                          className="comman-image-box h50w50 radius_3 upload-image with-border active"
                                        >
                                          {(
                                            <div
                                              className="h-100 w-100 d-flex cursor-pointer"
                                              onClick={() => {
                                                setOpenGallery(true);
                                                let src = [{
                                                  src : w,
                                                  type: w.type ||getFileType(w),
                                                }]
                                                let otherImg = []
                                                x.url.filter((data, index1)=>index1 !=  i).map(a =>{
                                                  otherImg.push({
                                                    src : a,
                                                    type: a.type ||
                                                          getFileType(a),
                                                  })
                                                })
                                                setLightboxInputArray(src.concat(otherImg)); 
                                              }}
                                              style={{
                                                alignContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <preview.url
                                                size={72}
                                                weight="light"
                                                className="c-icons"
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className="image-text d-flex flex-column justify-content-center d-none">
                                          <div className="text-truncate c-font f-13">
                                            {x.name}{" "}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                    }
                                    </>)
                                  }else{
                                    let preview = {};
                                    preview.url = getPreviewFromURL(
                                      x.name || x.url
                                    );
                                    return (
                                      <div
                                        className="upload-image-preview cursor-pointer col-auto"
                                        key={index}
                                      >
                                        <div
                                          className="comman-image-box h50w50 radius_3 upload-image with-border active"
                                        >
                                          {typeof preview.url === "string" ? (
                                            <div
                                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                                              onClick={() => {
                                                setOpenGallery(true);
                                                setLightboxInputArray(
                                                  imagePreviews[
                                                    inputName
                                                  ].previewArray.map((w) => {
                                                    return {
                                                      src: w.url,
                                                      type:
                                                        w.type ||
                                                        getFileType(w.url) ||
                                                        getFileType(w.name),
                                                    };
                                                  })
                                                );
                                              }}
                                              style={{
                                                backgroundImage: `url(${x.url})`,
                                              }}
                                            ></div>
                                          ) : (
                                            <div
                                              className="h-100 w-100 d-flex cursor-pointer"
                                              onClick={() => {
                                                setOpenGallery(true);
                                                setLightboxInputArray(
                                                  imagePreviews[
                                                    inputName
                                                  ].previewArray.map((w) => {
                                                    return {
                                                      src: w.url,
                                                      type:
                                                        w.type ||
                                                        getFileType(w.url) ||
                                                        getFileType(w.name),
                                                    };
                                                  })
                                                );
                                              }}
                                              style={{
                                                alignContent: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <preview.url
                                                size={72}
                                                weight="light"
                                                className="c-icons h30w30"
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className="image-text d-flex flex-column justify-content-center d-none">
                                          <div className="text-truncate c-font f-13">
                                            {x.name}{" "}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              )
                              :
                               <>
                               </>
                            }
                          </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </>
            ) : (
              <>
                {equipmentModal || selectedSection === "availablity_setup" ? (
                  <SetupAvilability
                    ref={availabilityRef}
                    handleClose={() => {
                      setEquipmentModal(false);
                    }}
                    show={
                      equipmentModal || selectedSection === "availablity_setup"
                    }
                    userId={userId}
                    userProfileData={userProfileData}
                    withinModal={true}
                    isReadOnly={isReadOnly}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        <OnboardingFooter
          backButtonText={backButtonText}
          hideBackButton={!backButtonText}
          backBtnHandler={navigateToPrevPage}
          nextBtnHandler={updateProfileData}
          nextButtonText={nextButtonText}
          loadingNextStep={loadingNextStep}
        />
      </div>
      {openGallery && lightboxInputArray.length ? (
        <DocumentPreview
          open={openGallery}
          setOpen={setOpenGallery}
          slides={lightboxInputArray}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
export default UserProfile;
