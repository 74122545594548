import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import HeaderWithInput from "./CommonComponents/HeaderWithInput";
import Select from "react-select";
import { _l, showError } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmation, toastCloseAction } from "../../../actions/messages";
import ListWithAction from "./CommonComponents/ListWithAction";
import SetupScreen from "./CommonComponents/SetupScreen";
import { Form } from "react-bootstrap";
import EquipmentsServices from "../../../services/equipments-services";
import { useSearchParams } from "react-router-dom";
import CloudArrowUpIcon from "../../../assets/icons/CloudArrowUpIcon";
import MinusIcon from "../../../assets/icons/MinusIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import XIcon from "../../../assets/icons/XIcon";
 

const EquipmentDetail = forwardRef(
  (
    {
      addNewEquipmentItem,
      assignToContact,
      assignToProvider,
      deleteEquipmentItem,
      editEquipmentTitle,
      equipmentData,
      setShowSetupScreen,
      showSetupScreen,
      setEquipmentData,
      isConsumableRef,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const topics = useSelector((state) => state.customer.defaultTopics);
    const equipmentTitleRef = useRef();

    const [searchParams, setSearchParams] = useSearchParams();

    const userType = localStorage.getItem("user_type");

    const [setupScreenDetails, setSetupScreenDetails] = useState({});
    const [equipmentQuantityData, setEquipmentQuantityData] = useState({
      assignee_id: 0,
      deviceId: "",
      itemName: "",
      imageURLs: [],
      contact_assignee: 0,
      provider_assignee: 0,
    });
    const [imagePreviews, setImagePreviews] = useState([]);

    let operatorData = topics
      ? topics.find((w) => w.main_key === "my_operator")
      : [];
    operatorData = operatorData && operatorData.categories
      ? operatorData.categories.filter((w) => w.main_sub_key !== "team").map((w) => {
          return { label: w.title, value: w.filter_key };
        })
      : [];

    let contactData = topics
      ? topics.find((w) => w.main_key === "my_contact")
      : [];
    contactData = contactData && contactData.categories
      ? contactData.categories.filter((w) => w.main_sub_key !== "team").map((w) => {
          return { label: w.title, value: w.filter_key };
        })
      : [];

    const updateEquipmentQuantity = (quantity) => {
      if (quantity > 0) {
        if (equipmentData.quantity < quantity) {
          addNewEquipmentItem(quantity);
        } else {
          dispatch(
            showConfirmation(
              _l("l_are_you_sure"),
              _l("l_delete_equipment_confirmation"),
              _l("l_delete"),
              _l("l_cancel"),
              () => {},
              () => {
                dispatch(toastCloseAction());
                deleteEquipmentItem();
              }
            )
          );
        }
      }
    };

    const getUpdatedEquipmentQuantityDetails = () => {
      return { ...equipmentQuantityData, id: setupScreenDetails.id };
    };

    const handleCloseSetupScreen = () => {
      dispatch(toastCloseAction());
      setSetupScreenDetails({});
      setEquipmentQuantityData({
        assignee_id: 0,
        deviceId: "",
        imageURLs: [],
        itemName: "",
        contact_assignee: 0,
        provider_assignee: 0,
      });
      setShowSetupScreen(false);
    };

    const removeAttachment = async (id) => {
      try {
        const response = await EquipmentsServices.removeEquipmentQuantityImage(
          equipmentData.project_id,
          equipmentData.id,
          setupScreenDetails.id,
          id
        );
        if (response.status) {
          setImagePreviews(imagePreviews.filter((w) => w.id != id));
        }
      } catch (e) {
        console.error(e);
      }
    };

    const uploadFile = async (e) => {
      try {
        const response = await EquipmentsServices.uploadEquipmentImages(
          equipmentData.project_id,
          equipmentData.id,
          setupScreenDetails.id,
          e.target.files
        );
        if (response.status) {
          setImagePreviews(
            imagePreviews.concat(
              response.data.map((file) => {
                return {
                  url: file.image_url,
                  id: file.file_id,
                };
              })
            )
          );
        } else {
          showError(response.message);
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      }
    };

    const setEquipmentQuantityInfo = (row) => {
      setSetupScreenDetails(row);
      setShowSetupScreen(true);
      setImagePreviews(
        row.attachments.filter((w) => w.file_id > 0).map((file) => {
          return {
            id: file.file_id,
            url: file.image_url,
          };
        })
      );
      setEquipmentQuantityData({
        ...equipmentQuantityData,
        itemName: row.quantity_name,
        deviceId: row.device_id,
        assignee_id: row.contact_assignee || row.provider_assignee,
        contact_assignee: row.contact_assignee,
        provider_assignee: row.provider_assignee
      });
    };
  
    const inputJSXForHeader = [
      {
        label: "l_quantity",
        jsxElement: (
          <React.Fragment>
            <a
              className={`c-font f-22 line-height-0 h32w32 with_bg action_icon`}
              onClick={() => {
                updateEquipmentQuantity(equipmentData.quantity - 1);
              }}
            >
              <MinusIcon className="HW18" />
            </a>
            <input
              type="number"
              className="bg-transparent border-0 text-center line-height-1"
              name=""
              value={equipmentData.quantity}
              disabled
            />
            <a
              className="c-font f-22 line-height-0 h32w32 with_bg action_icon"
              onClick={() => {
                updateEquipmentQuantity(equipmentData.quantity + 1);
              }}
            >
              <PlusIcon className="HW18" />
            </a>
          </React.Fragment>
        ),
        noBorder: false,
      },
      {
        label: "l_manager_assignee",
        jsxElement: (
          <Select
            placeholder={_l("l_manager_assignee")}
            className="custom-select-menu bg-transparent w-100"
            classNamePrefix="react-select"
            options={contactData}
            onChange={assignToContact}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            value={contactData.find(
              (w) => w.value == equipmentData.contact_assignee
            )}
          />
        ),
        noBorder: true,
      },
      {
        label: "l_provider_assignee",
        jsxElement: (
          <Select
            placeholder={_l("l_provider_assignee")}
            className="custom-select-menu bg-transparent w-100"
            classNamePrefix="react-select"
            options={operatorData}
            onChange={assignToProvider}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            value={operatorData.find(
              (w) => w.value == equipmentData.provider_assignee
            )}
          />
        ),
        noBorder: true,
      },
    ];

    const EquipmentDetailsForm = (
      <Form className="">
        <Form.Group className="mb-3">
          <Form.Label className="input-label no-asterisk">
            {_l("l_name")}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={_l("l_title")}
            value={
              equipmentQuantityData.itemName || setupScreenDetails.quantity_name
            }
            onChange={(e) => {
              setEquipmentQuantityData({
                ...equipmentQuantityData,
                itemName: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="input-label no-asterisk">
            {_l("l_device_id")}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={`${_l("l_device_id")} (${_l("l_optional")})`}
            value={
              equipmentQuantityData.deviceId || setupScreenDetails.device_id
            }
            onChange={(e) => {
              setEquipmentQuantityData({
                ...equipmentQuantityData,
                deviceId: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Label className="input-label no-asterisk">
          {_l("l_equipment_image_upload")}
        </Form.Label>
        <div className="form-control file-upload with-bg position-relative p-0 flex-grow-1">
          <div className="d-flex align-items-center justify-content-between p-15">
            <input
              className="z-index-3 hidden-input"
              type="file"
              onInput={uploadFile}
              accept="image/*"
            />
            <div className="m-auto">
              <CloudArrowUpIcon className="HW18" />
              <span className="color-white-60 ps-2">
                {_l("l_click_to_upload")}
              </span>
            </div>
          </div>
        </div>
        <div className="upload-image-preview pt-2 d-flex flex-wrap">
          <div className="d-flex flex-wrap">
            {imagePreviews.map((x, index) => {
              return (
                <div className="me-2 mt-2" key={index}>
                  <div className="comman-image-box h40w40 radius_3 upload-image with-margin">
                    <div
                      className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                      style={{ backgroundImage: `url('${x.url}')` }}
                    ></div>
                    <a href="#/"
                      className="upload-close"
                      onClick={() => {
                        removeAttachment(x.id);
                      }}
                    >
                      <XIcon className="HW10" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Form.Group className="my-3">
          <Form.Label className="input-label no-asterisk">
            {userType === "operator"
              ? _l("l_provider_assignee")
              : _l("l_contact_assignee")}
          </Form.Label>
          <Select
            placeholder={
              userType === "operator"
                ? _l("l_provider_assignee")
                : _l("l_contact_assignee")
            }
            className="custom-select-menu text-truncate m-0"
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            menuPlacement="top"
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            options={userType === "operator" ? operatorData : contactData}
            value={userType === "operator" ? operatorData.find((w) => w.value == equipmentQuantityData.contact_assignee) : contactData.find((w) => w.value == equipmentQuantityData.contact_assignee)}
            onChange={(e) => {
              setEquipmentQuantityData({
                ...equipmentQuantityData,
                contact_assignee: userType === "contact" ? e.value : 0,
                provider_assignee: userType === "operator" ? e.value : 0,
                assignee_id: e.value
              });
            }}
          />
        </Form.Group>
      </Form>
    );

    useImperativeHandle(ref, () => ({
      getUpdatedEquipmentQuantityDetails: getUpdatedEquipmentQuantityDetails,
    }));

    useEffect(() => {
      const itemId = searchParams.get("item");
      if(!isNaN(itemId) && Number(itemId) > 0)
      {
        searchParams.delete("item");
        setSearchParams(searchParams);
        const equipment = equipmentData && equipmentData.equipmentItems && equipmentData.equipmentItems.find((w) => w.id == itemId);
        setEquipmentQuantityInfo({...equipment, label: equipment.quantity_name,
          value: equipment.id,
          btnText: "l_setup",});
      }
    }, []);
    
    return (
      <React.Fragment>
        <HeaderWithInput inputs={inputJSXForHeader} />
        {setupScreenDetails.value > 0 && showSetupScreen ? (
          <SetupScreen
            headerText={setupScreenDetails.label}
            leftListingData={[]}
            rightPortionJSX={EquipmentDetailsForm}
            handleClose={handleCloseSetupScreen}
            handleDelete={() => {
              deleteEquipmentItem(setupScreenDetails.id);
              setSetupScreenDetails({});
            }}
          />
        ) : (
          <React.Fragment>
            <div className="row mt-0 g-4 position-sticky bg-white-03-solid z-index-2" style={{top: "110px"}}>
              <div className={`${Number(equipmentData.is_consumable)  > 0 ? "col-xl-4" : "col-xl-6"} c-input-box pb-3 position-relative m-0`}>
              <Form.Label className="input-label no-asterisk">
              {_l("l_name")}
              </Form.Label>
              
                <div className="form-control p-0 d-flex align-items-center">
                  <input
                    className="form-control border-0 bg-transparent c-box-shadow-box shadow-none"
                    placeholder={_l("l_equipment_name")}
                    type="text"
                    name=""
                    defaultValue={equipmentData.title}
                    ref={equipmentTitleRef}
                    onBlur={(e) =>
                      e.target.value
                        ? editEquipmentTitle(e.target.value)
                        : (equipmentTitleRef.current.value =
                            equipmentData.title)
                    }
                  />
                </div>
              </div>
              <div className={` ${Number(equipmentData.is_consumable) > 0 ? "col-xl-4" : "col-xl-6"} c-input-box pb-3 position-relative m-0`}>
              <Form.Label className="input-label no-asterisk opacity-0">
              {_l("l_qty")}
              </Form.Label>
              <div className="form-control p-0 d-flex align-items-center">
                      <Form.Check 
                        type="checkbox" 
                        className="m-auto plan-main" 
                        ref={isConsumableRef}
                        checked={Number(equipmentData.is_consumable)} 
                        onChange={() => {
                          editEquipmentTitle('',true);
                          }}/>
                      <span className="form-control border-0 bg-transparent c-box-shadow-box shadow-none">Is Consumable</span> 
                </div>
              </div>
             {Number(equipmentData.is_consumable)  ? <> <div className="col-xl-4 c-input-box pb-3 position-relative m-0">
              <Form.Label className="input-label no-asterisk">
              {_l("l_minimum_quantity")}
              </Form.Label>
            
                <div className="form-control p-0 d-flex align-items-center">
                  <input
                    className="form-control border-0 bg-transparent c-box-shadow-box shadow-none"
                    placeholder="25"
                    type="number"
                    name=""
                    value={equipmentData.minimum_quantity ? equipmentData.minimum_quantity : ""}
                    onChange={(e)=>{
                      setEquipmentData({...equipmentData, minimum_quantity : e.target.value});
                    }}
                    onBlur={(e)=>{
                      editEquipmentTitle("", Number(equipmentData.is_consumable) > 0)
                    }}
                  />
                </div>
              </div>
             </> : <></>}
              </div>
            <ListWithAction
              dataList={equipmentData && equipmentData.equipmentItems && equipmentData.equipmentItems.map((item) => {
                return {
                  ...item,
                  label: item.quantity_name,
                  value: item.id,
                  btnText: "l_setup",
                };
              })}
              onClick={setEquipmentQuantityInfo}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
);

export default EquipmentDetail;
