import React, { useState, useEffect } from "react";
import { _l, showError } from "../hooks/utilities";
import CustomerServices from "./../services/customer-services";
import { showMessage } from "../actions/messages";
import { useDispatch } from "react-redux";
import UserProfile from "./SpaceOnBoarding/UserProfile";
import { useSearchParams } from "react-router-dom";
import vapiService from "../services/vapi-service";
let empolyeesDetail = {
  admin: '0',
  firstName: "",
  lastName: "",
  role: [],
  position: "",
  email: "",
  countryCode: "",
  password: "",
  confirmPassword: "",
  selectedSpaces: [],
};
  const MyProfileSteps = ({ pageName, selectedSection, setContactId, setPushNotificationPreference, setEmailNotificationPreference, isReadOnly,   setAssistantData=()=>{} , assistantData = {}, selectedTopicName= '' 
}) => {
  const staff_id = localStorage.getItem("staff_id");
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let userId = searchParams.get("user");
  const [countryCodes, setCountryCodes] = useState([]);
  const [empolyeesDetails, setEmployeesDetails] = useState(Object.keys(assistantData).length > 0 ? assistantData : empolyeesDetail);
  const [employeeRoleList, setEmployeeRoleList] = useState([])
  useEffect(() => {
    let tempEmployeeRoleList = []
    CustomerServices.getCountryCodes(true).then((res) => {
      if (res.status == "1") {
        setCountryCodes(
          res.data.map((code) => {
            return {
              value: code.code_value,
              label: code.code_value,
            };
          })
        );
      }
    });
    const getAssistantProfileDetails = async() =>{
      try {
        let res = await vapiService.getAssistantProfileData()
        if (res && res.status) {
          setAssistantData({...empolyeesDetail,
            firstname: res.data.firstname,
            firstName: res.data.firstname,
            lastname: res.data.lastname,
            lastName: res.data.lastname,
            profile_image_url: res.data.profile_image,
            email: res.data.email,
            phoneNumber: res.data.phone_number,
            selectedSpaces: res.data.project_ids.map((s)=> Number(s)),
            is_profile_image_exist: res.data.profile_image,
            gender: res.data.gender,  
            language: res.data.language_id,
            voice: res.data.ai_voice,
            role: tempEmployeeRoleList.find(
              (employee) => employee.value == 4
            ),
            contact_role : 4,
            firstMessage: res.data.first_message,
            generalContext: res.data.system_prompt,
            selectedProviders: [
              {
                main_key: "providers",
                categories: res.data.topic_setting && res.data.topic_setting && res.data.topic_setting.filter((t) => t.main_key == "providers").length > 0 ? res.data.topic_setting.filter((t) => t.main_key == "providers")[0].categories : [],
              },
            ],
            selectedTenets: [
              {
                main_key: "my_contact",
                categories: res.data.topic_setting && res.data.topic_setting && res.data.topic_setting.filter((t) => t.main_key == "my_contact").length > 0 ? res.data.topic_setting.filter((t) => t.main_key == "my_contact")[0].categories : [],
              },
            ],
            selectedEquipments: [
              {
                main_key: "equipments",
                categories: res.data.topic_setting && res.data.topic_setting && res.data.topic_setting.filter((t) => t.main_key == "equipments").length > 0 ? res.data.topic_setting.filter((t) => t.main_key == "equipments")[0].categories : [],
              },
            ],
            staff_id : res.data.staffid ,
            contact_id : res.data.contactid,
            mobile_key : "",
            attachments : res.data.attachments ? res.data.attachments : [],
            topics : res.data.topic_setting ? res.data.topic_setting : [],
            gender : res.data.gender ? res.data.gender : '',
            assistant_role : tempEmployeeRoleList.find(
              (employee) => employee.value == (res.data.assistant_role || 3)
            )
          })
          setEmployeesDetails({
            ...empolyeesDetail,
            firstname: res.data.firstname,
            lastname: res.data.lastname,
            profile_image_url: res.data.profile_image,
            email: res.data.email,
            phonenumber: res.data.phone_number,
            selectedSpaces: res.data.project_ids.map((s)=> Number(s)),
            is_profile_image_exist: res.data.profile_image,
            gender: res.data.gender,
            language: res.data.language_id,
            voice: res.data.ai_voice,
            firstMessage: res.data.first_message,
            generalContext: res.data.system_prompt,
            topics: res.data.topic_setting,
            role: tempEmployeeRoleList.find(
              (employee) => employee.value == 4
            ),
            contact_role : 4,
            selectedProviders: [
              {
                main_key: "providers",
                categories: [],
              },
            ],
            selectedTenets: [
              {
                main_key: "my_contact",
                categories: [],
              },
            ],
            selectedEquipments: [
              {
                main_key: "equipments",
                categories: [],
              },
            ],
            staff_id : res.data.staffid ,
            contact_id : res.data.contactid,
            mobile_key : "",
          });
        } else {
          showError("l_something_went_wrong");        
        }
      } catch (error) {
        console.log(error);
      }
    }
    const getEmployeeDataAPI = () => {
      CustomerServices.getSelectedEmployeeDetails(userId || staff_id).then(
        (res) => {
          if (res.status == 1) {
            if(setContactId)
            {
              setContactId(res.data.id);
            }
            if (res.data.role_detail.role_id == "4" || res.data.role_detail.role_id == "5") {
              tempEmployeeRoleList.push({
                value: res.data.role_detail.role_id,
                label: res.data.role_detail.role_name ? res.data.role_detail.role_name : "Employee",
              })
              setEmployeeRoleList(tempEmployeeRoleList)
            }
            setPushNotificationPreference(Number(res.data.push_notification));
            setEmailNotificationPreference(Number(res.data.email_notification_preference_type));
            if (res.data.role_detail.role_id != "4") {   
              setEmployeesDetails({
                admin : res.data.admin ? res.data.admin : 0,
                firstname: res.data.first_name,
                lastname: res.data.last_name,
                profile_image_url: res.data.profile_image,
                datecreated: res.data.datecreated,
                role: tempEmployeeRoleList.find(
                  (employee) => employee.value == res.data.role_detail.role_id
                ),
                position: res.data.title,
                email: res.data.email,
                password: "",
                confirmPassword: "",
                countryCode: res.data.countrycode ? res.data.countrycode : "+33",
                phonenumber: res.data.role == "4" ? res.data.phonenumber : res.data.countrycode ? `${res.data.countrycode}${res.data.phonenumber}`: `"+33"${res.data.phonenumber}`,
                staff_id: res.data.staff_id,
                contact_id: res.data.id,
                selectedSpaces: res.data.assigned_project_ids,
                is_profile_image_exist: res.data.is_profile_image_exist,
                urls: {
                  l_id_card_upload: { previewArray: [{ url: res.data.id_card }] },
                  l_company_id_upload: {
                    previewArray: [{ url: res.data.company_id }],
                  },
                  l_payment_upload: { previewArray: [{ url: res.data.payment }] },
                  l_insurance_upload: {
                    previewArray: [{ url: res.data.insurance }],
                  },
                  l_skill_certificate_upload: { previewArray: [{ url: res.data.skill }] }
                },
                contact_role : res.data.role_detail.role_id,
                task_score: res.data.score,
                total_tasks: res.data.total_task,
                is_agent: res.data.is_agent
              });
            }else{
              getAssistantProfileDetails()
            }
            if (res.data.openai_assistant_id) {
              localStorage.setItem("openai_assistant_id", res.data.openai_assistant_id)
            }
            if (res.data.vapi_assistant_id) {
              localStorage.setItem("vapi_assistant_id", res.data.vapi_assistant_id)
            }
          } else {
            dispatch(
              showMessage(
                "unsucess",
                _l("l_error"),
                res.message ? res.message : ""
              )
            );
            return false;
          }
        }
      );
    }
    CustomerServices.getRoles().then((res) => {
      if (res.status == 1) {
          tempEmployeeRoleList = (
            res.data.map((role) => {
              return {
                value: role.roleid,
                label: role.name,
              };
            })
          );
          getEmployeeDataAPI();
          setEmployeeRoleList(tempEmployeeRoleList);
      } else {
        dispatch(
          showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
        );
        return false;
      }
    });
  }, []);
  return (
    <>
      <div className="comman-contnet-wrapper res-border-0 row h-100 border-0 d-flex justify-content-center overflow-hidden ">
          <div className={`${selectedSection !== "" ? "pe10per h-100 pb-20pximp" : "col-xl-12 d-flex flex-column h-100" }`}>
            <UserProfile
              setOnboardingStep={() => {}}
              profileData={empolyeesDetails.contact_role == 4 ? assistantData : empolyeesDetails }
              pageName={pageName}
              setEmployeesDetails={empolyeesDetails.contact_role == 4 ? setEmployeesDetails : setAssistantData}
              isMyprofileStep={true}
              userId={userId}
              countryCodes={countryCodes}
              selectedSection={selectedSection}
              employeeRoleList={employeeRoleList}
              isReadOnly={isReadOnly}
              selectedTopicName={selectedTopicName}
            />
          </div>
      </div>
    </>
  );
};
export default MyProfileSteps;
