import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
import ClockCounterClockwiseIcon from "../../assets/icons/ClockCounterClockwiseIcon";
const OnboardingHeader = ({
  title,
  description,
  status,
  statusClass,
  titlePrefix = "",
  nextButtonText,
  backButtonText,
  backBtnHandler,
  nextBtnHandler,
  skipButtonHandler,
  hideBackButton,
  skipButtonText,
  loadingNextStep,
  loader,
  hideNextButton = false,
  alignHeaderContentLeft,
  hideSkipButton,
  showContractbtn = false,
  from = "",
  subAddContract,
  spaceRecords = [],
  isAssistant = false,
}) => {
  const [assistantID, setAssistantID] = useState()
  const vapi_assistant_id = localStorage.getItem("vapi_assistant_id") ? localStorage.getItem("vapi_assistant_id") : 0;
  const openai_assistant_id = localStorage.getItem("openai_assistant_id") ? localStorage.getItem("openai_assistant_id") : 0;
  useEffect(() => {
    if (!assistantID && isAssistant) {
      const spaces = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")) : [];
      let TempassistantID = spaces.find(item => item.vapi_assistant_id != null)
      let assistantID = TempassistantID ? TempassistantID.vapi_assistant_id : ""
      setAssistantID(assistantID)
    }
  }, [assistantID, isAssistant])
  return (
    <>
      <div className={`onboarding-header text-center p-20 position-relative ${["l_basic_details", "l_upload_documents"].includes(title) ? "px-0 pt-20pximp" : ""}`}>
        <div className="d-flex align-items-center justify-content-between">
          <div className={alignHeaderContentLeft ? "d-none " : ""}>
            <div
              className={` d-flex ${!backButtonText ? "d-none" : ""}`}
            >
              {backButtonText && !hideBackButton ? (
                <a href="#/"
                  className="border-0 btn c-font color-white f-15 title-fonts btn-sm text-nowrap ps-0"
                  onClick={backBtnHandler}
                >
                  <ArrowLeftIcon className="HW18" />
                  <span className="ms-2">{_l(backButtonText)}</span>
                </a>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
          <div className={` ${alignHeaderContentLeft ? "p-0" : "px-3" } `}>
            <div className={`c-font fw-semibold title-fonts ${alignHeaderContentLeft ? "f-14 pt-10px" : "py-2 f-20"} ${isAssistant ? "d-flex" : ""}`}>
              {`${_l(title)} ${titlePrefix}`}
              <span className={`badge rounded-pill ms-2 ${statusClass}`}>
                {status}
              </span>
              {  isAssistant ? 
              <div className="d-flex ">
                <div className="mx-4">
                    <span className="input-label  form-label">O ID :- </span>
                     <span className="fw-semibold">{openai_assistant_id}</span>
                </div>
              {assistantID ? <> <span className="input-label  form-label">V ID :-</span> 
              <span className="fw-semibold">{vapi_assistant_id}</span> </> : <></>} 
            </div> : <></>}
            </div>
            <div className={`c-font f-16 sub-header py-1 text-center ${alignHeaderContentLeft ? "d-none" : ""} `}>
              <span>{_l(description)}</span>
            </div>
          </div>
          <div className={`${from === "space-details" ? "d-flex gap-2" : ""}`}>
            {
              from === "space-details"
                ? 
                <div className="comman_action_icon">
                  <div className="action_icon with_bg h32w32"
                      data-delay-show='500'
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false">
                      <ClockCounterClockwiseIcon className="HW18" />
                      <GeneralListing
                            unControlList={true}
                            list={spaceRecords.length && spaceRecords.map((space) => {
                              return {
                                name: space.postal_address,
                              };
                            })}
                            placeHolderName={_l("l_no_previous_addresses")}
                            widthClass={"w-460"}
                          />
                  </div>
                  </div>
              : <></>
            }
            <div
              className={`d-flex ${!nextButtonText ? "d-none" : ""}`}
            >
              {hideNextButton ? (
                <></>
              ) : (
                <div className="d-flex right-side-button ms-auto align-items-center">
                  <Button
                    disabled={loadingNextStep || loader}
                    variant={"primary btn-sm"}
                    className={`text-nowrap ${!backButtonText ? "mx-auto text-nowrap" : ""}`}
                    onClick={nextBtnHandler}
                    id="footer-primary-button"
                  >
                    {loadingNextStep || loader ? (
                      <>
                       <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={` `}
                          aria-hidden="true"
                       />
                      <span className="ms-2">{_l("l_please_wait")}</span>
                      </>
                    ) : (
                      <span>
                        {_l(nextButtonText)}
                      </span>
                    )}
                  </Button>
                  {skipButtonText && !hideSkipButton ? (
                    <Button variant="secondary btn-sm"
                      className={` color-white-60  title-fonts text-nowrap ms-10px`}
                      onClick={skipButtonHandler}
                    >
                      <span className="">{_l(skipButtonText)}</span>
                    </Button>
                    ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              )}
            </div>
            {
             !["lease-setup", "connect-provider"].includes(from) && localStorage.getItem("is_registered") != 1 && skipButtonText  && !hideSkipButton ? (
              <Button variant="secondary btn-sm"
                className={` color-white-60  title-fonts mt-2 text-nowrap`}
                onClick={skipButtonHandler}
              >
                <span className="">{_l(skipButtonText)}</span>
              </Button>
              ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default OnboardingHeader;
