import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { _l, fetchMyTaskFromIDB, hideLeftPanelNew, setHomePageTasksIDB, adjustDynamicInputHeight, showError, showSuccess } from '../hooks/utilities'
import { MentionsInput, Mention } from "react-mentions";
import Form from "react-bootstrap/Form";
import { getDashboardTasksData, getDefaultTopics, setTasklistForHomePage } from '../actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import customerServices from '../services/customer-services';
import TaskCard from '../components/TaskCard';
import ActionDropdown from '../components/Dropdowns/ActionDropdown';

import { readCollectionFromDB } from '../hooks/indexed_db_helpers';
import { startCall } from '../actions/vapi';
import outlookCalendar from '../services/outlook-calendar';
import CommanOffCanvas from '../components/Offcanvas/CommanOffCanvas';
import jquery from "jquery";
import CommanPlaceholder from '../components/Placeholder/CommanPlaceholder';
import TaskListSkeleton from '../components/Skeleton/TaskListSkeleton';
import ArrowRightIcon from '../assets/icons/ArrowRightIcon';
import PhoneCallIcon from '../assets/icons/PhoneCallIcon';
import CaretDoubleDownIcon from '../assets/icons/CaretDoubleDownIcon';

const NewThemeDashboard = () => {

  const {
    taskList,
    selectedTask,
    commanoffcanvasIsOpen,
    homepageTaskList

  } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [settingOptions, setSettingOptions] = useState({ label: "l_setup_my_account", icon: "PhoneIcon" })
  const IconComponent = require("../assets/icons/" + settingOptions.icon).default;
  const profileUrl = localStorage.getItem("contact_image") ? localStorage.getItem("contact_image") : ""
  const spaces = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")) : [];
  let contact_role = localStorage.getItem("contact_role")
  const spaceState = useSelector((state) => state.customer.spaces);
  const messagesStates = useSelector((state) => state.messages);



  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [taskPinned, setTaskPinned] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [taskId, setTaskId] = useState()
  const [callUserList, setCallUserList] = useState([]);
  const [selectedTaskIdforIconToggle, setSelectedTaskIdforIconToggle] = useState(0);
  const [showDefaultIcons, setShowDefaultIcons] = useState([]);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [commonModalData, setCommonModalData] = useState();
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [task_type, setTask_type] = useState('');
  const [taskPage, setTaskPage] = useState(1);
  const [topics, setTopics] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [isSkeltonShow, setIsSkeltonShow] = useState(true);
  const [homepageTaskInput, setHomepageTaskInput] = useState("");
  const taskCheckListInputHeight = useRef();
  const [loder, setLoder] = useState(false);

  const handleClose = () => setShowReqestedTaskModal(false);
  const handleShow = () => setShowReqestedTaskModal(true);

  

  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    getMeetingLink(taskId);
    setShowTaskModal(false);
    setSelectedTaskId(taskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {

      setShowTaskModal(show);
      hideLeftPanelNew();
      let el = document.getElementById("modal-overlay-toggle");
      if (el) {
        el.classList.remove("modal-overlay")
      }
    }
  };

  const getMeetingLink = (TaskId, calendar_id = "") => {

    outlookCalendar.getMeetingLink(TaskId, 0, calendar_id).then((res) => {
      if (res && res.status == 1) {
        setMeetingUrl(res.data.meetingUrl);
      } else {
        setMeetingUrl("");
      }
    });
  };

  const handleToggleIcons = (isTaskPinned, taskAssignee, taskAssigneePills, taskPriority, comments_count, rating, setTaskCardIconToggle, taskCardIconToggle) => {
    if (taskCardIconToggle) {
      let tempDefaultIcons = []

      if (isTaskPinned) {
        tempDefaultIcons.push("pin")
      }
      if (taskAssignee.length > 0) {
        tempDefaultIcons.push("peopels")
      }
      if (taskAssigneePills.length > 0) {
        tempDefaultIcons.push("pills")
      }
      if (taskPriority == 3) {
        tempDefaultIcons.push("priority")
      }
      if (comments_count > 0) {
        tempDefaultIcons.push("comment")
      }
      if (rating && rating > 0) {
        tempDefaultIcons.push("star")
      }

      setShowDefaultIcons(tempDefaultIcons)
      setTaskCardIconToggle(false)
    } else {
      let tempDefaultIcons = ["calendar", "assigneTo", "pin", "peopels", "pills", "priority", "cube", "map", "status", "comment", "star", , "delete", "share", "call", "template"]
      setShowDefaultIcons(tempDefaultIcons)
      setTaskCardIconToggle(true)
    }

  }

  const updateAttchmentChecklistCount = (taskid, imageCount = 0) => {
    
  };

  const getData = async () => {
    const data = await readCollectionFromDB("topics", "project_id");
    setTopics(data);
  }
  

  const taskListLocalData = useMemo(() => homepageTaskList ? homepageTaskList.slice() : [], 
  
  [homepageTaskList])

  useEffect(() => {
    const checkEmailSync = async () => {
      try {
        const response = await customerServices.getMailSettings();
      } catch (e) {
        console.error(e);
      }
    };
    checkEmailSync()
    setTimeout(() => {
      setIsSkeltonShow(false);
    }, 500);
    try {
      customerServices.getDashboardTasks().then((res) => {
        if ( res && res.status) {
          dispatch(getDashboardTasksData(res));
          if (res && res.status && res.data) {
            setHomePageTasksIDB(res.data);
            dispatch(setTasklistForHomePage(res.data));
            setIsDataAvailable(false);
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  }, [])

  useEffect(() => {
    if (selectedTask) {

      setTask_type(selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task')
      setCommonModalData(selectedTask)
      setSelectedTaskId(selectedTask.task_id || selectedTask.id)
      
      if (selectedTask.is_requested == 1) {
        handleShow();
      }
      if (selectedTask.task_id || selectedTask.id) {
        setShowTaskModal(true);
      }
    } else {
      setShowTaskModal(false)
      setSelectedTaskId(0)
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
       
      });
    });

    return () => {
      jquery(document).unbind("ready");
    };
  }, [selectedTask]);

  useEffect(() => {
    getData()
    dispatch(getDefaultTopics(0, "", "", "homepage"));
  }, [JSON.stringify(spaces), JSON.stringify(spaceState)])

  useEffect(() => {
    let taskId = localStorage.getItem("externalTaskHash");
    let externalTaskDetails = localStorage.getItem("externalTaskData") ? JSON.parse(localStorage.getItem("externalTaskData")) : {}
    if (taskId && externalTaskDetails && messagesStates.idbDataLoader == false) {
      setShowTaskModal(true); 
      setCommonModalData(externalTaskDetails)
    }
  }, [messagesStates])
  
  useEffect(() => {
    fetchMyTaskFromIDB(dispatch);
  }, [])
  
  useEffect(() => {

    let ids = homepageTaskList.map(task => task.id);
    let tasks = taskList.filter(t => ids.includes(t.id));
    if (tasks.length > 0 && tasks.length == homepageTaskList.length) {
      dispatch(setTasklistForHomePage(tasks));
      setHomePageTasksIDB(tasks);
    }
  }, [JSON.stringify(taskList)])
  
  
  const CreateHomepageTasks = () => {    
    if(homepageTaskInput == "") {
      showError(_l("l_enter_task_name"));
    } else {
      setLoder(true);
      customerServices.CreateHomepageTasksFunction(
        homepageTaskInput,
      ).then((res) => {
        setLoder(false);
        if(res && res.status) {
          try {
            customerServices.getDashboardTasks().then((res) => {
              if (res.status) {
                dispatch(getDashboardTasksData(res));
                showSuccess(_l("l_task_created"))
                if (res && res.status && res.data) {
                  setHomePageTasksIDB(res.data);
                  dispatch(setTasklistForHomePage(res.data));
                  setIsDataAvailable(false);
                }
              } else {
                showError("l_something_went_wrong");
              }
            })
          } catch (error) {
            console.log(error);
            showError("l_something_went_wrong");
          }
        } else {
          showError("l_something_went_wrong");
        }
      })
      .catch(() => {
        showError("l_something_went_wrong");
      })
      .finally(() => {
        setHomepageTaskInput("");

        if (taskCheckListInputHeight.current) {
          taskCheckListInputHeight.current.style.height = "auto";
        }
      })
    }
  }

  return (
    <>
      <div className='schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column'>
        <div className='comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian   '>
          <div className={`d-flex flex-column flex-grow-1 backdroplighttheme w-800px canimation ${commanoffcanvasIsOpen ? '' : 'daily-schedule-main'}`}>
            {/* cardAnimation this class need to add on above div for transform */}
            <div className='position-sticky top-0 start-0 z-index-8 bgpatternCommanImg'>
              <div className='d-flex justify-content-between '>
                <div className='c-font f-24 fw-semibold'>Hello, {localStorage.getItem("full_name")}</div>
                <div className='d-flex align-items-center gap-2 comman_action_icon'>
                  {contact_role != 3 ? <div
                    className="comman_action_icon card-view-less-padding p-0  dropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-popper-placement="left-start"
                  >
                    <a
                      href="#/"
                      className={` action_icon h32wauto btn btn-white-05-solid  gap10 text-nowrap`}
                       
                       
                       
                       

                    >
                      {
                        settingOptions.label == "l_setup_my_account"
                          ? <></>
                          : <IconComponent size={14} className="c-icons" weight="light" />
                      }
                      <di1v className='text-uppercase text-on-hover-active-green'>{_l(settingOptions.label)}</di1v>
                    </a>
                    <ActionDropdown textUppercase="text-uppercase" widthClass="w-auto"
                      actions={[
                        {
                          actionName: _l("l_setup_my_phone"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_phone", icon: "PhoneIcon" })
                           
                           },
                          actionIcon: "PhoneIcon",
                        },
                        {
                          actionName: _l("l_setup_my_email"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_email", icon: "EnvelopeSimpleIcon" })
                           
                           },
                          actionIcon: "EnvelopeSimpleIcon",
                        },
                        {
                          actionName: _l("l_setup_my_calendar"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_calendar", icon: "CalendarBlankIcon" })
                           
                           },
                          actionIcon: "CalendarBlankIcon",
                        },
                        {
                          actionName: _l("l_setup_my_drive"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_drive", icon: "FIleXicon" })
                          
                           },
                          actionIcon: "FIleXicon",
                        },
                      ]}
                    />
                  </div> : <></>}
                  {contact_role == 3 ?
                    <Button variant="primary" className='h32wauto lh-1'
                      onClick={() => {
                        getData()
                        let assistantID = spaces.find(item => item.vapi_assistant_id != null)
                        let projectID = assistantID.project_id
                        assistantID = assistantID ? assistantID.vapi_assistant_id : ""
                        if (topics.length) {
                          const currentProjectTopics = topics.find((projectTopics) => projectTopics.project_id == projectID);
                          if (currentProjectTopics && currentProjectTopics.topics) {
                            let Assistant = currentProjectTopics.topics.find((topics) => topics.main_key == "my_contact").categories.filter((category) => category.contact_role == 4)[0]
                            localStorage.setItem("selectedTopicForCall", JSON.stringify(Assistant))
                            localStorage.setItem("assistantCallerID", assistantID);
                            localStorage.setItem('selectedOffice', projectID);
                            startCall();
                          }
                        }
                      }}
                       
                       
                       >
                      <PhoneCallIcon className="HW18" />
                      <span className="ms-10px ps-0">{_l("l_assistant_call")}</span>
                    </Button> : <></>}

                </div>
              </div>
              <div className='audiocallbg p12px my-4'>
                <div className='align-items-center btnbluebg chat-comment-box comment-input-box d-flex flex-grow-1 p-10  position-sticky top-0 start-0 z-2 lightthemebgtranspaent light-theme-border'>
                  <div className='h32w32 comman-image-box with-bg rounded-circle'>
                    <div className='comman-bg-img h-100 w-100 bg-style-cover rounded-circle' style={{ backgroundImage: `url(${profileUrl})` }}></div>
                  </div>
               
                    <textarea
                      className="line-height-1-8 max-h20vh border-0 bg-transparent p-0 pe-2 ps-3"
                      rows="1"
                      ref={taskCheckListInputHeight}
                      value={homepageTaskInput}
                      onInput={(e) => {
                        const val = e.target.value
                        setHomepageTaskInput(val);

                        adjustDynamicInputHeight(
                          taskCheckListInputHeight,
                          e
                        );
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.keyCode === 13 &&
                          !e.ctrlKey &&
                          !e.shiftKey
                        ) {
                          e.preventDefault();
                          CreateHomepageTasks(e)
                        }
                      }}
                      placeholder={`${_l("l_create_task")}`}
                    />
                  <div className={`d-flex align-items-center comman_action_icon`} >

                    <a href="#/" className={`action_icon with_bg  h32w32 on-hover-active-toggle-img`} 
                      onClick={loder ? undefined : CreateHomepageTasks}
                       >
                      {loder ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={`ms-2 mt-2`}
                          aria-hidden="true"
                        />
                      )
                      :
                      <ArrowRightIcon className="HW16" />
                      }
                    </a>

                  </div>
                </div>
              </div>
            </div>

            <div className='card-list-box accordion-body dashboardlist'>
              <div className='mb-15px'>
                <div className='bgpatternCommanImg c-font color-white-60 f-12  pb-1 position-sticky top160px z-index-8'>{_l("l_today")}</div>
                <div id="list_view" className={`schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column ${showTaskModal || showReqestedTaskModal ? "list-view-toggle w-auto" : ""
             
           }`}>
                {isSkeltonShow || ( isDataAvailable && taskListLocalData.length == 0) ? 
                <>
                 {["0","1","2"].map((s)=>{return  <TaskListSkeleton />})}
                </> :
                 taskListLocalData && taskListLocalData.length > 0 ?
                  taskListLocalData.sort((a, b) => {
                    let totalCounta = Number(a.unread_checklist_comment_count) + Number(a.unread_comment_count);
                    let totalCountb = Number(b.unread_checklist_comment_count) + Number(b.unread_comment_count);

                    // First sort by total count
                    if (totalCounta > totalCountb) {
                      return -1; // Sort a before b if totalCounta is greater
                    } else if (totalCounta < totalCountb) {
                      return 1;  // Sort b before a if totalCountb is greater
                    }

                    // If total counts are equal, sort by id
                    if (a.id > b.id) {
                      return -1; // Sort a before b if id is greater
                    } else if (a.id < b.id) {
                      return 1;  // Sort b before a if id is greater
                    }

                    return 0; // If both total counts and ids are equal, keep the order unchanged
                  }).slice(0, taskPage * 10).map((task, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          key={index}
                          className=""

                        >
                          <TaskCard
                            key={task.id}
                            task={task}
                            active={selectedTaskId == task.id}
                            project_id={task.space_project_id}
                            handler={handleSelectedTask}
                            pageName='LiteVersion'
                            taskPinned={taskPinned}
                            setTaskPinned={setTaskPinned}
                            contacts={contacts}
                            setTaskId={setTaskId}
                            setCallUserList={setCallUserList}
                            setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                            selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                            showDefaultIcons={showDefaultIcons}
                            setShowDefaultIcons={setShowDefaultIcons}
                            handleToggleIcons={handleToggleIcons}
                            isFrom="homePage"
                          />
                        </div>
                      </React.Fragment>
                    )
                  }) :
                  <CommanPlaceholder placeholderText={_l("l_no_task_found")} />
                }
                {isSkeltonShow || ( isDataAvailable && taskListLocalData.length == 0) ? 
                  <>
                  </> 
                  :
                  (taskListLocalData.length >= (taskPage - 1) * 10 + 10 && 
                  taskListLocalData.length != (taskPage - 1) * 10 + 10) ? (
                  <a href="#/"
                    className="btn btn-white-03 w-100 view-more-btn"
                    onClick={() => {
                      setTaskPage(taskPage + 1)
                    }}
                  >
                    <div className="d-flex align-item-center justify-content-center">
                      <span className=" title-fonts c-font f-12 ms-2">
                        {_l("l_view_more_btn")} (
                        {taskPage > 1
                          ? taskListLocalData.length - ((taskPage - 1) * 10 + 10)
                          : taskListLocalData.length - 10}
                        )
                      </span>
                      <CaretDoubleDownIcon className="HW12 mx-2" />
                     
                    </div>
                  </a>
                ) : (
                  <></>
                )}
           </div>
              

            
              </div>
            

            </div>
          </div>
        </div>
      </div>


      {showTaskModal ? <CommanOffCanvas
        show={showTaskModal}
        handleClose={() => {
          setShowTaskModal(false);
          handleClose();
          setCommonModalData();
          setSelectedTaskId(0);
          setTask_type('')
        }}
        pinHandler={(id, type, flag) => {
        }}
        data={commonModalData}
        docType={task_type}
        setSelectedId={setSelectedTaskId}
        selectedTaskId={selectedTaskId}
        IsKanbanView={false}
        meetingUrl={meetingUrl}
        handleParentComponentAction={(type, taskData) => {
          if (task_type == 'task') {
            if (type == 'attachmentCountUpdate') {
              updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
              return;
            } else if (type == 'handleSelectedTask') {
              handleSelectedTask(taskData.task_id, taskData.flag)
              return;
            } else if (type == 'convertedTaskAction') {
              handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
              return;
            }
          } else if (task_type == 'request-task') {

            if (type == 'convertedTaskAction') {
              handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
              return;
            }
          }

        }}
        pageName={"Liteversion"}
        contacts={contacts}
        callUserList={callUserList}
        isFullScreen={localStorage.getItem("externalTaskHash") ? true : false}
      /> : <></>
      }
    </>
  )
}

export default NewThemeDashboard