import React, { useEffect, useState } from "react";
import { _l , getDatePickerLocale, getPreviewFromURL } from "../../hooks/utilities";
import DatePicker from "react-datepicker";
import moment from "moment";
import Button from "react-bootstrap/Button";
import PremiumEstimateIconCrown from "../../assets/icons/PremiumEstimateIconCrown";
import { DropdownMenu } from 'reactstrap';
import { Form } from "react-bootstrap";
import HashIcon from "../../assets/icons/HashIcon";
import UserCircleIcon from "../../assets/icons/UserCircleIcon";
import UsersThreeIcon from "../../assets/icons/UsersThreeIcon";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
const GeneralListing = ({ list, handler, isFromCalDropDown = null, selectedIds = [], placeHolderName = '', widthClass, roundedImage = true, type, actionStausChange,unControlList=false,isHash=false,filterListForDisplay, is_metrics = false, customSort = false, currentLanguage, task_priority_user = []}) => {
  const virtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const [dropdownData, setDropdownData] = useState(list);
  const [selectedItem, setSelectedItem] = useState(selectedIds);
  const [filterList, setFilterList] = useState(filterListForDisplay);
  const [dueDate, setDueDate] = useState(new Date());
  useEffect(() => {
    if (list && list.length > 0) {  
      if(is_metrics){
      let unOrderedData = list;
      unOrderedData.sort((a, b) =>  b.is_active - a.is_active )
      setDropdownData(unOrderedData);
    } else{      
      let unOrderedData = list;
      if (!customSort) {   
      unOrderedData.sort((a, b) => {
        if (virtualSpaces) {
          if (a.id > b.id)
          return -1;
        }else if( type == "dashboadkpiSort"){
          return a.is_disabled - b.is_disabled
        }else if (!virtualSpaces){
          if (a.id < b.id)
          return -1;
        }
      });
      setDropdownData(unOrderedData);
      } else {
      setDropdownData(unOrderedData);
      }
  }
    }else{
      setDropdownData([]);
    }
  }, [list]);
  const handlekpidata = (item) =>{
    let tempFilter = selectedItem;
    let tempFilterData = filterList;
    if (tempFilter.includes(item.id)) {
      tempFilter = tempFilter.filter((filter) => filter != item.id);
      tempFilterData = tempFilterData.filter((filter) => filter.id != item.id);
    } else {
      tempFilter.push(item.id);
      tempFilterData.push(item);
    }
    setSelectedItem(tempFilter.slice());
    setFilterList(tempFilterData.slice());
  }
  const GeneralListContain = (<>
  {dropdownData && dropdownData.length ? (
    dropdownData.map((item, index) => {
      let preview = {};
      if(item.image){
        preview.url = getPreviewFromURL(item.image);
      }
      return (
        <React.Fragment key={index}>
          <li className={`${item.is_disabled == 1 ? "for-disabled opacity-60" : ""}`}
            >
            <a href="#/"
              className={`dropdown-item ${item.id && (selectedItem.includes(parseInt(item.id)) || selectedItem.includes(item.id)) ? "active with-tick" : ""}`}
              onClick={() => {
                if(type == "dashboadkpiSort"){
                  handlekpidata(item)
                }else if (isFromCalDropDown == null && handler && type !== "languageWithFlag") {
                  handler(item);
                }
              }}
            >
              <div className="d-flex align-items-center text-truncate "
             >
              {type == "languageWithFlag" ?
                <div className="c-list-icon">
                  <div className={`h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05`}>
                    <div
                      className={`comman-bg-img h-100 w-100 bg-style-cover`}
                    >{item.flag}</div>
                  </div>
                </div> : <React.Fragment></React.Fragment>}
                {item.image ? (
                  <div className="c-list-icon position-relative">
                    <div className={`h32w32 comman-round-box with-bg d-flex align-items-center bgspacelisting  ${roundedImage ? "rounded-circle" : ""}`}>
                      {item.is_primary_user == 1 ?
                      <span className="login-crown d-flex p-0 light-pill bg-none">
                        <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute loginabscrown" />
                      </span>
                      : <></> }
                      {typeof preview.url === "string" ? 
                      <div
                        className={`comman-bg-img h-100 w-100 bg-style-cover ${roundedImage ? "rounded-circle" : ""}`}
                        style={{ backgroundImage: `url('${item.image}')` }}
                      ></div>
                      : <preview.url weight="light" className="c-icons h-100 w-100" /> }
                      {Array.isArray(task_priority_user) && item.id && task_priority_user.includes(item.id) ? 
                      <div className="absoluteiconbadge withbottomright badge-custom bg-transparent border-0 d-flex light-pill">
                        <HighPriorityActiveIcon className="HW14 check_span_img active" />
                      </div>
                      : <></> }
                    </div>
                  </div>
                ) : virtualSpaces && item.id > 0 ?
                <div className={`h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle`}>
                <UsersThreeIcon
                className="HW16"
              />
              </div>
              : !virtualSpaces && item.id < 0 ?
              <div className={`h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle`}>
              <UsersThreeIcon
              className="HW16"
            />
            </div>
                :(
                  isHash ? 
                    <HashIcon className="HW16" />
                    :
                    <></>
                )}
                {item.qty ? <>
                  <span className="rounded-circle badge with-bg d-flex h20w20 p-0">
                    <span className="m-auto p-0">
                      {item.qty}
                    </span>
                  </span>
                </> : <></>}
                <div className={`ps-2 w100minus20 text_wrap ${type == "languageWithFlag" ?  'd-flex align-items-center justify-content-between' : ''}`}>
                  <div className="c-font f-14 text-truncate color-white-60">
                    {item.name || _l(item.slug)}
                  </div>
                  {type == "languageWithFlag" ? <Form.Check
                    type="switch"
                    id=""
                    label=""
                    className="with-no-label-space"
                    checked={currentLanguage == item.language}
                    onChange={() => {
                      handler(item);
                    }}
                  /> : <React.Fragment></React.Fragment>}
                </div>
              </div>
            </a>
          </li>
          <li>
            <hr className="dropdown-divider my-0" />
          </li>
        </React.Fragment>
      );
    })
  ) : type == "bulkActionDate" ? (
  <>
        <DatePicker
          className="close-toast w-100"
          locale={getDatePickerLocale()}
          placeholderText={`${_l("l_duedate")}`}
          selected={dueDate}
          onChange={(date) => setDueDate(date)}
          showTimeSelect
          dateFormat="dd/MM/yyyy"
          inline
          calendarStartDay={1}
        ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
        <Button variant="primary" size="sm" className="m-2 mb-3" onClick={() => actionStausChange(0,moment(dueDate).format("YYYY-MM-DD HH:MM:SS"),"set_due_date")}> {_l("l_save")}</Button>
  </>
  ): (
    <li>
      <div className="comman-placeholder-wrapper w-100 py-0 d-flex h-100">
        <div className="d-flex flex-column align-items-center m-auto">
          <UserCircleIcon className="HW16 small-image opacity-50" />
          <span className="color-white-80 py-2 title-fonts">
            {placeHolderName != '' ? placeHolderName : _l("l_no_assignee")}
          </span>
        </div>
      </div>
    </li>
  )}
  {type == "dashboadkpiSort" ?
    <li className="dropdown-foot">
      <div className="d-flex  align-items-center">
          <Button variant="primary" size="sm" className="" onClick={() => {handler(filterList, selectedItem)}}> {_l("l_apply")}</Button>
      </div>
    </li>
   :<></>}
  </>)
  return (
    <>
    {
      unControlList ? (
        <DropdownMenu container="body" direction="down" className={`dropdown-menu w-200 overflowscroll ${widthClass}`}>{GeneralListContain}</DropdownMenu> 
      ) : (
        <ul className={`dropdown-menu w-200 overflowscroll ${widthClass}`} aria-labelledby="imageLstAndUploadDropdown">{GeneralListContain}</ul>
      )
    }
    </>
  );
};
export default GeneralListing;
