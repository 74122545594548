import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Form, Spinner } from "react-bootstrap";
import { _l, getFileType, getPreviewFromURL, showError } from "../hooks/utilities";
import CustomerServices from "../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import customerServices from "../services/customer-services";
import { showConfirmation, showMessage, toastCloseAction } from "../actions/messages";
import DocumentPreview from "./Documents/DocumentPreview";
import MagnifyingGlassIcon from "../assets/icons/MagnifyingGlassIcon";
import PaperclipIcon from "../assets/icons/PaperclipIcon";
import UserCircleIcon from "../assets/icons/UserCircleIcon";
import WrenchIcon from "../assets/icons/WrenchIcon";
import XIcon from "../assets/icons/XIcon";
let fileUplods = [];
const AITopicContext = forwardRef(
  (
    {
      category,
      projectId,
      setCategory,
      setLoadingNextStep,
      is_general_context,
      ai_assistant_id,
      main_key,
      assistantData,
      setAssistantData = ()=>{},
    },
    ref
  ) => {
  const dispatch = useDispatch();
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const urlParams = new URLSearchParams(window.location.search);
  const assistantId = urlParams.get('user') ? urlParams.get('user') : 0;
    const [providerContextDescription, setProviderContextDescription] =
      useState("");
    const [generalContextDescription, setGeneralContextDescription] =
      useState("");
    const [firstContextDescription, setFirstContextDescription] =
      useState("");
    const [attachmentContextDescription, setAttachmentContextDescription] =
      useState("");
    const [imagePreviews, setImagePreviews] = useState([]);
    const [attachmentsPreview, setAttachmentsPreview] = useState([]);
    const [loader, setloader] = useState(false);
    const [openGallery, setOpenGallery] = useState(false);
    const [lightboxInputArray, setLightboxInputArray] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [selectedsSwichIds, setselectedsSwichIds] = useState([])
    const generalContextRef = useRef();
    const firstMessageRef = useRef();
    const proviederContextRef = useRef();
    const attachmentContextRef = useRef();
    const updateContextValue = async () => {
      const generalContextValue =
        generalContextRef && generalContextRef.current
          ? generalContextRef.current.value
          : assistantData.generalContext;
      setLoadingNextStep(true);
      try {
        let assistantDataTopics = assistantData.selectedProviders.concat(assistantData.selectedEquipments , assistantData.selectedTenets)
        let topic = [];
        let flag = false;
        let newSelectedSwichIds = [];
          assistantDataTopics && assistantDataTopics.map((item) => {
            if (item.categories && item.categories.length > 0) {
              item.categories.map((category) => {
                newSelectedSwichIds.push(category);
                if (!selectedsSwichIds.includes(category)) {
                  flag = true;
                }
              })
            }
          })
        const TotalIdsLength = ((assistantData.selectedProviders && assistantData.selectedProviders[0].categories && assistantData.selectedProviders[0].categories.length || 0) +
          (assistantData.selectedEquipments && assistantData.selectedEquipments[0].categories && assistantData.selectedEquipments[0].categories.length || 0) +
          (assistantData.selectedTenets && assistantData.selectedTenets[0].categories && assistantData.selectedTenets[0].categories.length) || 0)
        if (flag || TotalIdsLength != selectedsSwichIds.length) {
          if (assistantData.selectedProviders && assistantData.selectedProviders[0].categories && assistantData.selectedProviders[0].categories.length > 0) {
            topic = (assistantData.selectedProviders).concat(topic)
          }
          if (assistantData.selectedEquipments && assistantData.selectedEquipments[0].categories && assistantData.selectedEquipments[0].categories.length > 0) {
            topic = (assistantData.selectedEquipments).concat(topic)
          }
          if (assistantData.selectedTenets && assistantData.selectedTenets[0].categories && assistantData.selectedTenets[0].categories.length > 0) {
            topic = (assistantData.selectedTenets).concat(topic)
          }
        }
        const response = await CustomerServices.editCompanyEmployees(
          assistantData.firstname,
          assistantData.lastname,
          assistantData.email,
          assistantData.phonenumber ? assistantData.phonenumber :  assistantData.phoneNumber,
          4,
          0,
          0,
          assistantData.position,
          "",
          "",
          0,
          0,
          projectId,
          assistantData.contact_id,
          assistantData.staff_id,
          assistantData.password,
          localStorage.getItem("user_type") === "staff" ? "" :
          assistantData.selectedSpaces,
            undefined,
            undefined,
            undefined,
            undefined,
            0,
            assistantData.firstMessage,
            generalContextValue,
            topic,
            undefined,
            attachments
         // imagePreviews
       );
       if (response && response.status == 1) {
        setAttachmentsPreview(response.data.attachments ? response.data.attachments : []);
        setAttachments([]);
        setselectedsSwichIds(newSelectedSwichIds);
        setAssistantData({
          firstname: response.data.firstname,
          firstName: response.data.firstname,
          lastname: response.data.lastname,
          lastName: response.data.lastname,
          profile_image_url: response.data.profile_image,
          email: response.data.email,
          phoneNumber: response.data.phone_number,
          selectedSpaces: response.data.project_ids.map((s)=> Number(s)),
          is_profile_image_exist: response.data.profile_image,
          gender: response.data.gender,  
          language: response.data.language_id,
          voice: response.data.ai_voice,
          role: {value: "4", label: "Employee"},
          contact_role : 4,
          firstMessage: response.data.first_message,
          generalContext: response.data.system_prompt,
          selectedProviders: [
            {
              main_key: "providers",
              categories: response.data.topic_setting && response.data.topic_setting && response.data.topic_setting.filter((t) => t.main_key == "providers").length > 0 ? response.data.topic_setting.filter((t) => t.main_key == "providers")[0].categories : [],
            },
          ],
          selectedTenets: [
            {
              main_key: "my_contact",
              categories: response.data.topic_setting && response.data.topic_setting && response.data.topic_setting.filter((t) => t.main_key == "my_contact").length > 0 ? response.data.topic_setting.filter((t) => t.main_key == "my_contact")[0].categories : [],
            },
          ],
          selectedEquipments: [
            {
              main_key: "equipments",
              categories: response.data.topic_setting && response.data.topic_setting && response.data.topic_setting.filter((t) => t.main_key == "equipments").length > 0 ? response.data.topic_setting.filter((t) => t.main_key == "equipments")[0].categories : [],
            },
          ],
          staff_id : response.data.staffid ,
          contact_id : response.data.contactid,
          mobile_key : "",
          attachments : response.data.attachments ? response.data.attachments : [],
          topics : response.data.topic_setting ? response.data.topic_setting : [],
          gender : response.data.gender ? response.data.gender : '',
          assistant_role : response.data.assistant_role
        })
        dispatch(showMessage("sucess", _l("l_success"), _l(response.message)));
       }else{
        showError(_l(response.message));
       }
        setLoadingNextStep(false);
      } catch (error) {
        console.log(error)
        setLoadingNextStep(false);
      }
    };
    const toggleAIContextForProvider = async (e) => {
      try {
        const status = e.target.checked ? 1 : 0;
        const response = await CustomerServices.toggleProviderOpenAIStatus(
          category.id,
          projectId,
          status
        );
        if (response.status) {
          setCategory({ ...category, ai_context_active: status });
        }
      } catch (e) {
        console.error(e);
      }
    };
    const [topics, setTopics] = useState({
      providers: [],
      my_contact: [],
      equipments: [],
    });
    const [temptopics, setTempTopics] = useState({
      providers: [],
      my_contact: [],
      equipments: [],
    });
    const [showSearch, setShowSearch] = useState({
      providerSearch: false,
      tenetsSearch: false,
      equipmentsearch: false,
    });
    const [searchString, setsearchString] = useState({
      providerSearchString: "",
      tenetsSearchString: "",
      equipmentsearchString: "",
    });
    const preparePreview = (files) => {
      let previrewArray = [];
      Array.from(files).map((img) => {
        let preview_url = URL.createObjectURL(img);
        previrewArray.push({ name: img.name, url: preview_url });
      });
      fileUplods = files;
      setImagePreviews(previrewArray);
    };
    const removeImageSelection = (name) => {
      imagePreviews.forEach((thumbnail) => {
        URL.revokeObjectURL(thumbnail.url);
      });
      const updatedFileList = Array.from(fileUplods).filter(
        (img) => img.name != name
      );
      preparePreview(updatedFileList);
    };
    useImperativeHandle(ref, () => ({
      updateContextValue: updateContextValue,
    }));
    const fecthFile = () => {
      try {
        customerServices.getuploadAiAssistantFiles(assistantId).then((res)=>{
          if (res && res.status) {
            setAttachmentsPreview(res.data)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
    const handleSearch = (value, key) => {
      if (key == "provider") {
        if (value && value.trim() != "") {
          setTopics({
            ...topics,
            providers: topics.providers.filter((cat) =>
              cat.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())
            ),
          });
        } else {
          setTopics({ ...topics, providers: temptopics.providers.slice() });
          return;
        }
      }
      if (key == "equipment") {
        if (value && value.trim() != "") {
          setTopics({
            ...topics,
            equipments: topics.equipments.filter((cat) =>
              cat.title
                .toLocaleLowerCase()
                .includes(searchString.equipmentsearchString.toLocaleLowerCase())
            ),
          });
        } else {
          setTopics({ ...topics, equipments: temptopics.equipments.slice() });
          return;
        }
      }
      if (key == "tenets") {
        if (value && value.trim() != "") {
          setTopics({
            ...topics,
            my_contact: topics.my_contact.filter((cat) =>
              cat.title
                .toLocaleLowerCase()
                .includes(searchString.tenetsSearchString.toLocaleLowerCase())
            ),
          });
        } else {
          setTopics({ ...topics, my_contact: temptopics.my_contact.slice() });
          return;
        }
      }
    };
    const handleSwitchAction = (value, key, mKey) => {
      let ids = assistantData[key][0].categories;
      if (ids.includes(value)) {
        ids = ids.filter((id) => id != value);
      } else {
        ids.push(value);
      }
      let finalArr = [
        {
          main_key: mKey,
          categories: ids,
        },
      ];
      setAssistantData({...assistantData , [key]: finalArr})
    };
    useEffect(() => {
      setAttachmentsPreview(assistantData.attachments)
      let setkeysIds = []
      assistantData && assistantData.topics && assistantData.topics.map((item) => {
        if (item.categories && item.categories.length > 0) {
          item.categories.map((category) => { 
            setkeysIds.push(category)
          })
        }
      })
      setselectedsSwichIds(setkeysIds)
    }, [])
    const deleteFile = (file) => {
      const deleteAssitantFile = async () => {
        try {
          if (file.id) {
            customerServices.deleteAssistantFile(file.id).then((res)=>{
              if (res && res.status) {
                const updatedFileList = attachmentsPreview.filter((attachment) => attachment.id != file.id);
                setAttachmentsPreview(updatedFileList)
                dispatch(toastCloseAction());
              }else{
                dispatch(toastCloseAction());
                showError(res.message);
              }
            })
          }
          if (file.unicId) {
            dispatch(toastCloseAction());
            const updatedFileList = attachmentsPreview.filter((attachment) => attachment.unicId != file.unicId);
            const updateAttechment = attachments.filter((attachment) => attachment.unicId != file.unicId)
            setAttachmentsPreview(updatedFileList)
            setAttachments(updateAttechment)
          }
        } catch (error) {
          console.log(error)
        }
      }
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_are_you_sure_you_want_to_delete_this_document"),
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => { deleteAssitantFile() }
        )
      );
    }
  const handleAttchmentFile =(files) => {
    const AttchFile = files.map((file) => {
      file.url = URL.createObjectURL(file);
      file.unicId = Math.floor(Math.random() *5000);
      return file;
    })
    setAttachments(attachments.concat(AttchFile));
    setAttachmentsPreview(attachmentsPreview.concat(AttchFile));
  }
  const handleAllSwitch = (key, main_key, categoriesId) => {
    let switchlArr = [
      {
        main_key: main_key,
        categories: categoriesId,
      },
    ];
    setAssistantData({...assistantData , [key]: switchlArr})
  }
  useEffect(() => {
    if (defaultTopics) {
      let tempTopic = {};
      defaultTopics.forEach((topic) => {
        if (
          ["equipments", "providers", "my_contact"].includes(topic.main_key)
        ) {
          tempTopic = { ...tempTopic, [topic.main_key]: topic.categories };
        }
      });
      setTopics(tempTopic);
      setTempTopics(tempTopic);
    }
  }, [defaultTopics]);
  useEffect(() => {
    setGeneralContextDescription(assistantData.generalContext ? assistantData.generalContext : "");
    setFirstContextDescription(assistantData.firstMessage ? assistantData.firstMessage : "");
  }, [assistantData])
    return (
      <React.Fragment>
      <div className="form-wrapper-main  pb-2 ">
          <>
            <div className="pe10per">
            <div className="row bgOfficelisting p-20 pb-0 lightthemebgwhite">
              {is_general_context > 0 ? (
                <>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center pb-3">
                    <Form.Label className="input-label no-asterisk mb-0">
                      {_l("l_first_message")}
                    </Form.Label>
                    <div className="form-check form-check-inline m-0 ps-2">
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    defaultValue={firstContextDescription}
                    ref={firstMessageRef}
                    onChange={(e)=>{
                      setAssistantData({...assistantData , firstMessage : e.target.value})
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center pb-3">
                    <Form.Label className="input-label no-asterisk mb-0">
                      {_l("l_general_context")}
                    </Form.Label>
                    <div className="form-check form-check-inline m-0 ps-2">
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    defaultValue={generalContextDescription ? generalContextDescription  : assistantData.generalContext}
                    ref={generalContextRef}
                  ></Form.Control>
                </Form.Group>
                </>
              ) : (
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Label className="input-label no-asterisk mb-0">
                      {_l("l_provider_context")}
                    </Form.Label>
                    <div className="d-flex align-items-center ">
                      <label class="input-label no-asterisk no-asterisk form-label pe-0 mb-0">
                        {_l("l_off_ai_instruction")}
                      </label>
                      <div className="form-check form-check-inline m-0 pe-2">
                        <Form.Check
                          className=" with-no-label-space"
                          type="switch"
                          role="switch"
                          checked={category.ai_context_active > 0}
                          onChange={toggleAIContextForProvider}
                          disabled={category.ai_context_disabled}
                        />
                      </div>
                      <label class="input-label no-asterisk no-asterisk form-label mb-0">
                        {_l("l_on_ai_instruction")}
                      </label>
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    defaultValue={providerContextDescription}
                    ref={proviederContextRef}
                  ></Form.Control>
                </Form.Group>
              )}
              {category && category.id > -1 ? <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <div className="d-flex align-items-center pb-3">
                  <Form.Label className="input-label no-asterisk mb-0">
                    {_l("l_attachment_context")}
                  </Form.Label>
                </div>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder={`${_l("l_type_description_here")}`}
                  type="text"
                  name=""
                  defaultValue={attachmentContextDescription}
                  ref={attachmentContextRef}
                ></Form.Control>
              </Form.Group> : <></> }
              <div className="border-bottom pt-2"></div>
              {/* upload file start */}
                <div className="d-flex flex-column pb-3">
                  <Form.Group className="flex-grow-1 c-input-box  position-relative pe-0 mb-10px mt-10">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_upload_file")}
                    </Form.Label>
                    <div className="upload-image-preview cursor-pointer col-auto  mb-10px d-flex "  >
                      {
                       attachmentsPreview && attachmentsPreview.length > 0 ? 
                       <>
                       {
                        attachmentsPreview.map((img)=>{
                          let preview = {};
                          preview.url = getPreviewFromURL(img.file_name || img.name);
                          return(<>
                                 <div
                        className="comman-image-box h50w50 radius_3 upload-image position-relative  me-3 mt-10px "
                      >
                        {typeof preview.url === "string" ? (
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                          style={{
                            backgroundImage: `url(${""})`,
                          }}
                        ></div>
                      ) : (
                        <div
                          className="h-100 w-100 d-flex cursor-pointer"
                          style={{
                            alignContent: "center",
                            flexWrap: "wrap",
                          }}
                          onClick={() => {
                            setOpenGallery(true);
                            setLightboxInputArray([
                               {
                                  src: img.file_url || img.url,
                                  type: getFileType(img.file_name || img.name),
                                }]);
                          }}
                        >
                          <preview.url
                            size={40}
                            weight="light"
                            className="c-icons"
                          />
                          <span className="text-truncate c-font f-11"> {img.file_name}</span>
                        </div>
                       )}
                       <a href="#/" className="upload-close"
                            onClick={() => {
                              deleteFile(img)
                            }}
                          >
                            <XIcon className="HW14" />
                          </a>
                      </div>
                          </>)
                        })
                       }
                       </> : <>
                       </>
                       }
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="comment-input-box flex-grow-1 me-2">
                        <div
                          className="input_box_main with_file_upload"
                        >
                          <label className="upload_inputbox w-100 upload_relative form-control height-45 border-0 p-0 d-flex align-items-center ">
                            <Form.Control
                              className="hidden-input"
                              type="file"
                              onChange={(event) => { 
                                handleAttchmentFile(Array.from(event.target.files))
                              }}
                              accept=".pdf ,.txt"
                            />
                            <div className="custom_file">
                              <PaperclipIcon className="upload_icon HW18" />
                              <div className="btn btn-sm btn-white-10 border-0">
                                <span className="c-font f-10 color-white-60">
                                  {" "}
                                  { loader ? _l('l_please_wait') : _l('l_select_file') }
                                  { loader ?  <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true"/> : <></> }
                                </span>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                  <div className="upload-image-preview cursor-pointer col-auto d-flex" >
                    {imagePreviews && imagePreviews.length > 0 && imagePreviews.map((img) => {
                      let preview = {};
                      preview.url = getPreviewFromURL(img.name);
                      return (<>
                        <div
                          className="comman-image-box h50w50 radius_3 upload-image with-border active position-relative me-2"  
                        >
                          {typeof img.url === "string" ? (
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                              style={{
                                backgroundImage: `url(${img.url})`,
                              }}
                            ></div>
                          ) : (
                            <div
                              className="h-100 w-100 d-flex cursor-pointer"
                              style={{
                                alignContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <preview.url
                                size={72}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                          )}
                          <a href="#/" className="upload-close"
                            onClick={() => {
                              removeImageSelection(img.name)
                            }}
                          >
                            <XIcon className="HW14" />
                          </a>
                        </div>
                      </>)
                    })}
                  </div>
                </div>
              {/* upload file end */}
                <div className="border-bottom pt-2"></div>
                {/* topic JSON section start */}
                <div className="row pb-3 mt-10px">
                                  {/* ----------------- Providers Start ------------------ */}
                <Form.Group className="col-lg-4  c-input-box  position-relative">
                  <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
                    <div>{`${_l("l_providers")}`}</div>
                    <div className="d-flex align-items-center gap10 comman_action_icon">
                      {/* search input */}
                      {showSearch.providerSearch ? (
                        <div class="searchToggle position-relative">
                          <div class="comment-input-box togglesearchfield">
                            <input
                              type="text"
                              class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                              placeholder="Search"
                              value={searchString.providerSearchString}
                              onChange={(e) => {
                                setsearchString({
                                  ...searchString,
                                  providerSearchString: e.target.value,
                                });
                                handleSearch(e.target.value, "provider");
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* search icon start */}
                      {showSearch.providerSearch ? (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              providerSearch: false,
                            });
                            handleSearch("", "provider");
                            setsearchString({
                              ...searchString,
                              providerSearchString: "",
                            });
                          }}
                        >
                          <XIcon className="HW16" />
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              providerSearch: true,
                            });
                          }}
                        >
                          <MagnifyingGlassIcon
                            className="HW16"
                          />
                        </a>
                      )}
                      <div className="form-check form-check-inline m-0 ps-2">
                        <Form.Check
                          className="with-no-label-space"
                          type="switch"
                          role="switch"
                          checked={topics && topics.providers && assistantData.selectedProviders && assistantData.selectedProviders[0] && topics.providers.length == assistantData.selectedProviders[0].categories.length}
                          onChange={(e) => {
                            handleAllSwitch("selectedProviders","providers", topics && topics.providers && topics.providers.length > 0 && e.target.checked ? topics.providers.map(eq => eq.filter_key) : []);
                          }}
                        />
                        <label className="form-check-label p-0"></label>
                      </div>
                      {/* search icon over */}
                    </div>
                  </Form.Label>
                  <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
                    {topics && topics.providers && topics.providers.length ? (
                      topics.providers.map((provider, index) => (
                        <>
                          <li
                            className="comman-list  with-after-50 p-0"
                            key={index}
                          >
                            <a
                              href="#/"
                              className="w-100 position-relative tab_link  left-chat "
                            >
                              <div className="d-flex align-items-center">
                                <div className="c-list-icon">
                                  <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                    <WrenchIcon
                                      className="c-icons HW24"
                                    />
                                  </div>
                                </div>
                                <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                                  <div className="top-part pb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="chat-u-name text-truncate">
                                        {provider.title}
                                      </div>
                                      <div className="d-flex align-items-cente">
                                        <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                                          <div className="form-check form-check-inline m-0 ps-2">
                                            <Form.Check
                                              className="with-no-label-space"
                                              type="switch"
                                              role="switch"
                                              checked={assistantData.selectedProviders && assistantData.selectedProviders.some(
                                                (item) =>
                                                  item.categories.includes(
                                                    provider.filter_key
                                                  )
                                              )}
                                              onChange={() => {
                                                handleSwitchAction(
                                                  provider.filter_key,
                                                  "selectedProviders",
                                                  "providers"
                                                );
                                              }}
                                            />
                                            <label className="form-check-label p-0"></label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </>
                      ))
                    ) : (
                      <>
                        <CommanPlaceholder placeholderText={_l("l_no_data")} />
                      </>
                    )}
                  </ul>
                </Form.Group>
                {/* ----------------- Providers end ------------------ */}
                {/* ----------------- Tenets Start ------------------ */}
                <Form.Group className="col-lg-4  c-input-box  position-relative">
                  <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
                    <div>{`${_l("l_tenets")}`}</div>
                    <div className="d-flex align-items-center gap10 comman_action_icon">
                      {/* search input */}
                      {showSearch.tenetsSearch ? (
                        <div class="searchToggle position-relative">
                          <div class="comment-input-box togglesearchfield">
                            <input
                              type="text"
                              class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                              placeholder="Search"
                              value={searchString.tenetsSearchString}
                              onChange={(e) => {
                                setsearchString({
                                  ...searchString,
                                  tenetsSearchString: e.target.value,
                                });
                                handleSearch(e.target.value, "tenets");
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* search icon start */}
                      {showSearch.tenetsSearch ? (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              tenetsSearch: false,
                            });
                            handleSearch("", "tenets");
                            setsearchString({
                              ...searchString,
                              tenetsSearchString: "",
                            });
                          }}
                        >
                          <XIcon className="HW16" />
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              tenetsSearch: true,
                            });
                          }}
                        >
                          <MagnifyingGlassIcon
                            className="Hw16"
                          />
                        </a>
                      )}
                      <div className="form-check form-check-inline m-0 ps-2">
                        <Form.Check
                          className="with-no-label-space"
                          type="switch"
                          role="switch"
                          checked={topics && topics.my_contact && assistantData.selectedTenets && assistantData.selectedTenets[0] && topics.my_contact.length == assistantData.selectedTenets[0].categories.length}
                          onChange={(e) => {
                            handleAllSwitch("selectedTenets", "my_contact", topics && topics.my_contact && topics.my_contact.length > 0 && e.target.checked ? topics.my_contact.map(eq => eq.filter_key) : []);
                          }}
                        />
                        <label className="form-check-label p-0"></label>
                      </div>
                      {/* search icon over */}
                    </div>
                  </Form.Label>
                  <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
                    {topics &&
                    topics.my_contact &&
                    topics.my_contact.length > 0 ? (
                      topics.my_contact.map((topic, index) => (
                        <>
                          <li
                            className="comman-list  with-after-50 p-0"
                            key={index}
                          >
                            <a
                              href="#/"
                              className="w-100 position-relative tab_link  left-chat "
                            >
                              <div className="d-flex align-items-center">
                                <div className="c-list-icon">
                                  <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                    <UserCircleIcon
                                      className="c-icons HW24"
                                    />
                                  </div>
                                </div>
                                <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                                  <div className="top-part pb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="chat-u-name text-truncate">
                                        {topic.title}
                                      </div>
                                      <div className="d-flex align-items-cente">
                                        <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                                          <div className="form-check form-check-inline m-0 ps-2">
                                            <Form.Check
                                              className="with-no-label-space"
                                              type="switch"
                                              role="switch"
                                              checked={assistantData.selectedTenets && assistantData.selectedTenets.some(
                                                (item) =>
                                                  item.categories.includes(
                                                    topic.filter_key
                                                  )
                                              )}
                                              onChange={() => {
                                                handleSwitchAction(
                                                  topic.filter_key,
                                                  "selectedTenets",
                                                  "my_contact"
                                                );
                                              }}
                                            />
                                            <label className="form-check-label p-0"></label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </>
                      ))
                    ) : (
                      <>
                        <CommanPlaceholder placeholderText={_l("l_no_data")} />
                      </>
                    )}
                  </ul>
                </Form.Group>
                {/* ----------------- Tenets end ------------------ */}
                {/* ----------------- Equipments Start ------------------ */}
                <Form.Group className="col-lg-4  c-input-box  position-relative">
                  <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
                    <div>{`${_l("l_equipments")}`}</div>
                    <div className="d-flex align-items-center gap10 comman_action_icon">
                      {/* search input */}
                      {showSearch.equipmentsearch ? (
                        <div class="searchToggle position-relative">
                          <div class="comment-input-box togglesearchfield">
                            <input
                              type="text"
                              class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                              placeholder="Search"
                              value={searchString.equipmentsearchString}
                              onChange={(e) => {
                                setsearchString({
                                  ...searchString,
                                  equipmentsearchString: e.target.value,
                                });
                                handleSearch(e.target.value, "equipment");
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* search icon start */}
                      {showSearch.equipmentsearch ? (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              equipmentsearch: false,
                            });
                            handleSearch("", "equipment");
                            setsearchString({
                              ...searchString,
                              equipmentsearchString: "",
                            });
                          }}
                        >
                          <XIcon className="HW16" />
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              equipmentsearch: true,
                            });
                          }}
                        >
                          <MagnifyingGlassIcon
                            className="HW16"
                          />
                        </a>
                      )}
                      <div className="form-check form-check-inline m-0 ps-2">
                        <Form.Check
                          className="with-no-label-space"
                          type="switch"
                          role="switch"
                          checked={topics && topics.equipments && assistantData.selectedEquipments && assistantData.selectedEquipments[0] && topics.equipments.length == assistantData.selectedEquipments[0].categories.length}
                          onChange={(e) => {
                            handleAllSwitch("selectedEquipments", "equipments", topics && topics.equipments && topics.equipments.length > 0 && e.target.checked ? topics.equipments.map(eq => eq.filter_key) : []);
                          }}
                        />
                        <label className="form-check-label p-0"></label>
                      </div>
                      {/* search icon over */}
                    </div>
                  </Form.Label>
                  <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
                    {topics && topics.equipments && topics.equipments.length ? (
                      topics.equipments.map((equipment, index) => (
                        <li
                          className="comman-list  with-after-50 p-0"
                          key={index}
                        >
                          <a
                            href="#/"
                            className="w-100 position-relative tab_link  left-chat "
                          >
                            <div className="d-flex align-items-center">
                              <div className="c-list-icon">
                                <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                  <UserCircleIcon
                                    className="c-icons HW24"
                                  />
                                </div>
                              </div>
                              <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                                <div className="top-part pb-1">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="chat-u-name text-truncate">
                                      {equipment.title}
                                    </div>
                                    <div className="d-flex align-items-cente">
                                      <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                                        <div className="form-check form-check-inline m-0 ps-2">
                                          <Form.Check
                                            className="with-no-label-space"
                                            type="switch"
                                            role="switch"
                                            checked={assistantData.selectedEquipments && assistantData.selectedEquipments.some(
                                              (item) =>
                                                item.categories.includes(
                                                  equipment.filter_key
                                                )
                                            )}
                                            onChange={() => {
                                              handleSwitchAction(
                                                equipment.filter_key,
                                                "selectedEquipments",
                                                "equipments"
                                              );
                                            }}
                                          />
                                          <label className="form-check-label p-0"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      ))
                    ) : (
                      <>
                        <CommanPlaceholder placeholderText={_l("l_no_data")} />
                      </>
                    )}
                  </ul>
                </Form.Group>
                {/* ----------------- Equipments end ------------------ */}
                </div>
                {/* topic JSON section end */}
            </div>
            </div>
          </>
      </div>
      {openGallery && lightboxInputArray.length ? (
        <DocumentPreview 
          open={openGallery}
          setOpen={setOpenGallery}
          slides={lightboxInputArray}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
    );
  }
);
export default AITopicContext;
