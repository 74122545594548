import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";


const CreditNoteBasicdetails = ({ data }) => {

    const [creditNoteDetails, setCreditNoteDetails] = useState(data);
    const [address, setAddress] = useState({
      address : "",
      billing : ""
    })
    const [creditNoteItems, setCreditNoteItems] = useState([]);
    const [temsAndCo, setTemsAndCo] = useState()

    useEffect(() => {
      setCreditNoteDetails({...creditNoteDetails, data});
      setAddress({
        address : data.address,
        billing : data.billing
      })
      setCreditNoteItems(data.items);
      setTemsAndCo(data.terms)
    }, [data , JSON.stringify(data)])
 
    
  return (
    <div>
      <div className="bill-from w-100 d-flex">
        <div className="w-50">
          <div className="color-white-60  pb-2">{_l("l_bill_from")}</div>
          <span dangerouslySetInnerHTML={{ __html: address.address }}></span>
          
        </div>
        <div className="w-50">
          <div className="color-white-60  pb-2">{_l("l_bill_to")}</div>
          <b class="company-name-formatted">{ creditNoteDetails.company }</b><br></br>
          <span dangerouslySetInnerHTML={{ __html: address.billing }}></span>
        </div>
      </div>
      <div className="">
        <div className=" d-flex flex-column pt-3">
          <div className="comman-content-scroll-wrapper">
            <div className="comman-content-scroll">
              <div className="comman-data-table GeneralItemTable">
                <table className="dataTable comman-table smaller-table">
                  <thead className="bg-transparent">
                    <tr className="bg-white-03">
                      <th style={{ width: "3%" }}>#</th>
                      <th>{_l("l_item")}</th>
                      <th style={{ width: "7%" }}>{_l("l_quantity")}</th>
                      <th style={{ width: "7%" }}>{_l("l_rate")}</th>
                      <th style={{ width: "18%" }}>{_l("l_tax")} (%)</th>
                      <th style={{ width: "8%" }}>{_l("l_amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditNoteItems &&
                      creditNoteItems.length &&
                      creditNoteItems.map((i, no) => {
                        return (
                          <tr className="">
                            <td className="border-bottom align-top">
                              {no + 1}
                            </td>
                            <td className="max-width-100 text-truncate border-bottom">
                              <div className="color-white-60 pt-2 ">
                                {i.description}
                              </div>
                            </td>
                            <td className="border-bottom align-top ">
                              {i.qty}
                            </td>
                            <td className="border-bottom align-top ">
                              {i.rate}
                            </td>
                            <td className="border-bottom align-top ">
                              {i.tax_rate}
                            </td>
                            <td className="border-bottom align-top ">
                              {i.amount}
                            </td>
                          </tr>
                        );
                      })}
                    <tr className="">
                      <td colSpan={5} className="text-end border-bottom ">
                        {_l("l_sub_total")}
                      </td>
                      <td className="border-bottom  color-white-60 ">
                        {data && data.subtotal
                          ? data.subtotal
                          : 0}
                      </td>
                    </tr>
                    <tr className="">
                      <td colSpan={5} className="text-end border-bottom  ">
                        {data && data.default_tax ? data.default_tax : ""}
                      </td>
                      <td className="border-bottom  color-white-60">
                        {data && data.total_tax}
                      </td>
                    </tr>
                    <tr className="">
                      <td colSpan={5} className="text-end color-green ">
                        {_l("l_total")}
                      </td>
                      <td className="fw-semibold  color-green">
                        {data && data.total ? data.total : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="terms-condition">
          <div className="fw-semibold pb-2">{_l("l_terms_conditions")}:</div>
          <div className="color-white-60 pb-3 URLsemibold">
            <span
              dangerouslySetInnerHTML={{
                __html: temsAndCo,
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreditNoteBasicdetails;
