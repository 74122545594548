import React, { useEffect, useState } from 'react'
import { _l, getPreviewFromURL } from '../../../hooks/utilities'
import moment from 'moment'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import GeneralListing from '../../TaskModalComponents/GeneralListing'
import Lightbox from "yet-another-react-lightbox";
import ActionDropdown from '../../Dropdowns/ActionDropdown'
import CalendarBlankIcon from '../../../assets/icons/CalendarBlankIcon'
import DotsThreeVerticalIcon from '../../../assets/icons/DotsThreeVerticalIcon'
import FileDocIcon from '../../../assets/icons/FileDocIcon'
import OperatorContract from '../../Modals/OperatorContract'
import customerServices from '../../../services/customer-services'
import { useDispatch } from 'react-redux'
import { showMessage } from "../../../actions/messages";
import {getproviderDoc } from "../../../actions/customer";


const ProviderDocCard = ({data, key, openDetailLease= () => {} },) => {
    const [openCalendar, setOpenCalendar] = useState(false)
    const [openGallery, setOpenGallery] = useState(false);
    const [lightBoxAttachments, setLightBoxAttachments] = useState([])
    const [showDocumentCreate, setShowDocumentCreate] = useState(false);
    const [editdata, setEditData] = useState([]);
    const dispatch = useDispatch();

    const handleEditProviderDocs = (data) => {
        setShowDocumentCreate(true);
        setEditData(data)
    }
    const handleDeleteProviderDocs = (providerDocument) => {
        try {
            const response = customerServices.removeProviderDocument(
               providerDocument.project_id,
               providerDocument.id, 
            );
            response.then((res) => {
              if (res.status === 1) {
                 dispatch(showMessage("sucess",_l("l_success"),_l("l_provider_contract_deleted")));
                //   dispatch(getproviderDoc(true));
                //   setDocuments()
                // setproviderDocumentList(commentsList.filter((x) => x.id !== comment.id));
                // setUpdatedProviderDocumentCount(Number(commentsList.length - 1));
              } else {
                console.error("Failed to remove provider document:", res.message);
                dispatch(showMessage("unsucess", _l("l_error"), _l(response.message)));
              }
            }).catch((e) => {
              console.error("Error removing provider document:", e);
            });
        } catch (e) {
          console.error("Error in handleDeleteComment:", e);
        }
      };
      
    return (
        <>
            { showDocumentCreate ? (
                <>
                  <OperatorContract
                     show={showDocumentCreate}
                     handleClose={()=> setShowDocumentCreate(false)}
                     data ={editdata}
                    />
                </>
            ) : (
                <>
            <div className='task-list-card list-view cursor-pointer' onClick={openDetailLease} key={key}>
                <div className='task-list-wrapper'>
                    <div className='d-flex w-100 align-items-start'>
                        <div className='d-flex task-list-detail'>
                            <div className='task-left flex-grow-1 width100minus160 '>
                                <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                                    <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                        <div className='h32w32'>
                                            <div className='comman-image-box-bg bg-style-cover h-100 w-100' style={{backgroundImage:`url('https://myr.ai/orb/assets/images/default-comapny-placeholder.svg')`}}></div>
                                        </div>
                                        <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                            <div className='fw-semibold mb-1 text-truncate w-100'>{data && data.document_name}</div>
                                            <div class="c-font color-white-60 f-10 lh-lg d-flex align-items-center">
                                                <div className='pe-10px'>{_l("l_start_date")}: <span class="p-0">{moment(data && data.start_date).format("DD/MM/yy") || ''}</span></div>
                                                    -
                                                <div className='ps-10px'>{_l("l_end_date")}: <span class="p-0">{moment(data && data.end_date).format("DD/MM/yy") || ''}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex align-items-center task-bottom-part-full '>
                                <div className='align-items-center comman_action_icon d-flex  task-right-static-options'>
                                    <div className={`d-flex align-items-center comman_action_icon multi_inner_wrapper`}>
                                        {data && data.documents && data.documents.length > 0 && data.documents.map ((img , index) => { 
                                        let preview = {};
                                        preview.url = getPreviewFromURL(img.image_url);
                                        return (
                                        <>
                                            {index == 0 ? 
                                            <div className="assignee-img-with-remove me-10px" key={index}>
                                                <a 
                                                    href="#/"
                                                    className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 radius_3"
                                                    onClick={(e)=>{
                                                        e.stopPropagation();
                                                        setOpenGallery(true);
                                                        setLightBoxAttachments([img])
                                                    }}
                                                >
                                                    {typeof preview.url === "string" ? 
                                                    <div
                                                        className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                                        style={{ backgroundImage: `url('${img.image_url}')` }}
                                                    ></div>
                                                    : <preview.url weight="light" className="c-icons h-100 w-100" /> }
                                                </a>
                                            </div> 
                                            : <></>}
                                            {index == 1 && data.documents.length > 1 ?
                                            <div className="assignee-img-with-remove me-10px" key={index}>
                                                <a 
                                                    href="#/"
                                                    className="h32w32 comman-round-box with_overlay d-flex align-items-center bg-white-05 radius_3"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <div 
                                                        class="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex"
                                                        // style={{backgroundImage:`url(${data.documents && data.documents[1].image_url})`}}
                                                        type="button"
                                                        id="GeneralListing"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-auto-close="outside"
                                                        aria-expanded="false"
                                                    >
                                                        <div class="unread_count m-auto">+{data.documents.length - 1}</div> 
                                                    </div>
                                                    <GeneralListing 
                                                        handler={
                                                        (attachData)=>{
                                                            setLightBoxAttachments([attachData]);
                                                            setOpenGallery(true);
                                                        }} 
                                                        roundedImage={false} 
                                                        list={data.documents.map((generalAttach, index) => {
                                                            return {
                                                                name: generalAttach.file_name,
                                                                image: generalAttach.image_url,
                                                                id: generalAttach.id,
                                                                otherData: generalAttach
                                                            };
                                                        })} 
                                                    />
                                                </a>
                                            </div>
                                            : <></>}
                                        </>
                                        )})}
                                    </div>

                                    {data && data.provider_notice_detail && data.provider_notice_detail.length > 0 && 
                                    <div className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}>
                                        <div className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus pe-10px`}>
                                            <div className="pe-1">
                                                <div className="badge rounded-pill fw-light h32wauto c-font f-12 d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className={`p-0`}>{data && data.provider_notice_detail && data.provider_notice_detail[0] && moment(data.provider_notice_detail[0].notice_date).format("DD/MM/YYYY")}</span>
                                                </div>
                                            </div>
                                            {data && data.provider_notice_detail && data.provider_notice_detail.length > 1 ?
                                            <UncontrolledDropdown className="w-100  position-relative light-theme-blue-bg radius_5" isOpen={openCalendar}>
                                                <DropdownToggle
                                                    data-bs-toggle="dropdown"
                                                    data-bs-auto-close="outside"
                                                    aria-expanded="false"
                                                    tag="a"
                                                    className=""
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setOpenCalendar(!openCalendar);
                                                    }}
                                                >
                                                    {data.provider_notice_detail.length > 1 ? 
                                                    <div className="badge rounded-pill text-nowrap h32w32 list-toggle-view-none d-flex">
                                                        <div className="unread_count m-auto c-font f-10">
                                                            +{data.provider_notice_detail.length - 1}
                                                        </div>
                                                    </div>    
                                                    : <></> }
                                                </DropdownToggle>
                                                <React.Fragment>
                                                    <DropdownMenu container="body" direction="down" className={`dropdown-menu w-140`}>
                                                        {data && data.provider_notice_detail && data.provider_notice_detail.length > 1 && data.provider_notice_detail.filter((notice) => notice.notice_date !== "").map((notice, index) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <li className="hr_sepretr l_50px">
                                                                        <DropdownItem toggle={false} className="text-truncate">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="c-list-icon comman_action_icon">
                                                                                    <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                                                                                        <CalendarBlankIcon className="HW18" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ps-2 w100minus20 text_wrap d-flex align-items-center justify-content-between">
                                                                                    <div className="c-list-detail c-font f-14 text-truncate color-white">
                                                                                        {moment(notice.notice_date).format("DD/MM/YYYY")}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </DropdownItem>
                                                                    </li>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </DropdownMenu>
                                                </React.Fragment>
                                            </UncontrolledDropdown> 
                                            : <></>}   
                                        </div>
                                    </div>}
                                    {/* <div className="pe-10px">
                                        <div className="badge rounded-pill fw-light h32wauto c-font f-12 d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className={`p-0`}>{data.status == '1' ? _l("l_active") : data.status == '2' ? _l("l_future") : _l("l_inactive")}</span> 
                                        </div>
                                    </div> */}
                                    {/* <div className={`comman_action_icon card-view-less-padding pe-0 before-dnone`}>
                                        <div className="c-font f-12 h32wauto d-flex align-items-center fw-semibold badge radius_3">          
                                            <span className="color-green">{data.detail && data.detail.amount}</span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="dropstart comman_action_icon ml-10" onClick={(e) => e.stopPropagation()} >
                                <a href="#/" type="button" className="action_icon h32w32 with_bg with-border-active line-height-0 on-hover-active-toggle-img" id="deLeteDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <DotsThreeVerticalIcon className="HW18" />
                                </a>
                                <ActionDropdown actions={[
                                    {
                                        actionName: _l("l_edit"),
                                        actionHandler: () => { handleEditProviderDocs (data)},
                                        actionIcon: "PencilSimpleLineIcon",
                                    },
                                    {
                                        actionName: _l("l_delete"),
                                        actionHandler: () => { handleDeleteProviderDocs (data)},
                                        actionIcon: "TrashIcon",
                                    }
                                ]} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { openGallery && lightBoxAttachments && lightBoxAttachments.length > 0 && 
            <Lightbox
                open={openGallery}
                close={() => setOpenGallery(false)}
                slides={lightBoxAttachments.map ((img , index)  => {
                    return { src: img.image_url || img.image};
                })}
            />}
                </>
            )}
            
        </>
    )
}

export default ProviderDocCard