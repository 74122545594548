// apiKey: 'AIzaSyCr-kB6s621Lpnk9gLz-MMKc66i2pxcyYs',
// clientId: '223036255815-hqjunp38va03leaa91s72pf90706t3m1.apps.googleusercontent.com',

import React, { useEffect, useState } from "react";
import { loadGapiInsideDOM } from "gapi-script";
import { gapi } from "gapi-script";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import { _l } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../../actions/messages";

const GOOGLE_CALENDAR_SCOPE =
  "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";

const CalendarApp = () => {


  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const [calendarId, setCalendarId] = useState("")

   const dispatch = useDispatch(); 
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  // Load gapi script and initialize it
  useEffect(() => {
    loadGapiInsideDOM(); // Load the gapi script
  }, []);

  useEffect(() => {
    const initClient = () => {
      gapi.client
        .init({
          apiKey: "AIzaSyCr-kB6s621Lpnk9gLz-MMKc66i2pxcyYs",
          clientId:
            "223036255815-hqjunp38va03leaa91s72pf90706t3m1.apps.googleusercontent.com",
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: "https://www.googleapis.com/auth/calendar.readonly",
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
  
          // Set authentication status
          setIsAuthenticated(authInstance.isSignedIn.get());
          authInstance.isSignedIn.listen(setIsAuthenticated);
  
          // Fetch current user details after authentication
          const user = authInstance.currentUser.get();
  
          // Get user details
          const userProfile = user.getBasicProfile(); // Basic profile info
          const userEmail = userProfile.getEmail();
          const userName = userProfile.getName();
          const userImage = userProfile.getImageUrl();
  
          // Log or set user details
          setCalendarId(userEmail)
          if (userEmail !== localStorage.getItem("email")) {
            // handleSignOutClick()
          }
          
          // You can use this information as needed
          // setUserEmail(userEmail);
          // setUserName(userName);
          // setUserImage(userImage);
        })
        .catch((error) => {
          console.error("Error initializing Google API client:", error);
        });
    };
  
    gapi.load("client:auth2", initClient);
  }, []);
  

  // Handle the sign-in button click
  const handleAuthClick = () => {
    const authInstance = window.gapi.auth2.getAuthInstance();
    authInstance.signIn().then(() => {
      // After sign-in, get the access token
      const authResponse = authInstance.currentUser.get().getAuthResponse();
      const token = authResponse.access_token;
      setAccessToken(token); // Store the access token in the state
      if (token) {
        createEvent();
      }

      // Optionally, you can now make API requests using the access token.
    });
  };

  // Handle the sign-out button click
  const handleSignOutClick = () => {
    const authInstance = window.gapi.auth2.getAuthInstance();
    authInstance.signOut().then(() => {
      setAccessToken(null); // Clear the access token when signing out
    });
  };

  // Fetch calendar events from the user's primary calendar
  const fetchCalendarEvents = () => {
    if (accessToken) {
      window.gapi.client.calendar.events
        .list({
          calendarId: "primary",
          timeMin: new Date().toISOString(),
          maxResults: 10,
          singleEvents: true,
          orderBy: "startTime",
        })
        .then((response) => {
          const events = response.result.items;
        })
        .catch((error) => {
          console.error("Error fetching calendar events:", error);
        });
    } else {
      console.error("No access token available. Please sign in first.");
    }
  };

  const fetchCalendarList = async () => {
    if (!isAuthenticated) {
      return;
    }

    const response = await gapi.client.calendar.calendarList.list();
    console.log("Calendar List:", response.result.items);
  };

  const addOneDay = (date) => {
    const newDate = new Date(date); // Make a copy of the current date to avoid modifying the original
    newDate.setDate(newDate.getDate() + 1); // Add 1 day to the date
  
    return newDate; // Return the new date with the same time but 1 day later
  };

  const createEvent = async () => {
    if (!isAuthenticated) {
      return;
    }

    const event = {
      summary: selectedTask.name,
      description:
        "This event is created through the Myr,ai ",
      start: {
        dateTime: new Date(), // Start time: 20th Dec 2024, 3:45 PM IST
        timeZone: "UTC", // Indian Standard Time Zone
      },
      end: {
        dateTime: addOneDay(new Date()), // End time: 21st Dec 2024, 3:45 PM IST
        timeZone: "UTC", // Indian Standard Time Zone
      },
      creator: {
        email: localStorage.getItem("email"), // Creator Email
      },
      attendees: [
        // { email: "aakashramani1020@gmail.com" }, // Guest Email
        { email: "vaibhavkanmeriya.cci@gmail.com" }, // Guest Email
        // { email: "akash.ramani@ciphercraft.co" }, // Guest Email
      ],
    };

    try {
      const response = await gapi.client.calendar.events.insert({
        // calendarId: localStorage.getItem("email"), // Calendar ID
        calendarId: calendarId, // Calendar ID
        // calendarId: "atest9722@gmail.com", // Calendar ID
        resource: event,
      });
      dispatch(showMessage("sucess", _l("l_success"), _l("l_event_created")));
      console.log("Event created:", response.result);
    } catch (error) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_event_not_created")));
      console.error("Error creating event:", error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center text-truncate">
        <div className="c-list-icon comman_action_icon">
          <div className="h32w32 d-flex align-items-center rounded-5 action_icon with_bg">
            <CalendarIcon />
          </div>
        </div>
        <div className="ps-2 text_wrap text-start">
          <a
          href="#/"
            className={`c-list-detail c-font f-14 text-truncate text-capitalize`}
            onClick={() => {
              if (isAuthenticated) {
                createEvent();
              }else{
                handleAuthClick()
              }
            }}
          >
            {_l("l_create_event")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CalendarApp;
